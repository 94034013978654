import React from 'react';
import { CommonActions, useNavigation } from '@react-navigation/native';
import {
  Text,
  Heading,
  useTheme,
  Modal,
  Box,
  VStack,
  Checkbox,
  Flex,
  HStack,
  Divider,
  Stack,
  ScrollView,
} from 'native-base';
import Button from 'components/Button';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { formatCurrency, getPercentage } from 'utils/formats';
import { getTotalAmount } from 'utils/rides';
import { Offer, Vehicle } from 'types/trips';
import { RideStatus } from 'types/ride';
import { Platform } from 'react-native';

type ModalDeatilOfferProps = {
  toggleModal: () => void;
  isOpen: boolean;
  vehicle: Partial<Vehicle>;
  value: string;
  setValue: (value: string) => void;
  tripStatus: RideStatus;
  selectedOffer: Offer;
  halfPaymentTitle: string;
  isTimeOfferOver: boolean;
  handleToggleModal: () => void;
  handleAcceptOffer: () => void;
};

export default function ModalDetailsOffer({
  toggleModal,
  isOpen,
  vehicle,
  value,
  setValue,
  tripStatus,
  selectedOffer,
  halfPaymentTitle,
  isTimeOfferOver,
  handleToggleModal,
  handleAcceptOffer,
}: ModalDeatilOfferProps) {
  const navigation = useNavigation();
  const { colors } = useTheme();
  const primaryColors = colors.primary as typeof colors['gray'];
  const secondaryColors = colors.secondary as typeof colors['gray'];

  const getIVA = (amount: string) => {
    return Number(amount) * 0.12;
  };

  const handleAccepTerms = () => {
    navigation.dispatch(
      CommonActions.navigate({
        name: 'Terms',
      }),
    );
  };

  return (
    <Modal
      bg="rgba(46, 40, 57, 0.2)"
      isOpen={isOpen}
      size={Platform.OS === 'web' ? 'xl' : 'full'}
      onClose={toggleModal}
    >
      <Modal.Content px={4} py={8} maxHeight={600} _web={{ shadow: 'none' }}>
        <Modal.CloseButton />
        <Heading
          mb={6}
          fontSize="18px"
          color={secondaryColors[900]}
          fontWeight={400}
        >
          Detalle de la oferta
        </Heading>
        <ScrollView>
          <Flex mb={0} direction="row" justify="space-between">
            <HStack space="md">
              <MaterialCommunityIcons
                name="truck"
                size={24}
                color={colors.gray[500]}
              />
              <VStack maxW="67%">
                <Text fontSize="sm" color={colors.gray[700]} noOfLines={2}>
                  {vehicle?.vehicle_type?.name || ''}
                </Text>
                <Text fontSize="12px" color={colors.gray[700]} noOfLines={2}>
                  {vehicle?.configuration} - {vehicle?.charge} kg
                </Text>
              </VStack>
            </HStack>
            <Text fontSize="md" color={colors.gray[700]}>
              {formatCurrency(Number(selectedOffer.amount))}
            </Text>
          </Flex>
          <Divider my={4} />
          <Box pl={10}>
            <Stack>
              <HStack justifyContent="space-between" mb={4}>
                <Text color={colors.gray[600]}>Subtotal</Text>
                <Text color={colors.gray[600]}>
                  {formatCurrency(Number(selectedOffer.amount))}
                </Text>
              </HStack>
              <HStack justifyContent="space-between" mb={4}>
                <Text color={colors.gray[600]}>IVA 12%</Text>
                <Text color={colors.gray[600]}>
                  {formatCurrency(Number(getIVA(selectedOffer.amount)))}
                </Text>
              </HStack>
              <HStack justifyContent="space-between">
                <Text color={colors.gray[600]} fontWeight={500}>
                  Total
                </Text>
                <Text color={colors.gray[600]} fontWeight={500}>
                  {getTotalAmount(
                    selectedOffer.amount,
                    getIVA(selectedOffer.amount),
                  )}
                </Text>
              </HStack>
            </Stack>
          </Box>
          <Divider my={4} />
          <HStack justifyContent="space-between">
            <HStack>
              <MaterialCommunityIcons
                name="clock"
                size={24}
                color={colors.gray[500]}
              />
              <VStack ml={4}>
                <Text color={colors.gray[800]} fontWeight={500}>
                  {halfPaymentTitle}
                </Text>
              </VStack>
            </HStack>
            <Text fontSize="md" color={colors.gray[800]} fontWeight={500}>
              {getPercentage(
                selectedOffer.amount,
                getIVA(selectedOffer.amount),
              )}
            </Text>
          </HStack>
          <Box>
            <VStack my={8} justifyContent="space-between" w="100%">
              <Checkbox
                value={value}
                name="type"
                accessibilityLabel="type transport"
                onChange={(isSelected: any) => setValue(isSelected)}
              >
                <Text ml={4} maxW="90%">
                  <Text>Confirmo haber leído los </Text>
                  <Text
                    fontWeight="600"
                    color={primaryColors[600]}
                    onPress={handleAccepTerms}
                  >
                    terminos y condiciones.
                  </Text>
                </Text>
              </Checkbox>
            </VStack>
            <VStack alignItems="center">
              <Text
                fontSize="xs"
                color={colors.gray[800]}
                mb={4}
                w="2xs"
                alignSelf="center"
                textAlign="center"
              >
                Lee los términos y condiciones para continuar con el proceso de
                pago.
              </Text>
            </VStack>
            <VStack alignItems="center">
              <Text
                fontSize="xs"
                color={colors.gray[800]}
                mb={4}
                w="2xs"
                alignSelf="center"
                textAlign="center"
              >
                Al aceptar la oferta se detendrá la subasta de tu viaje y nadíe
                más podrá ofertar
              </Text>
            </VStack>
            <Button
              isDisabled={tripStatus !== 'waiting_for_offers'}
              button="primary"
              onPress={!isTimeOfferOver ? handleToggleModal : handleAcceptOffer}
            >
              Aceptar oferta
            </Button>
          </Box>
        </ScrollView>
      </Modal.Content>
    </Modal>
  );
}
