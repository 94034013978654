import { extendTheme } from 'native-base';

type Colors = ReturnType<typeof extendTheme>['colors'] | Record<string, any>;

export const colors: Partial<Colors> = {
  gray: {
    800: '#2E2839',
    700: '#52515A',
    600: '#77707B',
    500: '#959397',
    400: '#D7D4D9',
    300: '#E6E3E9',
    200: '#F6F6F9',
    100: '#FFFEFE',
  },
  primary: {
    900: '#5B0570', // Figma
    800: '#6a077a',
    700: '#780f83',
    600: '#85198d',
    500: '#912497',
    400: '#9a31a0',
    300: '#a23fa9',
    200: '#a74eb2',
    100: '#a95ebb',
  },
  secondary: {
    900: '#ffa400', // Figma
    800: '#ffa91f',
    700: '#ffad31',
    600: '#ffb23f',
    500: '#ffb74b',
    400: '#ffbb57',
    300: '#ffbf61',
    200: '#ffc46c',
    100: '#ffc876',
  },
  error: {
    900: '#e23320', // Figma
    800: '#e63927',
    700: '#e93e2d',
    600: '#ed4333',
    500: '#f14939',
    400: '#f44e40',
    300: '#f85246',
    200: '#fb574c',
    100: '#ff5c52',
  },
  success: {
    900: '#77b610', // Figma
    800: '#7cbc14',
    700: '#82c217',
    600: '#87c71a',
    500: '#8ccd1d',
    400: '#92d320',
    300: '#97d923',
    200: '#9ddf26',
    100: '#a2e529',
  },
  warning: {
    900: '#ffc804', // Figma
    800: '#ffca2a',
    700: '#ffcc3e',
    600: '#ffce4d',
    500: '#ffd05b',
    400: '#ffd268',
    300: '#ffd373',
    200: '#ffd57e',
    100: '#ffd789',
  },
};
