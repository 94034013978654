/* eslint-disable no-restricted-syntax */
import { Toast } from 'native-base';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Documents, DocumentTypes } from 'types/documents';
import { ProfileType, UserSubType } from 'types/user';
import { documentsToUpload } from 'utils/catalogs';

export const setItemStorage = async ({
  key,
  value,
}: {
  key: string;
  value: string;
}) => {
  try {
    await AsyncStorage.setItem(key, value);
  } catch (e) {
    Toast.show({
      title: '¡Ocurrió un error!. Intenta nuevamente',
      placement: 'top',
    });
  }
};

// eslint-disable-next-line consistent-return
export const getItemStorage = async (key: string) => {
  try {
    const value: any = await AsyncStorage.getItem(key);
    return value;
  } catch (e) {
    Toast.show({
      title: '¡Ocurrió un error!. Intenta nuevamente',
      placement: 'top',
    });
  }
};
export const removeItemStorage = async (key: string) => {
  try {
    await AsyncStorage.removeItem(key);
  } catch (e) {
    Toast.show({
      title: '¡Ocurrió un error!. Intenta nuevamente',
      placement: 'top',
    });
  }
};

export const flushStorage = async () => {
  try {
    await AsyncStorage.clear();
  } catch (e) {
    Toast.show({
      title: '¡Ocurrió un error!. Intenta nuevamente',
      placement: 'top',
    });
  }
};

export const removeAddressStorage = async (keys: string[]) => {
  try {
    await AsyncStorage.multiRemove(keys);
  } catch (e) {
    Toast.show({
      title: '¡Ocurrió un error!. Intenta nuevamente',
      placement: 'top',
    });
  }
};

export const validateDocuments = ({
  documents,
  userType,
  userSubType,
}: {
  documents: Documents[];
  userType: ProfileType;
  userSubType: UserSubType;
}): boolean => {
  const allDocumentsToUpload = documentsToUpload(userType, userSubType);
  const filteredDocumentsToUpload = allDocumentsToUpload
    .filter((item) => item.show)
    .reduce((acc: any, current: any): DocumentTypes[] => {
      return [...acc, ...current.type];
    }, [])
    .reduce(
      (ac, element) => ({
        ...ac,
        [element]: documents.find((doc) => doc.type === element)
          ? documents.find((doc) => doc.type === element)
          : { status: 'empty', id: '', type: element, url: '', user_id: '' },
      }),
      {} as typeof documents,
    );

  return Object.values(filteredDocumentsToUpload).every(
    (item) => item.status !== 'empty',
  );
};

export const pickBy = (object: any) => {
  const obj = {};
  for (const key in object) {
    if (object[key]) {
      obj[key] = object[key];
    }
  }
  return obj;
};
