import React from 'react';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import {
  useNavigation,
  CommonActions,
  useRoute,
} from '@react-navigation/native';
import { useAssets } from 'expo-asset';

import {
  Box,
  Heading,
  Text,
  ScrollView,
  Button,
  Icon,
  Image,
  useTheme,
} from 'native-base';
import useCommon from 'webdesign/useCommon';
import { isWebDesktop } from 'utils/general';

export default function Help() {
  const navigation = useNavigation();
  const { path } = useRoute();
  const [images] = useAssets([require('assets/images/shipping.png')]);

  const { colors } = useTheme();
  const successColors = colors.success as typeof colors['gray'];
  const primaryColors = colors.primary as typeof colors['gray'];

  const {
    containerPt,
    containerPx,
    marginLeft,
    flexAlign,
    cardBg,
    cardPx,
    cardShadow,
    cardPy,
  } = useCommon();

  const handleChat = () => {
    navigation.dispatch(
      CommonActions.navigate({
        name: isWebDesktop ? 'chat' : 'Chat',
        path: 'chat',
      }),
    );
  };

  return (
    <Box
      flex="1"
      bg="gray.200"
      _web={{
        pt: containerPt,
        px: containerPx,
        justifyContent: 'center',
        alignItems: path && flexAlign,
      }}
    >
      <Box
        flex="1"
        width="100%"
        maxWidth="650px"
        mb={4}
        _web={{
          py: cardPy,
          px: cardPx,
          borderRadius: 20,
          bg: cardBg,
          shadow: cardShadow,
          marginLeft: path ? marginLeft : 0,
        }}
      >
        <ScrollView flex="1">
          <Box flex="1" px={10} py={4}>
            <Heading fontSize="24" fontWeight="normal" mb="16px">
              ¿Necesitas ayuda?
            </Heading>
            <Text fontSize="16px" color="gray.700" mt={8}>
              Ponte en contacto con nosotros, para solucionar cualquier duda que
              tengas.
            </Text>
            <Button
              mt="80px"
              width="100%"
              rounded={30}
              height="64px"
              variant="ghost"
              bg={colors.gray[100]}
              endIcon={
                <Icon
                  mt="1"
                  ml="4"
                  mr="4"
                  size={10}
                  onPress={handleChat}
                  color={successColors[900]}
                  as={<MaterialCommunityIcons name="whatsapp" />}
                />
              }
              style={{
                shadowColor: colors.gray[500],
                shadowOpacity: 0.11,
                shadowRadius: 4,
                shadowOffset: { width: 0, height: 4 },
                elevation: 11,
              }}
            >
              Escríbemos por WhatsApp
            </Button>
          </Box>
        </ScrollView>
        <Box alignItems="center">
          {images?.map(({ uri, name }) => (
            <Image
              width="236px"
              height="116px"
              alt="logo image"
              key={name}
              source={{ uri }}
            />
          ))}
          <Box bg={primaryColors[500]} height="65px" width="100%" />
        </Box>
      </Box>
    </Box>
  );
}
