/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
import React, { useContext, useRef } from 'react';
import { Platform } from 'react-native';
import {
  Box,
  Heading,
  VStack,
  HStack,
  Toast,
  Text,
  ScrollView,
  Pressable,
  Center,
} from 'native-base';
import { AuthContext } from 'context/AuthContext';
import RideCard from 'components/RideCard';
import useTrips from 'hooks/useTrips';
import { TripProps } from 'types/trips';

export default function RidesHistory() {
  const loadMoreButtonRef = useRef(null);
  const { state: userProfile } = useContext(AuthContext);
  const userType =
    // eslint-disable-next-line no-nested-ternary
    userProfile?.type === 'driver'
      ? userProfile.sub_type === 'moral'
        ? 'moral_driver_id'
        : 'driver_id'
      : 'client_id';

  const { error, trips, hasNextPage, fetchNextPage, isFetchingNextPage } =
    useTrips({
      status: ['completed', 'cancelled'],
      [userType]: userProfile?.id,
    });

  if (error) {
    Toast.show({
      title: 'Ocurrio un error. Intenta de nuevo',
      placement: 'top',
    });
  }

  return (
    <Box flex="1" mb={4}>
      <ScrollView>
        <HStack justifyContent="space-between" alignItems="center" p={4}>
          <Heading fontSize="2xl" fontWeight="normal" color="gray.700">
            Historial de viajes
          </Heading>
        </HStack>
        {trips.map((page, index) => (
          <VStack
            space={4}
            px={4}
            key={index}
            mb={Platform.OS !== 'web' ? 4 : 0}
          >
            {page.data.map((trip: TripProps) => (
              <Box key={trip.id}>
                <RideCard key={trip.id} {...trip} />
              </Box>
            ))}
          </VStack>
        ))}
        {hasNextPage ? (
          <Pressable
            ref={loadMoreButtonRef}
            disabled={!hasNextPage || isFetchingNextPage}
            onPress={() => fetchNextPage()}
            py={3}
          >
            <Center>
              <Text
                borderBottomColor="gray.400"
                borderBottomWidth="1"
                fontWeight="medium"
              >
                Más resultados
              </Text>
            </Center>
          </Pressable>
        ) : (
          <Center py={3}>
            <Text
              borderBottomColor="gray.400"
              borderBottomWidth="1"
              fontWeight="medium"
            >
              No hay más resultados para mostrar
            </Text>
          </Center>
        )}
      </ScrollView>
    </Box>
  );
}
