import React, { useState } from 'react';
import { MaterialIcons } from '@expo/vector-icons';

import { User, UserProfileResponse, UserSubType } from 'types/user';
import ModalUpdatePassword from 'components/modals/ModalUpdatePassword';
import { Box, HStack, Text, Icon } from 'native-base';

const type: { [key in UserSubType]: 'Moral' | 'Física' } = {
  moral: 'Moral',
  physical: 'Física',
};

export default function GeneralInformation({ user }: { user: User }) {
  const [isEditPass, setIsEditPass] = useState(false);
  const toggleModal = () => setIsEditPass(!isEditPass);
  return (
    <Box mt="10">
      <Box mb="8">
        <Text fontSize="14px" color="gray.700">
          Tipo de regimen
        </Text>
        <Text fontSize="16px" color="gray.700" textTransform="capitalize">
          Persona {type[user.sub_type]}
        </Text>
      </Box>
      <Box mb="8">
        <Text fontSize="14px" color="gray.700">
          Correo electrónico
        </Text>
        <Text fontSize="16px" color="gray.700">
          {user.email}
        </Text>
      </Box>
      <Box mb="8">
        <Text fontSize="14px" color="gray.700">
          Teléfono
        </Text>
        <Text fontSize="16px" color="gray.700">
          {user.phone}
        </Text>
      </Box>
      <Box>
        <HStack justifyContent="space-between">
          <Box>
            <Text fontSize="14px" color="gray.700">
              Contraseña
            </Text>
            <Text fontSize="16px" color="gray.700">
              ********
            </Text>
          </Box>
          <Icon
            mr={2}
            size={6}
            color="gray.500"
            onPress={toggleModal}
            as={<MaterialIcons name="mode-edit" />}
          />
        </HStack>
        <ModalUpdatePassword isOpen={isEditPass} toggleModal={toggleModal} />
      </Box>
    </Box>
  );
}
