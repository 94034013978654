import React from 'react';
import {
  Box,
  Flex,
  Divider,
  HStack,
  Stack,
  Text,
  useTheme,
  VStack,
  Avatar,
  Icon,
} from 'native-base';
import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import { formatCurrency, getPercentage, timeAgo } from 'utils/formats';
import { getTotalAmount } from 'utils/rides';
import { Vehicle, Offer } from 'types/trips';
import { isEmptyArray } from 'utils/validators';
import { Pressable } from 'react-native';

type Icons = React.ComponentProps<typeof MaterialIcons>['name'];
const starsDefaultIcon: Icons[] = new Array(5).fill('star-border');

export default function CardOfferingSelected({
  title = 'La oferta más baja hasta el momento',
  bestOfferAmount = '',
  vehicle,
  service,
  halfPaymentTitle = 'Iniciar viaje 50%',
  paymentStatus,
  screen,
  hideDefault,
  children,
  stars = '',
  offers = [] as Offer[],
  handleToggleModalOffer,
}: {
  title: string;
  bestOfferAmount: string | undefined;
  vehicle?: Partial<Vehicle>;
  children?: React.ReactNode;
  service: number;
  halfPaymentTitle?: string;
  paymentStatus?: string;
  screen?: string;
  hideDefault?: boolean;
  stars?: string;
  offers?: Offer[];
  handleToggleModalOffer?: (arg: Offer) => void;
}) {
  const { colors } = useTheme();
  const secondaryColors = colors.secondary as typeof colors['gray'];
  const primaryColors = colors.primary as typeof colors['gray'];
  const isOfferscreen = screen === 'offer';
  const hasOffers = !isEmptyArray(offers);
  const userBestOfferStar = parseInt(stars, 10) || 0;

  return (
    <Box
      bg={colors.gray[100]}
      shadow={isOfferscreen ? '0px' : 6}
      p={hasOffers ? 0 : 4}
      pb={4}
      mt={4}
      mb={0}
      rounded={8}
      _web={{
        width: '100%',
      }}
      _ios={{
        borderRadius: 8,
      }}
    >
      {paymentStatus === 'irregularity' ? (
        <Text fontSize="md" color="red.900" fontWeight="medium">
          Tu pago ha sido rechazado. Vuelve a subir un comprobante de pago
          valido.
        </Text>
      ) : null}
      <Text fontSize="md" color={secondaryColors[900]}>
        {title}
      </Text>
      {hasOffers &&
        offers.map(offer => {
          const { user, inserted_at: insertedAt, amount, id } = offer;
          return (
            <Pressable onPress={() => handleToggleModalOffer?.(offer)}>
              <Box
                mt={4}
                key={id}
                shadow="1"
                padding={4}
                rounded="8px"
                bg={colors.gray[100]}
              >
                <HStack
                  width="100%"
                  alignItems={userBestOfferStar > 0 ? 'flex-end' : 'center'}
                >
                  <HStack width="90%">
                    {user.avatar ? (
                      <Avatar
                        size="48px"
                        bg={colors.gray[400]}
                        source={{ uri: user.avatar }}
                      />
                    ) : (
                      <Avatar size="48px" bg={colors.gray[300]}>
                        <MaterialCommunityIcons
                          size={40}
                          name="account-circle"
                          color={colors.gray[200]}
                        />
                      </Avatar>
                    )}
                    <Box ml={6}>
                      <Text
                        fontSize="16px"
                        fontWeight={400}
                        color={colors.gray[700]}
                      >
                        {user.names} {user.first_surname}
                      </Text>
                      <HStack justifyContent="center" space={2}>
                        {userBestOfferStar > 0 &&
                          starsDefaultIcon.map((start, index) => (
                            <Icon
                              // eslint-disable-next-line react/no-array-index-key
                              key={index}
                              mt="4px"
                              size={6}
                              as={
                                <MaterialIcons
                                  name={
                                    userBestOfferStar < index + 1
                                      ? start
                                      : 'star'
                                  }
                                />
                              }
                              color={
                                userBestOfferStar < index + 1
                                  ? colors.gray[500]
                                  : colors.orange[400]
                              }
                            />
                          ))}
                      </HStack>
                    </Box>
                  </HStack>
                  <Icon
                    width="50%"
                    name="eye"
                    alignSelf="flex-start"
                    color={primaryColors[900]}
                    onPress={() => handleToggleModalOffer?.(offer)}
                    as={MaterialCommunityIcons}
                  />
                </HStack>
                <HStack
                  justifyContent="space-between"
                  mt={4}
                  alignItems="center"
                >
                  <Text fontSize="16px" color={colors.gray[800]}>
                    Ofertó
                  </Text>
                  <Text
                    fontSize="12px"
                    fontWeight={300}
                    color={colors.gray[500]}
                  >
                    Hace {timeAgo(insertedAt)}
                  </Text>
                  <Text fontSize="16px" color={colors.gray[800]}>
                    {formatCurrency(Number(amount))}
                  </Text>
                </HStack>
              </Box>
            </Pressable>
          );
        })}
      {!hideDefault && (
        <>
          <Divider my={4} />
          <Flex mb={0} direction="row" justify="space-between">
            <HStack space="md">
              <MaterialCommunityIcons
                name="truck"
                size={24}
                color={colors.gray[500]}
              />
              <VStack maxW="67%">
                <Text fontSize="sm" color={colors.gray[700]} noOfLines={2}>
                  {vehicle?.vehicle_type?.name || ''}
                </Text>
                <Text fontSize="12px" color={colors.gray[700]} noOfLines={2}>
                  {vehicle?.configuration} - {vehicle?.charge} kg
                </Text>
              </VStack>
            </HStack>
            <Text fontSize="md" color={colors.gray[700]}>
              {formatCurrency(Number(bestOfferAmount))}
            </Text>
          </Flex>
          <Divider my={4} />
          <Box pl={10}>
            <Stack>
              <HStack justifyContent="space-between" mb={4}>
                <Text color={colors.gray[600]}>Subtotal</Text>
                <Text color={colors.gray[600]}>
                  {formatCurrency(Number(bestOfferAmount))}
                </Text>
              </HStack>
              <HStack justifyContent="space-between" mb={4}>
                <Text color={colors.gray[600]}>IVA 12%</Text>
                <Text color={colors.gray[600]}>
                  {formatCurrency(Number(service))}
                </Text>
              </HStack>
              <HStack justifyContent="space-between">
                <Text color={colors.gray[600]} fontWeight={500}>
                  Total
                </Text>
                <Text color={colors.gray[600]} fontWeight={500}>
                  {getTotalAmount(bestOfferAmount, service)}
                </Text>
              </HStack>
            </Stack>
          </Box>
          <Divider my={4} />
          <HStack justifyContent="space-between">
            <HStack>
              <MaterialCommunityIcons
                name="clock"
                size={24}
                color={colors.gray[500]}
              />
              <VStack ml={4}>
                <Text color={colors.gray[800]} fontWeight={500}>
                  {halfPaymentTitle}
                </Text>
              </VStack>
            </HStack>
            <Text fontSize="md" color={colors.gray[800]} fontWeight={500}>
              {getPercentage(bestOfferAmount, service)}
            </Text>
          </HStack>
        </>
      )}
      {children}
    </Box>
  );
}
