import { AuthContext } from 'context/AuthContext';
import { useContext, useEffect, useState } from 'react';
import { useIsFetching, useIsMutating } from 'react-query';
import { ProgressStateProps } from 'types/general';

function useProgress() {
  const { isLoading } = useContext(AuthContext);
  const isFetching = useIsFetching();
  const isMutating = useIsMutating();
  const initialstate: ProgressStateProps = {
    bg: 'white',
    opacity: 1,
    show: false,
    colorSpinner: '#6a077a',
  };

  const [progress, setProgress] = useState(initialstate);

  useEffect(() => {
    onShowProgress({
      show: !!isFetching || !!isMutating || !isLoading,
      bg: 'gray.800',
      opacity: 0.6,
    });
  }, [isFetching, isMutating, isLoading]);

  const onShowProgress = (
    customProgressProps?: Partial<ProgressStateProps>,
  ) => {
    setProgress((prevState: ProgressStateProps) => ({
      ...prevState,
      ...customProgressProps,
    }));
  };
  const onHideProgress = () => {
    setProgress(initialstate);
  };

  return { onShowProgress, onHideProgress, progress };
}

export default useProgress;
