import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { Image, Text, Toast, Center, Pressable, HStack } from 'native-base';
import { Platform } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Button from 'components/Button';
import {
  DriveRequestStart,
  DriveRequestEnd,
  ClientAcceptStart,
  ClientAcceptEnd,
} from 'api';
import { colors } from 'constants/colors';
import { formatDateTimeCard } from 'utils/formats';
import { AuthContext } from 'context/AuthContext';
import { TripProps } from 'types/trips';
import ModalConfirmTrip from 'components/modals/ModalConfirmTrip';
import useDocuments, { Action } from 'hooks/useDocuments';
import LabelPressable from 'components/LabelPressable';
import ModalPackage from 'components/modals/ModalPackage';
import { CommonActions, useNavigation } from '@react-navigation/native';
import RidesTrackCard from './RidesTrackCard';

type Status = 'default' | 'process' | 'end';
export const statusColor: { [key in Status]: string } = {
  default: colors.gray[800],
  process: colors.secondary[900],
  end: colors.secondary[900],
};

export default function RidesTrack({
  trip,
  ride,
  refetch,
}: {
  trip: TripProps;
  ride: 'start' | 'inProgress' | 'end';
  refetch?: () => void;
}) {
  const navigation = useNavigation();
  const { selectedDocument, onSelectedDocument } = useDocuments();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [picture, setDonePicture] = useState<string>('');
  const [isOpenModalConfirm, setIsOpenModalConfirm] = useState(false);
  const { state } = useContext(AuthContext);
  const mutationDriver = ride === 'start' ? DriveRequestStart : DriveRequestEnd;
  const mutationClient = ride === 'start' ? ClientAcceptStart : ClientAcceptEnd;
  const primaryColors = colors.primary as typeof colors['gray'];
  const secondaryColors = colors.secondary as typeof colors['gray'];
  const driver = trip.driver || ({} as TripProps['driver']);
  const isDriverReady = Boolean(trip?.driver_start_accepted_at);
  const isDriverEnd = Boolean(trip?.driver_finish_accepted_at);
  const isClientReady = Boolean(trip?.client_start_accepted_at);
  const isClientEnd = Boolean(trip?.client_finish_accepted_at);
  const clientStartDescription = !isDriverReady
    ? 'Esperando a que el transportista inicie el viaje.'
    : 'El transportista ha iniciado el viaje. \n ¿Deseas iniciar el viaje?';

  const clientEndDescription = !isDriverEnd
    ? 'Al terminar el viaje el transportista finalizará el viaje y deberás confirmar el termino del viaje.'
    : 'El transportista desea finalizar el viaje. \n ¿Deseas confirmar?';
  const { mutate: mutateDriver, isLoading: isLoadingDriver } = useMutation(
    mutationDriver,
    {
      onSuccess: () => {
        refetch?.();
        handleToggleModalConfirm();
      },
      onError: () => {
        Toast.show({
          title: 'Hubo un error. intenta nuevamente.',
          placement: 'top',
        });
        handleToggleModalConfirm();
      },
    },
  );
  const { mutate: mutateClient, isLoading: isLoadingClient } = useMutation(
    mutationClient,
    {
      onSuccess: () => {
        refetch?.();
        handleToggleModalConfirm();
      },
      onError: () => {
        Toast.show({
          title: 'Hubo un error. intenta nuevamente.',
          placement: 'top',
        });
        handleToggleModalConfirm();
      },
    },
  );
  const FinalPaymentSchema = Yup.object()
    .shape({
      pictureDone: Yup.string(),
    })
    .when((_, schema) => {
      if (!picture) {
        return schema.shape({
          pictureDone: Yup.string()
            .nullable()
            .required('El campo es requerido'),
        });
      }
      return schema.shape({
        pictureDone: Yup.string().notRequired(),
      });
    });
  useEffect(() => {
    if (selectedDocument) {
      setDonePicture(selectedDocument?.uri);
    }
  }, [selectedDocument]);

  const handleTakePhoto = () => {
    setOpenModal(!openModal);
  };

  const handleSelectedPhoto = (action: Action) => {
    onSelectedDocument({
      action,
      web: { mimeType: 'image/*' },
    });
    setOpenModal(!openModal);
  };

  const handleRequestStartAndEnd = () => {
    if (state.type === 'client') {
      mutateClient({
        tripId: trip.id,
        ...(ride === 'end' && {
          dataToSend: {
            base64: selectedDocument?.base64,
          },
        }),
      });
      return;
    }
    mutateDriver({
      tripId: trip.id,
    });
  };

  const handleToggleModalConfirm = () =>
    setIsOpenModalConfirm(!isOpenModalConfirm);

  const onHelp = () => {
    navigation.dispatch(
      CommonActions.navigate({
        name: 'Chat',
      }),
    );
  };

  return (
    <>
      {state.type === 'client' ? (
        <>
          {ride === 'start' && (
            <RidesTrackCard
              title="Empezar viaje"
              description={clientStartDescription}
              typeUser="client"
              date={trip.start_at}
              ride={ride}
            >
              {isDriverReady && (
                <Button
                  button="primary"
                  onPress={handleToggleModalConfirm}
                  mb={4}
                  isDisabled={isDriverReady && isClientReady}
                >
                  {!isClientReady ? 'Aceptar' : 'Viaje iniciado'}
                </Button>
              )}
              <Center my={2}>
                <Pressable p={2} onPress={onHelp}>
                  <HStack alignItems="center" space={4}>
                    <Text color={primaryColors[900]} underline>
                      ¿Necesitas ayuda?
                    </Text>
                    <MaterialIcons
                      name="message"
                      size={24}
                      color={secondaryColors[900]}
                    />
                  </HStack>
                </Pressable>
              </Center>
            </RidesTrackCard>
          )}
          {ride === 'inProgress' && (
            <RidesTrackCard
              title="En camino"
              typeUser="client"
              date={trip.start_at}
              ride={ride}
            >
              <Center my={3}>
                <HStack alignItems="center" space={4}>
                  <Text color={primaryColors[900]}>
                    Entrega estimada:{" "}
                    <Text color={primaryColors[900]} fontWeight="medium">
                      {formatDateTimeCard(trip.end_at)}
                    </Text>
                  </Text>
                </HStack>
              </Center>
            </RidesTrackCard>
          )}
          {ride === 'end' && (
            <RidesTrackCard
              title="Terminar viaje"
              description={clientEndDescription}
              typeUser="client"
              date={trip.updated_at}
              ride={ride}
            >
              {isDriverEnd ? (
                <Formik
                  validationSchema={FinalPaymentSchema}
                  initialValues={{
                    pictureDone: picture || trip?.client_finish_accepted_photo,
                  }}
                  onSubmit={handleToggleModalConfirm}
                  enableReinitialize
                >
                  {({ handleSubmit, values }) => (
                    <>
                      {picture || trip?.client_finish_accepted_photo ? (
                        <Center flex="1" w="100%" mb={10}>
                          <Image
                            w="full"
                            h="250px"
                            resizeMode="contain"
                            source={{
                              uri:
                                picture || trip?.client_finish_accepted_photo,
                            }}
                            alt="Payment picture"
                          />
                        </Center>
                      ) : (
                        <LabelPressable
                          text="Suba una foto para confirmar el termino de viaje"
                          icon="photo-camera"
                          onLabelPress={handleTakePhoto}
                          iconRounded={false}
                          value={values.pictureDone}
                          name="pictureDone"
                          mb={8}
                          iconProps={{
                            color: 'primary.900',
                          }}
                          mandatory
                        />
                      )}
                      <Button
                        button="primary"
                        isDisabled={isDriverEnd && isClientEnd}
                        onPress={() => handleSubmit()}
                      >
                        {isDriverEnd && !isClientEnd
                          ? 'Confirmar'
                          : 'Viaje finalizado'}
                      </Button>
                    </>
                  )}
                </Formik>
              ) : null}

              <Center my={2}>
                <Pressable p={2} onPress={onHelp}>
                  <HStack alignItems="center" space={4}>
                    <Text color={primaryColors[900]} underline>
                      ¿Necesitas ayuda?
                    </Text>
                    <MaterialIcons
                      name="message"
                      size={24}
                      color={secondaryColors[900]}
                    />
                  </HStack>
                </Pressable>
              </Center>
            </RidesTrackCard>
          )}
        </>
      ) : (
        <>
          {ride === 'start' && (
            <RidesTrackCard
              description="Te recordamos estar en la dirección de origen en fecha y hora acordadas"
              typeUser="driver"
              date={trip.start_at}
              ride={ride}
            >
              <Button
                button="primary"
                onPress={handleToggleModalConfirm}
                mb={4}
                isDisabled={isDriverReady}
              >
                {isDriverReady ? 'Viaje iniciado' : 'Iniciar viaje'}
              </Button>
              {isDriverReady && !isClientReady ? (
                <Text
                  textAlign="center"
                  color={secondaryColors[900]}
                  fontWeight="medium"
                >
                  Esperando a que el cliente acepte inciar el viaje.
                </Text>
              ) : null}
            </RidesTrackCard>
          )}
          {ride === "inProgress" && (
            <RidesTrackCard
              description="En camino"
              typeUser="driver"
              ride={ride}
            >
              <Center my={3}>
                <HStack alignItems="center" space={4}>
                  <Text color={primaryColors[900]}>
                    Entrega estimada:{" "}
                    <Text color={primaryColors[900]} fontWeight="medium">
                      {formatDateTimeCard(trip.end_at)}
                    </Text>
                  </Text>
                </HStack>
              </Center>
            </RidesTrackCard>
          )}
          {ride === "end" && (
            <RidesTrackCard
              description="Para finalizar el viaje deberás estar en la dirección de destino."
              typeUser="driver"
              date={trip.updated_at}
              ride={ride}
            >
              <Button
                button="primary"
                onPress={handleToggleModalConfirm}
                mb={4}
                isDisabled={isDriverEnd}
              >
                {isDriverEnd ? 'Viaje finalizado' : 'Finalizar viaje'}
              </Button>
              {isDriverEnd && !isClientEnd ? (
                <Text
                  textAlign="center"
                  color={secondaryColors[900]}
                  fontWeight="medium"
                >
                  Esperando a que el cliente acepte finalizar el viaje.
                </Text>
              ) : null}
            </RidesTrackCard>
          )}
        </>
      )}
      <ModalPackage
        open={openModal}
        showUploadMobileFile={Platform.OS !== "web"}
        onSelectDocument={handleSelectedPhoto}
      />
      <ModalConfirmTrip
        isOpen={isOpenModalConfirm}
        toggleModal={handleToggleModalConfirm}
        onConfirmTrip={handleRequestStartAndEnd}
        step={ride}
        isDisabled={isLoadingDriver || isLoadingClient}
      />
    </>
  );
}
