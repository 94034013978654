import React, { useState, useEffect, useContext } from 'react';

import UploadPdfDocument from 'screens/UploadPdfDocument';
import useDocuments, { Action } from 'hooks/useDocuments';
import Context from 'context/Documents';
import useUpload from 'hooks/useUpload';
import ModalDocument from 'components/modals/ModalDocument';
import { DocumentTypes } from 'types/documents';
import { AuthContext } from 'context/AuthContext';

export default function UploadSocialSecurity() {
  const { selectedDocument, onSelectedDocument } = useDocuments();
  const { getDocument } = useContext(Context);
  const { state } = useContext(AuthContext);

  const { addDocument, documentUploaded, nextDocument } = useUpload();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [documentToUpload, setDocumentToUpload] = useState<DocumentTypes>();

  useEffect(() => {
    if (selectedDocument.base64) {
      addDocument({
        base64: selectedDocument?.base64,
        user_id: state.id ?? '',
        type: documentToUpload ?? '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDocument.base64]);

  const handleDocumentToUpload = (type: DocumentTypes) => {
    setOpenModal(!openModal);
    setDocumentToUpload(type);
  };

  const handleSelectedPhoto = (action: Action) => {
    onSelectedDocument({
      action,
      web: { mimeType: '/*' },
    });
    setOpenModal(!openModal);
  };

  const handleNextDocument = () => {
    const isUploaded = Boolean(
      documentUploaded.social_security || socialSecurity,
    );

    nextDocument({
      isUploaded,
      route: 'social',
    });
  };

  const socialSecurity = getDocument('social_security');
  const status = documentUploaded?.social_security
    ? documentUploaded.social_security.status
    : socialSecurity.status;

  const isSocialSecurity =
    documentUploaded?.social_security ||
    (socialSecurity.status && socialSecurity.status !== 'irregularity');

  const isValidated = socialSecurity.status === 'validated';

  return (
    <>
      <UploadPdfDocument
        heading="Seguro Social"
        cardTitle="Seguro Social"
        showMessage={Boolean(isSocialSecurity) && !isValidated}
        hideButton={Boolean(isSocialSecurity)}
        cardStatus={status || 'default'}
        cardIcon={status || 'default'}
        description="Toma una foto cuidando que la información se pueda leer con claridad."
        cardDescription="Cuida que en el documento se lea la información de forma clara."
        onNextDocument={handleNextDocument}
        onPress={() => handleDocumentToUpload('social_security')}
      />
      <ModalDocument
        open={openModal}
        toggle={() => setOpenModal(!openModal)}
        onSelectDocument={handleSelectedPhoto}
      />
    </>
  );
}
