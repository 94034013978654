import { useState } from 'react';
import { Platform } from 'react-native';
import Constants from 'expo-constants';
import * as Location from 'expo-location';
import { LocationObject } from 'expo-location';

export default function useLocation(options: Location.LocationOptions = {}) {
  const isSimulation = Platform.OS === 'android' && !Constants.isDevice;
  const [permissions, setPermission] = useState('');
  const [error, setError] = useState<string | null>(() =>
    isSimulation
      ? 'Oops, this will not work on Sketch in an Android emulator. Try it on your device!'
      : null,
  );
  const [location, setLocation] = useState<LocationObject>(
    {} as LocationObject,
  );

  const getLocationAsync = async () => {
    const { status } = await Location.requestForegroundPermissionsAsync();
    if (status !== 'granted') {
      setError('Permission to access location was denied');
    }
    const currentLocation = await Location.getCurrentPositionAsync(options);
    console.log(currentLocation);
    setPermission(status);
    setLocation(currentLocation);
  };

  return { location, error, permissions, getLocation: getLocationAsync };
}
