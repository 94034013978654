import React, { useContext, useState } from 'react';
import { Box, Flex, ScrollView, Toast } from 'native-base';
import CardVehicle from 'components/CardVehicle';
import { TransportationCarsProps, VehiclesProps } from 'types/transportation';
import Context from 'context/Vehicles';
import { AuthContext } from 'context/AuthContext';
import { isEmptyArray } from 'utils/validators';
import {
  existsCurrentDriverVehicles,
  getDriverVehiclesIds,
  removeCurrentDriverVehicles,
} from 'utils/vehicles';

export default function TransportationCars({
  isTransportist,
  isClient,
  canDelete,
  vehicles = [],
}: TransportationCarsProps) {
  const { indexKey, currentVehicles, vehicleIds, setVehicle, setVehicleIds } =
    useContext(Context);
  const { state } = useContext(AuthContext);
  const currentDriverVehicles: string[] = getDriverVehiclesIds(
    state.vehicles || [],
  );
  const [checkboxValue, setCheckboxValue] = useState<string[]>([]);

  const handleChangeValue = (vehicleId: string) => {
    const vehicleSelected =
      vehicles.find((vehicle: VehiclesProps) => vehicle.id === vehicleId) ||
      ({} as VehiclesProps);
    setVehicle(vehicleSelected);
  };

  const handleChangeMultipleValues = (ids: string[]) => {
    const cleanedIds = removeCurrentDriverVehicles(currentDriverVehicles, ids);
    const originalIds = currentVehicles[indexKey].map(
      (vehicle: any) => vehicle.id,
    );

    const idsToRemove = originalIds.filter(
      (x: string) => !cleanedIds.includes(x),
    );
    const driverHasThisVehicules = existsCurrentDriverVehicles(
      currentDriverVehicles,
      idsToRemove,
    );

    if (!isEmptyArray(driverHasThisVehicules)) {
      Toast.show({
        title:
          'Para eliminar un vehículo, puedes hacerlo desde "Mis vehículos".',
        placement: 'top',
      });
      return;
    }
    if (!isEmptyArray(idsToRemove)) {
      setVehicleIds(
        Array.from(
          new Set(
            vehicleIds
              .filter((item: string) => !idsToRemove.includes(item))
              .concat(cleanedIds),
          ),
        ),
      );

      setCheckboxValue(cleanedIds);
      return;
    }
    setVehicleIds(
      Array.from(new Set(vehicleIds.concat(cleanedIds).concat(cleanedIds))),
    );
    setCheckboxValue(cleanedIds);
  };

  return (
    <Box flex="1" py={0}>
      <ScrollView>
        <Flex direction="column" px={4} pt={2}>
          {vehicles.map((vehicle: VehiclesProps) => (
            <CardVehicle
              key={vehicle.id}
              onChangeValue={handleChangeValue}
              onChangeMultipleValues={handleChangeMultipleValues}
              currentDriveVehicleSelected={currentDriverVehicles}
              {...vehicle}
              {...{
                isTransportist,
                isClient,
                canDelete,
                checkboxValue,
              }}
            />
          ))}
        </Flex>
      </ScrollView>
    </Box>
  );
}
