import React from 'react';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Stack, HStack, VStack, Text, Avatar, useTheme } from 'native-base';

import { TripProps } from 'types/trips';

export default function AssignedRide({
  trip,
  isDriverAssigned,
}: {
  trip: TripProps;
  isDriverAssigned?: boolean;
}) {
  const { colors } = useTheme();
  const secondaryColors = colors.secondary as typeof colors['gray'];

  const buildName = () => {
    return `${trip.driver.names} ${trip.driver.first_surname} ${trip.driver.second_surname}`;
  };
  return (
    <Stack
      flex="1"
      bg={colors.gray[100]}
      padding={isDriverAssigned ? 0 : 4}
      pb={8}
      rounded={8}
      mt={isDriverAssigned ? 0 : 6}
      _web={{
        shadowColor: colors.gray[500],
        shadowOpacity: 0.11,
        shadowRadius: 4,
        shadowOffset: { width: 0, height: 4 },
        elevation: 11,
      }}
    >
      <Text
        ml={isDriverAssigned ? 0 : 5}
        fontSize="16px"
        fontWeight={400}
        color={secondaryColors[900]}
      >
        Transportista asignado
      </Text>
      <HStack alignItems="center" mt={10}>
        {trip.driver ? (
          <Avatar
            size="54px"
            bg={colors.gray[400]}
            source={{ uri: trip.driver.avatar }}
          />
        ) : (
          <Avatar size="54px" bg={colors.gray[400]}>
            <MaterialCommunityIcons
              size={50}
              name="account-circle"
              color={colors.gray[500]}
            />
          </Avatar>
        )}
        <VStack>
          <Text
            ml={5}
            color={colors.gray[700]}
            fontSize="16px"
            fontWeight={400}
          >
            {buildName()}
          </Text>
        </VStack>
      </HStack>
    </Stack>
  );
}
