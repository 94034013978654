/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useRef, useState, useEffect } from 'react';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import {
  Box,
  Center,
  Heading,
  HStack,
  VStack,
  Icon,
  Toast,
  Pressable,
  Text,
  ScrollView,
} from 'native-base';
import { AuthContext } from 'context/AuthContext';
import ModalFilter from 'components/modals/ModalFilter';
import { TripProps } from 'types/trips';
import useTrips from 'hooks/useTrips';
import RideCard from 'components/RideCard';
import { RefreshControl } from 'react-native';

export type Filters = {
  startRadio: number;
  start_at_to: Date | string;
  start_address_state: string;
  end_address_state: string;
  latitude: number | string;
  longitude: number | string;
  hourStart: string;
  minutesStart: string;
};

export type Params = {
  start_at_to: Date | string;
  start_address_state: string;
  end_address_state: string;
  radius_start_address: string;
  radius_end_address: string;
};

export default function RidesInitial() {
  const loadMoreButtonRef = useRef(null);
  const { state: userProfile } = useContext(AuthContext);
  const [openFilters, setOpenFilters] = useState(false);
  const [params, setParams] = useState<Params | null>({} as Params);
  const userType =
    // eslint-disable-next-line no-nested-ternary
    userProfile?.type === 'driver'
      ? userProfile.sub_type === 'moral'
        ? 'moral_driver_id'
        : 'driver_id'
      : 'client_id';

  const {
    error,
    trips,
    hasNextPage,
    isFetchingNextPage,
    refetch,
    fetchNextPage,
  } = useTrips({
    status: [
      'in_progress',
      'pending_last_step',
      'pending_first_step',
      'offer_accepted',
      'pending_start',
      'in_validation_last_step',
      'in_validation_first_step',
      'failed_last_step',
      'waiting_for_review',
    ],
    [userType]: userProfile.id,
    ...params,
  });

  if (error) {
    Toast.show({
      title: 'Ocurrio un error. Intenta de nuevo',
      placement: 'top',
    });
  }

  useEffect(() => {
    refetch();
  }, [JSON.stringify(params)]);

  const handleFilters = (filters: Filters) => {
    const currentParams = {
      start_address_state: filters.start_address_state,
      end_address_state: filters.end_address_state,
      start_at_to: filters.start_at_to
        ? new Date(filters.start_at_to).toISOString()
        : '',
      radius_start_address: filters.latitude
        ? `${filters.latitude}_${filters.longitude}_${filters.startRadio}`
        : '',
      radius_end_address: filters.longitude
        ? `${filters.latitude}_${filters.longitude}_100`
        : '',
    };
    setParams(currentParams);
    handleToggleFilter();
  };

  const handleCleanFilters = () => {
    setParams(null);
  };
  const handleToggleFilter = () => setOpenFilters(!openFilters);

  return (
    <Box flex="1" mb={4}>
      <ScrollView
        flex={1}
        refreshControl={
          <RefreshControl
            colors={['#5B0570']}
            tintColor="#6a077a"
            refreshing={false}
            onRefresh={() => refetch()}
          />
        }
      >
        <HStack justifyContent="space-between" alignItems="center" p={4}>
          <Heading fontSize="2xl" fontWeight="normal" color="gray.700">
            Mis viajes
          </Heading>
          <Icon
            size="6"
            mr={2}
            color="gray.500"
            onPress={handleToggleFilter}
            as={
              <MaterialCommunityIcons name="tune" size={24} color="gray.600" />
            }
          />
        </HStack>
        <>
          {trips.map((page, index) => (
            <VStack space={4} px={4} key={index} mb={4}>
              {page.data.map((trip: TripProps) => {
                return (
                  <Box key={trip.id}>
                    <RideCard {...trip} />
                  </Box>
                );
              })}
            </VStack>
          ))}
        </>
        {hasNextPage ? (
          <Pressable
            ref={loadMoreButtonRef}
            disabled={!hasNextPage || isFetchingNextPage}
            onPress={() => fetchNextPage()}
            py={3}
          >
            <Center>
              <Text
                borderBottomColor="gray.400"
                borderBottomWidth="1"
                fontWeight="medium"
              >
                Más resultados
              </Text>
            </Center>
          </Pressable>
        ) : (
          <Center py={3}>
            <Text
              borderBottomColor="gray.400"
              borderBottomWidth="1"
              fontWeight="medium"
            >
              No hay más resultados para mostrar
            </Text>
          </Center>
        )}
      </ScrollView>
      <ModalFilter
        isOpen={openFilters}
        params={params}
        handleCloseModal={handleToggleFilter}
        handleCleanFilters={handleCleanFilters}
        handleFilters={handleFilters}
      />
    </Box>
  );
}
