import React from 'react';
import { Text, Pressable, HStack, useTheme } from 'native-base';
import { useNavigation, CommonActions } from '@react-navigation/native';

export default function FooterAuth() {
  const { colors } = useTheme();
  const navigation = useNavigation();

  const primaryColors = colors.primary as typeof colors['gray'];

  const handleNavigate = (route: 'Privacy' | 'Terms') => {
    navigation.dispatch(
      CommonActions.navigate({
        name: route,
      }),
    );
  };

  return (
    <HStack flexWrap="wrap" paddingY={4} justifyContent="center">
      <Text>Al registrarte aceptas nuestra polictica de</Text>
      <Pressable onPress={() => handleNavigate('Privacy')}>
        <Text ml={1} color={primaryColors[900]}>
          Privacidad
        </Text>
      </Pressable>
      <Text>y de</Text>
      <Pressable onPress={() => handleNavigate('Terms')}>
        <Text ml={1} color={primaryColors[900]}>
          Términos y condiciones
        </Text>
      </Pressable>
    </HStack>
  );
}
