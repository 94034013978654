import React from 'react';
import { MaterialIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import {
  createNativeStackNavigator,
  NativeStackHeaderProps,
} from '@react-navigation/native-stack';
import { Icon } from 'native-base';
import Profile from 'screens/Profile';
import Document from 'screens/Document';
import Help from 'screens/Help';
import HomeStack from 'navigation/HomeStack';
import Chat from 'screens/Chat';
import AboutUs from 'screens/AboutUs';
import Notifications from 'screens/Notifications';
import BackHeader from 'components/BackHeader';
import Subordinates from 'screens/Subordinates';

const Stack = createNativeStackNavigator();

export default function AuthStack() {
  const navigation = useNavigation();
  return (
    <Stack.Navigator initialRouteName="Home">
      <Stack.Screen
        name="Home"
        component={HomeStack}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="Profile"
        component={Profile}
        options={{
          header: (props: NativeStackHeaderProps) => (
            <BackHeader showLogout {...props} />
          ),
        }}
      />
      <Stack.Screen
        name="Document"
        component={Document}
        options={{
          title: 'Documentos',
          headerShadowVisible: false,
          headerLeft: () => {
            return (
              <Icon
                size="6"
                mt="1"
                mr="4"
                ml="4"
                color="gray.500"
                onPress={() => navigation.goBack()}
                as={<MaterialIcons name="arrow-back-ios" />}
              />
            );
          },
        }}
      />
      <Stack.Screen
        name="Help"
        component={Help}
        options={{
          title: 'Ayuda',
          headerShadowVisible: false,
          headerLeft: () => (
            <Icon
              size="6"
              mt="1"
              mr="4"
              ml="4"
              color="gray.500"
              onPress={() => navigation.goBack()}
              as={<MaterialIcons name="arrow-back-ios" />}
            />
          ),
        }}
      />
      <Stack.Screen
        name="Chat"
        component={Chat}
        options={{
          title: 'Chat',
          headerShadowVisible: false,
          headerLeft: () => (
            <Icon
              size="6"
              mt="1"
              mr="4"
              ml="4"
              color="gray.500"
              onPress={() => navigation.goBack()}
              as={<MaterialIcons name="arrow-back-ios" />}
            />
          ),
        }}
      />
      <Stack.Screen
        name="About"
        component={AboutUs}
        options={{
          header: (props: NativeStackHeaderProps) => (
            <BackHeader iconBack {...props} />
          ),
        }}
      />
      <Stack.Screen
        name="Notifications"
        component={Notifications}
        options={{
          title: 'Notificaciones',
          header: (props: NativeStackHeaderProps) => (
            <BackHeader iconBack hideLogo {...props} />
          ),
        }}
      />
      <Stack.Screen
        name="Subordinates"
        component={Subordinates}
        options={{
          title: 'Transportistas asociados ',
          header: (props: NativeStackHeaderProps) => (
            <BackHeader iconBack hideLogo {...props} />
          ),
        }}
      />
    </Stack.Navigator>
  );
}
