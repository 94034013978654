import React, { useState } from 'react';
import {
  Box,
  Text,
  Flex,
  Icon,
  Radio,
  useTheme,
  FormControl,
  ScrollView,
} from 'native-base';
import { MaterialIcons } from '@expo/vector-icons';
import { CommonActions, useNavigation } from '@react-navigation/native';
import { capitalize } from 'lodash';
import { ProfileType } from 'types/user';

import Button from 'components/Button';
import BackWeb from 'components/BackWeb';
import useDesignAuth from 'webdesign/useDesignAuth';
import { isWebDesktop } from 'utils/general';
import { Platform } from 'react-native';

type Icons = React.ComponentProps<typeof MaterialIcons>['name'];
const types: { id: number; title: string; type: ProfileType; icon: Icons }[] = [
  {
    id: 0,
    title: 'Quiero realizar viajes',
    type: 'driver',
    icon: 'local-shipping',
  },
  {
    id: 1,
    title: 'Necesito un viaje',
    type: 'client',
    icon: 'account-circle',
  },
];

export default function Profile() {
  const navigation = useNavigation();
  const { colors } = useTheme();
  const [value, setValue] = useState<string | ProfileType>('');
  const { cardPx, cardPy, cardBg, cardShadow } = useDesignAuth();

  const isWeb = Platform.OS === 'web';
  const handleChangeValue = (nextValue: string) => setValue(nextValue);

  const handleNavigate = () => {
    navigation.dispatch(
      CommonActions.navigate({
        name: 'Terms',
        path: 'Terms',
        params: { profileType: value as ProfileType },
      }),
    );
  };

  return (
    <Box
      flex="1"
      px={4}
      pt={10}
      _web={{
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        flex="1"
        width="100%"
        maxWidth="650px"
        _web={{
          py: cardPy,
          px: cardPx,
          borderRadius: 20,
          bg: cardBg,
          shadow: cardShadow,
          justifyContent: 'center',
        }}
      >
        {isWebDesktop && <BackWeb />}
        <ScrollView flex="1" mt={isWeb ? 4 : 0}>
          <Text fontSize="24px" textAlign="center" mb={10}>
            ¿Qué tipo de perfil eres?
          </Text>
          {types.map(({ id, title, type, icon }) => (
            <Box
              key={id}
              shadow={3}
              bg="gray.100"
              overflow="hidden"
              height="120px"
              mb="60px"
              borderRadius="10"
            >
              <Flex
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                padding={0}
                height="100%"
              >
                <Box pl="24px">
                  <FormControl.Label>
                    <Text fontSize="16px" fontWeight="400" color="gray[600]">
                      {title}
                    </Text>
                  </FormControl.Label>
                  <Radio.Group
                    name="type"
                    value={value}
                    accessibilityLabel="type client"
                    onChange={handleChangeValue}
                  >
                    <Radio value={type} my={1}>
                      {capitalize(
                        type === 'driver' ? 'transportista' : 'cliente',
                      )}
                    </Radio>
                  </Radio.Group>
                </Box>

                <Box
                  bg={colors.gray[200]}
                  width="170px"
                  height="170px"
                  borderRadius={80}
                  justifyContent="center"
                  alignItems="center"
                  mr={-30}
                >
                  <Icon
                    mr={4}
                    size={60}
                    color={colors.gray[300]}
                    as={<MaterialIcons name={icon} />}
                  />
                </Box>
              </Flex>
            </Box>
          ))}
        </ScrollView>
        <Button
          my={8}
          onPress={handleNavigate}
          button="primary"
          isDisabled={!value}
        >
          Continuar
        </Button>
      </Box>
    </Box>
  );
}
