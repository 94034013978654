import React, { useState, useEffect } from 'react';
import { useField } from 'formik';
import { Animated, GestureResponderEvent } from 'react-native';
import {
  Input,
  Center,
  Box,
  Pressable,
  Text,
  IInputProps,
  useTheme,
  IBoxProps,
} from 'native-base';

type TextStyle = React.ComponentProps<typeof Animated.Text>['style'];
interface FloatingInputProps extends IBoxProps {
  label: string;
  name: string;
  onPressIcon?: null | ((event: GestureResponderEvent) => void);
  value: string;
  mandatory?: boolean;
  leftElement?: string | JSX.Element;
  rightElement?: string | JSX.Element;
  helperText?: string;
  maxLength?: number;
  onChangeText?: (text: string) => void;
  inputProps: IInputProps;
  onBlurChange?: (cp: string) => void;
}

export default function FloatingInputWithElements({
  label,
  onChangeText,
  onPressIcon,
  onBlurChange,
  name = '',
  value = '',
  mandatory = true,
  leftElement,
  rightElement,
  helperText,
  inputProps,
  ...props
}: FloatingInputProps) {
  const [_, meta] = useField(name);
  const [isFocused, setIsFocused] = useState(false);
  const { colors } = useTheme();
  const animatedIsFocused = new Animated.Value(value === '' ? 0 : 1);

  const primaryColors = colors.primary as typeof colors['gray'];
  const errorColors = colors.error as typeof colors['gray'];

  useEffect(() => {
    Animated.timing(animatedIsFocused, {
      toValue: isFocused || value !== '' ? 1 : 0,
      duration: 200,
      useNativeDriver: false,
    }).start();
    if (value) {
      setIsFocused(true);
    }
  }, [animatedIsFocused, isFocused, value]);

  const setFloating = () => setIsFocused(true);
  const setSticky = () => setIsFocused(false);
  const handleFocus = () => !isFocused && setFloating();

  const handleBlur = () => {
    if (!value) {
      setSticky();
      return;
    }
    onBlurChange?.(value);
  };

  const handleTextChange: IInputProps['onChangeText'] = (newText: string) => {
    onChangeText?.(newText);
  };

  const dynamicStyles: { [k in 'label']: TextStyle } = {
    label: {
      zIndex: 0,
      position: 'absolute',
      marginLeft: 48,
      color: animatedIsFocused.interpolate({
        inputRange: [0, 1],
        outputRange: [`${colors.gray[500]}`, `${primaryColors[900]}`],
      }),
      top: animatedIsFocused.interpolate({
        inputRange: [0, 1],
        outputRange: [18, 6],
      }),
      fontSize: animatedIsFocused.interpolate({
        inputRange: [0, 1],
        outputRange: [16, 12],
      }),
    },
  };
  const showError = meta.error && meta.touched;
  return (
    <Pressable onPress={onPressIcon}>
      <Box {...props}>
        <Box bg={colors.gray[300]} rounded="2xl">
          <Animated.Text style={dynamicStyles.label}>
            {label}{' '}
            {mandatory ? (
              <Text mx={0.5} color={colors.red[600]}>
                *
              </Text>
            ) : null}
          </Animated.Text>
          <Input
            {...inputProps}
            value={value}
            pt={6}
            fontSize="md"
            height="58px"
            isInvalid={!!showError}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChangeText={handleTextChange}
            _hover={{
              backgroundColor: 'none',
            }}
            InputLeftElement={
              typeof leftElement === 'string' ? (
                <Center bg={colors.gray[400]} h="100%" minW={10} px={3}>
                  <Text
                    fontSize="md"
                    color={colors.gray[600]}
                    fontWeight="medium"
                  >
                    {leftElement}
                  </Text>
                </Center>
              ) : (
                leftElement
              )
            }
            InputRightElement={
              typeof rightElement === 'string' ? (
                <Center bg={colors.gray[400]} h="100%" minW={10} px={3}>
                  <Text
                    fontSize="md"
                    color={colors.gray[600]}
                    fontWeight="medium"
                  >
                    {rightElement}
                  </Text>
                </Center>
              ) : (
                rightElement
              )
            }
          />
        </Box>
        {helperText ? (
          <Text fontSize="sm" mx={4} color={colors.gray[600]}>
            {helperText}
          </Text>
        ) : null}
        {showError && (
          <Text
            position="absolute"
            bottom="-20px"
            fontSize="sm"
            ml="4"
            color={errorColors[900]}
          >
            {meta.error}
          </Text>
        )}
      </Box>
    </Pressable>
  );
}
