import * as FileSystem from 'expo-file-system';
import { Platform } from 'react-native';

export const getImageBase64 = async (uri: string) => {
  if (Platform.OS !== 'web') {
    return FileSystem.readAsStringAsync(uri, {
      encoding: FileSystem.EncodingType.Base64,
    });
  }
  return uri;
};

export const getUserValidation = (
  document_status: string,
  general_information_status: string,
): boolean => {
  return (
    document_status === 'validated' &&
    general_information_status === 'validated'
  );
};
