/* eslint-disable react/no-array-index-key */
import React, { useContext, useRef } from 'react';
import {
  VStack,
  Center,
  Text,
  Pressable,
  ScrollView,
  Toast,
  Heading,
  Box,
} from 'native-base';

import RideCard from 'components/RideCard';
import { TripProps } from 'types/trips';
import useTrips from 'hooks/useTrips';
import { AuthContext } from 'context/AuthContext';
import { RideStatus } from 'types/ride';
import { RefreshControl } from 'react-native';

type Filter = {
  status: RideStatus[];
  offer_by_moral_driver_id?: string;
  offer_by_driver_id?: string;
  user_id: string;
};

export default function Request() {
  const loadMoreButtonRef = useRef(null);
  const { state: userProfile } = useContext(AuthContext);

  const filters = (): Filter => {
    if (userProfile.type === 'driver' && userProfile.sub_type === 'moral') {
      return {
        status: ['waiting_for_offers', 'offer_accepted'],
        offer_by_moral_driver_id: userProfile?.id,
        user_id: userProfile?.id || '',
      };
    }
    return {
      status: ['waiting_for_offers', 'offer_accepted'],
      offer_by_driver_id: userProfile?.id,
      user_id: userProfile?.id || '',
    };
  };

  const {
    error,
    trips,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
  } = useTrips(filters());

  if (error) {
    Toast.show({
      title: 'Ocurrio un error. Intenta de nuevo',
      placement: 'top',
    });
  }

  return (
    <ScrollView
      flex={1}
      refreshControl={
        <RefreshControl
          colors={['#5B0570']}
          tintColor="#6a077a"
          refreshing={false}
          onRefresh={() => refetch()}
        />
      }
    >
      <Heading p={4} fontSize="2xl" fontWeight="normal" color="gray.700">
        Mis ofertas
      </Heading>
      {trips.map((page, index) => (
        <VStack space={4} px={4} key={index} mb={4}>
          {page.data.map((trip: TripProps) => {
            return (
              <Box key={trip.id}>
                <RideCard key={trip.id} {...trip} />
              </Box>
            );
          })}
        </VStack>
      ))}
      <Center>
        {hasNextPage ? (
          <Pressable
            ref={loadMoreButtonRef}
            disabled={!hasNextPage || isFetchingNextPage}
            onPress={() => fetchNextPage()}
            py={3}
          >
            <Center>
              <Text
                borderBottomColor="gray.400"
                borderBottomWidth="1"
                fontWeight="medium"
              >
                Más resultados
              </Text>
            </Center>
          </Pressable>
        ) : (
          <Center pt={4} pb={4}>
            <Text
              fontWeight="medium"
              borderColor="gray.400"
              borderBottomWidth="1"
            >
              No hay más resultados para mostrar
            </Text>
          </Center>
        )}
      </Center>
    </ScrollView>
  );
}
