import React from 'react';
import {
  createNativeStackNavigator,
  NativeStackHeaderProps,
} from '@react-navigation/native-stack';
import { MaterialIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { Icon } from 'native-base';
import Document from 'screens/Document';
import BackHeader from 'components/BackHeader';
import Profile from 'screens/Profile';

const Stack = createNativeStackNavigator();

export default function IncompleteProfileStack() {
  const navigation = useNavigation();
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Profile"
        component={Profile}
        options={{
          header: (props: NativeStackHeaderProps) => (
            <BackHeader showLogout {...props} />
          ),
        }}
      />
      <Stack.Screen
        name="Document"
        component={Document}
        options={{
          title: 'Documentos',
          headerShadowVisible: false,
          headerLeft: () => {
            return (
              <Icon
                size="6"
                mt="1"
                mr="4"
                ml="4"
                color="gray.500"
                onPress={() => navigation.goBack()}
                as={<MaterialIcons name="arrow-back-ios" />}
              />
            );
          },
        }}
      />
    </Stack.Navigator>
  );
}
