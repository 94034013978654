import React from 'react';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import {
  useNavigation,
  CommonActions,
  useRoute,
} from '@react-navigation/native';
import { HStack, Stack, Text, Icon, useTheme, VStack } from 'native-base';
import { TripProps } from 'types/trips';
import Button from 'components/Button';
import AssignedRide from 'screens/rides/AssignedRide';

export default function AssignRide({ trip }: { trip: TripProps }) {
  const navigation = useNavigation();
  const route = useRoute();
  const { colors } = useTheme();
  const secondaryColors = colors.secondary as typeof colors['gray'];

  const isTripAcepted =
    trip.driver_start_accepted_at && trip.client_start_accepted_at;

  const isDriverAssigned = trip.driver;

  const handleCarrier = () => {
    navigation.dispatch(
      CommonActions.navigate({
        name: 'Carrier',
        path: 'Carrier',
        params: {
          tab: route.name,
          tripId: trip.id,
        },
      }),
    );
  };

  return (
    <Stack
      flex="1"
      bg={colors.gray[100]}
      padding={4}
      pb={8}
      rounded={16}
      mt={6}
      style={{
        shadowColor: colors.gray[500],
        shadowOpacity: 0.11,
        shadowRadius: 4,
        shadowOffset: { width: 0, height: 4 },
        elevation: 11,
      }}
    >
      {isDriverAssigned ? (
        <AssignedRide trip={trip} isDriverAssigned={!!isDriverAssigned} />
      ) : (
        <>
          <Text
            mb={6}
            color={secondaryColors[900]}
            fontSize="16px"
            fontWeight={400}
          >
            Asigna este viaje
          </Text>
          <HStack mb={10}>
            <Icon
              size={6}
              mr={4}
              color={colors.gray[500]}
              as={<MaterialCommunityIcons name="truck" />}
            />
            <VStack>
              <Text color={colors.gray[700]} fontSize="12px" fontWeight={300}>
                Selecciona un conductor para este viaje.
              </Text>
            </VStack>
          </HStack>
        </>
      )}
      {!isTripAcepted && (
        <Button
          onPress={handleCarrier}
          button="primary"
          isDisabled={trip.status === 'completed'}
        >
          {isDriverAssigned ? 'Cambiar' : 'Asignar Viaje'}
        </Button>
      )}
    </Stack>
  );
}
