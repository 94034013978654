import { Documents, DocumentStatus } from 'types/documents';
import { isEmptyArray } from './validators';

const getStatusCount = (status: DocumentStatus[]) => {
  const counts: { [key: string]: number } = {};
  status.forEach((stat: string) => {
    counts[stat] = (counts[stat] || 0) + 1;
  });
  return counts;
};

export const getDocumentsKey = (documents: Documents[]) =>
  documents.map((item: Documents) => item.type);

export const documentsUploaded = (documents: string[], key: string) => {
  return documents.filter((doc: string) => doc.includes(key));
};
export const getSingleDocumentStatus = (
  totaldocumentationExpected: number,
  documents: Documents[],
  type: string[],
): string | string[] => {
  if (totaldocumentationExpected === 2) {
    return documents
      .map((item: Documents) => type.includes(item.type) && item.status)
      .filter(Boolean);
  }
  return (
    documents.find((item: Documents) => type.includes(item.type))?.status || ''
  );
};
export const getDocumentStatus = (
  totaldocumentationExpected: number,
  completeUserDocumentation: string[],
  currentUserDocumentStatus: DocumentStatus[] | DocumentStatus,
): string => {
  if (
    (totaldocumentationExpected !== completeUserDocumentation.length &&
      !currentUserDocumentStatus) ||
    isEmptyArray(currentUserDocumentStatus)
  ) {
    return 'empty';
  }
  if (totaldocumentationExpected === 2) {
    const statusCount = getStatusCount(currentUserDocumentStatus);
    if (
      totaldocumentationExpected !== completeUserDocumentation.length &&
      statusCount.validated !== totaldocumentationExpected
    ) {
      return 'incomplete';
    }
    if (
      totaldocumentationExpected === completeUserDocumentation.length &&
      statusCount.validated === totaldocumentationExpected
    ) {
      return 'validated';
    }
    if (
      ((totaldocumentationExpected === completeUserDocumentation.length &&
        statusCount.sended === totaldocumentationExpected) ||
        (totaldocumentationExpected === completeUserDocumentation.length &&
          statusCount.sended !== totaldocumentationExpected) ||
        (totaldocumentationExpected === completeUserDocumentation.length &&
          statusCount.validated !== totaldocumentationExpected)) &&
      !statusCount.irregularity
    ) {
      return 'sended';
    }
    if (
      totaldocumentationExpected === completeUserDocumentation.length &&
      !!statusCount.irregularity
    ) {
      return 'irregularity';
    }
  }

  return currentUserDocumentStatus;
};
