import React, { useEffect, useState } from 'react';
import {
  Box,
  Text,
  Pressable,
  Icon,
  Flex,
  useTheme,
  IBoxProps,
  HStack,
} from 'native-base';
import { MaterialIcons, MaterialCommunityIcons } from '@expo/vector-icons';

type IconsColors = 'alert-circle' | 'clock' | 'check-circle';
type Icons = React.ComponentProps<typeof MaterialCommunityIcons>['name'];
interface DropdownProps extends IBoxProps {
  title: string;
  openDefault?: boolean;
  showIcon?: boolean;
  canOpen?: boolean;
  icon?: Icons;
  children: React.ReactNode;
}

export default function Dropdown({
  title,
  children,
  showIcon,
  icon,
  canOpen,
  openDefault = false,
  ...props
}: DropdownProps) {
  const { colors } = useTheme();
  const [isOpen, setIsOpen] = useState<boolean | undefined>();
  const secondaryColors = colors.secondary as typeof colors['gray'];
  const successColors = colors.success as typeof colors['gray'];

  useEffect(() => {
    if (openDefault) {
      setIsOpen(openDefault);
    }
  }, [openDefault]);

  const handleToggle = () => {
    if (!canOpen) return;
    setIsOpen(!isOpen);
  };

  const iconColors: { [key in IconsColors]: string } = {
    'alert-circle': colors.gray[400],
    clock: secondaryColors[900],
    'check-circle': successColors[800],
  };

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Box width="100%" {...props} pt={1} pb={isOpen ? 0 : 1}>
      <Pressable
        onPress={handleToggle}
        _web={{
          cursor: 'pointer',
        }}
      >
        <Flex
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <HStack alignItems="center">
            {showIcon && (
              <Icon
                mr={4}
                size={6}
                color={iconColors[icon as IconsColors] || colors.gray[500]}
                as={<MaterialCommunityIcons name={icon} />}
              />
            )}
            <Text
              color={
                icon !== 'alert-circle' ? colors.gray[700] : colors.gray[500]
              }
              fontSize="24"
            >
              {title}
            </Text>
          </HStack>
          <Icon
            size={6}
            mr={4}
            color={isOpen ? secondaryColors[900] : colors.gray[500]}
            as={
              <MaterialIcons
                name={isOpen ? 'arrow-drop-up' : 'arrow-drop-down'}
              />
            }
          />
        </Flex>
      </Pressable>
      {isOpen && children}
    </Box>
  );
}
