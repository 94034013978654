import React, { useContext } from 'react';
import { HStack, VStack, Flex, Box, Text, Center, useTheme } from 'native-base';
import { TripProps } from 'types/trips';

import useRideMenu from 'hooks/useRideMenu';
import { AuthContext } from 'context/AuthContext';
import RideDealsPhysical from 'screens/rides/RideDealsPhysical';
import { getTimeOfferOver, getBestOffer } from 'utils/rides';

export default function RideDealsMoral({
  trip,
  refetch,
}: {
  trip: TripProps;
  refetch: () => void;
}) {
  const { state: userProfile } = useContext(AuthContext);
  const { statusTrip } = useRideMenu();
  const { colors } = useTheme();

  const isDriverPhysicalAssociated =
    userProfile?.type === 'driver' &&
    userProfile.sub_type === 'physical' &&
    userProfile.reverse_subordinates.length > 0;

  const existsBestOffer = getBestOffer(trip?.offers);

  const isTimeOfferOver =
    trip.client_accepted_offer_at || getTimeOfferOver(trip?.inserted_at, 1);

  if (isDriverPhysicalAssociated) {
    return (
      <VStack
        px={3}
        mt={4}
        mb={0}
        shadow={6}
        rounded={8}
        bg={colors.gray[100]}
        width="full"
        height="155px"
        _web={{
          width: '100%',
        }}
        _ios={{
          borderRadius: 8,
          shadowColor: '#000000',
          shadowOffset: {
            width: 0,
            height: 8,
          },
          shadowOpacity: 0.22,
          shadowRadius: 2.22,
        }}
      >
        <HStack justifyContent="flex-end" pb={3}>
          <Box
            height="32px"
            bg={statusTrip.bg}
            borderBottomLeftRadius={8}
            borderBottomRightRadius={8}
          >
            <Text
              px={4}
              fontSize="sm"
              fontWeight="medium"
              color={colors.gray[100]}
              lineHeight="32px"
            >
              {statusTrip.message || 'Cargando mensaje...'}
            </Text>
          </Box>
        </HStack>
        <Flex height="90px" justifyContent="center" alignItems="center">
          <Text fontSize="16px" color={colors.gray[800]}>
            {trip.status === 'offer_accepted' || trip.status === 'completed'
              ? '¡Se ha aceptado una oferta!'
              : 'Aún no tienes ofertas aceptadas'}
          </Text>
        </Flex>
      </VStack>
    );
  }
  return (
    <>
      {isTimeOfferOver && !existsBestOffer ? (
        <Center mt={4} p={6} flex="1" rounded={8} bg={colors.gray[100]}>
          <Text fontSize="md" color={colors.gray[800]} textAlign="center">
            Este viaje ya no recibe más ofertas.
          </Text>
        </Center>
      ) : (
        <RideDealsPhysical trip={trip} refetch={refetch} />
      )}
    </>
  );
}
