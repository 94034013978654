import React from 'react';
import { Dimensions, Platform } from 'react-native';
import { Box, HStack, Text, useTheme, IBoxProps } from 'native-base';

import DatePicker from 'components/DatePicker';
import { getTimeOption } from 'utils/addressUtil';
import Select from 'components/Select';

type WebTimePickerProps = {
  handleChange: any;
  label: string;
  hourName?: 'horas' | string;
  minutesName?: 'minutos' | string;
  dateName: string;
  pickerProps?: IBoxProps;
  containerProps?: any;
  onlyDate?: boolean;
  values: { [key: string | number]: string | number | any };
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
};

export default function WebTimePicker({
  setFieldValue,
  values,
  handleChange,
  label,
  hourName = 'horas',
  minutesName = 'minutos',
  dateName,
  pickerProps,
  containerProps,
  onlyDate = false,
}: WebTimePickerProps) {
  const { colors } = useTheme();
  const { width } = Dimensions.get('window');

  const primaryColors = colors.primary as typeof colors['gray'];

  const hourOptions = getTimeOption('hour');
  const minutesOptions = getTimeOption('minutes');
  const isMobileWeb = width < 600 && Platform.OS === 'web';

  const witdthOnlydate = onlyDate ? '100%' : '60%';
  return (
    <Box {...containerProps}>
      <Text mb={4} fontSize="14px" color={primaryColors[900]}>
        {label}
      </Text>
      <HStack width="100%">
        <DatePicker
          label="Fecha"
          mode="datetime"
          name={dateName}
          value={values[dateName]}
          setFieldValue={setFieldValue}
          icon={Platform.OS !== 'web' ? 'calendar-today' : ''}
          isReadOnly={Platform.OS !== 'web'}
          inputProps={{
            mb: 8,
            width: isMobileWeb ? '50%' : witdthOnlydate,
          }}
          {...pickerProps}
        />
        {!onlyDate && (
          <HStack width={isMobileWeb ? '50%' : '40%'}>
            <Select
              label="Horas"
              name={hourName}
              options={hourOptions}
              selectedValue={values[hourName]}
              onValueChange={handleChange(hourName)}
              containerProps={{
                width: '50%',
              }}
            />
            <Select
              label="Min"
              name={minutesName}
              options={minutesOptions}
              selectedValue={values[minutesName]}
              onValueChange={handleChange(minutesName)}
              containerProps={{
                width: '50%',
              }}
            />
          </HStack>
        )}
      </HStack>
    </Box>
  );
}
