import { useTheme, useBreakpointValue } from 'native-base';

export default function useCommon() {
  const { colors } = useTheme();

  const cardBg = useBreakpointValue({
    base: 'transparent',
    sm: colors.gray[100],
  });
  const cardShadow = useBreakpointValue({
    base: 'none',
    sm: 'login',
  });
  const containerPt = useBreakpointValue({
    base: 0,
    sm: 10,
  });
  const containerPx = useBreakpointValue({
    base: 0,
    sm: 4,
  });
  const cardPy = useBreakpointValue({
    base: 0,
    md: 50,
  });
  const cardPx = useBreakpointValue({
    base: 0,
    md: 60,
  });
  const flexDirection = useBreakpointValue({
    base: 'column',
    md: 'row',
  });
  const flexWrap = useBreakpointValue({
    base: 'nowrap',
    md: 'wrap',
  });
  const flexJustify = useBreakpointValue({
    base: 'flex-start',
    md: 'space-between',
  });
  const flexAlign = useBreakpointValue({
    base: 'center',
    xl: 'flex-start',
  });

  const sizeCard = useBreakpointValue({
    base: '100%',
    md: '48%',
    lg: '100%',
    xl: '48%',
  });
  const marginLeft = useBreakpointValue({
    base: 0,
    xl: 130,
  });
  return {
    cardBg,
    cardPx,
    cardPy,
    flexWrap,
    sizeCard,
    flexAlign,
    marginLeft,
    cardShadow,
    containerPt,
    containerPx,
    flexJustify,
    flexDirection,
  };
}
