import React from 'react';
import { Button as NBButton, IButtonProps, Spinner } from 'native-base';

type Variants = 'primary' | 'secondary' | 'ghost' | 'link';

const buttons: { [k in Variants]: string } = {
  primary: 'primary.900',
  secondary: 'gray.500',
  ghost: 'white',
  link: 'transparent',
};

interface ButtonProps extends IButtonProps {
  button?: keyof typeof buttons;
  loading?: boolean;
  hide?: boolean;
}

export default function Button({
  children,
  button = 'primary',
  variant = 'solid',
  style,
  loading,
  hide,
  ...props
}: ButtonProps) {
  if (hide) return null;

  return (
    <NBButton
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      py={props.py || 4}
      bg={buttons[button]}
      height={props.height}
      rounded={props.rounded || 'full'}
      minWidth={props.minWidth}
      variant={buttons[button] === buttons.ghost ? 'ghost' : variant}
      style={{
        shadowColor: '#000',
        shadowOffset: {
          width: 0,
          height: 2,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 1,
      }}
      _text={{ fontSize: 'md' }}
    >
      {loading ? <Spinner accessibilityLabel="Loading posts" /> : children}
    </NBButton>
  );
}
