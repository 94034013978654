import React, { useEffect } from 'react';
import {
  Select as NBSelect,
  Icon,
  ISelectProps,
  Box,
  Text,
  FormControl,
  useTheme,
  IBoxProps,
} from 'native-base';
import { Animated } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';
import { useField } from 'formik';

type Option = { value: string; label: string };
type TextStyle = React.ComponentProps<typeof Animated.Text>['style'];
interface SelectProps extends ISelectProps {
  placeholder?: string;
  options: Option[];
  mbWrapper?: string | number;
  mtWrapper?: string | number;
  selectedValue?: string;
  label?: string;
  name?: string;
  mandatory?: boolean;
  containerProps?: IBoxProps;
}

export default function Select({
  options = [],
  mbWrapper = 8,
  mtWrapper = '0',
  label = 'Selecciona una opción',
  selectedValue = '',
  name = '',
  mandatory = true,
  containerProps = {},
  ...props
}: SelectProps) {
  const { colors } = useTheme();
  const animatedIsFocused = new Animated.Value(selectedValue === '' ? 0 : 1);
  const [_, meta] = useField(name);
  const isInvalid = Boolean(meta.error && meta.touched && !selectedValue);
  const primaryColors = colors.primary as typeof colors['gray'];

  useEffect(() => {
    Animated.timing(animatedIsFocused, {
      toValue: selectedValue !== '' ? 1 : 0,
      duration: 200,
      useNativeDriver: false,
    }).start();
  }, [animatedIsFocused, selectedValue]);

  const dynamicStyles: { [k in 'label']: TextStyle } = {
    label: {
      zIndex: 100,
      position: 'absolute',
      marginLeft: 16,
      color: animatedIsFocused.interpolate({
        inputRange: [0, 1],
        outputRange: [`${colors.gray[500]}`, `${primaryColors[900]}`],
      }),
      top: animatedIsFocused.interpolate({
        inputRange: [0, 1],
        outputRange: [18, 6],
      }),
      fontSize: animatedIsFocused.interpolate({
        inputRange: [0, 1],
        outputRange: [16, 12],
      }),
    },
  };
  return (
    <Box
      w="full"
      rounded={20}
      bg={colors.gray[300]}
      mb={mbWrapper}
      mt={mtWrapper}
      {...containerProps}
    >
      <FormControl isInvalid={isInvalid}>
        <Animated.Text style={dynamicStyles.label}>
          {label}{' '}
          {mandatory ? (
            <Text mx={0.5} color={colors.red[600]}>
              *
            </Text>
          ) : null}
        </Animated.Text>
        <NBSelect
          {...props}
          selectedValue={selectedValue}
          width="100%"
          placeholder=""
          h="58px"
          pt={6}
          px={3.5}
          rounded="2xl"
          dropdownIcon={
            <Icon
              size="md"
              color="gray.500"
              mr={2}
              as={<MaterialIcons name="arrow-drop-down" />}
            />
          }
        >
          <NBSelect.Item isDisabled={!selectedValue} label="" value="" />
          {options.map(({ value, label: optionLabel }) => (
            <NBSelect.Item key={value} label={optionLabel} value={value} />
          ))}
        </NBSelect>
      </FormControl>
    </Box>
  );
}
