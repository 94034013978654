/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createContext,
  useReducer,
  useMemo,
  useEffect,
  useState,
} from 'react';
import { useQuery } from 'react-query';
import { Toast } from 'native-base';
import axios from 'axios';
import { getItemStorage, setItemStorage, flushStorage } from 'utils';
import { getUserValidation } from 'utils/users';
import { GetProfile, GetUserVehicles } from 'api';
import { LoginReponse, ActionsReducerProps, User } from 'types/user';
import { UserVehiclesProps } from 'types/transportation';
import { isEmptyObject } from 'utils/validators';

function authReducer(state: User, action: ActionsReducerProps) {
  switch (action.type) {
    case 'SIGN_IN':
    case 'RESTORE_TOKEN':
    case 'UPDATE_USER':
      return {
        ...state,
        ...action.user,
      };
    case 'SIGN_OUT':
      return {} as User;
    default:
      return state;
  }
}

export const AuthContext = createContext<{
  signIn: (session: LoginReponse) => void;
  signOut: () => void;
  updateProfile: (user: Partial<User>) => void;
  state: User;
  isLoading: boolean;
  screen: string;
  showWelcome: boolean;
  isDocumentationValid: boolean | null;
}>({
  signIn: () => null,
  signOut: () => null,
  updateProfile: () => null,
  state: {} as User,
  isLoading: true,
  screen: '',
  showWelcome: false,
  isDocumentationValid: null,
});

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isDocumentationValid, setIsDocumentationValid] = useState<
    boolean | null
  >(null);
  const [screen, setScreen] = useState<string>('');
  const [showWelcome, setShowWelcome] = useState<boolean>(false);
  const [state, dispatch] = useReducer(authReducer, {} as User);

  const { refetch: refetchVehicles } = useQuery(
    'userVehicles',
    () => GetUserVehicles(state.id || ''),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: (vehicles: UserVehiclesProps[]) => {
        dispatch({
          type: 'UPDATE_USER',
          user: { vehicles },
        });
        handleAuthUser();
      },
      onError: async () => {
        Toast.show({
          title: 'Hubo un error al cargar el perfil del usuario intenta nuevamente.',
          placement: 'top',
        });
        await flushStorage();
        setIsDocumentationValid(null);
        dispatch({
          type: 'SIGN_OUT',
          user: {} as User,
        });
        setScreen('PublicStack');
        setIsLoading(false);
      },
    },
  );
  useQuery('profile', GetProfile, {
    refetchOnWindowFocus: false,
    enabled: Boolean(state.id),
    onSuccess: (userData: User) => {
      dispatch({
        type: 'UPDATE_USER',
        user: userData,
      });
      refetchVehicles();
      handleDocumentValidation(
        userData.document_status,
        userData.general_information_status,
      );
    },
    onError: async () => {
      Toast.show({
        title: 'Hubo un error al cargar el perfil del usuario intenta nuevamente.',
        placement: 'top',
      });
      await flushStorage();
      setIsDocumentationValid(null);
      dispatch({
        type: 'SIGN_OUT',
        user: {} as User,
      });
      setScreen('PublicStack');
      setIsLoading(false);
    },
  });

  useEffect(() => {
    initApp();
  }, []);

  const initApp = async () => {
    const session = JSON.parse(await getItemStorage('session'));

    if (!session) {
      setScreen('PublicStack');
      setIsLoading(false);
      return;
    }
    dispatch({
      type: 'SIGN_IN',
      user: {
        token: session.token,
        ...session,
      },
    });
    setCommonHeaders(session.token);
  };

  const endSession = async () => {
    await flushStorage();
    setIsDocumentationValid(null);
    dispatch({
      type: 'SIGN_OUT',
      user: {} as User,
    });
    setScreen('PublicStack');
    setIsLoading(false);
  };

  const handleDocumentValidation = (
    document_status: string,
    general_information_status: string,
  ) => {
    const isCompleteProfile = getUserValidation(
      document_status,
      general_information_status,
    );
    setIsDocumentationValid(isCompleteProfile);
  };

  const handleAuthUser = async () => {
    const showWelcomeFromStorage = !JSON.parse(
      await getItemStorage('showWelcome'),
    );
    let whichScreen = 'PublicStack';
    if (!isEmptyObject(state) && !isDocumentationValid) {
      whichScreen = 'IncompleteProfileStack';
    }
    if (!isEmptyObject(state) && isDocumentationValid) {
      whichScreen = 'AuthStack';
    }
    setShowWelcome(!isDocumentationValid && showWelcomeFromStorage);
    setScreen(whichScreen);
    setIsLoading(false);
  };

  const setCommonHeaders = async (token: string) => {
    if (token) {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    }
  };

  const setSessionToStorage = async ({
    token,
    user: { id, document_status, general_information_status },
  }: LoginReponse) => {
    await setItemStorage({
      key: 'session',
      value: JSON.stringify({
        token,
        id,
        document_status,
        general_information_status,
      }),
    });
  };

  const handleSignIn = async (session: LoginReponse) => {
    setSessionToStorage(session);
    setCommonHeaders(session.token);
    dispatch({
      type: 'UPDATE_USER',
      user: {
        token: session.token,
        ...session.user,
      },
    });
  };

  const handleUpdateProfile = (user: User) => {
    dispatch({
      type: 'UPDATE_USER',
      user,
    });
  };

  const authContext = useMemo(
    () => ({
      signIn: async (session: LoginReponse) => {
        handleSignIn(session);
      },
      signOut: async () => {
        endSession();
      },
      updateProfile: async (user: Partial<User>) => {
        handleUpdateProfile(user);
      },
    }),
    [],
  );

  return (
    <AuthContext.Provider
      value={{
        ...authContext,
        isLoading,
        isDocumentationValid,
        screen,
        state,
        showWelcome,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
