/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import { MaterialIcons } from '@expo/vector-icons';
import { Box, Heading, Text, HStack, useTheme, Pressable } from 'native-base';
import { AddressProps, CardAddressProps } from 'types/address';

export default function CardAddress(props: CardAddressProps & AddressProps) {
  const {
    id,
    street,
    int_number,
    ext_number,
    neiborhood,
    city,
    state,
    zip_code,
    address_reference,
    address_name = '',
    addressSelected,
    canChangeAddress,
    canEditAddress,
    start_address_id = '',
    end_address_id = '',
    onEditAddress = () => {},
    onSelectAddress = () => {},
    onChangeAddress = () => {},
  } = props;
  const { colors } = useTheme();
  return (
    <Pressable
      onPress={() =>
        start_address_id !== id && end_address_id !== id && onSelectAddress(id)
      }
      _pressed={{ shadow: 9 }}
      rounded="2xl"
      shadow={
        addressSelected === id ||
        start_address_id === id ||
        end_address_id === id
          ? 9
          : 0
      }
      bg={
        // eslint-disable-next-line no-nested-ternary
        addressSelected === id
          ? 'white'
          : start_address_id === id || end_address_id === id
          ? colors.purple[100]
          : 'white'
      }
      borderWidth={1}
      borderColor={
        addressSelected === id ||
        start_address_id === id ||
        end_address_id === id
          ? colors.purple[800]
          : 'gray.300'
      }
      w="full"
      mb={4}
    >
      <Box p={4}>
        <HStack alignItems="center" justifyContent="space-between">
          <Heading fontWeight="normal" size="sm">
            {address_name}
          </Heading>
          {canChangeAddress ? (
            <Pressable p={2} onPress={onChangeAddress}>
              <Text fontWeight="bold" color="primary.700">
                Cambiar
              </Text>
            </Pressable>
          ) : null}
          {start_address_id === id && (
            <Text fontWeight="bold" color="primary.700">
              Origen
            </Text>
          )}
          {end_address_id === id && (
            <Text fontWeight="bold" color="primary.700">
              Destino
            </Text>
          )}
          {canEditAddress ? (
            <Pressable zIndex={10} onPress={onEditAddress} p={1}>
              <MaterialIcons name="edit" size={24} color={colors.gray[500]} />
            </Pressable>
          ) : null}
        </HStack>
        <Box mb={2}>
          <Text fontSize="md">
            <Text bold>Calle</Text> {street}, <Text bold>No. Ext. </Text>
            {ext_number}, <Text bold>No. Int. </Text>
            {int_number || 'sin número interior'}, {neiborhood}, {city}, {state}{' '}
            C.P. {zip_code}
          </Text>
        </Box>
        <Heading fontWeight="normal" size="sm" mb={1}>
          Referencias
        </Heading>
        <Text>{address_reference}</Text>
      </Box>
    </Pressable>
  );
}
