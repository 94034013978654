import { format, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';

export const formatDate = (value: Date | string, dateFormat?: string) => {
  const cleanValue = typeof value === 'object' ? value : parseISO(value);
  const currentFormat = dateFormat || 'dd/MM/yyyy';
  return format(cleanValue, currentFormat);
};
export const formatDateTime = (value: Date | string) => {
  const cleanValue = typeof value === 'object' ? value : parseISO(value);
  return `${format(cleanValue, 'dd/MMMM/yyyy', { locale: es })} a las ${format(
    cleanValue,
    'HH:mm',
    { locale: es },
  )} hrs`;
};
export const formatDateTimeCard = (value: Date | string) => {
  const cleanValue = typeof value === 'object' ? value : parseISO(value);
  if (!value) return '-';
  return `${format(cleanValue, 'dd MMMM', { locale: es })} ${format(
    cleanValue,
    'HH:mm',
    { locale: es },
  )} hrs`;
};

export const formatDateTimeCardShort = (value: Date | string) => {
  const cleanValue = typeof value === 'object' ? value : parseISO(value);
  if (!value) return '-';
  return `${format(cleanValue, 'dd MMM', { locale: es })} ${format(
    cleanValue,
    'HH:mm',
    { locale: es },
  )} hrs`;
};

export const formatCurrency = (value: number) => {
  return `$${value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
};
export const getPercentage = (amount: string, service: number) => {
  const percentage = 50;
  const total = Number(amount) + Number(service);
  return formatCurrency((percentage / 100) * total);
};

const DATE_UNITS = {
  day: 86400,
  hour: 3600,
  minute: 60,
  second: 1,
};

export const timeAgo = (date: string | Date) => {
  const timestamp = new Date(date).getTime();
  const parseTime = (Date.now() - timestamp) / 1000;

  const days = Math.floor(parseTime / DATE_UNITS.day);

  const diffHourPerDay = Math.floor(
    Math.floor(parseTime % DATE_UNITS.day) / DATE_UNITS.hour,
  );

  const hours = Math.floor(parseTime / DATE_UNITS.hour);

  const minutes =
    Math.floor(parseTime / DATE_UNITS.minute) - hours * DATE_UNITS.minute;

  const seconds = parseTime % DATE_UNITS.minute;

  if (Number.isNaN(parseTime)) return `${date}`;
  if (days > 30) return formatDate(date);
  if (days > 0) return `${days} Día(s) ${diffHourPerDay}h ${minutes}min`;
  if (hours > 0) return `${hours}h ${minutes}min`;
  if (minutes > 0) return `${minutes}min`;
  return `${seconds} seg`;
};
