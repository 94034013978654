import React, { Fragment } from 'react';
import { MaterialIcons, MaterialCommunityIcons } from '@expo/vector-icons';
import * as Clipboard from 'expo-clipboard';
import {
  Box,
  HStack,
  Text,
  useTheme,
  VStack,
  Link,
  Image,
  Pressable,
  Toast,
} from 'native-base';
import { Products, TripProps, StartAddress, EndAddress } from 'types/trips';
import { formatDateTimeCardShort, formatCurrency } from 'utils/formats';
import { createURL } from 'utils/addressUtil';

export default function RideDetails({ trip }: { trip: TripProps }) {
  const { colors } = useTheme();

  const successColors = colors.success as typeof colors['gray'];
  const secondaryColors = colors.secondary as typeof colors['gray'];

  const products = trip.products || ([] as Products[]);
  const startAddress = trip.start_address || ({} as StartAddress);
  const endAddress = trip.end_address || ({} as EndAddress);

  const handleCopy = () => {
    Clipboard.setString(trip.id);
    Toast.show({
      title: 'Folio copiado',
      placement: 'top',
    });
  };

  return (
    <>
      <Box
        flex="1"
        bg={colors.gray[100]}
        px={4}
        mt={4}
        mb={0}
        shadow={6}
        rounded={8}
        width="full"
        _web={{
          width: '100%',
        }}
        _ios={{
          borderRadius: 8,
        }}
      >
        <VStack justifyContent="space-between" my={4} space={4}>
          <Pressable onPress={handleCopy}>
            <Text color={colors.gray[700]} fontWeight="medium">
              Folio
            </Text>
            <HStack justifyContent="space-between">
              <Text
                fontSize="14px"
                color={colors.gray[600]}
                w="90%"
                isTruncated
              >
                {trip.id}
              </Text>
              <MaterialIcons
                name="file-copy"
                size={24}
                color={colors.gray[700]}
              />
            </HStack>
          </Pressable>
          <VStack>
            <Text color={colors.gray[700]} fontWeight="medium">
              Presupuesto
            </Text>
            <Text fontSize="14px" color={colors.gray[600]}>
              {formatCurrency(Number(trip.budget))}
            </Text>
          </VStack>
        </VStack>
        <VStack mb={6}>
          <Text fontSize="12px" color={successColors[900]}>
            Viaje {trip.type}
          </Text>
          <Text fontSize="16px" color={colors.gray[700]}>
            {startAddress.city} - {endAddress.city}
          </Text>
        </VStack>
        <HStack mb={6} space="3">
          <HStack space="3" w="50%">
            <MaterialCommunityIcons
              name="clock"
              size={24}
              color={secondaryColors[900]}
            />
            <VStack>
              <Text color={colors.gray[700]} fontWeight="medium">
                Salida
              </Text>
              <Text fontSize="xs" color={colors.gray[700]}>
                {formatDateTimeCardShort(trip.start_at)}
              </Text>
            </VStack>
          </HStack>
          <HStack space="3" w="50%">
            <MaterialCommunityIcons
              name="clock"
              size={24}
              color={successColors[900]}
            />
            <VStack>
              <Text color={colors.gray[700]} fontWeight="medium">
                Llegada estimada
              </Text>
              <Text fontSize="xs" color={colors.gray[700]}>
                {formatDateTimeCardShort(trip.end_at)}
              </Text>
            </VStack>
          </HStack>
        </HStack>
        <HStack mb={6} space="3">
          <MaterialCommunityIcons
            size={24}
            name="truck"
            color={colors.gray[600]}
          />
          <VStack>
            <Text color={colors.gray[700]} fontWeight="medium">
              {trip.vehicle?.vehicle_type?.name || ''}
            </Text>
            <Text fontSize="xs" color={colors.gray[700]}>
              {trip.vehicle?.configuration} - {trip.vehicle?.charge} kg
            </Text>
          </VStack>
        </HStack>
        {products.length > 0 &&
          products.map(({ id, volume, weight, description, photos }) => (
            <Fragment key={id + products.length + 1}>
              <VStack mb={6}>
                <Text color={colors.gray[700]} fontWeight="medium">
                  Descripción de producto a transportar
                </Text>
                <Text fontSize="12px" color={colors.gray[700]}>
                  {description}
                </Text>
              </VStack>
              <HStack justifyContent="space-between" mb={6} space="3">
                <HStack space="3" w="50%">
                  <Image
                    source={require('assets/images/weight.png')}
                    boxSize={7}
                    alt="Peso"
                  />
                  <VStack>
                    <Text color={colors.gray[700]} fontWeight="medium">
                      Peso
                    </Text>
                    <Text fontSize="xs" color={colors.gray[700]}>
                      {weight} Ton
                    </Text>
                  </VStack>
                </HStack>
                <HStack space="3" w="50%">
                  <Image
                    source={require('assets/images/package.png')}
                    boxSize={7}
                    alt="Paquete"
                  />
                  <VStack>
                    <Text color={colors.gray[700]} fontWeight="medium">
                      Volumen
                    </Text>
                    <Text fontSize="xs" color={colors.gray[700]}>
                      {volume} m3
                    </Text>
                  </VStack>
                </HStack>
              </HStack>
              {photos.map(({ id: photoId, url }) => (
                <Image
                  mb={6}
                  key={photoId}
                  width="100%"
                  height="300px"
                  bg={colors.gray[200]}
                  resizeMode="contain"
                  source={{
                    uri: url,
                  }}
                  alt="Paquete a enviar"
                />
              ))}
            </Fragment>
          ))}

        <VStack mb={6}>
          <Text fontSize="14px" color={colors.gray[700]}>
            Dirección de origen
          </Text>
          <Text fontSize="md" mb={2}>
            <Text bold>Calle</Text> {startAddress.street || 'Sin calle'},{' '}
            <Text bold>No. Ext. </Text>
            {startAddress.ext_number || 'sin número exterior'},{' '}
            <Text bold>No. Int. </Text>
            {startAddress.int_number || 'sin número interior'},{' '}
            {startAddress.neiborhood}, {startAddress.city}, {startAddress.state}{' '}
            C.P. {startAddress.zip_code}
          </Text>
          <VStack>
            <Text fontSize="14px" color={colors.gray[700]}>
              Nota
            </Text>
            <Text fontSize="12px" color={colors.gray[700]}>
              {startAddress.address_reference}
            </Text>
            <Link
              px={3}
              py={4}
              alignSelf="flex-end"
              justifyContent="flex-end"
              href={createURL(trip.start_address)}
              isExternal
            >
              Ir a mapa
            </Link>
          </VStack>
        </VStack>
        <VStack mb={2}>
          <Text fontSize="14px" color={colors.gray[700]}>
            Dirección de destino
          </Text>
          <Text fontSize="md" mb={2}>
            <Text bold>Calle</Text> {endAddress.street || 'Sin calle'},{' '}
            <Text bold>No. Ext. </Text>
            {endAddress.ext_number || 'sin número exterior'},{' '}
            <Text bold>No. Int. </Text>
            {endAddress.int_number || 'sin número interior'},{' '}
            {endAddress.neiborhood}, {endAddress.city}, {endAddress.state} C.P.{' '}
            {endAddress.zip_code}
          </Text>
          <VStack>
            <Text fontSize="14px" color={colors.gray[700]}>
              Nota
            </Text>
            <Text fontSize="12px" color={colors.gray[700]}>
              {endAddress.address_reference}
            </Text>
            <Link
              px={3}
              py={4}
              alignSelf="flex-end"
              justifyContent="flex-end"
              href={createURL(trip.end_address)}
              isExternal
            >
              Ir a mapa
            </Link>
          </VStack>
        </VStack>
      </Box>
    </>
  );
}
