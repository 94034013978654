import React from 'react';
import {
  createNativeStackNavigator,
  NativeStackHeaderProps,
} from '@react-navigation/native-stack';
import Login from 'screens/Login';
import ProfileType from 'screens/ProfileType';
import Registration from 'screens/Registration';
import ValidationNIP from 'screens/ValidationNIP';
import ResetPassword from 'screens/ResetPasswordByEmail';
import BackHeader from 'components/BackHeader';
import Terms from 'screens/Terms';

const Stack = createNativeStackNavigator();
export default function PublicStack() {
  return (
    <Stack.Navigator initialRouteName="Login">
      <Stack.Screen
        name="Login"
        component={Login}
        options={{
          title: 'Login',
          header: (props: NativeStackHeaderProps) => (
            <BackHeader hideNotification {...props} />
          ),
        }}
      />
      <Stack.Screen
        name="ProfileType"
        component={ProfileType}
        options={{
          header: (props: NativeStackHeaderProps) => (
            <BackHeader iconBack hideNotification {...props} />
          ),
        }}
      />
      <Stack.Screen
        name="Terms"
        component={Terms}
        options={{
          header: (props: NativeStackHeaderProps) => (
            <BackHeader iconBack hideNotification {...props} />
          ),
        }}
      />
      <Stack.Screen
        name="Registration"
        component={Registration}
        options={{
          header: (props: NativeStackHeaderProps) => (
            <BackHeader iconBack hideNotification {...props} />
          ),
        }}
      />
      <Stack.Screen
        name="ValidationNIP"
        component={ValidationNIP}
        options={{
          title: 'Validación de NIP',
        }}
      />
      <Stack.Screen
        name="ResetPasswordByEmail"
        component={ResetPassword}
        options={{
          header: (props: NativeStackHeaderProps) => (
            <BackHeader iconBack hideNotification {...props} />
          ),
        }}
      />
    </Stack.Navigator>
  );
}
