import React, { useEffect, useState } from 'react';
import {
  Box,
  Image,
  Text,
  Center,
  Stack,
  Divider,
  useTheme,
  VStack,
  Toast,
  HStack,
  Pressable,
} from 'native-base';
import { Platform } from 'react-native';
import * as Clipboard from 'expo-clipboard';
import { MaterialIcons } from '@expo/vector-icons';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { getHalfPayment } from 'utils/rides';
import { VehiclesProps } from 'types/transportation';
import { PaymentToSendProps } from 'types/trips';
import useDocuments, { Action } from 'hooks/useDocuments';
import CardOfferingSelected from 'components/CardOfferingSelected';
import Button from 'components/Button';
import LabelPressable from 'components/LabelPressable';
import ModalPackage from 'components/modals/ModalPackage';

export default function PaymentClient({
  tripId,
  bestOfferAmount,
  vehicle,
  service = 0,
  paymentStatus,
  label,
  onSubmitPayment,
}: {
  tripId: string;
  payment?: 'initial' | 'final';
  bestOfferAmount: string;
  vehicle?: Partial<VehiclesProps>;
  service: number;
  paymentStatus: string;
  onSubmitPayment: (bodyPayment: PaymentToSendProps) => void;
  label: string;
}) {
  const { selectedDocument, onSelectedDocument } = useDocuments();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [picture, setPicture] = useState<string>('');
  const { colors } = useTheme();
  const InitialPaymentSchema = Yup.object()
    .shape({
      picturePayment: Yup.string(),
    })
    .when((_, schema) => {
      if (!picture) {
        return schema.shape({
          picturePayment: Yup.string().required('El campo es requerido'),
        });
      }
      return schema.shape({
        picturePayment: Yup.string().notRequired(),
      });
    });

  useEffect(() => {
    if (selectedDocument) {
      setPicture(selectedDocument?.uri);
    }
  }, [selectedDocument]);

  const handleTakePhoto = () => {
    setOpenModal(!openModal);
  };

  const handleSelectedPhoto = (action: Action) => {
    onSelectedDocument({
      action,
      web: { mimeType: 'image/*' },
    });
    setOpenModal(!openModal);
  };

  const handleCopy = (nameNumber: string, accountNumber: string) => {
    Clipboard.setString(accountNumber);
    Toast.show({
      title: `Número de ${nameNumber} copiada`,
      placement: 'top',
    });
  };

  const handleSubmitInitialPayment = () => {
    const bodyPayment = {
      dataToSend: {
        base64: selectedDocument?.base64 || '',
        amount: Number(getHalfPayment(bestOfferAmount, service)),
      },
      tripId,
    };
    onSubmitPayment(bodyPayment);
  };
  return (
    <>
      <Stack flex="1">
        <Box
          shadow={6}
          p={4}
          mt={4}
          mb={4}
          bg="gray.100"
          rounded={8}
          width="full"
          _web={{
            width: '100%',
          }}
          _ios={{
            borderRadius: 8,
          }}
        >
          <Text fontSize="md" color={colors.gray[900]}>
            Datos para hacer deposito
          </Text>
          <Divider my={4} />
          <Box>
            <Box>
              <HStack justifyContent="space-between" alignItems="center" mb={4}>
              <VStack justifyContent="space-between">
                  <Text color={colors.gray[600]}>Banco</Text>
                  <Text
                    color={colors.gray[600]}
                    fontSize="md"
                    fontWeight="medium"
                  >
                    BBVA Bancomer
                  </Text>
                </VStack>
              </HStack>
              <HStack justifyContent="space-between" alignItems="center" mb={4}>
                <VStack justifyContent="space-between">
                  <Text color={colors.gray[600]}>Clabe Interbancaria</Text>
                  <Text
                    color={colors.gray[600]}
                    fontSize="md"
                    fontWeight="medium"
                  >
                    012540001116325744
                  </Text>
                </VStack>
                <Pressable p={4} onPress={() => handleCopy('clabe interbancaria', '012540001116325744')}>
                  <MaterialIcons
                    name="file-copy"
                    size={24}
                    color={colors.gray[700]}
                  />
                </Pressable>
              </HStack>
              <HStack justifyContent="space-between" alignItems="center" mb={4}>    
                <VStack justifyContent="space-between">
                  <Text color={colors.gray[600]}>Número de cuenta</Text>
                  <Text
                    color={colors.gray[600]}
                    fontSize="md"
                    fontWeight="medium"
                  >
                    0111632574
                  </Text>
                </VStack>
                <Pressable p={4} onPress={() => handleCopy('cuenta', '0111632574')}>
                  <MaterialIcons
                    name="file-copy"
                    size={24}
                    color={colors.gray[700]}
                  />
                </Pressable>
              </HStack>
            </Box>
            <Box>
              <Text color={colors.gray[500]} fontWeight={500} fontSize="xs">
                Nombre de la cuenta
              </Text>
              <Text color={colors.gray[600]} fontWeight={500} fontSize="md">
              FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES SA DE CV
              </Text>
            </Box>
          </Box>
        </Box>
        <CardOfferingSelected
          paymentStatus={paymentStatus}
          title="Infast Logistics"
          halfPaymentTitle={label}
          {...{ bestOfferAmount, vehicle, service }}
        >
          <Box mt={8}>
            <Formik
              validationSchema={InitialPaymentSchema}
              initialValues={{ picturePayment: '' }}
              onSubmit={handleSubmitInitialPayment}
              enableReinitialize
            >
              {({ handleSubmit, values }) => (
                <>
                  {picture ? (
                    <Center flex="1" w="100%" mb={10}>
                      <Image
                        w="full"
                        h="250px"
                        resizeMode="contain"
                        source={{
                          uri: picture,
                        }}
                        alt="Done trip picture"
                      />
                    </Center>
                  ) : (
                    <LabelPressable
                      text="Subir comprobante de pago"
                      icon="photo-camera"
                      onLabelPress={handleTakePhoto}
                      iconRounded={false}
                      value={values.picturePayment}
                      name="picturePayment"
                      mb={8}
                      iconProps={{
                        color: 'primary.900',
                      }}
                      mandatory
                    />
                  )}
                  <Button button="primary" onPress={() => handleSubmit()}>
                    Confirmar
                  </Button>
                </>
              )}
            </Formik>
          </Box>
        </CardOfferingSelected>
      </Stack>
      <ModalPackage
        open={openModal}
        showUploadMobileFile={Platform.OS !== 'web'}
        onSelectDocument={handleSelectedPhoto}
      />
    </>
  );
}
