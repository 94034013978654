import * as FileSystem from 'expo-file-system';
import { formatISO, isMatch } from 'date-fns';
import {
  DateAndAddressProps,
  GeocodingProps,
  ProductTypeProps,
} from 'types/address';
import { EndAddress, StartAddress } from 'types/trips';
import {
  CreateTripProps,
  TransportAndPaymentProps,
} from 'types/transportation';
import { Platform } from 'react-native';
import { getItemStorage } from 'utils';
import { isEmptyObject } from './validators';

export const createColoniaOptions = (copomex: any) => {
  return copomex.map((item: any) => ({
    label: item.response.asentamiento,
    value: item.response.asentamiento,
    state: item.response.estado,
    city: item.response.municipio,
  }));
};

// export const createColoniaOptions = (copomex: CopomexProps) => {
//   return copomex.response.asentamiento.map((item: string) => ({
//     label: item,
//     value: item,
//     state: copomex.response.estado,
//     city: copomex.response.municipio,
//   }));
// };

export const getStateFromColonia = (
  copomexDataRef: any,
  selectedNeiborhood: string,
) => {
  return copomexDataRef.find(
    ({ label }: { label: string }) => label === selectedNeiborhood,
  );
};
export const getUriPicture = async (photo: string) => {
  if (Platform.OS !== 'web') {
    const getImage = await FileSystem.getInfoAsync(photo);
    return getImage.uri || '';
  }
  return photo;
};
export const getStepData = async (step: any) => {
  const stepData = (await getItemStorage(step)) || {};
  if (isEmptyObject(stepData)) {
    return null;
  }
  return JSON.parse(stepData);
};

export const getLocationAndViewport = ({ geometry }: GeocodingProps) => {
  const { location = {}, viewport = {} } = geometry || {};
  return { location, viewport };
};
export const getReverseLocation = ({ address_components, geometry }: any) => {
  const [ext_number, street] = address_components || {};
  const { location = {}, viewport = {} } = geometry || {};
  return {
    street: street.long_name,
    ext_number: ext_number.long_name,
    location,
    viewport,
  };
};

export const createAddressBodyRaw = async (
  id: string,
  stepOne: DateAndAddressProps,
  stepTwo: ProductTypeProps,
  stepThree: TransportAndPaymentProps,
) => {
  const getImageBase64 =
    Platform.OS !== 'web' &&
    (await FileSystem.readAsStringAsync(stepTwo?.picturePackage, {
      encoding: FileSystem.EncodingType.Base64,
    }));

  const imageToSend = getImageBase64
    ? `data:application/jpg;base64,${getImageBase64}`
    : stepTwo?.picturePackage;

  const validDate = isMatch(stepOne.start_at, 'dd/MM/yyyy');
  const startDate =
    validDate && Platform.OS === 'web'
      ? stepOne.start_at.split('/')
      : stepOne.start_at;
  const endDate =
    validDate && Platform.OS === 'web'
      ? stepOne.end_at.split('/')
      : stepOne.end_at;

  const webStartDate = formatISO(
    new Date(
      Number(startDate[2]), // year
      Number(startDate[1]) - 1, // month
      Number(startDate[0]), // day
      Number(stepOne.hourStart),
      Number(stepOne.minutesStart),
    ),
  );
  const webEndDate = formatISO(
    new Date(
      Number(endDate[2]), // year
      Number(endDate[1]) - 1, // month
      Number(endDate[0]), // day
      Number(stepOne.hourEnd),
      Number(stepOne.minutesEnd),
    ),
  );
  const validateStartDate =
    Platform.OS === 'web' ? webStartDate : stepOne.start_at;
  const validateEndDate = Platform.OS === 'web' ? webEndDate : stepOne.end_at;

  const valuesTosubmit: CreateTripProps = {
    user_id: id,
    trip: {
      budget: Number(stepThree.budget),
      vehicle_id: stepThree.vehicle_id,
      start_at: validateStartDate,
      end_at: validateEndDate,
      description: 'testing',
      start_address_id: stepOne.start_address_id ?? '',
      end_address_id: stepOne.end_address_id ?? '',
    },
    products: [
      {
        description: stepTwo.description,
        weight: Number(stepTwo?.weight),
        volume: Number(stepTwo?.volume),
        photos: [
          {
            base64: imageToSend,
          },
        ],
      },
    ],
  };
  return valuesTosubmit;
};

export const createURL = (address: Partial<StartAddress & EndAddress>) => {
  const addressQuery = `${address?.street} ${address.ext_number} ${address.neiborhood} ${address.city} ${address.zip_code}`;
  return `https://www.google.com/maps/search/?api=1&query=${addressQuery}`;
};

const generateArray = (n: number) => [...Array(n)].map((_, index) => index + 1);
export const getTimeOption = (type: 'hour' | 'minutes') => {
  const time = generateArray(type === 'hour' ? 24 : 59);
  const currentTime = type === 'minutes' ? ['0', ...time] : time;
  return currentTime.map(item => {
    const label = item < 10 ? `0${item}` : item;
    return {
      label: String(label),
      value: String(item),
    };
  });
};
