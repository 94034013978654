import React, { useState, useEffect, useContext } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';

import { map } from 'lodash';
import { MaterialCommunityIcons } from '@expo/vector-icons';

import {
  Box,
  ScrollView,
  Text,
  useTheme,
  VStack,
  Icon,
  Input,
  Stack,
  Toast,
} from 'native-base';

import {
  KeyboardAvoidingView,
  TouchableWithoutFeedback,
  Keyboard,
  Platform,
} from 'react-native';

import { colors as Colors } from 'constants/colors';
import { formatDateTimeCardShort } from 'utils/formats';
import { AuthContext } from 'context/AuthContext';
import { ListMessages, NewMessage } from 'api';
import { Message } from 'types/chat';
import useNotification from 'hooks/useNotification';
import { useRoute } from '@react-navigation/native';

// --------------------------------------------------------------------------

function SupportMessage({
  colors,
  message,
}: {
  colors: typeof Colors;
  message: Message;
}) {
  return (
    <Stack
      bg={colors.gray[100]}
      borderTopRadius="16px"
      borderRightRadius="16px"
      minHeight="40px"
      padding="10px"
      width="300px"
      mb={4}
    >
      <VStack>
        <Text fontSize="16px">{message.message}</Text>
        <Text alignSelf="flex-end" color={colors.gray[500]} fontSize="12px">
          {formatDateTimeCardShort(message.inserted_at)}
        </Text>
      </VStack>
    </Stack>
  );
}

// --------------------------------------------------------------------------

function ClientMessage({
  colors,
  message,
}: {
  colors: typeof Colors;
  message: Message;
}) {
  return (
    <Stack
      bg={colors.gray[300]}
      borderTopRadius="16px"
      borderLeftRadius="16px"
      minHeight="40px"
      padding="10px"
      width="300px"
      mb={4}
      alignSelf="flex-end"
    >
      <VStack>
        <Text fontSize="16px">{message.message}</Text>
        <Text color={colors.gray[500]} fontSize="12px">
          {formatDateTimeCardShort(message.inserted_at)}
        </Text>
      </VStack>
    </Stack>
  );
}

// --------------------------------------------------------------------------
// --------------------------------------------------------------------------
//
// Component
//
// --------------------------------------------------------------------------
// --------------------------------------------------------------------------

export default function Chat() {
  const route = useRoute();
  const { colors } = useTheme();
  const { state } = useContext(AuthContext);
  const [currentMessage, setCurrentMessage] = useState('');
  const [roomId, setRoomId] = useState('');
  const { notifications, recentNotification, removeNotifications } =
    useNotification();

  const { id: client_id = '' } = state;
  const isWeb = Platform.OS === 'web';

  const {
    charge,
    type,
    comment = '',
    screen,
  } = (route.params as {
    charge: string;
    type: string;
    comment: string;
    screen: string;
  }) || {};

  useEffect(() => {
    const transportationMessage =
      screen === 'transportation'
        ? `Buen día. Quiero agregar un vehículo. ${
            charge ? `${charge},` : ''
          } ${type ? `${type},` : ''} ${comment}`
        : '';

    setCurrentMessage(transportationMessage);
  }, [charge, comment, screen, type]);

  const default_mesage: Message = {
    id: '',
    sender_id: '',
    room_id: '',
    message: 'Bienvenido al chat ¿en que te podemos ayudar?',
    inserted_at: '',
    type: '',
    viewed: false,
    sender_name: '',
  };

  // --------------------------------------------------------------------------
  // API requests
  // --------------------------------------------------------------------------
  const queryClient = useQueryClient();

  const {
    isLoading,
    isError,
    data,
    refetch: refetchMessages,
  } = useQuery(['listMessages', client_id], () => ListMessages(client_id));

  const messages = data || ({} as Message);
  const mutation = useMutation(NewMessage, {
    onSuccess: () => queryClient.invalidateQueries('listMessages'),
  });

  // --------------------------------------------------------------------------
  // useEffect
  // --------------------------------------------------------------------------

  useEffect(() => {
    if (isLoading === false && isError === false) {
      if (Object.values(messages).length > 0) {
        const first_message = Object.values(messages)[0];
        setRoomId(first_message.room_id)
      }

      if (Object.keys(notifications).length > 0) {
        Object.entries(notifications).forEach(([key, notification]) => {
          const {
            request: { content, identifier },
          } = notification;

          if (content.data.context === 'SECTION_MESSAGES') {
            removeNotifications(identifier);
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, isLoading, messages]);

  useEffect(() => {
    if (recentNotification !== undefined) {
      const {
        request: { content, identifier },
      } = recentNotification;

      if (content.data.context === 'SECTION_MESSAGES') {
        refetchMessages();

        if (!isWeb) {
          removeNotifications(identifier);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(recentNotification)]);

  // --------------------------------------------------------------------------

  if (isLoading === false && isError === true) {
    Toast.show({
      title: 'Ocurrio un error. Intenta de nuevo',
      placement: 'top',
    });
  }

  if (Object.keys(notifications).length > 0) {
    let counter = 0;

    Object.entries(notifications).forEach(([key, notification]) => {
      const {
        request: { content, identifier },
      } = notification;

      if (content.data.context === 'SECTION_MESSAGES') {
        removeNotifications(identifier);
        counter++;
      }
    });

    if (counter > 0) {
      refetchMessages();
    }
  }

  // --------------------------------------------------------------------------
  // Functions
  // --------------------------------------------------------------------------

  const newMessage = (roomid: string, sender: string) => {
    if (currentMessage !== '') {
      const message = {
        message: {
          room_id: roomid,
          sender_id: sender,
          type: 'text',
          message: currentMessage,
        },
      };

      mutation.mutate(message);
      setCurrentMessage('');
    }
  };

  const onChangeMessage = (text: string) => {
    setCurrentMessage(text);
  };

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      keyboardVerticalOffset={90}
      style={{ flex: 1 }}
    >
      <TouchableWithoutFeedback
        disabled
        onPress={Keyboard.dismiss}
        style={{ flex: 1 }}
      >
        <Box flex="1" bg={colors.gray[200]}>
          <ScrollView flex="1" _contentContainerStyle={{ py: 4, px: 4 }}>
            <SupportMessage colors={colors} message={default_mesage} />
            {map(messages, (message: Message, index: number) => {
              return message.sender_id === client_id ? (
                <ClientMessage colors={colors} message={message} key={index} />
              ) : (
                <SupportMessage colors={colors} message={message} key={index} />
              );
            })}
          </ScrollView>
          <Stack
            py="20px"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-around"
            width="100%"
            bg={colors.gray[100]}
            _web={{
              borderTopStyle: 'solid',
              borderTopWidth: '1px',
              borderTopColor: colors.gray[300],
            }}
          >
            <Icon
              mt="1"
              ml={2}
              mr={2}
              size={6}
              color="rgba(255, 255, 255, 0)"
              // as={<MaterialCommunityIcons name="paperclip" />}
            />
            <Input
              height="40px"
              width="90%"
              minWidth="270px"
              value={currentMessage}
              placeholder="Escribe un mensaje"
              onChangeText={onChangeMessage}
              _ios={{ width: '75%' }}
            />
            <Icon
              mt="1"
              ml="4"
              mr={2}
              size={6}
              color={colors.gray[500]}
              as={<MaterialCommunityIcons name="arrow-right-circle" />}
              onPress={() => newMessage(roomId, client_id)}
            />
          </Stack>
        </Box>
      </TouchableWithoutFeedback>
    </KeyboardAvoidingView>
  );
}
