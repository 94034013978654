/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useContext, useEffect, useState } from 'react';
import { Box, Heading, Text, ScrollView, useTheme, Toast } from 'native-base';
import { useNavigation, CommonActions } from '@react-navigation/native';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { isEmptyArray } from 'utils/validators';
import {
  isEqual,
  add,
  addMinutes,
  getHours,
  getMinutes,
  isBefore,
} from 'date-fns';
import {
  PageDateAndAddressProps,
  AddressProps,
  DateAndAddressProps,
  StartEndAddressProps,
} from 'types/address';
import LabelPressable from 'components/LabelPressable';
import Modal from 'components/Modal';
import DatePicker from 'components/DatePicker';
import Button from 'components/Button';
import CardAddress from 'components/CardAddress';
import { getStepData } from 'utils/addressUtil';
import { setItemStorage } from 'utils';
import { useQuery } from 'react-query';
import { GetAddresses } from 'api';
import { AuthContext } from 'context/AuthContext';
import { Platform } from 'react-native';
import { dateInputRegex, isWebDesktop } from 'utils/general';
import WebTimePicker from 'components/WebTimePicker';

export default function DateAndAddress({
  start_address_id = '',
  end_address_id = '',
  submitForm,
}: PageDateAndAddressProps) {
  const isWeb = Platform.OS === 'web';
  const { state } = useContext(AuthContext);
  const navigation = useNavigation();
  const [addressInitialValues, setAddressInitialValues] =
    useState<DateAndAddressProps>({
      start_at: '',
      end_at: '',
      start_address_id,
      end_address_id,
      hourStart: String(getHours(new Date())) || '',
      hourEnd: String(getHours(new Date())) || '',
      minutesStart: String(getMinutes(new Date())) || '',
      minutesEnd: String(getMinutes(new Date())) || '',
    });
  const [startAndEndAddresses, setStartAndEndAddresses] =
    useState<StartEndAddressProps>({
      start_address_id,
      end_address_id,
    });

  const { colors } = useTheme();
  const [showModal, setShowModal] = useState<boolean>(false);
  const addressTypeRef = useRef<string | null>(null);
  const minDate = add(new Date(), {
    days: 1,
  });

  const DateAndAddressSchema = Yup.object()
    .shape({
      start_at: !isWeb
        ? Yup.string().required('El campo es requerido')
        : Yup.string()
            .trim()
            .matches(dateInputRegex, 'Ingresa el fomato requerido. dd/mm/yyyy')
            .typeError('Ingresa el fomato requerido. dd/mm/yyyy')
            .required('El campo es requerido'),
      end_at: !isWeb
        ? Yup.date()
            .when(
              'start_at',
              (start_at, schema) =>
                start_at &&
                schema.min(
                  addMinutes(new Date(start_at), 1),
                  'La fecha debe ser mayor a la de salida',
                ),
            )
            .required('El campo es requerido')
        : Yup.string()
            .trim()
            .matches(dateInputRegex, 'Ingresa el fomato requerido. dd/mm/yyyy')
            .typeError('Ingresa el fomato requerido. dd/mm/yyyy')
            .required('El campo es requerido'),
    })
    .when((_, schema) => {
      if (
        !startAndEndAddresses.start_address_id ||
        !startAndEndAddresses.end_address_id
      ) {
        return schema.shape({
          start_address_id: Yup.string().required('El campo es requerido'),
          end_address_id: Yup.string().required('El campo es requerido'),
        });
      }
      return schema.shape({
        start_address_id: Yup.string().notRequired(),
        end_address_id: Yup.string().notRequired(),
      });
    });
  const { data: dataAddresses = [] } = useQuery('addresses', () =>
    GetAddresses({ user_id: state.id ?? '' }),
  );

  useEffect(() => {
    handleGetStorageAddress();
  }, []);

  useEffect(() => {
    if (start_address_id || end_address_id) handleSetTravel();
  }, [start_address_id, end_address_id]);

  const getCardAddress = (addresdId: string) => {
    return dataAddresses.find(
      (singleAddress: AddressProps) => singleAddress.id === addresdId,
    );
  };

  const handleSetTravel = async () => {
    setStartAndEndAddresses((prevState: StartEndAddressProps) => ({
      ...prevState,
      start_address_id,
      end_address_id,
    }));
  };

  const handleGetStorageAddress = async () => {
    const stepData: DateAndAddressProps = await getStepData('step1');
    if (!stepData) {
      return;
    }
    const {
      start_at = '',
      end_at = '',
      hourEnd,
      hourStart,
      minutesEnd,
      minutesStart,
    } = stepData;
    setAddressInitialValues({
      start_at: isWeb ? start_at : new Date(start_at),
      end_at: isWeb ? end_at : new Date(end_at),
      start_address_id: '',
      end_address_id: '',
      hourStart: hourStart || '',
      hourEnd: hourEnd || '',
      minutesStart: minutesStart || '',
      minutesEnd: minutesEnd || '',
    });
    setStartAndEndAddresses({
      start_address_id: stepData.start_address_id,
      end_address_id: stepData.end_address_id,
    });
  };

  const handleOk = () => {
    setShowModal(!showModal);
    navigation.dispatch(
      CommonActions.navigate({
        name: 'NewAddress',
      }),
    );
  };

  const handleLabelPress = async (sourceAddress: string) => {
    setItemStorage({ key: 'sourceType', value: sourceAddress });
    addressTypeRef.current = sourceAddress;
    if (isEmptyArray(dataAddresses)) {
      setShowModal(true);
      return;
    }
    navigation.dispatch(
      CommonActions.navigate({
        name: isWebDesktop ? 'myAddresses' : 'MyAddresses',
        path: 'webAddresses',
        params: {
          start_address_id,
          end_address_id,
          sourceType: sourceAddress,
          screen: 'DateAndAddress',
        },
      }),
    );
  };

  const handleSubmitStep = (values: DateAndAddressProps) => {
    if (isWeb) {
      const [startDay, startMonth, startYear] = values.start_at.split('/');
      const [endDay, endMonth, endYear] = values.end_at.split('/');

      const startWebAt = new Date(
        Number(startYear), // year
        Number(startMonth) - 1, // month
        Number(startDay), // day
        Number(values.hourStart),
        Number(values.minutesStart),
      );

      const endWebAt = new Date(
        Number(endYear), // year
        Number(endMonth) - 1, // month
        Number(endDay), // day
        Number(values.hourEnd),
        Number(values.minutesEnd),
      );

      const validateStartDate = isBefore(startWebAt, minDate);
      const validateEndDate = isBefore(endWebAt, minDate);
      const isEndBeforeStart = isBefore(endWebAt, startWebAt);
      const isTheSameDate = isEqual(startWebAt, endWebAt);

      if (isEndBeforeStart) {
        Toast.show({
          title: '¡La fecha de llegada no puede ser menor a la de salida!',
          placement: 'top',
        });
        return;
      }

      if (isTheSameDate) {
        Toast.show({
          title: '¡La fechas no pueden ser iguales!',
          placement: 'top',
        });
        return;
      }

      if (validateStartDate) {
        Toast.show({
          title: '¡La fecha de salida debe ser mayor a 24 horas!',
          placement: 'top',
        });
        return;
      }
      if (validateEndDate) {
        Toast.show({
          title: '¡La fecha de llegada debe ser mayor a 24 horas!',
          placement: 'top',
        });
        return;
      }
    }
    submitForm({ ...values, ...startAndEndAddresses });
  };

  return (
    <>
      <Box flex="1">
        <Heading
          fontSize="2xl"
          fontWeight="normal"
          mb={2}
          color={colors.gray[700]}
        >
          Elige la fecha y dirección del viaje
        </Heading>
        <Text color="primary.900" fontWeight="medium" mb={4}>
          Planifica tu viaje, con almenos 48hrs de antelación
        </Text>
        <ScrollView flex="1">
          <Formik
            validationSchema={DateAndAddressSchema}
            initialValues={addressInitialValues}
            onSubmit={handleSubmitStep}
            enableReinitialize
          >
            {({ handleSubmit, setFieldValue, handleChange, values }) => (
              <Box pt={2} pb={4}>
                <Box justifyContent="space-between" flex="1">
                  {isWeb ? (
                    <WebTimePicker
                      values={values}
                      hourName="hourStart"
                      minutesName="minutesStart"
                      dateName="start_at"
                      setFieldValue={setFieldValue}
                      handleChange={handleChange}
                      label="Fecha y hora de salida estimada"
                    />
                  ) : (
                    <DatePicker
                      isReadOnly
                      value={values.start_at}
                      name="start_at"
                      label="Fecha y hora de salida estimada"
                      setFieldValue={setFieldValue}
                      mode="datetime"
                      icon="calendar-today"
                      inputProps={{
                        mb: 8,
                      }}
                      pickerProps={{
                        minimumDate: minDate,
                      }}
                    />
                  )}
                  {isWeb ? (
                    <WebTimePicker
                      values={values}
                      hourName="hourEnd"
                      minutesName="minutesEnd"
                      dateName="end_at"
                      setFieldValue={setFieldValue}
                      handleChange={handleChange}
                      label="Fecha y hora de llegada estimada"
                    />
                  ) : (
                    <DatePicker
                      value={values.end_at}
                      name="end_at"
                      label="Fecha y hora de llegada estimada"
                      setFieldValue={setFieldValue}
                      mode="datetime"
                      icon="calendar-today"
                      isReadOnly
                      inputProps={{
                        mb: 8,
                      }}
                      pickerProps={{
                        minimumDate: minDate,
                      }}
                    />
                  )}
                  <Box mb={4}>
                    {startAndEndAddresses.start_address_id &&
                    getCardAddress(start_address_id) ? (
                      <>
                        <Heading fontSize="md" m={2}>
                          Dirección de origen
                        </Heading>
                        <CardAddress
                          {...(getCardAddress(
                            start_address_id,
                          ) as AddressProps)}
                          onChangeAddress={() =>
                            handleLabelPress('start_address_id')
                          }
                          canChangeAddress
                        />
                      </>
                    ) : (
                      <LabelPressable
                        text="Agregar dirección de origen"
                        icon="add"
                        onLabelPress={() =>
                          handleLabelPress('start_address_id')
                        }
                        mb={8}
                        value={values.start_address_id}
                        name="start_address_id"
                        iconRounded={false}
                        iconProps={{
                          color: 'primary.900',
                        }}
                        mandatory
                      />
                    )}
                    {startAndEndAddresses.end_address_id &&
                    getCardAddress(end_address_id) ? (
                      <>
                        <Heading fontSize="md" m={2}>
                          Dirección de destino
                        </Heading>
                        <CardAddress
                          {...(getCardAddress(end_address_id) as AddressProps)}
                          onChangeAddress={() =>
                            handleLabelPress('end_address_id')
                          }
                          canChangeAddress
                        />
                      </>
                    ) : (
                      <LabelPressable
                        text="Agregar dirección de destino"
                        icon="add"
                        onLabelPress={() => handleLabelPress('end_address_id')}
                        value={values.end_address_id}
                        name="end_address_id"
                        mb={8}
                        iconRounded={false}
                        iconProps={{
                          color: 'primary.900',
                        }}
                        mandatory
                      />
                    )}
                  </Box>
                </Box>
                <Button minWidth={150} onPress={() => handleSubmit()}>
                  Siguiente
                </Button>
              </Box>
            )}
          </Formik>
        </ScrollView>
      </Box>
      <Modal
        open={showModal}
        onToggle={() => setShowModal(!showModal)}
        onOkLabel="Continuar"
        body="Al continuar se abrirá una pantalla para agregar nuevas direcciones."
        onOk={handleOk}
      />
    </>
  );
}
