import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from 'react-query';
import { Heading, Modal, HStack, Toast, Box, useTheme } from 'native-base';

import FloatingInput from 'components/FloatingInput';
import Button from 'components/Button';
import { UpdatePassword } from 'api';

const validationSchema = Yup.object().shape({
  oldpassword: Yup.string()
    .required('Contraseña anterior requerida')
    .min(8, 'Debe tener mínimo 8 caracteres.'),
  newpassword: Yup.string()
    .required('Contraseña nueva requerida')
    .min(8, 'Debe tener mínimo 8 caracteres.'),
});

export default function ModalUpdatePassword({
  isOpen,
  toggleModal,
}: {
  isOpen: boolean;
  toggleModal: () => void;
}) {
  const { colors } = useTheme();
  const [showPassword, setShowPassword] = useState(new Set());

  const { mutate } = useMutation(UpdatePassword, {
    onSuccess: (data: { status: string }) => {
      if (data) {
        Toast.show({
          title: 'Contraseña actualizada',
          placement: 'top',
        });
        toggleModal();
      }
    },
    onError: () => {
      Toast.show({
        title: 'Ocurrió un error. Intenta de nuevo',
        placement: 'top',
      });
    },
  });

  const handlePressIcon = (show: 'oldpassword' | 'newpassword') => {
    const newSet = new Set(showPassword);
    if (newSet.has(show)) {
      newSet.delete(show);
    } else {
      newSet.add(show);
    }
    setShowPassword(newSet);
  };

  const initialValues = {
    oldpassword: '',
    newpassword: '',
  };

  const onSubmit = (values: typeof initialValues) => {
    mutate({
      old_password: values.oldpassword,
      new_password: values.newpassword,
    });
  };

  const passwords = Array.from(showPassword);

  return (
    <Modal
      bg="rgba(46, 40, 57, 0.2)"
      isOpen={isOpen}
      size="xl"
      onClose={toggleModal}
    >
      <Modal.Content px={4} py={8} _web={{ shadow: 'none' }}>
        <Modal.CloseButton />
        <Heading
          mb={10}
          mt={4}
          fontSize="22px"
          color={colors.gray[700]}
          fontWeight={400}
          textAlign="center"
        >
          Actualizar contraseña
        </Heading>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ values, handleChange, handleSubmit, isSubmitting }) => (
            <Box>
              <FloatingInput
                name="oldpassword"
                mbWrapper="9"
                label="Contraseña anterior"
                value={values.oldpassword}
                onChangeText={handleChange('oldpassword')}
                onPressIcon={() => handlePressIcon('oldpassword')}
                type={passwords.includes('oldpassword') ? 'text' : 'password'}
                icon={
                  passwords.includes('oldpassword')
                    ? 'visibility'
                    : 'visibility-off'
                }
              />
              <FloatingInput
                name="newpassword"
                label="Contraseña nueva"
                value={values.newpassword}
                onChangeText={handleChange('newpassword')}
                onPressIcon={() => handlePressIcon('newpassword')}
                type={passwords.includes('newpassword') ? 'text' : 'password'}
                icon={
                  passwords.includes('newpassword')
                    ? 'visibility'
                    : 'visibility-off'
                }
              />
              <HStack justifyContent="space-between">
                <Button button="ghost" minWidth="45%" onPress={toggleModal}>
                  Regresar
                </Button>
                <Button
                  button="primary"
                  minWidth="45%"
                  isLoading={isSubmitting}
                  isDisabled={isSubmitting}
                  onPress={() => handleSubmit()}
                >
                  Actualizar
                </Button>
              </HStack>
            </Box>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  );
}
