import React, { useContext, useState } from 'react';
import { CommonActions, useNavigation } from '@react-navigation/native';
import {
  DrawerContentScrollView,
  DrawerContentComponentProps,
} from '@react-navigation/drawer';
import {
  Divider,
  HStack,
  Image,
  Pressable,
  ScrollView,
  useTheme,
  VStack,
  Text,
  Icon,
} from 'native-base';

import { AuthContext } from 'context/AuthContext';
import { useAssets } from 'expo-asset';
import { Routes, WebMenuRoutes } from 'types/catalogs';
import { menuItemsWeb } from 'utils/catalogs';
import { MaterialIcons } from '@expo/vector-icons';
import { Dimensions, Platform } from 'react-native';

export default function DrawerContentWeb(props: DrawerContentComponentProps) {
  const navigation = useNavigation();
  const { colors } = useTheme();
  const { signOut, state } = useContext(AuthContext);
  const { type = '', sub_type = '' } = state;
  const { width } = Dimensions.get('window');

  const initialRoute = type === 'driver' ? 'request' : 'travels';
  const [currentRoute, setCurrentRoute] = useState(initialRoute);
  const [images] = useAssets([require('assets/images/adaptive-icon.png')]);

  const warningColors = colors.warning as typeof colors['gray'];

  const isDriverPhysicalAssociated =
  state?.type === 'driver' &&
  state.sub_type === 'physical' &&
  state?.reverse_subordinates.length > 0;
  
  const handleNavigate = (route: WebMenuRoutes | Routes) => {
    if (Platform.OS === 'web') {
      navigation.dispatch(
        CommonActions.navigate({
          name: route,
          path: route,
          params: {
            screen: 'menu',
          },
        }),
      );
      setCurrentRoute(route);
    }
  };

  const handleCloseDrawer = () => {
    // eslint-disable-next-line react/destructuring-assignment
    props.navigation.closeDrawer();
  };

  const hanldeCloseSession = () => {
    signOut();
  };

  return (
    <DrawerContentScrollView {...props}>
      <HStack px={6} alignItems="center" pl={width > 992 ? 0 : 6}>
        {width < 992 && (
          <Icon
            size="6"
            mr="4"
            color={colors.gray[800]}
            onPress={handleCloseDrawer}
            as={<MaterialIcons name="arrow-back-ios" />}
          />
        )}
        {images?.map(({ uri, name }) => (
          <Image
            width="180px"
            height="50px"
            alt="logo image"
            ml={-4}
            key={name}
            source={{ uri }}
          />
        ))}
      </HStack>
      <ScrollView flex={1} p="6">
        <VStack divider={<Divider />}>
          <VStack space="5">
            <VStack space="3">
              {menuItemsWeb(type, sub_type, isDriverPhysicalAssociated)
                .filter(item => item.show)
                .map(({ id, route, label, icon }) => {
                  const selectedRoute = currentRoute === route;
                  return (
                    <Pressable
                      py="3"
                      key={id}
                      onPress={() => handleNavigate(route)}
                    >
                      <HStack
                        space="7"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Text
                          color={
                            selectedRoute
                              ? warningColors[900]
                              : colors.gray[700]
                          }
                          fontSize="22px"
                        >
                          {label}
                        </Text>
                        <Icon
                          size="6"
                          color={
                            selectedRoute
                              ? warningColors[900]
                              : colors.gray[500]
                          }
                          as={<MaterialIcons name={icon} />}
                        />
                      </HStack>
                    </Pressable>
                  );
                })}
            </VStack>
          </VStack>
        </VStack>
        <VStack space="7">
          <Pressable onPress={hanldeCloseSession}>
            <HStack justifyContent="space-between" alignItems="center">
              <Text color={colors.gray[700]} fontSize="14px">
                Cerrar sesión
              </Text>
              <Icon
                size="6"
                color={colors.gray[500]}
                as={<MaterialIcons name="logout" />}
              />
            </HStack>
          </Pressable>
          <Pressable
            onPress={() => {
              navigation.dispatch(
                CommonActions.navigate({
                  name: 'Terms',
                  path: 'Terms',
                }),
              );
            }}
          >
            <Text
              width="160px"
              fontSize="14px"
              color={colors.gray[700]}
              borderBottomColor={colors.gray[400]}
              borderBottomWidth="1"
            >
              Términos y condiciones
            </Text>
            <Text
              width="160px"
              fontSize="14px"
              color={colors.gray[700]}
              borderBottomColor={colors.gray[400]}
              borderBottomWidth="1"
            >
              v1.0.9
            </Text>
          </Pressable>
        </VStack>
      </ScrollView>
    </DrawerContentScrollView>
  );
}
