import React, { useContext } from 'react';
import { Toast } from 'native-base';
import { useMutation } from 'react-query';
import { TripProps, Payments, Vehicle, PaymentToSendProps } from 'types/trips';
import { getBestOfferAmount } from 'utils/rides';
import {
  getCurrentPayment,
  getPaymentRequest,
  getStatusPayment,
} from 'utils/payments';
import { PutClientFirstStep, PutClientLastStep } from 'api';
import { AuthContext } from 'context/AuthContext';
import StatusPayment from './StatusPayment';
import PaymentClient from './PaymentClient';

export default function InitialPayment({
  trip,
  payment,
  isDriverMoral,
  isClient,
  refetch,
  label,
}: {
  trip: TripProps;
  isDriverMoral: boolean;
  isClient?: boolean;
  payment: 'initial' | 'final';
  refetch: () => void;
  label: string;
}) {
  const { state } = useContext(AuthContext);
  const bestOfferAmount = getBestOfferAmount(trip?.offers);
  const payments = trip?.payments || ([] as Payments[]);
  const isPaymentRequest = getPaymentRequest(payments, payment);
  const statusPayment = getStatusPayment(payments, payment, isClient);
  const currentPayment = getCurrentPayment(payments, payment);
  const ClientEndpoint =
    payment === 'initial' ? PutClientFirstStep : PutClientLastStep;
  const vehicle = trip?.vehicle || ({} as Vehicle);
  const servicePercent = 0.12;
  const service: number = Number(bestOfferAmount) * servicePercent;

  const { mutate } = useMutation(ClientEndpoint, {
    onSuccess: () => {
      Toast.show({
        title: 'Recibo enviado correctamente.',
        placement: 'top',
      });
      refetch?.();
    },
    onError: () => {
      Toast.show({
        title: 'Hubo un error al enviar el comprobante. intenta nuevamente.',
        placement: 'top',
      });
    },
  });

  const handleSubmitPayment = (bodyPayment: PaymentToSendProps) => {
    mutate(bodyPayment);
  };

  return (
    <>
      {state.type === 'client' ? (
        <>
          {isPaymentRequest ? (
            <PaymentClient
              tripId={trip?.id}
              onSubmitPayment={handleSubmitPayment}
              paymentStatus={currentPayment?.status}
              {...{
                bestOfferAmount,
                payments,
                service,
                vehicle,
                payment,
                label,
                refetch,
              }}
            />
          ) : (
            <StatusPayment
              status={statusPayment}
              {...{ bestOfferAmount, payments, isDriverMoral, service }}
            />
          )}
        </>
      ) : (
        <StatusPayment
          status={statusPayment}
          {...{ bestOfferAmount, payments, isDriverMoral, service }}
        />
      )}
    </>
  );
}
