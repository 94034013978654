/* eslint-disable no-nested-ternary */
import React from 'react';
import { Heading, Text, Stack, useTheme } from 'native-base';
import { theme } from 'constants/theme';
import { formatDateTimeCard } from 'utils/formats';

export default function RidesTrackCard({
  typeUser,
  ride,
  date = '',
  title,
  description,
  children,
}: {
  typeUser: 'client' | 'driver';
  ride?: 'start' | 'inProgress' | 'end';
  date?: Date | string;
  title?: string;
  description?: string;
  children?: any;
}) {
  const { colors } = useTheme<typeof theme>();
  const primaryColors = colors.primary as typeof colors['gray'];
  const secondaryColors = colors.secondary as typeof colors['gray'];

  return (
    <Stack
      p={4}
      mt={4}
      mb={0}
      shadow={6}
      rounded={8}
      bg={colors.gray[100]}
      borderRadius={8}
      borderLeftWidth={8}
      borderLeftColor={
        typeUser === 'client' ? primaryColors[900] : secondaryColors[900]
      }
      width="full"
      _web={{
        width: '100%',
      }}
      _ios={{
        borderRadius: 8,
      }}
    >
      {title && (
        <Heading size="md" fontWeight="medium" mb={4}>
          {title}
        </Heading>
      )}

      {description && (
        <Text
          mb={title ? 8 : 2}
          fontSize="md"
          lineHeight="sm"
          color={
            typeUser === 'client'
              ? 'gray.900'
              : ride === 'inProgress'
              ? secondaryColors[900]
              : 'gray.500'
          }
        >
          {description}
        </Text>
      )}
      {typeUser === 'driver' && date ? (
        <Text
          color={primaryColors[900]}
          fontWeight="medium"
          textAlign="right"
          mb={8}
        >
          {formatDateTimeCard(date)}
        </Text>
      ) : null}
      {children}
    </Stack>
  );
}
