/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { Box, Heading, ScrollView, Text, useTheme } from 'native-base';
import {
  useNavigation,
  CommonActions,
  useRoute,
} from '@react-navigation/native';
import { Dimensions, Platform } from 'react-native';
import { useQuery } from 'react-query';
import Tabs from 'components/Tabs';
import Button from 'components/Button';
import { GetVehicles, GetVehiclesType } from 'api';
import Context from 'context/Vehicles';
import { SingleVehicleTypeProps, VehiclesProps } from 'types/transportation';
import { TabsProps } from 'types/general';
import { isEmptyArray, isEmptyObject } from 'utils/validators';
import { createVehicleTabs, createVehiclesObjectMap } from 'utils/vehicles';
import useCommon from 'webdesign/useCommon';
import { isWebDesktop } from 'utils/general';
import BackWeb from 'components/BackWeb';
import TransportationCars from './TransportationCars';
import TransportationAddOther from './TransportationAddOther';

const otherVehicle = {
  id: Math.random().toString(16).slice(2),
  inserted_at: new Date(),
  name: 'OTRO',
  updated_at: new Date(),
};

export default function TransportationCatalog() {
  const navigation = useNavigation();
  const { vehicle } = useContext(Context);
  const route = useRoute();
  const { path } = useRoute();
  const { width } = Dimensions.get('window');

  const { title } = (route.params as {
    title: 'MADRINA' | 'OTRO';
  }) || {
    title: undefined,
  };

  const { data: dataVehiclesType = [] } = useQuery<SingleVehicleTypeProps[]>(
    'vehiclesType',
    GetVehiclesType,
  );
  const { data: dataVehicles = [] } = useQuery<VehiclesProps[]>(
    'vehicles',
    GetVehicles,
  );

  const isOtherTab = title === 'OTRO';
  const { colors } = useTheme();
  const [tabs, setTabs] = useState<TabsProps[]>([]);

  const {
    containerPt,
    containerPx,
    marginLeft,
    flexAlign,
    cardBg,
    cardPx,
    cardShadow,
    cardPy,
  } = useCommon();

  useEffect(() => {
    if (!isEmptyArray(dataVehiclesType) && !isEmptyArray(dataVehicles)) {
      handleVehiclesTabs();
    }
  }, [dataVehiclesType, dataVehicles]);

  const handleVehiclesTabs = () => {
    const dataVehiclesTypes = [...dataVehiclesType, otherVehicle];
    const vehicleTabs = createVehicleTabs(dataVehiclesTypes);
    const vehiclesTabViewer = createVehiclesObjectMap(
      dataVehiclesType,
      dataVehicles,
    );
    const vehicleTabsWithComponent: TabsProps[] = vehicleTabs.map(
      (tab: Partial<TabsProps>) => ({
        ...(tab as TabsProps),
        component: () => {
          const hasOtherTab = tab.title === 'OTRO';
          if (hasOtherTab) {
            return <TransportationAddOther />;
          }
          return (
            <TransportationCars
              vehicles={vehiclesTabViewer[tab.key]}
              isClient
            />
          );
        },
      }),
    );
    setTabs(vehicleTabsWithComponent);
  };
  const handleSelectedVehicle = () => {
    navigation.dispatch(CommonActions.goBack());
  };

  const handleSwipe = ({ title: tabTitle }: { title: string }) => {
    navigation.dispatch(CommonActions.setParams({ title: tabTitle }));
  };
  const isMobileWeb = Platform.OS === 'web' && width < 480;

  return (
    <Box
      flex={1}
      _web={{
        pt: containerPt,
        px: containerPx,
        justifyContent: 'center',
        alignItems: path && flexAlign,
      }}
    >
      <Box
        flex={1}
        width="100%"
        maxWidth="650px"
        mb={4}
        _web={{
          py: cardPy,
          px: cardPx,
          borderRadius: 20,
          bg: cardBg,
          shadow: cardShadow,
          marginLeft: path ? marginLeft : 0,
        }}
      >
        <Box mb={4}>{isWebDesktop && <BackWeb />}</Box>
        <Box px={4}>
          <Heading fontSize="2xl" fontWeight="normal" color="gray.700">
            Catálogo de Transportes
          </Heading>
          <Text fontSize="md" color={colors.gray[600]}>
            ¿Qué tipo de camiones necesitas?
          </Text>
        </Box>
        <Box flex={1}>
          {!isEmptyArray(tabs) ? (
            <Tabs tabs={tabs} handleSwipe={handleSwipe} />
          ) : null}
        </Box>
        {!isOtherTab ? (
          <Box px={5}>
            <Button
              isDisabled={isEmptyObject(vehicle)}
              onPress={handleSelectedVehicle}
            >
              Listo
            </Button>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
}
