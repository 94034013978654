import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { HStack, Icon, Text, useTheme, useBreakpointValue } from 'native-base';
import { MaterialIcons } from '@expo/vector-icons';
import { Pressable } from 'react-native';

export default function BackWeb() {
  const { colors } = useTheme();
  const navigation = useNavigation();

  const backWebPt = useBreakpointValue({
    base: 5,
    lg: 0,
  });
  const backWebPx = useBreakpointValue({
    base: 5,
    lg: 0,
  });

  const handleBack = () => {
    navigation.goBack();
  };

  return (
    <Pressable onPress={handleBack}>
      <HStack px={backWebPx} pt={backWebPt}>
        <Icon
          mr={2}
          size={6}
          opacity={1}
          color={colors.gray[500]}
          as={<MaterialIcons name="arrow-back-ios" />}
        />
        <Text fontSize="18px" fontWeight={400} color={colors.gray[500]}>
          Regresar
        </Text>
      </HStack>
    </Pressable>
  );
}
