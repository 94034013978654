import React, { useEffect, useState, useContext } from 'react';
import { Formik } from 'formik';
import { useMutation } from 'react-query';
import * as Yup from 'yup';
import { useNavigation, CommonActions } from '@react-navigation/native';
import { Box, Text, Center, Toast, Pressable, ScrollView } from 'native-base';

import Button from 'components/Button';
import FloatingInput from 'components/FloatingInput';
import useDesignAuth from 'webdesign/useDesignAuth';
import { AuthContext } from 'context/AuthContext';
import { getItemStorage } from 'utils';
import { Signin, SaveExpoToken } from 'api';
import { LoginReponse } from 'types/user';
import Constants from 'expo-constants';
import * as Notifications from 'expo-notifications';
import { Platform } from 'react-native';
import FooterAuth from 'components/FooterAuth';

const SigninSchema = Yup.object().shape({
  password: Yup.string()
    .required('Contraseña requerida')
    .min(8, 'Debe tener mínimo 8 caracteres.'),
  email: Yup.string().email('Correo inválido').required('Correo requerido'),
});

const checkStatus = async (navigation: any) => {
  try {
    const isActive = await getItemStorage('isActive');
    const id = await getItemStorage('id');

    if (id != null && isActive === 'false') {
      navigation.navigate('ValidationNIP');
    }
  } catch (e) {
    Toast.show({
      title: '¡Ocurrió un error!. Intenta nuevamente.',
      placement: 'top',
    });
  }
};

export default function Login() {
  const navigation = useNavigation();
  const { signIn } = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);
  const { cardPx, cardPy, cardBg, cardShadow } = useDesignAuth();

  const { mutate } = useMutation(Signin, {
    onSuccess: (data: LoginReponse) => {
      signIn(data);
      if (Platform.OS !== 'web') {
        registerForPushNotificationsAsync();
      }
    },
    onError: () => {
      Toast.show({
        title: '¡Ocurrió un error!. Intenta nuevamente.',
        placement: 'top',
      });
    },
  });

  useEffect(() => {
    checkStatus(navigation);
  }, [navigation]);

  const handleLogin = (values: { email: string; password: string }) => {
    mutate({
      email: values.email.trim(),
      password: values.password.trim(),
    });
  };

  const handlePressIcon = () => setShowPassword(!showPassword);

  const registerForPushNotificationsAsync = async () => {
    if (Constants.isDevice) {
      const { status: existingStatus } =
        await Notifications.getPermissionsAsync();
      let finalStatus = existingStatus;
      if (existingStatus !== 'granted') {
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
      }
      if (finalStatus !== 'granted') {
        console.log('Failed to get push token for push notification!');
        return;
      }
      const token = (await Notifications.getExpoPushTokenAsync()).data;
      SaveExpoToken({ token });
      console.log(token);
    } else {
      console.log('Must use physical device for Push Notifications');
    }

    if (Platform.OS === 'android') {
      Notifications.setNotificationChannelAsync('default', {
        name: 'default',
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: '#FF231F7C',
      });
    }
  };

  return (
    <ScrollView
      flex="1"
      _contentContainerStyle={{
        pt: 10,
        px: 6,
      }}
    >
      <Center flex="1">
        <Box
          width="100%"
          flex="1"
          maxWidth="650px"
          _web={{
            py: cardPy,
            px: cardPx,
            borderRadius: 20,
            bg: cardBg,
            shadow: cardShadow,
          }}
        >
          <Formik
            enableReinitialize
            validationSchema={SigninSchema}
            initialValues={{ email: '', password: '' }}
            onSubmit={handleLogin}
          >
            {({ handleChange, handleSubmit, values }) => (
              <Box flex="1" width="100%" justifyContent="center">
                <Text fontSize="24px" mb="16" textAlign="center">
                  Inicia sesión
                </Text>

                <FloatingInput
                  name="email"
                  mbWrapper="9"
                  label="Correo electrónico"
                  value={values.email}
                  onChangeText={handleChange('email')}
                  keyboardType="email-address"
                  autoCapitalize="none"
                />
                <FloatingInput
                  name="password"
                  mbWrapper="0"
                  label="Contraseña"
                  onPressIcon={handlePressIcon}
                  value={values.password}
                  onChangeText={handleChange('password')}
                  type={showPassword ? 'text' : 'password'}
                  icon={showPassword ? 'visibility' : 'visibility-off'}
                  autoCapitalize="none"
                />
                <Box mx="auto" my={8}>
                  <Pressable
                    p={4}
                    _web={{
                      cursor: 'pointer',
                    }}
                    onPress={() => {
                      navigation.dispatch(
                        CommonActions.navigate({
                          name: 'ResetPasswordByEmail',
                        }),
                      );
                    }}
                  >
                    <Text
                      borderBottomColor="gray.800"
                      borderBottomWidth="1"
                      fontSize="14px"
                      color="gray.800"
                    >
                      ¿Olvidaste tu contraseña?
                    </Text>
                  </Pressable>
                </Box>
                <Button mb="8" button="primary" onPress={() => handleSubmit()}>
                  Ingresar
                </Button>
                <Button
                  onPress={() => {
                    navigation.dispatch(
                      CommonActions.navigate({
                        name: 'ProfileType',
                      }),
                    );
                  }}
                  button="ghost"
                >
                  Regístrate
                </Button>
              </Box>
            )}
          </Formik>
          <FooterAuth />
        </Box>
      </Center>
    </ScrollView>
  );
}
