import { ReviewMessage, RideStatus } from 'types/ride';

export const statusTripDetailMap: { [k in Partial<RideStatus>]?: string } = {
  completed: 'Viaje finalizado',
  cancelled: 'Viaje vencido',
  in_progress: 'En camino',
  pending_last_step: 'En espera de pago',
  pending_first_step: 'En espera de pago',
  waiting_for_offers: 'Buscando oferta de viaje',
  failed_first_step: 'Pago rechazado',
  failed_last_step: 'Pago rechazado',
  in_validation_first_step: 'Pago en revisión',
  in_validation_last_step: 'Pago en revisión',
  waiting_for_review: 'En espera de review',
  offer_accepted: 'Oferta aceptada',
  pending_start: 'En espera de inicio',
  default: 'Quien sabe...',
};

export const statusMap: { [k in Partial<RideStatus>]?: string } = {
  completed: 'Viaje finalizado',
  cancelled: 'Viaje vencido',
  in_progress: 'En camino',
  pending_last_step: 'En espera de pago',
  pending_first_step: 'En espera de pago',
  waiting_for_offers: 'Esperando ofertas',
  failed_first_step: 'Pago rechazado',
  failed_last_step: 'Pago rechazado',
  in_validation_first_step: 'Pago en revisión',
  in_validation_last_step: 'Pago en revisión',
  waiting_for_review: 'En espera de review',
  offer_accepted: 'Oferta aceptada',
  pending_start: 'En espera de inicio',
  default: 'Quien sabe...',
};

export const reviewOptions: { [k in ReviewMessage]?: string[] } = {
  driver: ['Pago a tiempo', 'No pago a tiempo'],
  client: ['A tiempo', 'En buen estado', 'Retraso'],
};
