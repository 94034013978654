import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Payments } from 'types/trips';
import { isEmptyArray, validatedIconStatus } from 'utils/validators';

type CommunityIcons = React.ComponentProps<
  typeof MaterialCommunityIcons
>['name'];

const getPaymentsFromStep = (payments: Payments[], step: number) => {
  return payments.filter((payment: Payments) => payment.number === step);
};

export const getStatusPayments = (
  payments: Payments[],
  paymentStatus: string,
) => {
  return payments.filter((item: Payments) => item.status === paymentStatus);
};
export const getPaymentRequest = (
  payments: Payments[],
  paymentStep: string,
) => {
  const initialPayments = getPaymentsFromStep(payments, 1);
  const finalPayments = getPaymentsFromStep(payments, 2);

  if (paymentStep === 'initial') {
    const sended = getStatusPayments(initialPayments, 'sended');
    const validated = getStatusPayments(initialPayments, 'validated');
    const irregularity = getStatusPayments(initialPayments, 'irregularity');
    return (
      isEmptyArray(initialPayments) ||
      (validated.length === 0 && irregularity.length > 0 && sended.length === 0)
    );
  }
  if (paymentStep === 'final') {
    const sended = getStatusPayments(finalPayments, 'sended');
    const validated = getStatusPayments(finalPayments, 'validated');
    const irregularity = getStatusPayments(finalPayments, 'irregularity');
    return (
      isEmptyArray(finalPayments) ||
      (validated.length === 0 && irregularity.length > 0 && sended.length === 0)
    );
  }
  return false;
};

export const getCurrentPayment = (
  payments: Payments[],
  paymentStep: string,
) => {
  if (paymentStep === 'initial') {
    const paymentsFromInitialStep = getPaymentsFromStep(payments, 1);
    const sended = getStatusPayments(paymentsFromInitialStep, 'sended');
    const validated = getStatusPayments(paymentsFromInitialStep, 'validated');
    const irregularity = getStatusPayments(
      paymentsFromInitialStep,
      'irregularity',
    );

    return sended[0] || validated[0] || irregularity[0];
  }
  const paymentsFromFinalStep = getPaymentsFromStep(payments, 2);
  const sended = getStatusPayments(paymentsFromFinalStep, 'sended');
  const validated = getStatusPayments(paymentsFromFinalStep, 'validated');
  const irregularity = getStatusPayments(paymentsFromFinalStep, 'irregularity');
  return sended[0] || validated[1] || irregularity[0];
};

export const getStatusPayment = (
  payments: Payments[],
  paymentStep: 'initial' | 'final' | string,
  isClient?: boolean,
) => {
  let bgColorStatus = 'gray.500';
  let label = 'En espera de pago';
  let icon: CommunityIcons = 'clock';

  if (paymentStep === 'initial') {
    const paymentsFromInitialStep = getPaymentsFromStep(payments, 1);
    const sended = getStatusPayments(paymentsFromInitialStep, 'sended');
    const validated = getStatusPayments(paymentsFromInitialStep, 'validated');
    const irregularity = getStatusPayments(
      paymentsFromInitialStep,
      'irregularity',
    );

    if (
      (sended.length === 1 && irregularity.length === 0) ||
      (sended.length === 1 && irregularity.length > 0)
    ) {
      bgColorStatus = 'primary.900';
      label = 'Pago en revisión';
      icon = validatedIconStatus.sended;
    }
    if (sended.length === 0 && irregularity.length > 0 && isClient) {
      bgColorStatus = 'danger.900';
      label = 'Pago rechazado';
      icon = validatedIconStatus.irregularity;
    }
    if (validated.length === 1) {
      bgColorStatus = 'success.900';
      label = 'Pago inicial aceptado';
      icon = validatedIconStatus.validated;
    }
  }
  if (paymentStep === 'final') {
    const paymentsFromFinalStep = getPaymentsFromStep(payments, 2);
    const sended = getStatusPayments(paymentsFromFinalStep, 'sended');
    const validated = getStatusPayments(paymentsFromFinalStep, 'validated');
    const irregularity = getStatusPayments(
      paymentsFromFinalStep,
      'irregularity',
    );

    if (
      (sended.length === 1 && irregularity.length === 0) ||
      (sended.length === 1 && irregularity.length > 0)
    ) {
      bgColorStatus = 'primary.900';
      label = 'Pago en revisión';
      icon = validatedIconStatus.sended;
    }
    if (sended.length === 0 && irregularity.length > 0 && isClient) {
      bgColorStatus = 'danger.900';
      label = 'Pago rechazado';
      icon = validatedIconStatus.irregularity;
    }
    if (validated.length === 1) {
      bgColorStatus = 'success.900';
      label = 'Pago final aceptado';
      icon = validatedIconStatus.validated;
    }
  }
  return {
    bgColorStatus,
    label,
    icon,
  };
};
