import React from 'react';
import { Platform } from 'react-native';
import { Pressable, Icon, Modal, Text, useTheme, Box } from 'native-base';

import { MaterialIcons, MaterialCommunityIcons } from '@expo/vector-icons';
import { Action } from '../../hooks/useDocuments';

interface ModalDocumentProps {
  open: boolean;
  showUploadMobileFile?: boolean;
  hideUploadPhoto?: boolean;
  toggle: () => void;
  onSelectDocument: (arg: Action) => void;
}

export default function ModalDocument({
  open = true,
  showUploadMobileFile = false,
  hideUploadPhoto = false,
  toggle,
  onSelectDocument,
}: ModalDocumentProps) {
  const { colors } = useTheme();
  const isWeb = Platform.OS === 'web' || hideUploadPhoto;
  return (
    <Modal
      justifyContent="flex-end"
      bg="rgba(46, 40, 57, 0.6)"
      isOpen={open}
      onClose={toggle}
    >
      <Modal.Content
        width="100%"
        maxWidth="100%"
        height="auto"
        maxHeight="280px"
        borderRadius="0"
      >
        <Modal.Footer py={8} justifyContent="center">
          <Box
            width={Platform.OS !== 'web' ? '40%' : '100%'}
            justifyContent="flex-start"
          >
            {!isWeb && (
              <>
                <Pressable
                  onPress={() => onSelectDocument('takePhoto')}
                  flexDirection="row"
                  alignItems="center"
                  w="full"
                  py={6}
                >
                  <Icon
                    size={6}
                    mr={4}
                    color={colors.gray[800]}
                    as={<MaterialIcons name="photo-camera" />}
                  />
                  <Text color={colors.gray[800]} fontSize="16px">
                    Tomar foto
                  </Text>
                </Pressable>
                <Pressable
                  onPress={() => onSelectDocument('selectPhoto')}
                  flexDirection="row"
                  alignItems="center"
                  w="full"
                  py={6}
                >
                  <Icon
                    size={6}
                    mr={4}
                    color={colors.gray[800]}
                    as={<MaterialIcons name="image" />}
                  />
                  <Text color={colors.gray[800]} fontSize="16px">
                    Selecionar foto
                  </Text>
                </Pressable>
              </>
            )}
            {!showUploadMobileFile && (
              <Pressable
                onPress={() => onSelectDocument('uploadPdf')}
                flexDirection="row"
                minWidth="170px"
                py={Platform.OS !== 'web' ? 6 : 0}
                justifyContent={Platform.OS !== 'web' ? 'flex-start' : 'center'}
              >
                <Icon
                  size={6}
                  mr={4}
                  color={colors.gray[800]}
                  as={<MaterialCommunityIcons name="file-document" />}
                />
                <Text color={colors.gray[800]} fontSize="16px">
                  {Platform.OS === 'web'
                    ? 'Subir archivo o imagen'
                    : 'Subir archivo'}
                </Text>
              </Pressable>
            )}
          </Box>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
}
