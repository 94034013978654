/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { Box, ScrollView, Toast } from 'native-base';
import {
  useNavigation,
  CommonActions,
  useRoute,
} from '@react-navigation/native';
import { useMutation } from 'react-query';
import useSteps from 'hooks/useSteps';
import {
  getItemStorage,
  removeAddressStorage,
  removeItemStorage,
  setItemStorage,
} from 'utils';
import { CreateTrip } from 'api';
import Stepper from 'components/Stepper';
import { createAddressBodyRaw } from 'utils/addressUtil';
import { DateAndAddressProps, ProductTypeProps } from 'types/address';
import {
  CreateTripProps,
  CreateTripResponse,
  VehiclesProps,
} from 'types/transportation';
import Context from 'context/Vehicles';
import { isEmptyObject } from 'utils/validators';
import useCommon from 'webdesign/useCommon';
import BackWeb from 'components/BackWeb';
import { Dimensions, Platform } from 'react-native';
import { isWebDesktop } from 'utils/general';
import DateAndAddress from './DateAndAddress';
import ProductType from './ProductType';
import TransportAndPayment from './TransportAndPayment';

const steps = 3;

export default function TravelRequest() {
  const { path } = useRoute();
  const { setVehicle } = useContext(Context);
  const navigation = useNavigation();
  const [addressSelected, setAddressSelected] = useState({
    start_address_id: '',
    end_address_id: '',
    sourceType: '',
  });

  const { width } = Dimensions.get('window');
  const isMobileWeb = Platform.OS === 'web' && width < 480;
  const isDesktopWeb = Platform.OS === 'web' && width > 480;

  const { nextStep, prevStep, activeStep } = useSteps({
    initialStep: 1,
    steps,
  });
  const { isLoading, mutate } = useMutation(CreateTrip, {
    onSuccess: (data: CreateTripResponse) => {
      if (!isEmptyObject(data)) {
        removeAddressStorage(['address', 'step1', 'step2', 'step3']);
        navigation.dispatch(
          CommonActions.navigate({
            name: isMobileWeb ? 'Tab' : isDesktopWeb ? 'travels' : 'Viajes',
            path: isMobileWeb ? 'Tab' : isDesktopWeb ? 'travels' : 'Viajes'
          }),
        );
      }
    },
    onError: () => {
      Toast.show({
        title: 'Hubo un error al crear el viaje, intenta nuevamente.',
        placement: 'top',
      });
    },
  });

  const {
    containerPt,
    containerPx,
    marginLeft,
    flexAlign,
    cardBg,
    cardPx,
    cardShadow,
    cardPy,
  } = useCommon();

  useEffect(() => {
    return () => {
      setVehicle({} as VehiclesProps);
      removeAddressStorage(['address', 'step1', 'step2', 'step3']);
      removeItemStorage('typeAddress');
      removeItemStorage('address');
    };
  }, []);
  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', async () => {
      const address = JSON.parse(await getItemStorage('address')) || {};
      if (address) {
        setAddressSelected(address);
      }
    });

    return unsubscribe;
  }, [navigation]);

  const handleSubmitForm = async (
    values: Partial<DateAndAddressProps> | Partial<ProductTypeProps>,
  ) => {
    if (activeStep !== steps) {
      setItemStorage({
        key: `step${activeStep}`,
        value: JSON.stringify(values),
      });
      nextStep();
    } else {
      handleSubmitNewRide(values);
    }
  };

  const handleSubmitNewRide = async (stepThree: any) => {
    const { id } = JSON.parse(await getItemStorage('session')) || {};
    const stepOne = JSON.parse(await getItemStorage('step1'));
    const stepTwo = JSON.parse(await getItemStorage('step2'));
    const valuesTosubmit: CreateTripProps = await createAddressBodyRaw(
      id,
      stepOne,
      stepTwo,
      stepThree,
    );
    mutate(valuesTosubmit);
  };
  
  return (
    <Box
      px={5}
      flex={1}
      _web={{
        pt: containerPt,
        px: containerPx,
        justifyContent: 'center',
        alignItems: path && flexAlign,
      }}
    >
      <Box
        flex="1"
        width="100%"
        maxWidth="650px"
        mb={4}
        _web={{
          py: cardPy,
          px: cardPx,
          borderRadius: 20,
          bg: cardBg,
          shadow: cardShadow,
          marginLeft: path ? marginLeft : 0,
        }}
      >
        {isWebDesktop && <BackWeb />}
        <ScrollView px={isMobileWeb ? 5 : 4}>
          <Stepper activeStep={activeStep} steps={steps} />
          {activeStep === 1 && (
            <DateAndAddress
              submitForm={handleSubmitForm}
              {...addressSelected}
            />
          )}
          {activeStep === 2 && (
            <ProductType submitForm={handleSubmitForm} prevStep={prevStep} />
          )}
          {activeStep === 3 && (
            <TransportAndPayment
              submitForm={handleSubmitForm}
              prevStep={prevStep}
              isLoading={isLoading}
            />
          )}
        </ScrollView>
      </Box>
    </Box>
  );
}
