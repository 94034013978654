/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import { useQuery } from 'react-query';
import {
  useNavigation,
  CommonActions,
  useRoute,
} from '@react-navigation/native';
import { Box, Center, Heading, ScrollView, Toast, useTheme } from 'native-base';
import { GetAddresses } from 'api';
import { AddressProps } from 'types/address';
import CardAddress from 'components/CardAddress';
import Button from 'components/Button';
import { AuthContext } from 'context/AuthContext';
import { getItemStorage, setItemStorage } from 'utils';
import useCommon from 'webdesign/useCommon';
import { Platform } from 'react-native';
import BackWeb from 'components/BackWeb';
import { isWebDesktop } from 'utils/general';

export default function MyAddresses() {
  const navigation = useNavigation();
  const route = useRoute();
  const { key, name, path } = route;
  const { start_address_id, end_address_id, sourceType, screen } =
    route.params as {
      start_address_id: string;
      end_address_id: string;
      sourceType: string;
      screen: 'menu' | 'DateAndAddress';
    };
  const { state: userProfile } = useContext(AuthContext);
  const [addressSelected, setAddressSelected] = useState<string>('');
  const [isAddresSelected, setIsAddresSelected] = useState<boolean>(false);
  const { colors } = useTheme();
  const { refetch, data = [] } = useQuery('addresses', () =>
    GetAddresses({ user_id: userProfile.id || '' }),
  );

  const canChangeAddress: boolean = isWebDesktop
    ? screen === 'DateAndAddress' && name === 'travelRequest'
    : key !== 'Drawer' && name !== 'MyAddresses';

  const canEditAddress: boolean = isWebDesktop
    ? screen === 'menu' && name === 'myAddresses'
    : key === 'Drawer' && name === 'MyAddresses';

  const showButtonContinue: boolean = isWebDesktop
    ? screen !== 'menu'
    : key !== 'Drawer' || path !== 'myAddresses';

  const {
    containerPt,
    containerPx,
    marginLeft,
    flexAlign,
    cardBg,
    cardPx,
    cardShadow,
    cardPy,
  } = useCommon();

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      refetch();
    });
    return unsubscribe;
  }, [navigation, refetch]);

  const handleSelectAddress = (idAddress = '') => {
    setAddressSelected(idAddress);
    setIsAddresSelected(true);
  };
  const handleContinueAddress = async () => {
    const currentAddress = JSON.parse(await getItemStorage('address')) || {};
    const currentSource = (await getItemStorage('sourceType')) || '';
    setItemStorage({
      key: 'address',
      value: JSON.stringify({
        ...currentAddress,
        [sourceType || currentSource]: addressSelected,
        sourceType,
      }),
    });
    navigation.dispatch(
      CommonActions.navigate({
        name: isWebDesktop ? 'travelRequest' : 'TravelRequest',
        path: 'TravelRequest',
      }),
    );
  };
  const handleNewAddress = () => {
    navigation.dispatch(
      CommonActions.navigate({
        name: 'NewAddress',
        path: 'NewAddress',
      }),
    );
  };

  const handleEditAddress = (address: AddressProps) => {
    navigation.dispatch(
      CommonActions.navigate({
        name: 'NewAddress',
        path: 'NewAddress',
        params: address,
      }),
    );
  };

  return (
    <Box
      px={5}
      flex={1}
      _web={{
        pt: containerPt,
        px: containerPx,
        justifyContent: 'center',
        alignItems: path && flexAlign,
      }}
    >
      <Box
        flex="1"
        width="100%"
        maxWidth="650px"
        mb={4}
        _web={{
          py: cardPy,
          px: cardPx,
          borderRadius: 20,
          bg: cardBg,
          shadow: cardShadow,
          marginLeft: path ? marginLeft : 0,
        }}
      >
        {isWebDesktop && <BackWeb />}
        <Heading
          fontSize="2xl"
          fontWeight="normal"
          mb="16px"
          mt={4}
          px={Platform.OS === 'web' ? 4 : 0}
          color={colors.gray[700]}
        >
          Catálogo de direcciones
        </Heading>
        <ScrollView px={Platform.OS === 'web' ? 4 : 0}>
          <Center justifyContent="flex-start" flex="1" pb={0}>
            {data.map((address: AddressProps) => (
              <CardAddress
                {...address}
                key={address.id}
                onSelectAddress={handleSelectAddress}
                onEditAddress={() => handleEditAddress(address)}
                addressSelected={addressSelected}
                canChangeAddress={canChangeAddress}
                canEditAddress={canEditAddress}
                start_address_id={start_address_id}
                end_address_id={end_address_id}
              />
            ))}
          </Center>
        </ScrollView>
        <Box mt={4} px={Platform.OS === 'web' ? 4 : 0}>
          <Button
            rounded="3xl"
            mb={4}
            button="ghost"
            _text={{ fontSize: 'md' }}
            onPress={handleNewAddress}
          >
            Agregar dirección
          </Button>
          {showButtonContinue ? (
            <Button
              isDisabled={!isAddresSelected}
              onPress={handleContinueAddress}
            >
              Continuar
            </Button>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
}
