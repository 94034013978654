const firebase = {
  apiKey: 'AIzaSyDcj4b9pS5R8w44npxK4epU4d4BviFbrpc',
  authDomain: 'infast-d4ba9.firebaseapp.com',
  projectId: 'infast-d4ba9',
  storageBucket: 'infast-d4ba9.appspot.com',
  messagingSenderId: '812763376093',
  appId: '1:812763376093:web:cd4836e5bbd23dfc5f2e34',
  measurementId: 'G-KPT829W3GF',
};
export default firebase;
