import React, { Fragment, useContext, useState } from 'react';
import {
  useNavigation,
  CommonActions,
  useRoute,
} from '@react-navigation/native';
import { Stack, Box, Text, HStack, Divider, useTheme } from 'native-base';

import Button from 'components/Button';
import ModalAddVehicle from 'components/modals/ModalAddVehicle';
import { AuthContext } from 'context/AuthContext';
import { formatCurrency, timeAgo } from 'utils/formats';
import { isEmptyArray } from 'utils/validators';
import { TripProps } from 'types/trips';
import { UserVehiclesProps } from 'types/transportation';

export default function RideOffers({ trip }: { trip: TripProps }) {
  const navigation = useNavigation();
  const route = useRoute();
  const { colors } = useTheme();
  const { state: userProfile } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);
  const secondaryColors = colors.secondary as typeof colors['gray'];

  const handleOffer = () => {
    navigation.dispatch(
      CommonActions.navigate({
        path: 'MakeOffer',
        name: 'MakeOffer',
        params: {
          tab: route.name,
          tripId: trip.id,
        },
      }),
    );
  };

  const toggleModal = () => setIsOpen(!isOpen);

  const hasVehicles = !isEmptyArray(userProfile.vehicles);

  const filteredVehicles = hasVehicles
    ? userProfile.vehicles.find(item => item.vehicle.id === trip.vehicle.id)
    : ({} as UserVehiclesProps);

  const hasVehicleType = filteredVehicles?.id;
  const vehicleTypeRequired = trip.vehicle.vehicle_type;

  return (
    <Stack mt={4} p={6} flex="1" rounded={8} bg={colors.gray[100]}>
      <Text fontSize="sm" color={secondaryColors[900]}>
        Buscando oferta más baja
      </Text>
      <Box mt={6} mb={3} mx="auto">
        <Text fontSize="md" color={colors.gray[500]} my={2} textAlign="center">
          {trip?.offers?.length} ofertas recibidas
        </Text>
        {!trip.offers.some(offer => offer.user_id === userProfile.id) && (
          <Text
            fontSize="md"
            color={colors.gray[500]}
            my={2}
            textAlign="center"
          >
            Aún no has realizado una oferta
          </Text>
        )}
      </Box>
      <Box mt={0} mb={10}>
        {trip?.offers?.map(({ user_id, id, amount, inserted_at }) => (
          <Fragment key={id}>
            <Divider my={4} />
            <HStack key={id} alignItems="center" justifyContent="space-between">
              <Text fontSize="md" fontWeight="medium" color={colors.gray[700]}>
                {user_id === userProfile.id
                  ? 'Has ofertado'
                  : 'Alguien ha ofertado'}
              </Text>
              <Text fontSize="sm" color={colors.gray[500]}>
                {timeAgo(inserted_at)}
              </Text>
              <Text fontSize="md" color="gray.700">
                {formatCurrency((Number(amount)/1.1))}
              </Text>
            </HStack>
          </Fragment>
        ))}
      </Box>
      <Button
        button="primary"
        onPress={hasVehicleType ? handleOffer : toggleModal}
        isDisabled={Boolean(trip.client_accepted_offer_at)}
      >
        Realiza una oferta
      </Button>
      <ModalAddVehicle
        vehicleTypeRequired={vehicleTypeRequired}
        vehicle={trip.vehicle}
        charge={trip.vehicle.charge}
        isOpen={isOpen}
        toggleModal={toggleModal}
      />
    </Stack>
  );
}
