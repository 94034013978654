import React from 'react';
import { Text, Heading, useTheme, Modal, Center, HStack } from 'native-base';
import Button from 'components/Button';

export default function ModalConfirmOffer({
  isOpen,
  toggleModal,
  handleAcceptOffer,
}: {
  isOpen: boolean;
  toggleModal: () => void;
  handleAcceptOffer: () => void;
}) {
  const { colors } = useTheme();

  return (
    <Modal
      bg="rgba(46, 40, 57, 0.2)"
      isOpen={isOpen}
      size="xl"
      onClose={toggleModal}
    >
      <Modal.Content px={4} py={8} _web={{ shadow: 'none' }}>
        <Modal.CloseButton />
        <Heading
          mb={10}
          mt={4}
          fontSize="22px"
          color={colors.gray[700]}
          fontWeight={400}
          textAlign="center"
        >
          Confirmar oferta
        </Heading>
        <Center mb={12}>
          <Text fontSize="16px" color={colors.gray[700]} textAlign="center">
            Al aceptar la oferta ya no se aceptaran más ofertas.
          </Text>
          <Text fontSize="16px" color={colors.gray[700]} textAlign="center">
            ¿Desea aceptar esta oferta?
          </Text>
        </Center>
        <HStack justifyContent="space-between">
          <Button button="ghost" minWidth="45%" onPress={toggleModal}>
            Regresar
          </Button>
          <Button
            button="primary"
            minWidth="45%"
            onPress={() => handleAcceptOffer()}
          >
            Aceptar
          </Button>
        </HStack>
      </Modal.Content>
    </Modal>
  );
}
