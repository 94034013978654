import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  CommonActions,
  useNavigation,
  useRoute,
} from '@react-navigation/native';

import FloatingInputWithElements from 'components/FloatingInputWithElements';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import Button from 'components/Button';
import FloatingInput from 'components/FloatingInput';
import {
  Box,
  ScrollView,
  Icon,
  useTheme,
  Center,
  TextArea,
  Text,
} from 'native-base';
import { isWebDesktop } from 'utils/general';

const numberRegExp = /^[0-9]*$/;

export const schema = Yup.object().shape({
  charge: Yup.string()
    .trim()
    .matches(numberRegExp, 'El campo debe ser un número')
    .typeError('Debe ser un número.'),
});

export const initialValues = {
  charge: '',
  type: '',
  comment: '',
};

export default function TransportationAddOther() {
  const navigation = useNavigation();
  const route = useRoute();
  const { colors } = useTheme();
  const { title } = (route.params as {
    screen: string;
    title: 'MADRINA' | 'OTRO';
  }) || {
    title: undefined,
  };
  const isOtherTab = title === 'OTRO';

  const onSubmit = (values: typeof initialValues) => {
    navigation.dispatch(
      CommonActions.navigate({
        name: isWebDesktop ? 'chat' : 'Chat',
        path: isWebDesktop ? 'chat' : 'Chat',
        params: {
          ...values,
          screen: 'transportation',
          charge: values.charge ? `${values.charge} ton` : '',
        },
      }),
    );
  };

  return (
    <Box flex="1">
      <Formik
        validationSchema={schema}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({ values, handleChange, handleSubmit }) => (
          <Box flex="1">
            <ScrollView flex="1" px={4} pt={2}>
              <Box mb={8}>
                <Text fontSize="md" fontWeight={300} color={colors.gray[700]}>
                  ¿No encuentras el vehículo? ¿Deseas que INFAST agregue uno
                  nuevo? por favor llena los siguientes campos.
                </Text>
                <Text
                  mt={4}
                  fontSize="md"
                  fontWeight={300}
                  color={colors.gray[700]}
                >
                  Esta información será enviada al equipo de soporte y en breve
                  te darán una respuesta.
                </Text>
              </Box>
              <FloatingInput
                name="type"
                mbWrapper="8"
                mandatory={false}
                value={values.type}
                label="Tipo de vehículo"
                onChangeText={handleChange('type')}
              />
              <FloatingInputWithElements
                mb={8}
                maxLength={5}
                name="charge"
                label="Capacidad"
                rightElement="ton"
                mandatory={false}
                value={values.charge}
                onChangeText={handleChange('charge')}
                inputProps={{ keyboardType: 'numeric', maxLength: 5 }}
                leftElement={
                  <Icon
                    ml={2}
                    size={6}
                    color={colors.gray[600]}
                    name="weight"
                    as={MaterialCommunityIcons}
                  />
                }
              />
              <TextArea
                mb={12}
                rounded="10"
                height="80px"
                minWidth="100%"
                value={values.comment}
                textAlignVertical="top"
                placeholder="Descripción"
                onChangeText={handleChange('comment')}
              />
            </ScrollView>
            {isOtherTab && (
              <Center mb={5}>
                <Button
                  width="80%"
                  button="ghost"
                  onPress={() => handleSubmit()}
                >
                  Solicitar vehículo
                </Button>
              </Center>
            )}
          </Box>
        )}
      </Formik>
    </Box>
  );
}
