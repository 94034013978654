import React, { useState, useContext } from 'react';
import { useMutation } from 'react-query';
import {
  Box,
  Center,
  Checkbox,
  Toast,
  Text,
  VStack,
  useTheme,
} from 'native-base';
import { AcceptDriverTripOffer } from 'api';
import { TripProps, Vehicle } from 'types/trips';
import { getBestOffer, getBestOfferAmount } from 'utils/rides';
import Button from 'components/Button';
import RideOffers from 'screens/rides/RideOffers';
import CardOfferingSelected from 'components/CardOfferingSelected';
import { AuthContext } from 'context/AuthContext';
import { isEmptyArray } from 'utils/validators';
import { CommonActions, useNavigation } from '@react-navigation/native';

export default function RideDealsPhysical({
  trip,
  refetch,
}: {
  trip: TripProps;
  refetch: () => void;
}) {
  const navigation = useNavigation();
  const { colors } = useTheme();
  const { state: userProfile } = useContext(AuthContext);
  const [value, setValue] = useState<string>('');
  const primaryColors = colors.primary as typeof colors['gray'];
  const secondaryColors = colors.secondary as typeof colors['gray'];
  const bestOfferAmount = getBestOfferAmount(trip?.offers);
  const vehicle = trip?.vehicle || ({} as Vehicle);
  const servicePercent = 0.12;
  const service: number = Number(bestOfferAmount) * servicePercent;

  const { mutate } = useMutation(AcceptDriverTripOffer, {
    onSuccess: () => {
      Toast.show({
        title: 'Oferta aceptada.',
        placement: 'top',
      });
      refetch();
    },
    onError: () => {
      Toast.show({
        title: 'Hubo un error. intenta nuevamente.',
        placement: 'top',
      });
    },
  });

  const handleAcceptOffer = () => {
    if (!value) {
      Toast.show({
        title: 'Debes leer y aceptar los términos y condiciones',
        placement: 'top',
      });
      return;
    }
    mutate({
      tripId: trip.id,
      params: {
        driver_id: userProfile.id,
      },
    });
  };

  const isAceptedOffer =
    Boolean(trip.client_accepted_offer_at) &&
    Boolean(trip.driver_accepted_offer_at);
  const isClientAcceptedOffer = Boolean(trip.client_accepted_offer_at);

  const myOffers = trip.offers.filter(
    offer => offer.user_id === userProfile.id,
  );

  const existsBestOffer = getBestOffer(myOffers);
  const showOfferAccepted = existsBestOffer && isClientAcceptedOffer;

  const offer = existsBestOffer ? undefined : (
    <Center p={6} flex="1" rounded={8} bg={colors.gray[100]}>
      <Text fontSize="md" color={colors.gray[700]}>
        Tu oferta no fue seleccionada.
      </Text>
    </Center>
  );

  return (
    <Box flex="1">
      {isClientAcceptedOffer ? offer : <RideOffers trip={trip} />}
      {!isEmptyArray(trip?.offers) && showOfferAccepted && (
        <CardOfferingSelected
          title="Tu oferta ha sido seleccionada"
          halfPaymentTitle="Iniciar viaje 50%"
          {...{ bestOfferAmount, vehicle, service }}
        >
          {trip.client_accepted_offer_at ? (
            <>
              <VStack my={8} justifyContent="space-between" w="100%">
                <Checkbox
                  defaultIsChecked={isAceptedOffer}
                  value={value}
                  name="type"
                  accessibilityLabel="type transport"
                  onChange={(isSelected: any) => setValue(isSelected)}
                  isDisabled={isAceptedOffer}
                >
                  <Text ml={4} maxW="90%">
                    <Text>Confirmo haber leído los </Text>
                    <Text
                      fontWeight="600"
                      color={primaryColors[600]}
                      onPress={() => {
                        navigation.dispatch(
                          CommonActions.navigate({
                            name: 'Terms',
                          }),
                        );
                      }}
                    >
                      terminos y condiciones.
                    </Text>
                  </Text>
                </Checkbox>
              </VStack>
              <VStack alignItems="center">
                <Text
                  fontSize="xs"
                  color={colors.gray[800]}
                  mb={4}
                  w="2xs"
                  alignSelf="center"
                  textAlign="center"
                >
                  Lee los términos y condiciones para continuar con el proceso
                  de pago.
                </Text>
              </VStack>
              <Button
                button="primary"
                isDisabled={isAceptedOffer}
                onPress={handleAcceptOffer}
              >
                {isAceptedOffer ? 'Oferta aceptada' : 'Aceptar oferta'}
              </Button>
            </>
          ) : (
            <Text
              mt={4}
              textAlign="center"
              color={secondaryColors[900]}
              fontWeight="medium"
            >
              Esperando a que el cliente acepte la oferta.
            </Text>
          )}
        </CardOfferingSelected>
      )}
    </Box>
  );
}
