import { useState, useEffect, useContext } from 'react';
import { useNavigation, CommonActions } from '@react-navigation/native';
import * as Notifications from 'expo-notifications';

import { NotificationContext } from 'context/NotificationContext';
import { ContextProps } from 'types/notification';

export const routeToRedirect: {
  [key in ContextProps['context']]: 'Profile' | 'Details' | 'Chat';
} = {
  SECTION_DOCUMENTS: 'Profile',
  SECTION_TRIP_DETAIL: 'Details',
  SECTION_MESSAGES: 'Chat',
};

type Screen = {
  SECTION_DOCUMENTS: 'profile';
  SECTION_MESSAGES: 'chat';
  SECTION_TRIP_DETAIL: 'details';
};

export default function useNotification() {
  const navigation = useNavigation();
  const {
    notifications,
    setNotificationOpened,
    getNotifications,
    removeLocalNotifications,
    setLocalOnTapNotication,
  } = useContext(NotificationContext);

  const [badge, setBadge] = useState<number>(0);
  const [recentNotification, setRecentNotification] =
    useState<Notifications.Notification>();

  useEffect(() => {
    const onTapNotificacion =
      Notifications.addNotificationResponseReceivedListener(response => {
        setLocalOnTapNotication(response.notification);
        const { content } = response.notification.request;
        const notificationData = content.data as ContextProps;
        handleRoute(notificationData);
      });

    const onNotificationListener =
      Notifications.addNotificationReceivedListener(currentNotification => {
        setRecentNotification(currentNotification);
        setLocalOnTapNotication(currentNotification, true);
      });

    getBadge();
    return () => {
      onTapNotificacion.remove();
      onNotificationListener.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBadge = () =>
    Notifications.getBadgeCountAsync().then(currentBadge => {
      setBadge(currentBadge);
    });

  const updateBadge = () => {
    const newBadge = badge - 1;
    Notifications.setBadgeCountAsync(newBadge)
      .then(() => {
        setBadge(newBadge);
      })
      .catch(err => console.error('err', err));
  };

  const removeNotifications = (identifier: string) =>
    Notifications.dismissNotificationAsync(identifier)
      .then(() => {
        removeLocalNotifications(identifier);
      })
      .catch(err => console.error(err));

  const onOpenedNotification = (identifier: string) => {
    setNotificationOpened(identifier);
  };

  const handleRoute = (context: ContextProps) => {
    const screen: Screen = {
      SECTION_DOCUMENTS: 'profile',
      SECTION_MESSAGES: 'chat',
      SECTION_TRIP_DETAIL: 'details',
    };

    navigation.dispatch(
      CommonActions.navigate({
        name: routeToRedirect[context.context],
        params: {
          rideId: context.context_id ?? undefined,
          screen: screen[context.context],
        },
      }),
    );
  };

  return {
    notifications,
    recentNotification,
    badge,
    getNotifications,
    onOpenedNotification,
    removeNotifications,
    updateBadge,
  };
}
