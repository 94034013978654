import React, { useState, useContext } from 'react';
import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import {
  useNavigation,
  CommonActions,
  useRoute,
} from '@react-navigation/native';
import {
  ScrollView,
  Box,
  Text,
  Heading,
  Avatar,
  HStack,
  VStack,
  Radio,
  Center,
  Modal,
  Toast,
  useTheme,
} from 'native-base';
import BackWeb from 'components/BackWeb';
import Button from 'components/Button';
import { UserProfile } from 'types/user';
import { AuthContext } from 'context/AuthContext';
import { UpdateTrip } from 'api';
import useCommon from 'webdesign/useCommon';
import { isWebDesktop } from 'utils/general';

export default function Carrier() {
  const navigation = useNavigation();
  const route = useRoute();
  const { path } = useRoute();

  const { tripId } = route.params as { tripId: string };
  const { colors } = useTheme();
  const { state } = useContext(AuthContext);
  const [value, setValue] = useState<string | undefined>('');
  const [driverId, setDriverId] = useState<string | undefined>('');
  const [openModal, setOpenModal] = useState(false);

  const secondaryColors = colors.secondary as typeof colors['gray'];
  const {
    containerPt,
    containerPx,
    marginLeft,
    flexAlign,
    cardBg,
    cardPx,
    cardShadow,
    cardPy,
  } = useCommon();

  const { mutate, isLoading } = useMutation(UpdateTrip, {
    onSuccess: data => {
      if (data) {
        Toast.show({
          title: 'Transportista asignado con éxito!',
          placement: 'top',
        });
        navigation.dispatch(
          CommonActions.navigate({
            name: 'Details',
            path: 'Details',
            params: {
              tab: route.name,
              rideId: tripId,
            },
          }),
        );
        handleToggleModal();
      }
    },
    onError: (error: AxiosError<any, string>) => {
      console.error(error);
      Toast.show({
        title: 'Hubo un error. intenta nuevamente.',
        placement: 'top',
      });
      handleToggleModal();
    },
  });

  const handleToggleModal = () => setOpenModal(!openModal);
  const handleChangeValue = (nextValue: string, id: string) => {
    setValue(nextValue);
    setDriverId(id);
  };
  const handleAceptDriver = () => {
    mutate({
      tripId,
      dataToUpdate: {
        trip: {
          driver_id: driverId,
        },
      },
    });
  };

  const subordinates = [
    {
      ...state,
      userId: state.id,
    },
    ...(state.subordinates || ([] as UserProfile['subordinates'][])),
  ];

  return (
    <Box
      flex="1"
      _web={{
        pt: containerPt,
        px: containerPx,
        justifyContent: 'center',
        alignItems: path && flexAlign,
      }}
    >
      <Box
        flex="1"
        width="100%"
        maxWidth="650px"
        mb={4}
        _web={{
          py: cardPy,
          px: cardPx,
          borderRadius: 20,
          bg: cardBg,
          shadow: cardShadow,
          marginLeft: path ? marginLeft : 0,
        }}
      >
        {isWebDesktop && <BackWeb />}
        <ScrollView flex="1" _contentContainerStyle={{ px: 8, my: 5 }}>
          <Heading
            mb={8}
            fontSize="24px"
            color={colors.gray[700]}
            fontWeight={400}
          >
            Transportistas disponibles
          </Heading>
          <VStack>
            {subordinates.map(
              ({ id, userId, names, first_surname: firstSurname, avatar }) => {
                const name = `${names} ${firstSurname}`;
                return (
                  <HStack
                    key={id}
                    mb={4}
                    px={4}
                    py={1}
                    rounded="8px"
                    alignItems="center"
                    _web={{ shadow: 'login' }}
                    bg={value === name ? colors.gray[100] : 'transparent'}
                    justifyContent="space-between"
                  >
                    <HStack alignItems="center">
                      {avatar ? (
                        <Avatar
                          size="54px"
                          bg={colors.gray[400]}
                          source={{ uri: avatar }}
                        />
                      ) : (
                        <Avatar size="54px" bg={colors.gray[400]}>
                          <MaterialCommunityIcons
                            size={50}
                            name="account-circle"
                            color={colors.gray[500]}
                          />
                        </Avatar>
                      )}
                      <VStack>
                        <Text
                          ml={5}
                          color={colors.gray[700]}
                          fontSize="16px"
                          fontWeight={400}
                        >
                          {name}
                        </Text>
                        {userId && (
                          <Text
                            ml={5}
                            fontSize="16px"
                            fontWeight={400}
                            color={secondaryColors[900]}
                          >
                            Asignarme el viaje a mi
                          </Text>
                        )}
                      </VStack>
                    </HStack>
                    <Radio.Group
                      name={name}
                      value={value}
                      accessibilityLabel="type client"
                      onChange={nextValue => handleChangeValue(nextValue, id)}
                    >
                      <Radio value={name} my={1} />
                    </Radio.Group>
                  </HStack>
                );
              },
            )}
          </VStack>
        </ScrollView>
        <Center>
          <Button
            width="140px"
            button="primary"
            onPress={handleToggleModal}
            isDisabled={!value}
            _web={{
              width: '100%',
            }}
          >
            Confirmar
          </Button>
        </Center>
      </Box>
      <Modal bg="rgba(46, 40, 57, 0.2)" isOpen={openModal}>
        <Modal.Content
          px={4}
          pt={10}
          pb={6}
          _web={{ shadow: 'none' }}
          width="xl"
          alignItems="center"
          justifyContent="center"
        >
          <Heading
            fontSize="24px"
            color={colors.gray[700]}
            textAlign="center"
            fontWeight="normal"
          >
            Has asignado el viaje a
          </Heading>
          <Text fontSize="16px" color={colors.gray[700]} mb={10} mt={6}>
            {value}
          </Text>
          <HStack>
            <Button
              mb="8"
              height={10}
              width="152px"
              minWidth="152px"
              button="ghost"
              paddingTop="0px"
              paddingBottom="0px"
              onPress={handleToggleModal}
            >
              Cambiar
            </Button>
            <Button
              ml={5}
              paddingTop="0px"
              paddingBottom="0px"
              height={10}
              width="152px"
              minWidth="152px"
              button="primary"
              isLoading={isLoading}
              onPress={handleAceptDriver}
            >
              Aceptar
            </Button>
          </HStack>
        </Modal.Content>
      </Modal>
    </Box>
  );
}
