import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { MaterialIcons } from '@expo/vector-icons';
import { LocationObject } from 'expo-location';
import * as Yup from 'yup';
import {
  Heading,
  useTheme,
  HStack,
  Stack,
  Modal,
  Slider,
  Box,
  Icon,
  Text,
  Switch,
} from 'native-base';

import useLocation from 'hooks/useLocation';
import Button from 'components/Button';
import Select from 'components/Select';
import DatePicker from 'components/DatePicker';
import { STATES } from 'utils/catalogs';
import { dateInputRegex } from 'utils/general';
import { Params, Filters } from 'screens/rides/RidesInitial';
import { Platform } from 'react-native';
import WebTimePicker from 'components/WebTimePicker';
import { getDateFilter } from 'utils/rides';
import { isEmptyObject } from 'utils/validators';
import { formatDate } from 'utils/formats';

const schema = Yup.object().shape({
  start_at_to: Yup.string()
    .trim()
    .matches(dateInputRegex, 'Ingresa el fomato requerido. dd/mm/yyy')
    .typeError('Ingresa el fomato requerido. dd/mm/yyyy'),
});

export default function ModalFilter({
  isOpen,
  params,
  handleFilters,
  handleCleanFilters,
  handleCloseModal,
}: {
  isOpen: boolean;
  params: Params | null;
  handleFilters: (arg: Filters) => void;
  handleCleanFilters: () => void;
  handleCloseModal: () => void;
}) {
  const { colors } = useTheme();
  const { location, getLocation } = useLocation();
  const [isChecked, setIsChecked] = useState(false);
  const [initialValues, setInitialValues] = useState({
    startRadio: 0,
    start_at_to: '',
    start_address_state: params?.start_address_state || '',
    end_address_state: params?.end_address_state || '',
  });

  const colorsPrimary = colors.primary as typeof colors['gray'];
  const isWeb = Platform.OS === 'web';

  const handleToggleSwitch = async (
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean,
    ) => void,
  ) => {
    setIsChecked(!isChecked);
    setFieldValue('startRadio', 0);
  };

  useEffect(() => {
    if (!isEmptyObject(params)) {
      const validateDate = isWeb
        ? formatDate(new Date(params?.start_at_to))
        : new Date(params?.start_at_to);
      setInitialValues({
        startRadio: 0,
        start_at_to: validateDate,
        start_address_state: params?.start_address_state || '',
        end_address_state: params?.end_address_state || '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWeb]);

  useEffect(() => {
    getLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChecked]);

  const coords = location?.coords || ({} as LocationObject['coords']);

  const handleValidateFilters = (values: Filters) => {
    handleFilters({
      ...values,
      start_at_to:
        isWeb && values.start_at_to
          ? getDateFilter({
              date: values.start_at_to,
              hours: '0',
              minutes: '0',
            })
          : values.start_at_to,
      latitude: values.startRadio === 0 ? '' : coords.latitude,
      longitude: values.startRadio === 0 ? '' : coords.longitude,
    });
  };

  const handleClearFilters = (setValues: any) => {
    handleCleanFilters();
    setValues(initialValues);
    setIsChecked(false);
  };

  return (
    <Modal
      bg="rgba(46, 40, 57, 0.2)"
      isOpen={isOpen}
      size="xl"
      onClose={handleCloseModal}
    >
      <Modal.Content px={4} py={8} _web={{ shadow: 'none' }} maxHeight="90%">
        <Modal.CloseButton onPress={handleCloseModal} />
        <Heading
          mb={5}
          fontSize="24px"
          color={colors.gray[700]}
          fontWeight="medium"
        >
          Aplicar filtro
        </Heading>
        <Formik
          enableReinitialize
          validationSchema={isWeb ? schema : undefined}
          initialValues={initialValues}
          onSubmit={values => handleValidateFilters(values)}
        >
          {({
            values,
            handleChange,
            setFieldValue,
            setValues,
            handleSubmit,
          }) => (
            <Stack mb={8} alignItems="center">
              <Box mx="5" w="90%">
                <HStack
                  mb={5}
                  alignItems="center"
                  justifyContent="space-around"
                  width="100%"
                >
                  <Switch
                    size="md"
                    colorScheme="primary"
                    isChecked={isChecked}
                    onToggle={() => handleToggleSwitch(setFieldValue)}
                  />
                  <Text fontSize="md" color={colors.gray[800]}>
                    Activar geolocalización
                  </Text>
                  <Icon
                    size="md"
                    name="location-on"
                    color={colors.gray[800]}
                    as={MaterialIcons}
                  />
                </HStack>
                {isChecked && (
                  <>
                    <Text mb={5} fontSize="md" color={colors.gray[700]}>
                      Define radio de viaje
                    </Text>
                    <Slider
                      mb={5}
                      defaultValue={0}
                      onChange={value => {
                        setFieldValue('startRadio', Math.floor(value));
                      }}
                    >
                      <Slider.Track bg={colorsPrimary[100]}>
                        <Slider.FilledTrack bg={colorsPrimary[900]} />
                      </Slider.Track>
                      <Slider.Thumb borderWidth="0" bg="transparent">
                        <Icon
                          mt={1}
                          size="md"
                          name="arrow-drop-up"
                          color={colorsPrimary[900]}
                          as={MaterialIcons}
                        />
                      </Slider.Thumb>
                      <HStack
                        mt={4}
                        justifyContent="space-between"
                        width="100%"
                      >
                        <Text color={colors.gray[700]} fontSize="sm">
                          {values.startRadio}KM
                        </Text>
                        <Text color={colors.gray[700]} fontSize="sm">
                          100KM
                        </Text>
                      </HStack>
                    </Slider>
                  </>
                )}
              </Box>
              {Platform.OS === 'web' ? (
                <Box width="100%">
                  <WebTimePicker
                    onlyDate
                    values={values}
                    dateName="start_at_to"
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    label="Fecha de inicio de viaje"
                    containerProps={{
                      width: '100%',
                    }}
                  />
                </Box>
              ) : (
                <DatePicker
                  mandatory={false}
                  label="Fecha de inicio de viaje"
                  name="start_at_to"
                  value={values.start_at_to}
                  setFieldValue={setFieldValue}
                  inputProps={{ width: '100%', marginBottom: 6 }}
                />
              )}
              {!isChecked && (
                <Select
                  mbWrapper={5}
                  mandatory={false}
                  name="start_address_state"
                  label="¿Dónde inicia el viaje?"
                  options={STATES}
                  selectedValue={values.start_address_state}
                  onValueChange={handleChange('start_address_state')}
                />
              )}
              <HStack justifyContent="space-between" width="100%">
                <Button
                  button="ghost"
                  minWidth="45%"
                  onPress={() => handleClearFilters(setValues)}
                >
                  Limpiar
                </Button>
                <Button
                  button="primary"
                  minWidth="45%"
                  isLoadingText="Creando..."
                  onPress={() => handleSubmit()}
                >
                  Aplicar
                </Button>
              </HStack>
            </Stack>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  );
}
