/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext, useState } from 'react';
import { Animated, Dimensions, Platform } from 'react-native';
import {
  TabView,
  SceneMap,
  SceneRendererProps,
  NavigationState,
} from 'react-native-tab-view';
import {
  CommonActions,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { Box, Pressable, ScrollView, useTheme } from 'native-base';
import { TabsProps } from 'types/general';
import Context from 'context/Vehicles';

const initialLayout = { width: Dimensions.get('window').width };
type RestProps = React.ComponentProps<typeof TabView>;

export default function Tabs({
  tabs,
  handleSwipe,
  ...rest
}: { tabs: TabsProps[] } & Partial<RestProps>) {
  const navigation = useNavigation();
  const routeParams = useRoute();
  const { setIndexKey } = useContext(Context);
  const { colors } = useTheme();
  const [index, setIndex] = useState(0);
  const [routes] = useState(tabs);

  const { title } = (routeParams.params as { title: '' }) || {
    title: undefined,
  };

  useEffect(() => {
    const tabSelected = title || 'Solicitudes';
    const filteredKey = routes
      .map((item, itemIndex) => {
        return {
          ...item,
          id: itemIndex,
        };
      })
      .find(item => item.title === title);
    const keySelected = filteredKey ? filteredKey.key : routes[0].key;
    setIndexKey(keySelected);
    handleTab(tabSelected);
    setIndex(filteredKey ? filteredKey.id : 0);
  }, []);

  const handleTab = (tabName: string) => {
    navigation.dispatch(CommonActions.setParams({ title: tabName }));
  };

  const primaryColors = colors.primary as typeof colors['gray'];
  const renderScene = tabs.reduce((acc, current) => {
    return {
      ...acc,
      [current.key]: current.component,
    };
  }, {});

  const hanldeSwipeEnd = () => {
    if (handleSwipe) {
      const currentTab = routes[index];
      setIndexKey(currentTab.key || '');
      handleSwipe(currentTab);
      return;
    }
    setIndexKey(routes[index].key || '');
  };
  const renderTabBar = (
    props: SceneRendererProps & {
      navigationState: NavigationState<any>;
    },
  ) => {
    const isTabTravels = routeParams.name === 'Viajes';

    return (
      <Box flexDirection="row" px={4} pt={4}>
        <ScrollView horizontal flex="1">
          {props.navigationState.routes.map((route, curentIndex) => {
            const borderColor =
              index === curentIndex ? primaryColors[500] : 'transparent';

            return (
              <Box
                flex={1}
                mt={1}
                mb={8}
                pb={0}
                mr={isTabTravels ? 4 : 6}
                key={route.key}
                justifyContent="space-between"
                alignItems="center"
                borderBottomWidth="3"
                borderColor={borderColor}
                _web={{
                  cursor: 'pointer',
                }}
                _android={{
                  paddingY: 3,
                }}
                _ios={{
                  paddingY: 3,
                }}
              >
                <Pressable
                  onPress={() => {
                    handleTab(route.title);
                    setIndexKey(routes[curentIndex].key || '');
                    setIndex(curentIndex);
                  }}
                  textAlign="center"
                >
                  <Animated.Text
                    style={{
                      color:
                        index === curentIndex
                          ? colors.gray[800]
                          : colors.gray[500],
                      fontSize: 16,
                      paddingVertical: 6,
                      textAlign: 'center',
                      fontWeight: '700',
                      paddingHorizontal: isTabTravels ? 15 : 0,
                      width: isTabTravels ? 180 : 'auto',
                      ...Platform.select({
                        web: {
                          paddingHorizontal: isTabTravels ? 0 : 0,
                          width: isTabTravels ? 152 : 'auto',
                        },
                        android: {
                          width: isTabTravels ? 170 : 'auto',
                        },
                      }),
                    }}
                  >
                    {route.title}
                  </Animated.Text>
                </Pressable>
              </Box>
            );
          })}
        </ScrollView>
      </Box>
    );
  };
  return (
    <TabView
      {...rest}
      navigationState={{ index, routes }}
      renderScene={SceneMap({ ...renderScene })}
      renderTabBar={renderTabBar}
      onIndexChange={setIndex}
      onSwipeEnd={hanldeSwipeEnd}
      initialLayout={initialLayout}
      swipeEnabled={Platform.OS === 'web' && false}
      lazy
    />
  );
}
