/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Center, HStack, Text } from 'native-base';
import useInterval from 'hooks/useInterval';
import { getTimeLeft } from 'utils/rides';

export default function CountDownOffer({
  from,
  onFinishTime,
}: {
  from: string;
  onFinishTime: () => void;
}) {
  const ONE_DAY = 60 * 60 * 24;
  const ONE_HOUR = 60 * 60;
  const ONE_MINUTE = 60;
  const [countdown, setCountdown] = useState<{
    hours: string | null;
    minutes: string | null;
    seconds: string | null;
  }>({
    hours: null,
    minutes: null,
    seconds: null,
  });
  const { diffInSeconds } = getTimeLeft(from, 1);

  useEffect(() => {
    if (diffInSeconds < 0) {
      onFinishTime();
    }
  }, [diffInSeconds]);

  useEffect(() => {
    if (countdown.minutes === '0' && countdown.seconds === '0') {
      onFinishTime();
    }
  }, [countdown]);

  useInterval(
    () => {
      if (diffInSeconds >= 0) {
        getCoundown();
      }
    },
    1000,
    countdown,
  );

  const getCoundown = () => {
    const days = Math.floor(diffInSeconds / ONE_DAY);
    const hours = Math.floor((diffInSeconds - days * ONE_DAY) / ONE_HOUR);
    const minutes = Math.floor(
      (diffInSeconds - days * ONE_DAY - hours * ONE_HOUR) / ONE_MINUTE,
    );
    const seconds =
      diffInSeconds - days * ONE_DAY - hours * ONE_HOUR - minutes * ONE_MINUTE;

    setCountdown({
      hours: String(hours),
      minutes: String(minutes),
      seconds: String(seconds),
    });
  };

  return (
    <Center mb={4}>
      <HStack>
        <Text px={1} fontSize="2xl" minW="40px" textAlign="center">
          {countdown?.hours?.length === 1
            ? `0${countdown.hours}`
            : countdown.hours || '00'}
        </Text>
        <Text fontSize="2xl">:</Text>
        <Text px={1} fontSize="2xl" minW="40px" textAlign="center">
          {countdown?.minutes?.length === 1
            ? `0${countdown.minutes}`
            : countdown.minutes || '00'}
        </Text>
        <Text fontSize="2xl">:</Text>
        <Text px={1} fontSize="2xl" minW="40px" textAlign="center">
          {countdown?.seconds?.length === 1
            ? `0${countdown.seconds}`
            : countdown.seconds || '00'}
        </Text>
      </HStack>
    </Center>
  );
}
