/* eslint-disable array-callback-return */
import {
  SingleVehicleTypeProps,
  UserVehiclesProps,
  VehiclesProps,
} from 'types/transportation';
import { isEmptyArray } from './validators';

const getVehicleTypeVehicles = (vehicles: VehiclesProps[], idType: string) => {
  return vehicles.filter(
    (vehicle: VehiclesProps) => vehicle.vehicle_type_id === idType,
  );
};

const cleanWord = (word: string) => {
  if (!word) {
    return '';
  }
  return word
    .trim()
    .toLowerCase()
    .normalize('NFD')
    .split(' ')
    .join('_')
    .replace(/[\u0300-\u036f]/g, '');
};

export const createVehicleTabs = (vehiclesType: SingleVehicleTypeProps[]) => {
  return vehiclesType.map(({ name }: SingleVehicleTypeProps) => {
    const cleanedWord = cleanWord(name);
    return {
      key: cleanedWord,
      title: name,
    };
  });
};

export const createVehiclesObjectMap = (
  vehiclesType: SingleVehicleTypeProps[],
  vehicles: VehiclesProps[],
) => {
  return vehiclesType.reduce((acc, curr) => {
    const { id = '', name = '' } = curr || {};
    const cleanedKey = cleanWord(name);

    return {
      ...acc,
      [cleanedKey]:
        vehicles.filter(
          (vehicle: VehiclesProps) => vehicle.vehicle_type_id === id,
        ) || [],
    };
  }, {});
};

export const createUserVehiclesObjectMap = (
  vehiclesType: SingleVehicleTypeProps[],
  vehicles: VehiclesProps[] | undefined,
) => {
  return vehiclesType
    .map((type: SingleVehicleTypeProps) => {
      const { id = '', name = '' } = type || {};
      const cleanedKey = cleanWord(name);
      const vehicleTypeHasVehicles = getVehicleTypeVehicles(vehicles, id);

      if (!isEmptyArray(vehicleTypeHasVehicles)) {
        return {
          key: cleanedKey,
          vehicles: vehicles.filter(
            (vehicle: VehiclesProps) => vehicle.vehicle_type_id === id,
          ),
        };
      }
    })
    .filter(Boolean);
};

export const createVehiclesArray = (vehiculos: UserVehiclesProps[]) =>
  vehiculos.map((vehiculo: UserVehiclesProps) => ({
    ...vehiculo.vehicle,
    userVehicleId: vehiculo.id,
  }));
export const getDriverVehiclesIds = (vehicles: UserVehiclesProps[]) =>
  vehicles.map((vehicle: UserVehiclesProps) => vehicle.vehicle_id);

export const removeCurrentDriverVehicles = (
  current: string[],
  selected: string[],
) => selected.filter((currentId: string) => !current.includes(currentId));
export const existsCurrentDriverVehicles = (
  current: string[],
  selected: string[],
) => selected.filter((currentId: string) => current.includes(currentId));

export const getClientVehicleId = (vehiculo: VehiclesProps) =>
  vehiculo.id || '';
