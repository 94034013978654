import React, { useState, useContext } from 'react';
import { useMutation, useQuery } from 'react-query';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useAssets } from 'expo-asset';
import { useRoute } from '@react-navigation/native';
import {
  ScrollView,
  Box,
  Text,
  Heading,
  Avatar,
  HStack,
  VStack,
  Toast,
  Image,
  Icon,
  useTheme,
} from 'native-base';
import ModalRemovedSubordinate from 'components/modals/ModalRemovedSubordinate';
import useCommon from 'webdesign/useCommon';
import { RefreshControl } from 'react-native';
import { AuthContext } from 'context/AuthContext';
import { DeleteUserSubordinate, GetProfile } from 'api';
import {
  UserProfile,
  Subordinates as SubordinatesProps,
  UserProfileResponse,
} from 'types/user';

export default function Subordinates() {
  const { colors } = useTheme();
  const { path } = useRoute()
  const { state, updateProfile } = useContext(AuthContext);
  const [subordinate, setSubordinate] = useState<SubordinatesProps>(
    {} as SubordinatesProps,
  );
  const [openModal, setOpenModal] = useState(false);
  const [images] = useAssets([require('assets/images/shipping.png')]);

  const {
    containerPt,
    containerPx,
    marginLeft,
    flexAlign,
    cardBg,
    cardPx,
    cardShadow,
    cardPy,
  } = useCommon();

  const { refetch } = useQuery<UserProfileResponse>('profile', GetProfile, {
    refetchOnWindowFocus: false,
    enabled: false,
    onSuccess: (userData: UserProfileResponse) => {
      updateProfile(userData);
    },
  });

  const { mutate, isLoading } = useMutation(DeleteUserSubordinate, {
    onSuccess: data => {
      if (data) {
        Toast.show({
          title: 'Subordinado eliminado con éxito!',
          placement: 'top',
        });
        handleToggleModal();
        refetch();
      }
    },
    onError: () => {
      Toast.show({
        title: 'Hubo un error. intenta nuevamente.',
        placement: 'top',
      });
      handleToggleModal();
    },
  });

  const handleToggleModal = () => setOpenModal(!openModal);

  const handleSelectedSubordinate = (currentSubordinate: SubordinatesProps) => {
    setSubordinate(currentSubordinate);
    handleToggleModal();
  };

  const handleRemovedSubordinate = () => {
    mutate({
      userId: state.id || '',
      subordinateId: subordinate.id || '',
    });
  };

  const subordinates =
    state.subordinates || ([] as UserProfile['subordinates'][]);

  if (!subordinates.length) {
    return (
      <Box
        flex={1}
        _web={{
          pt: containerPt,
          px: containerPx,
          justifyContent: 'center',
          alignItems: path && flexAlign,
        }}
      >
        <VStack
          flex="1"
          px="6"
          my="5"
          width="100%"
          maxWidth="650px"
          alignItems="center"
          justifyContent="center"
          _web={{
            py: cardPy,
            px: cardPx,
            borderRadius: 20,
            bg: cardBg,
            shadow: cardShadow,
            marginLeft: path ? marginLeft : 0,
          }}
        >
          <Text
            textAlign="center"
            mb={6}
            color={colors.gray[700]}
            fontSize="18px"
          >
            No tienes transportistas asignados.
          </Text>
          {images?.map(({ uri, name }) => (
            <Image
              maxWidth="260px"
              width="100%"
              height="140px"
              alt="shipping"
              key={name}
              source={{ uri }}
              alignSelf="center"
            />
          ))}
        </VStack>
      </Box>
    );
  }

  return (
    <Box
      flex="1"
      pb={5}
      _web={{
        pt: containerPt,
        px: containerPx,
        justifyContent: 'center',
        alignItems: path && flexAlign,
      }}
    >
      <Box
        flex="1"
        width="100%"
        maxWidth="650px"
        mb={4}
        _web={{
          py: cardPy,
          px: cardPx,
          borderRadius: 20,
          bg: cardBg,
          shadow: cardShadow,
          marginLeft: path ? marginLeft : 0,
        }}
      >
        <ScrollView
          flex="1"
          _contentContainerStyle={{ px: 6, my: 5 }}
          refreshControl={
            <RefreshControl
              colors={['#5B0570']}
              tintColor="#6a077a"
              refreshing={false}
              onRefresh={refetch}
            />
          }
        >
          <Heading
            mb={8}
            fontSize="24px"
            color={colors.gray[700]}
            fontWeight={400}
            _web={{
              fontSize: '22px',
            }}
          >
            Mis transportistas asoaciados
          </Heading>
          <VStack>
            {subordinates.map(item => {
              const { id, names, first_surname: firstSurname, avatar } = item;
              const name = `${names} ${firstSurname}`;
              return (
                <HStack
                  key={id}
                  mb={4}
                  px={4}
                  py={1}
                  rounded="8px"
                  alignItems="center"
                  bg={colors.gray[100]}
                  justifyContent="space-between"
                >
                  <HStack alignItems="center" justifyContent="space-around">
                    {avatar ? (
                      <Avatar
                        size="54px"
                        bg={colors.gray[400]}
                        source={{ uri: avatar }}
                      />
                    ) : (
                      <Avatar size="54px" bg={colors.gray[400]}>
                        <MaterialCommunityIcons
                          size={50}
                          name="account-circle"
                          color={colors.gray[500]}
                        />
                      </Avatar>
                    )}
                    <Text
                      ml={5}
                      color={colors.gray[700]}
                      fontSize="16px"
                      fontWeight={400}
                    >
                      {name}
                    </Text>
                  </HStack>
                  <Icon
                    mr={2}
                    size={6}
                    color={colors.gray[500]}
                    onPress={() => handleSelectedSubordinate(item)}
                    as={<MaterialCommunityIcons name="delete" />}
                  />
                </HStack>
              );
            })}
          </VStack>
        </ScrollView>
        <ModalRemovedSubordinate
          isOpen={openModal}
          isLoading={isLoading}
          toggleModal={handleToggleModal}
          subordinate={subordinate}
          onRemoveSubordinate={handleRemovedSubordinate}
        />
      </Box>
    </Box>
  );
}
