import React, { useState } from 'react';

import { Pressable, IInputProps } from 'native-base';
import FloatingInput from './FloatingInput';

interface WebDatePickerProps extends IInputProps {
  name: string;
  label: string;
  mandatory: boolean;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

export default function WebDatePicker({
  inputProps,
  name,
  label,
  mandatory,
  setFieldValue,
  ...props
}: WebDatePickerProps & any) {
  const [placeholder, setPlaceholder] = useState('');
  const hanldeFocus = () => setPlaceholder('dd/mm/yyyy');
  return (
    <Pressable {...inputProps}>
      <FloatingInput
        mbWrapper={0}
        name={name}
        label={label}
        value={props.value}
        mandatory={mandatory}
        placeholder={placeholder}
        onFocus={hanldeFocus}
        onChangeText={value => setFieldValue(name, value)}
        {...props}
      />
    </Pressable>
  );
}
