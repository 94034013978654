import React, { useContext } from 'react';
import { Box, Heading, HStack, Text, useTheme, ScrollView } from 'native-base';
import { useNavigation, CommonActions } from '@react-navigation/native';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { TRANSPORT_PAYMENT_VALUES } from 'constants/initialValues';
import { PageTransportAndPaymentProps } from 'types/address';
import { TransportAndPaymentProps } from 'types/transportation';
import Context from 'context/Vehicles';
import { isEmptyObject } from 'utils/validators';
import Button from 'components/Button';
import FloatingInputWithElements from 'components/FloatingInputWithElements';
import LabelPressable from 'components/LabelPressable';
import CardVehicle from 'components/CardVehicle';

export default function TransportAndPayment({
  submitForm,
  prevStep,
  isLoading,
}: PageTransportAndPaymentProps) {
  const { vehicle } = useContext(Context);
  const navigation = useNavigation();
  const { colors } = useTheme();
  const ProductTypeSchema = Yup.object().shape({
    budget: Yup.string().required('El campo es requerido'),
    vehicle_id: isEmptyObject(vehicle)
      ? Yup.string().required('El campo es requerido')
      : Yup.string().notRequired(),
  });

  const handleLabelPress = () => {
    navigation.dispatch(
      CommonActions.navigate({
        name: 'TransportationCatalog',
        path: 'TransportationCatalog',
      }),
    );
  };

  const handleSubmitForm = (values: TransportAndPaymentProps) => {
    const updatedValues = { ...values, vehicle_id: vehicle.id };
    submitForm(updatedValues);
  };

  return (
    <Box flex="1">
      <ScrollView flex="1">
        <Heading
          fontSize="2xl"
          fontWeight="normal"
          mb={8}
          color={colors.gray[700]}
        >
          Tipo de transporte y pago
        </Heading>
        <Formik
          validationSchema={ProductTypeSchema}
          initialValues={TRANSPORT_PAYMENT_VALUES}
          onSubmit={(values: TransportAndPaymentProps) =>
            handleSubmitForm(values)
          }
          enableReinitialize
        >
          {({ handleSubmit, handleChange, values }) => (
            <Box>
              <Box justifyContent="space-between" mb={8}>
                {isEmptyObject(vehicle) ? (
                  <LabelPressable
                    text="Selecciona el tipo de transporte"
                    icon={require('assets/images/transport.png')}
                    mb={10}
                    onLabelPress={handleLabelPress}
                    value={values.vehicle_id}
                    name="vehicle_id"
                    isCustomIcon
                    mandatory
                  />
                ) : (
                  <CardVehicle
                    onChangeVehicle={handleLabelPress}
                    canChangeVehicle
                    {...vehicle}
                  />
                )}
                <FloatingInputWithElements
                  name="budget"
                  label="¿Cuál es tu presupuesto sin IVA 12%?"
                  onChangeText={handleChange('budget')}
                  leftElement="$"
                  rightElement="MXN"
                  value={values.budget}
                  mb={10}
                  inputProps={{ keyboardType: 'numeric' }}
                  helperText="El IVA será añadido en cada pago que realices"
                />
                <Box my={8}>
                  <Text>
                    Al momento de hacer trato con un transportista se habilitará
                    pantalla de pago.
                  </Text>
                </Box>
              </Box>
              <HStack justifyContent="space-around">
                <Button minWidth="45%" button="ghost" onPress={prevStep}>
                  Regresar
                </Button>
                <Button
                  isDisabled={isLoading}
                  minWidth="45%"
                  onPress={() => handleSubmit()}
                >
                  Solicitar
                </Button>
              </HStack>
            </Box>
          )}
        </Formik>
      </ScrollView>
    </Box>
  );
}
