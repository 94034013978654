import React, { useContext } from 'react';
import { useAssets } from 'expo-asset';
import { MaterialIcons } from '@expo/vector-icons';
import { NativeStackHeaderProps } from '@react-navigation/native-stack';
import { CommonActions } from '@react-navigation/native';
import { Dimensions } from 'react-native';
import { Image, Icon, HStack, Text, Box, useTheme } from 'native-base';

import useNotification from 'hooks/useNotification';
import { AuthContext } from 'context/AuthContext';
import { isWebDesktop } from 'utils/general';

interface BackHeaderProps extends NativeStackHeaderProps {
  iconBack?: boolean;
  hideLogo?: boolean;
  hideNotification?: boolean;
  showLogout?: boolean;
  showMenuIcon?: boolean;
}

export default function BackHeader({
  navigation,
  route,
  showMenuIcon = false,
  iconBack = false,
  hideLogo = false,
  hideNotification = false,
  showLogout = false,
  options,
}: BackHeaderProps) {
  const { colors } = useTheme();
  const { signOut } = useContext(AuthContext);
  const { updateBadge, notifications, recentNotification } = useNotification();
  const [images] = useAssets([require('assets/images/adaptive-icon.png')]);

  const { width } = Dimensions.get('window');
  const errorColors = colors.error as typeof colors['gray'];
  const secondaryColors = colors.secondary as typeof colors['gray'];
  const params = route.params as {
    screen: 'profile' | 'modalVehicle' | 'home';
  };

  const showBack = !isWebDesktop && iconBack;
  const hideTitle = options?.title === 'Login';

  const toggleDrawer = () => {
    if (
      params?.screen === 'profile' ||
      params?.screen === 'modalVehicle' ||
      params?.screen === 'home'
    ) {
      navigation.push('Home');
    } else {
      navigation.goBack();
    }
  };

  const handleNotification = () => {
    navigation.dispatch(
      CommonActions.navigate({
        name: 'Notifications',
      }),
    );
    updateBadge();
  };

  const toggleMenu = () => {
    navigation.toggleDrawer();
  };

  const showIconBack = params?.screen === 'profile' || showBack;
  const isNotificationScreen = options.title === 'Notificaciones';
  const hasNotifications =
    (notifications.length > 0 && !hideNotification) ||
    (recentNotification && !hideNotification);

  const validateIcon = () => {
    if (showMenuIcon && width < 992) {
      return (
        <Icon
          mr={2}
          size={6}
          color="gray.500"
          opacity={1}
          onPress={toggleMenu}
          as={<MaterialIcons name="menu" />}
        />
      );
    }
    return (
      <Icon
        mr={2}
        size={6}
        color="gray.500"
        opacity={showIconBack ? 1 : 0}
        onPress={showIconBack ? toggleDrawer : undefined}
        as={<MaterialIcons name="arrow-back-ios" />}
      />
    );
  };

  return (
    <HStack
      padding="4"
      bg={colors.gray[100]}
      height={60}
      justifyContent={hideTitle ? 'center' : 'space-between'}
      _web={{
        paddingTop: '4',
      }}
      _android={{
        background: colors.gray[100],
        paddingTop: 15,
        paddingBottom: 15,
      }}
    >
      {!hideTitle && (
        <HStack alignItems="center">
          {validateIcon()}
          <Text fontSize="lg" color="gray.700">
            {options.title}
          </Text>
        </HStack>
      )}
      {!hideLogo &&
        images?.map(({ uri, name }) => (
          <Image
            width="120px"
            alt="logo image"
            key={name}
            source={{ uri }}
            _web={{
              width: '180px',
              maxWidth: '180px',
            }}
          />
        ))}
      <HStack mr={4} justifyContent="center">
        <Icon
          size="6"
          color={isNotificationScreen ? secondaryColors[900] : colors.gray[500]}
          onPress={showLogout ? signOut : handleNotification}
          opacity={hideNotification ? 0 : 1}
          as={<MaterialIcons name={showLogout ? 'logout' : 'notifications'} />}
          alignSelf="center"
        />
        {hasNotifications && !showLogout && (
          <Box
            position="absolute"
            rounded="100px"
            borderWidth={1}
            borderColor={colors.gray[100]}
            width="8px"
            height="8px"
            right={0}
            top={2}
            bg={errorColors[900]}
          />
        )}
      </HStack>
    </HStack>
  );
}
