import React, { useState, useEffect, useContext } from 'react';
import { useAssets } from 'expo-asset';
import { useRoute } from '@react-navigation/native';
import { Box, Text, ScrollView, Flex, useTheme, Image } from 'native-base';
import { Platform } from 'react-native';
import Context from 'context/Documents';
import Card from 'components/Card';
import Button from 'components/Button';
import ModalDocument from 'components/modals/ModalDocument';
import { DocumentsToUpload, DocumentTypes, Documents } from 'types/documents';
import useUpload from 'hooks/useUpload';
import { AuthContext } from 'context/AuthContext';
import useDesignProfile from 'webdesign/useDesignProfile';
import BackWeb from 'components/BackWeb';
import { isWebDesktop } from 'utils/general';
import useDocuments, { Action } from '../hooks/useDocuments';

const documentsToUpload: DocumentsToUpload[] = [
  {
    id: 0,
    type: 'official_identification_front',
    label: 'Identificación Oficial (INE) frente',
  },
  {
    id: 1,
    type: 'official_identification_back',
    label: 'Identificación Oficial (INE) reverso',
  },
];

export default function UploadINE() {
  const { path } = useRoute();
  const { getDocument } = useContext(Context);
  const { state } = useContext(AuthContext);

  const { colors } = useTheme();
  const { onSelectedDocument, selectedDocument } = useDocuments();
  const { addDocument, nextDocument, documentUploaded } = useUpload();

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [documentToUpload, setDocumentToUpload] = useState<DocumentsToUpload>(
    {} as DocumentsToUpload,
  );

  const {
    cardProfilePy,
    containerProfilePx,
    containerProfilePt,
    cardProfilePx,
    marginLeftProfile,
    alignProfile,
    cardBg,
    cardShadow,
  } = useDesignProfile();

  const [images] = useAssets([
    require('assets/images/ine-front.png'),
    require('assets/images/ine-back.png'),
  ]);

  useEffect(() => {
    if (selectedDocument.base64) {
      addDocument({
        base64: selectedDocument?.base64,
        user_id: state.id ?? '',
        type: documentToUpload.type,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDocument.base64]);

  const handleDocumentToUpload = (currentDocument: DocumentsToUpload) => {
    setOpenModal(!openModal);
    setDocumentToUpload(currentDocument);
  };

  const handleSelectedPhoto = (action: Action) => {
    onSelectedDocument({
      action,
      web: { mimeType: 'image/*' },
    });
    setOpenModal(!openModal);
  };

  const handleNextDocument = () => {
    const isUploaded =
      (documentUploaded.official_identification_back &&
        documentUploaded.official_identification_front) ||
      (identification.official_identification_back &&
        identification.official_identification_front);
    nextDocument({
      isUploaded: Boolean(isUploaded),
      route: isUploaded ? 'ine' : null,
    });
  };

  const identification: { [key in Partial<DocumentTypes>]?: Documents } = {
    official_identification_front: getDocument('official_identification_front'),
    official_identification_back: getDocument('official_identification_back'),
  };

  return (
    <Box
      flex="1"
      pt={10}
      _web={{
        pt: containerProfilePt,
        px: containerProfilePx,
        justifyContent: 'center',
        alignItems: path && alignProfile,
      }}
    >
      <Box
        flex="1"
        width="100%"
        maxWidth="650px"
        _web={{
          py: cardProfilePy,
          px: cardProfilePx,
          borderRadius: 20,
          bg: cardBg,
          shadow: cardShadow,
          marginLeft: path ? marginLeftProfile : 0,
        }}
      >
        {isWebDesktop && <BackWeb />}
        <ScrollView
          flex="1"
          _contentContainerStyle={{
            px: 4,
          }}
        >
          <Box
            flex="1"
            alignItems="flex-start"
            _web={{
              padding: 4,
            }}
          >
            <Text fontSize="24px" textAlign="center" mb="20px">
              Identificación oficial (INE)
            </Text>
            <Text fontSize="14px" color={colors.gray[700]}>
              Sube una foto cuidando los siguientes aspectos. Captura el frente
              y reverso de tu identificación oficial vigente (jpg).
            </Text>
            <Flex
              justifyContent="center"
              direction="row"
              alignItems="center"
              height="170px"
              width="100%"
            >
              {images?.map(({ uri, name }, index) => (
                <Image
                  key={name}
                  width="104px"
                  height="65px"
                  alt="image ine"
                  source={{ uri }}
                  mr={index === 0 ? '30px' : '0'}
                />
              ))}
            </Flex>
            <Box width="100%">
              {documentsToUpload.map(({ id, label, type }) => {
                const status =
                  documentUploaded && documentUploaded[type]
                    ? documentUploaded[type].status
                    : identification[type]?.status;

                const hideButton =
                  (documentUploaded && documentUploaded[type]) ||
                  (identification[type]?.status && status !== 'irregularity');

                const isUploaded = identification[type]?.status === 'validated';

                return (
                  <Card
                    key={id}
                    title={label}
                    status={status || 'default'}
                    variant="document"
                    mb="44px"
                    icon={status || 'camera'}
                  >
                    <Text fontSize="14px" color={colors.gray[700]}>
                      Cuida que en el documento se lea la información de forma
                      clara y sin reflejos.
                    </Text>
                    <Flex
                      alignItems="center"
                      direction="row"
                      justifyContent="flex-end"
                    >
                      <Button
                        py={2}
                        hide={Boolean(hideButton) || isUploaded}
                        onPress={() =>
                          handleDocumentToUpload({ id, label, type })
                        }
                      >
                        Tomar foto
                      </Button>
                      {Boolean(hideButton) && !isUploaded && (
                        <Text color="secondary.800">
                          Estamos validando tu información
                        </Text>
                      )}
                    </Flex>
                  </Card>
                );
              })}
            </Box>
          </Box>
        </ScrollView>
        <Button
          rounded="0"
          height="64px"
          button="primary"
          variant="solid"
          onPress={handleNextDocument}
        >
          Continuar
        </Button>
        <ModalDocument
          open={openModal}
          showUploadMobileFile={Platform.OS !== 'web'}
          toggle={() => setOpenModal(!openModal)}
          onSelectDocument={handleSelectedPhoto}
        />
      </Box>
    </Box>
  );
}
