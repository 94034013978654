import React from 'react';
import {
  Input,
  Center,
  Text,
  Image,
  HStack,
  Flex,
  Pressable,
  Hidden,
  useTheme,
  Icon,
} from 'native-base';
import { useField } from 'formik';
import { theme } from 'constants/theme';
import { LabelPressableProps } from 'types/address';
import { MaterialIcons } from '@expo/vector-icons';

export default function LabelPressable({
  text = 'Sin label',
  value = '',
  name = '',
  mandatory = false,
  icon,
  withBackground = false,
  asLink = true,
  iconRounded = true,
  onLabelPress,
  isCustomIcon = false,
  iconProps,
  ...rest
}: LabelPressableProps) {
  const [_, meta] = useField(name);
  const { colors } = useTheme<typeof theme>();
  const primaryColors = colors.primary as typeof colors['gray'];
  const isInvalid = meta.error && meta.touched;
  return (
    <Pressable onPress={onLabelPress} {...rest}>
      <Flex
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        rounded="2xl"
        color={colors.gray[800]}
        borderWidth={1}
        bg={withBackground ? colors.gray[300] : 'white'}
        borderColor={isInvalid ? colors.red[500] : 'white'}
        shadow={1}
        h="58px"
      >
        <>
          <Hidden>
            <Input isInvalid={Boolean(isInvalid)} value={value} />
          </Hidden>
          <HStack justifyContent="center" flex="1">
            <Text
              fontSize="md"
              fontWeight="medium"
              color="primary.900"
              underline={asLink}
              ml={4}
              textAlign="left"
            >
              {text}
            </Text>
            {mandatory ? (
              <Text mx={0.5} color={colors.red[600]}>
                *
              </Text>
            ) : null}
          </HStack>
          {isCustomIcon ? (
            <Center
              rounded="full"
              w="45px"
              h="45px"
              mr={2}
              bg={iconRounded ? primaryColors[900] : 'transparent'}
            >
              <Image size={6} p={2} source={icon} alt="icon pressable" />
            </Center>
          ) : (
            <Icon
              size={6}
              mx={4}
              as={<MaterialIcons name={icon} />}
              {...iconProps}
            />
          )}
        </>
      </Flex>
      {isInvalid ? (
        <Text fontSize="sm" mx={4} color={colors.red[500]}>
          {meta.error}
        </Text>
      ) : null}
    </Pressable>
  );
}
