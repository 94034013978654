import { useBreakpointValue } from 'native-base';
import useCommon from './useCommon';

export default function useAuth() {
  const { cardBg, cardShadow } = useCommon();

  const cardPx = useBreakpointValue({
    base: 0,
    sm: 30,
  });
  const cardPy = useBreakpointValue({
    base: 0,
    sm: 50,
  });

  return {
    cardPx,
    cardPy,
    cardBg,
    cardShadow,
  };
}
