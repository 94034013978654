import { extendTheme, useThemeProps } from 'native-base';
import { colors } from './colors';
import { components } from './components';

export const theme = extendTheme({
  colors,
  components,
  fontConfig: {
    Rubik: {
      100: {
        normal: 'Rubik_300Light',
        italic: 'Rubik_300Light',
      },
      200: {
        normal: 'Rubik_300Light',
        italic: 'Rubik_300Light',
      },
      300: {
        normal: 'Rubik_300Light',
        italic: 'Rubik_300Light',
      },
      400: {
        normal: 'Rubik_400Regular',
        italic: 'Roboto-Italic',
      },
      500: {
        normal: 'Rubik_500Medium',
      },
      600: {
        normal: 'Rubik_500Medium',
        italic: 'Rubik_500Medium',
      },
      700: {
        normal: 'Rubik_700Bold',
        italic: 'Rubik_700Bold',
      },
    },
  },
  fonts: {
    heading: 'Rubik',
    body: 'Rubik',
  },
  shadows: {
    login: {
      shadowColor: '#e6e3e9',
      shadowOffset: {
        width: 0,
        height: 17,
      },
      shadowOpacity: 0.75,
      shadowRadius: 8.486,
      elevation: 2,
    },
  },
  breakpoints: {
    base: 0,
    sm: 480,
    md: 768,
    lg: 992,
    xl: 1200,
    '2xl': 1440,
  },
});
