export const VEHICLE_TYPE: {
  [key in 'pick_up' | 'camion_torton' | string]: {
    type: string;
    title: string;
  };
} = {
  pick_up: {
    type: 'Pick Up',
    title: 'Estos son las Pick up’s que tienes',
  },
  camion_torton: {
    type: 'Camión Tortón',
    title: 'Estos son los camiones tipo Tortón que tienes',
  },
  tolva: {
    type: 'Camión Tolva',
    title: 'Estos son los camiones tipo Tolva que tienes',
  },
  auto_tanque: {
    type: 'Auto tanque',
    title: 'Estos son los camiones tipo Tanque que tienes',
  },
  camion_especializado: {
    type: 'Camión especializado',
    title: 'Estos son los camiones especializados que tienes',
  },
  camion_rabon: {
    type: 'Camión rabón',
    title: 'Estos son los camiones tipo Rabón que tienes',
  },
  madrina: {
    type: 'Camión madrina',
    title: 'Estos son los camiones tipo Madrina que tienes',
  },
  trailer: {
    type: 'Camión Trailer',
    title: 'Estos son los camiones tipo Trailer que tienes',
  },
  'camioneta_3.5': {
    type: 'Camionetas 3.5',
    title: 'Estos son los camionetas 3.5 que tienes',
  },
};
