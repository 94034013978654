import React from 'react';
import { Text, Modal as NativeModal } from 'native-base';
import Button from 'components/Button';

interface ModalProps {
  open: boolean;
  onToggle: () => void;
  onOk?: () => void;
  onCancel?: () => void;
  onOkLabel?: string;
  onCancelLabel?: string;
  body: any;
}

export default function Modal({
  open = false,
  onToggle,
  onOk,
  onCancel,
  onOkLabel = 'Aceptar',
  onCancelLabel = 'Cancelar',
  body,
}: ModalProps) {
  return (
    <NativeModal size="xl" isOpen={open} onClose={onToggle}>
      <NativeModal.Content maxWidth="400px">
        <NativeModal.CloseButton />
        <NativeModal.Header borderWidth={0}>
          <Text fontSize="2xl" p={4} pb={1} px={2} textAlign="center">
            Registra una nueva dirección
          </Text>
        </NativeModal.Header>
        <NativeModal.Body>
          <Text fontSize="lg" py={4} textAlign="center">
            {body}
          </Text>
        </NativeModal.Body>
        <NativeModal.Footer
          display="flex"
          flexDirection="row"
          bg="transparent"
          pb={4}
          textAlign="center"
          justifyContent={onCancel ? 'space-around' : 'center'}
        >
          {onCancel ? (
            <Button
              rounded="3xl"
              py={4}
              minWidth="40%"
              _text={{ fontSize: 'md' }}
              onPress={onCancel}
            >
              {onCancelLabel}
            </Button>
          ) : null}
          {onOk ? (
            <Button
              rounded="3xl"
              py={4}
              minWidth={onCancel ? '40%' : '75%'}
              _text={{ fontSize: 'md' }}
              onPress={onOk}
            >
              {onOkLabel}
            </Button>
          ) : null}
        </NativeModal.Footer>
      </NativeModal.Content>
    </NativeModal>
  );
}
