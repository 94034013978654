import { useBreakpointValue } from 'native-base';
import useCommon from './useCommon';

export default function useDesignProfile() {
  const { cardBg, cardShadow } = useCommon();
  const containerProfilePt = useBreakpointValue({
    base: 0,
    sm: 10,
  });
  const containerProfilePx = useBreakpointValue({
    base: 0,
    sm: 4,
  });
  const cardProfilePy = useBreakpointValue({
    base: 0,
    md: 50,
  });
  const cardProfilePx = useBreakpointValue({
    base: 0,
    md: 60,
  });
  const alignProfile = useBreakpointValue({
    base: 'center',
    xl: 'flex-start',
  });
  const marginLeftProfile = useBreakpointValue({
    base: 0,
    xl: 130,
  });
  return {
    cardProfilePy,
    cardProfilePx,
    containerProfilePt,
    containerProfilePx,
    alignProfile,
    marginLeftProfile,
    cardBg,
    cardShadow,
  };
}
