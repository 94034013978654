/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';
import {
  createNativeStackNavigator,
  NativeStackHeaderProps,
} from '@react-navigation/native-stack';
import { AuthContext } from 'context/AuthContext';
import StackMenu from 'navigation/MainStack';
import BackHeader from 'components/BackHeader';
import Details from 'screens/rides/Details';
import MakeOffer from 'screens/rides/MakeOffer';
import Carrier from 'screens/rides/Carrier';
import Transportation from 'screens/Transportation';
import TransportationAdd from 'screens/TransportationAdd';

import TravelRequest from 'screens/Travels/TravelRequest';
import NewAddress from 'screens/NewAddress';
import TransportationCatalog from 'screens/TransportationCatalog';
import MyAddresses from 'screens/MyAddresses';
import RidesHistory from 'screens/rides/RidesHistory';
import Terms from 'screens/Terms';

const Stack = createNativeStackNavigator();

export default function HomeStack() {
  const { state } = useContext(AuthContext);
  const { type } = state;
  const route = type === 'driver' ? 'driver' : 'client';

  return (
    <Stack.Navigator>
      <Stack.Group>
        <Stack.Screen
          name={route}
          component={StackMenu}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="Details"
          component={Details}
          options={{
            title: 'Detalle viaje',
            header: (props: NativeStackHeaderProps) => (
              <BackHeader iconBack hideLogo {...props} />
            ),
          }}
        />
        <Stack.Screen
          name="Carrier"
          component={Carrier}
          options={{
            title: 'Asignar viaje',
            header: (props: NativeStackHeaderProps) => (
              <BackHeader iconBack hideLogo {...props} />
            ),
          }}
        />
        <Stack.Screen
          name="MakeOffer"
          component={MakeOffer}
          options={{
            title: 'Oferta de viaje',
            header: (props: NativeStackHeaderProps) => (
              <BackHeader iconBack hideLogo {...props} />
            ),
          }}
        />
        <Stack.Screen
          name="Transportation"
          component={Transportation}
          options={{
            title: 'Selecciona tipo de transporte',
            headerShadowVisible: false,
            header: (props: NativeStackHeaderProps) => (
              <BackHeader iconBack hideNotification hideLogo {...props} />
            ),
          }}
        />
        <Stack.Screen
          name="TransportationAdd"
          component={TransportationAdd}
          options={{
            title: 'Selecciona tipo transporte',
            headerShadowVisible: false,
            header: (props: NativeStackHeaderProps) => (
              <BackHeader iconBack hideNotification hideLogo {...props} />
            ),
          }}
        />
      </Stack.Group>
      <Stack.Group>
        <Stack.Screen
          name="TravelRequest"
          component={TravelRequest}
          options={{
            contentStyle: {
              paddingTop: 0,
              paddingBottom: 0,
            },
            title: 'Crear solicitud de viaje',
            headerShadowVisible: false,
            header: (props: NativeStackHeaderProps) => (
              <BackHeader iconBack hideLogo {...props} />
            ),
          }}
        />
        <Stack.Screen
          name="NewAddress"
          component={NewAddress}
          options={({ route: routeProp }: { route: any }) => ({
            contentStyle: {
              paddingVertical: 16,
            },
            title: routeProp.params
              ? 'Editar una dirección'
              : 'Registrar nueva dirección',
            headerShadowVisible: false,
            header: (props: NativeStackHeaderProps) => (
              <BackHeader iconBack hideLogo {...props} />
            ),
          })}
        />
        <Stack.Screen
          name="MyAddresses"
          component={MyAddresses}
          options={{
            contentStyle: {
              paddingVertical: 16,
            },
            headerShadowVisible: false,
            header: (props: NativeStackHeaderProps) => (
              <BackHeader iconBack {...props} />
            ),
          }}
        />
        <Stack.Screen
          name="TransportationCatalog"
          component={TransportationCatalog}
          options={{
            contentStyle: {
              paddingVertical: 16,
            },
            title: 'Selecciona tipo de camión',
            headerShadowVisible: false,
            header: (props: NativeStackHeaderProps) => (
              <BackHeader iconBack hideLogo {...props} />
            ),
          }}
        />
        <Stack.Screen
          name="Myhistory"
          component={RidesHistory}
          options={{
            contentStyle: {
              paddingVertical: 16,
            },
            headerShadowVisible: false,
            title: 'Historial de viajes',
            header: (props: NativeStackHeaderProps) => (
              <BackHeader iconBack hideLogo {...props} />
            ),
          }}
        />
        <Stack.Screen
          name="Terms"
          component={Terms}
          options={{
            header: (props: NativeStackHeaderProps) => (
              <BackHeader iconBack hideNotification {...props} />
            ),
          }}
        />
      </Stack.Group>
    </Stack.Navigator>
  );
}
