/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useState } from 'react';
import { VehiclesProps } from 'types/transportation';

export const Context = createContext<{
  setVehicle: React.Dispatch<React.SetStateAction<VehiclesProps>>;
  setCurrentVehicles: React.Dispatch<React.SetStateAction<any>>;
  setVehicleIds: React.Dispatch<React.SetStateAction<string[]>>;
  setIndexKey: React.Dispatch<React.SetStateAction<string>>;
  vehicle: VehiclesProps;
  vehicleIds: string[];
  currentVehicles: { [key: number | string]: VehiclesProps[] };
  indexKey: string;
}>({
  setVehicle: () => ({} as VehiclesProps),
  setCurrentVehicles: () => {},
  setVehicleIds: () => [] as string[],
  setIndexKey: () => {},
  vehicle: {} as VehiclesProps,
  vehicleIds: [] as string[],
  currentVehicles: [] as { [key: number]: VehiclesProps[] },
  indexKey: '',
});

export function VehicleProvider({ children }: { children: React.ReactNode }) {
  const [indexKey, setIndexKey] = useState<string>('');
  const [vehicle, setVehicle] = useState<VehiclesProps>({} as VehiclesProps);
  const [currentVehicles, setCurrentVehicles] = useState<{
    [key: number | string]: VehiclesProps[];
  }>([] as { [key: number]: VehiclesProps[] });
  const [vehicleIds, setVehicleIds] = useState<string[]>([] as string[]);

  return (
    <Context.Provider
      value={{
        setVehicle,
        setVehicleIds,
        setIndexKey,
        setCurrentVehicles,
        vehicle,
        vehicleIds,
        currentVehicles,
        indexKey,
      }}
    >
      {children}
    </Context.Provider>
  );
}
export default Context;
