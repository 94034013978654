import { useState, useEffect } from 'react';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { initializeApp } from 'firebase/app';
import firebaseConfig from 'webconfig/firebaseConfig';
import { Platform } from 'react-native';

initializeApp(firebaseConfig);
const vapidKey =
  'BNOFV8MKWK6EaLMfr_JN9axCVny4tGbVD4W42witeov8Ng8OvU0PVuQlfyKUOGxtmQn5zhP00VYKLJ6Bjq6yxxU';

const useWebFirebase = () => {
  const messaging = getMessaging();
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    getToken(messaging, { vapidKey })
      .then(currentToken => {
        if (currentToken) {
          console.log('>>>>>>>>>>>', currentToken);
          setToken(currentToken);
        } else {
          // Show permission request UI
          console.log(
            'No registration token available. Request permission to generate one.',
          );
        }
      })
      .catch(err =>
        console.log('An error occurred while retrieving token. ', err),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onMessageListener = () =>
    new Promise(resolve => {
      onMessage(messaging, payload => {
        resolve(payload);
      });
    });

  return { token, onMessageListener };
};

const useFirebase = () => {
  if (Platform.OS !== 'web') {
    return { webToken: '', onMessageListener: () => Promise.resolve({}) };
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { token, onMessageListener } = useWebFirebase();
  return { webToken: token, onMessageListener };
};

export default useFirebase;
