import React, { useState, useEffect } from 'react';
import { useField } from 'formik';
import {
  Animated,
  GestureResponderEvent,
  NativeSyntheticEvent,
  TextInputFocusEventData,
} from 'react-native';
import { Input, Icon, Box, Text, IInputProps, useTheme } from 'native-base';
import { MaterialIcons } from '@expo/vector-icons';

type Icons = React.ComponentProps<typeof MaterialIcons>['name'];
type TextStyle = React.ComponentProps<typeof Animated.Text>['style'];

interface FloatingInputProps extends IInputProps {
  label: string;
  icon?: Icons;
  name: string;
  mbWrapper?: string | number;
  mtWrapper?: string;
  onPressIcon?: null | ((event: GestureResponderEvent) => void);
  mandatory?: boolean;
  onBlurChange?: (cp: string) => void;
}

export default function FloatingInput({
  label,
  value = '',
  onChangeText,
  onPressIcon,
  onBlurChange,
  name = '',
  mbWrapper = 8,
  mtWrapper = '0',
  icon,
  mandatory = true,
  ...props
}: FloatingInputProps) {
  const [_, meta] = useField(name);
  const [isFocused, setIsFocused] = useState(false);
  const { colors } = useTheme();
  const animatedIsFocused = new Animated.Value(value ? 1 : 0);
  const primaryColors = colors.primary as typeof colors['gray'];
  const errorColors = colors.error as typeof colors['gray'];
  useEffect(() => {
    Animated.timing(animatedIsFocused, {
      toValue: isFocused || value ? 1 : 0,
      duration: 200,
      useNativeDriver: false,
    }).start();
  }, [animatedIsFocused, isFocused, value]);

  const setSticky = () => setIsFocused(false);
  const handleFocus = (
    event: NativeSyntheticEvent<TextInputFocusEventData>,
  ) => {
    setIsFocused(true);
    if (props.onFocus) {
      props.onFocus(event);
    }
  };

  const handleBlur = () => {
    if (!value) {
      setSticky();
      return;
    }
    onBlurChange?.(value);
  };

  const handleTextChange: IInputProps['onChangeText'] = (newText: string) => {
    onChangeText?.(newText);
  };

  const top = props.placeholder ? 10 : 18;

  const dynamicStyles: { [k in 'label']: TextStyle } = {
    label: {
      zIndex: 100,
      position: 'absolute',
      marginLeft: 16,
      color: animatedIsFocused.interpolate({
        inputRange: [0, 1],
        outputRange: [`${colors.gray[500]}`, `${primaryColors[900]}`],
      }),
      top: animatedIsFocused.interpolate({
        inputRange: [0, 1],
        outputRange: [top, 6],
      }),
      fontSize: animatedIsFocused.interpolate({
        inputRange: [0, 1],
        outputRange: [16, 12],
      }),
    },
  };
  const showError = Boolean(
    (meta.error && meta.touched) || (meta.error && !meta.touched && value),
  );
  return (
    <Box mb={mbWrapper} mt={mtWrapper}>
      <Box rounded="2xl" bg={colors.gray[300]} position="relative">
        <>
          <Animated.Text style={dynamicStyles.label}>
            {label}{' '}
            {mandatory ? (
              <Text mx={0.5} color={colors.red[600]}>
                *
              </Text>
            ) : null}
          </Animated.Text>
          <Input
            {...props}
            value={value}
            height="58px"
            isInvalid={!!showError}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChangeText={handleTextChange}
            px={4}
            pt={6}
            _hover={{
              backgroundColor: 'none',
            }}
          />
        </>
        {icon ? (
          <Icon
            mx={4}
            size={6}
            color="gray.500"
            position="absolute"
            style={{ right: 0, top: 18 }}
            onPress={ev => onPressIcon?.(ev)}
            as={<MaterialIcons name={icon} />}
          />
        ) : undefined}
      </Box>
      {showError && (
        <Text
          position="absolute"
          bottom="-20px"
          fontSize="12px"
          ml="4"
          width="100%"
          minWidth="300px"
          color={errorColors[900]}
        >
          {meta.error}
        </Text>
      )}
    </Box>
  );
}
