/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  useNavigation,
  CommonActions,
  useRoute,
} from '@react-navigation/native';
import {
  Flex,
  Text,
  Box,
  Center,
  ScrollView,
  Toast,
  Heading,
} from 'native-base';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import Button from 'components/Button';
import CardVehicle from 'components/CardVehicle';
import { isEmptyArray } from 'utils/validators';
import {
  createUserVehiclesObjectMap,
  createVehiclesArray,
} from 'utils/vehicles';
import { getItemStorage } from 'utils';
import { VEHICLE_TYPE } from 'constants/transportation';
import {
  SingleVehicleTypeProps,
  UserVehiclesProps,
  VehiclesProps,
  UserVehiclesMapProps,
} from 'types/transportation';
import { DeleteUserVehicle, GetUserVehicles, GetVehiclesType } from 'api';
import { User } from 'types/user';
import useCommon from 'webdesign/useCommon';

export default function Transportation() {
  const queryClient = useQueryClient();
  const navigation = useNavigation();
  const { path } = useRoute();
  const [userVehicles, setUserVehicles] = useState<UserVehiclesMapProps[]>([]);

  const {
    containerPt,
    containerPx,
    marginLeft,
    flexAlign,
    cardBg,
    cardPx,
    cardShadow,
    cardPy,
  } = useCommon();

  const { data: dataVehiclesType = [] } = useQuery<SingleVehicleTypeProps[]>(
    'vehiclesType',
    async () => {
      return GetVehiclesType();
    },
    {
      onError: () => {
        Toast.show({
          title: 'Ocurrio un error. Intenta de nuevo',
          placement: 'top',
        });
      },
    },
  );
  useQuery<UserVehiclesProps[]>(
    'userVehicles',
    async () => {
      const { id } = JSON.parse(await getItemStorage('session')) || {};
      return GetUserVehicles(id);
    },
    {
      enabled: !isEmptyArray(dataVehiclesType),
      onSuccess: (data: UserVehiclesProps[]) => {
        handleMyVehicles(data);
      },
      onError: () => {
        Toast.show({
          title: 'Ocurrio un error. Intenta de nuevo',
          placement: 'top',
        });
      },
    },
  );
  const { mutate } = useMutation(DeleteUserVehicle, {
    onMutate: async (currentVehicleDeleted: Partial<User & VehiclesProps>) => {
      await queryClient.cancelQueries('vehiclesType');
      await queryClient.cancelQueries('userVehicles');
      const oldVehicles =
        queryClient.getQueryData<UserVehiclesProps[]>('userVehicles') || [];
      const newVehicles = oldVehicles.filter(
        (vehicles: UserVehiclesProps) =>
          vehicles.id !== currentVehicleDeleted.userVehicleId,
      );
      queryClient.setQueryData('userVehicles', () => newVehicles);
    },
    onSuccess: () => {
      Toast.show({
        title: 'Vehículo eliminado correctamente.',
        placement: 'top',
      });
    },
    onError: () => {
      Toast.show({
        title: 'Ocurrio un error. Intenta de nuevo',
        placement: 'top',
      });
    },
  });

  const handleMyVehicles = (data: UserVehiclesProps[]) => {
    const clearData = createVehiclesArray(data);
    const vehiclesObjectMap = createUserVehiclesObjectMap(
      dataVehiclesType,
      clearData,
    );
    setUserVehicles(vehiclesObjectMap);
  };

  const handleNextDocument = () => {
    navigation.dispatch(
      CommonActions.navigate({
        name: 'TransportationAdd',
        key: 'Transportation',
        path: 'transportation',
      }),
    );
  };

  const handleDeleteVehicle = async (userVehicleId: string) => {
    const { id } = JSON.parse(await getItemStorage('session')) || {};
    mutate({ id, userVehicleId });
  };

  return (
    <Box
      flex="1"
      _web={{
        pt: containerPt,
        px: containerPx,
        justifyContent: 'center',
        alignItems: path && flexAlign,
      }}
    >
      <Box
        flex="1"
        width="100%"
        maxWidth="650px"
        mb={4}
        _web={{
          py: cardPy,
          px: cardPx,
          borderRadius: 20,
          bg: cardBg,
          shadow: cardShadow,
          marginLeft: path ? marginLeft : 0,
        }}
      >
        {!isEmptyArray(userVehicles) ? (
          <ScrollView flex="1">
            <Heading
              fontSize="2xl"
              fontWeight="normal"
              color="gray.700"
              mb={4}
              mx={4}
            >
              Mis vehículos
            </Heading>
            {userVehicles.map(({ key, vehicles }: UserVehiclesMapProps) => (
              <Box key={key} mb={8}>
                <>
                  {VEHICLE_TYPE[key] ? (
                    <Box mb={4} mx={4}>
                      <Text fontSize="xl" color="gray.700">
                        {VEHICLE_TYPE[key].type}
                      </Text>
                      <Text fontSize="sm" color="gray.600">
                        {VEHICLE_TYPE[key].title}
                      </Text>
                    </Box>
                  ) : null}
                  {vehicles.map((vehicle: VehiclesProps) => (
                    <Box px={4} key={vehicle.id}>
                      <CardVehicle
                        key={vehicle.id}
                        onDeleteVehicle={handleDeleteVehicle}
                        canDelete
                        {...vehicle}
                      />
                    </Box>
                  ))}
                </>
              </Box>
            ))}
          </ScrollView>
        ) : (
          <Flex flex="1" my={10} alignItems="center" justifyContent="center">
            <Text fontSize="md" color="gray.600">
              No has agregado vehiculos
            </Text>
          </Flex>
        )}
        <Center mb={5}>
          <Button width="80%" button="primary" onPress={handleNextDocument}>
            Agregar
          </Button>
        </Center>
      </Box>
    </Box>
  );
}
