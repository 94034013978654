import React, { useRef, useState } from 'react';
import { Box, Heading, HStack, ScrollView, Toast, useTheme } from 'native-base';
import {
  useNavigation,
  CommonActions,
  useRoute,
} from '@react-navigation/native';
import { useMutation } from 'react-query';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  createColoniaOptions,
  getLocationAndViewport,
  getStateFromColonia,
} from 'utils/addressUtil';
import FloatingInput from 'components/FloatingInput';
import Select from 'components/Select';
import Button from 'components/Button';
import { NEW_ADDRESS_VALUES } from 'constants/initialValues';
import { GeocodingApi, CopomexApi, CreateAddress, UpdateAddress } from 'api';
import { AddressProps, ColoniaProps, CopomexProps } from 'types/address';
import { getItemStorage } from 'utils';
import useCommon from 'webdesign/useCommon';
import BackWeb from 'components/BackWeb';
import { isWebDesktop } from 'utils/general';

const NewAddressSchema = Yup.object().shape({
  street: Yup.string().required('El campo es requerido'),
  ext_number: Yup.string()
    .notOneOf(['0'], 'Numero inválido')
    .required('El campo es requerido'),
  int_number: Yup.string().notRequired(),
  neiborhood: Yup.string().required('El campo es requerido'),
  city: Yup.string().required('El campo es requerido'),
  state: Yup.string().required('El campo es requerido'),
  zip_code: Yup.string()
    .min(5, 'Código postal incorrecto')
    .max(5, 'Código postal incorrecto')
    .matches(/[0-9]+/gi)
    .required('El campo es requerido'),
  address_reference: Yup.string().required('El campo es requerido'),
  address_name: Yup.string().required('El campo es requerido'),
});

export default function NewAddress() {
  const { params, path } = useRoute();
  const address = params
    ? {
        ...(params as AddressProps),
        int_number: !params?.int_number ? '' : String(params?.int_number),
      }
    : NEW_ADDRESS_VALUES;
  const navigation = useNavigation();
  const { colors } = useTheme();
  const copomexDataRef = useRef<ColoniaProps[] | null>(null);
  const preGeocodingDataRef = useRef({});
  const AddressEndpoint = params ? UpdateAddress : CreateAddress;
  const formikRef = useRef();

  const {
    containerPt,
    containerPx,
    marginLeft,
    flexAlign,
    cardBg,
    cardPx,
    cardShadow,
    cardPy,
  } = useCommon()

  const [copomexOptions, setCopomexOptions] = useState(() => {
    if (address) {
      return [{ value: address.neiborhood, label: address.neiborhood }];
    }
    return [];
  });
  const { isLoading: isLoadingGeocoding, mutate: mutateGeocoding } =
    useMutation(GeocodingApi, {
      onSuccess: (data: any) => {
        handleCreatePayload(data);
      },
      onError: () => {
        Toast.show({
          title: 'Hubo un error, intenta nuevamente.',
          placement: 'top',
        });
      },
    });
  const { mutate: mutateCopomex } = useMutation(CopomexApi, {
    onSuccess: (data: CopomexProps) => {
      if (data) {
        setColoniaOptions(data);
      }
    },
    onError: () => {
      Toast.show({
        title:
          'Hubo un error al obtener datos del código postal, intenta nuevamente.',
        placement: 'top',
      });
    },
  });
  const { isLoading, mutate } = useMutation(AddressEndpoint, {
    onSuccess: (data) => {
      if (data) {
        formikRef.current?.resetForm();
        navigation.goBack()
        Toast.show({
          title: params
            ? 'Dirección actualizada correctamente'
            : 'Dirección creada correctamente',
          placement: 'top',
        });
      }
    },
    onError: (error) => {
      Toast.show({
        title: `Error: ${error.response?.data.errors.detail}`,
        placement: 'top',
      });
    },
  });
  const handleCreatePayload = async (data?: any) => {
    const { id } = JSON.parse(await getItemStorage('session')) || {};
    const { location = {} } = getLocationAndViewport(data || {});

    const updatedAddressValues = {
      ...preGeocodingDataRef.current,
      country: 'mx',
      latitude: location.lat,
      longitude: location.lng,
      user_id: id,
    };
    mutate(updatedAddressValues);
  };
  const setColoniaOptions = (copomex: CopomexProps) => {
    const options = createColoniaOptions(copomex);
    copomexDataRef.current = options;
    setCopomexOptions(options);
  };
  const setStateFromColonia = (selectedNeiborhood: string) => {
    return getStateFromColonia(copomexDataRef.current, selectedNeiborhood);
  };
  const handleBlurChange = (cp: string, invalidZipCode: string) => {
    if (invalidZipCode) return;
    mutateCopomex({ cp });
  };
  const handleParseFormValues = async (values: AddressProps) => {
    preGeocodingDataRef.current = values;
    const addressGeocoding = `${values.street} ${values.ext_number} ${values.neiborhood} ${values.city} ${values.zip_code}`;
    mutateGeocoding({ address: addressGeocoding });
  };
  return (
    <Box
      px={5}
      flex={1}
      _web={{
        pt: containerPt,
        px: containerPx,
        justifyContent: 'center',
        alignItems: path && flexAlign,
      }}
    >
       <Box
        flex="1"
        width="100%"
        maxWidth="650px"
        mb={4}
        _web={{
          py: cardPy,
          px: cardPx,
          borderRadius: 20,
          bg: cardBg,
          shadow: cardShadow,
          marginLeft: path ? marginLeft : 0,
        }}
      >
        {isWebDesktop && <BackWeb />}
        <ScrollView>
          <Heading
            fontSize="2xl"
            fontWeight="normal"
            px={4}
            pb={4}
            mt={4}
            color={colors.gray[700]}
          >
            {params ? 'Actualizar dirección' : 'Registrar nueva dirección'}
          </Heading>
          <>
            <Formik
              validationSchema={NewAddressSchema}
              initialValues={address}
              onSubmit={handleParseFormValues}
              innerRef={formikRef}
              enableReinitialize
            >
              {({
                handleSubmit,
                handleChange,
                setFieldValue,
                values,
                errors,
              }) => (
                <Box flex="1">
                  <Box px={4}>
                    <FloatingInput
                      name="street"
                      label="Calle"
                      onChangeText={handleChange('street')}
                      value={values.street}
                      icon="location-pin"
                    />
                    <HStack justifyContent="space-between">
                      <FloatingInput
                        name="ext_number"
                        label="No. Ext."
                        onChangeText={handleChange('ext_number')}
                        value={String(values.ext_number)}
                        minW="45%"
                      />
                      <FloatingInput
                        name="int_number"
                        label="No. Int."
                        onChangeText={handleChange('int_number')}
                        value={String(values.int_number)}
                        minW="45%"
                        mandatory={false}
                      />
                    </HStack>
                    <FloatingInput
                      keyboardType="numeric"
                      name="zip_code"
                      label="Código postal"
                      onChangeText={handleChange('zip_code')}
                      onBlurChange={async (value: string) => {
                        await setFieldValue('neiborhood', '');
                        await setFieldValue('state', '');
                        await setFieldValue('city', '');
                        handleBlurChange(value, errors.zip_code || '');
                      }}
                      value={String(values.zip_code)}
                      maxLength={5}
                    />
                    <Select
                      label="Colonia"
                      name="neiborhood"
                      options={copomexOptions}
                      selectedValue={values.neiborhood}
                      onValueChange={async (data: string) => {
                        setFieldValue('neiborhood', data);
                        const { state = '', city = '' } = setStateFromColonia(
                          data,
                        ) || { state: '', city: '' };
                        await setFieldValue('state', state);
                        await setFieldValue('city', city);
                      }}
                    />
                    <FloatingInput
                      name="city"
                      label="Alcaldía o Ciudad"
                      onChangeText={handleChange('city')}
                      value={values.city}
                    />
                    <FloatingInput
                      name="state"
                      label="Estado"
                      onChangeText={handleChange('state')}
                      value={values.state}
                    />
                    <FloatingInput
                      name="address_reference"
                      label="Referencias de la dirección"
                      onChangeText={handleChange('address_reference')}
                      value={values.address_reference}
                    />
                    <FloatingInput
                      name="address_name"
                      label="Descripción para la dirección"
                      onChangeText={handleChange('address_name')}
                      value={values.address_name}
                    />
                  </Box>
                  <Box px={4}>
                    <Button
                      isDisabled={isLoading || isLoadingGeocoding}
                      onPress={() => handleSubmit()}
                    >
                      {params ? 'Actualizar dirección' : 'Registrar dirección'}
                    </Button>
                  </Box>
                </Box>
              )}
            </Formik>
          </>
        </ScrollView>
      </Box>
    </Box>
  );
}
