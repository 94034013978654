import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  VStack,
  Center,
  Text,
  Pressable,
  ScrollView,
  Toast,
  Heading,
  HStack,
  Icon,
  Box,
} from 'native-base';

import RideCard from 'components/RideCard';
import { TripProps } from 'types/trips';
import useTrips from 'hooks/useTrips';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import ModalFilter from 'components/modals/ModalFilter';
import { RefreshControl } from 'react-native';
import { AuthContext } from 'context/AuthContext';

export type Filters = {
  startRadio: number;
  start_at_to: Date | string;
  start_address_state: string;
  end_address_state: string;
  latitude: number;
  longitude: number;
};

export type Params = {
  start_at_to: Date | string;
  start_address_state: string;
  end_address_state: string;
  radius_start_address: string;
  radius_end_address: string;
};

export default function Request() {
  const loadMoreButtonRef = useRef(null);
  const { state: userProfile } = useContext(AuthContext);
  const [openFilters, setOpenFilters] = useState(false);
  const [params, setParams] = useState<Params | null>({} as Params);

  const isDriverPhysicalAssociated =
  userProfile?.type === 'driver' &&
  userProfile.sub_type === 'physical' &&
  userProfile?.reverse_subordinates.length > 0;
  
  const driverId = isDriverPhysicalAssociated ? userProfile.id : ''
  
  const {
    error,
    trips,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
  } = useTrips({
    status: ['waiting_for_offers', 'created'],
    start_at_from: new Date(),
    driver_id: driverId,
    ...params,
  });

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(params)]);

  if (error) {
    Toast.show({
      title: 'Ocurrio un error. Intenta de nuevo',
      placement: 'top',
    });
  }

  const handleFilters = (filters: Filters) => {
    const currentParams = {
      start_address_state: filters.start_address_state,
      end_address_state: filters.end_address_state,
      start_at_to: filters.start_at_to
        ? new Date(filters.start_at_to).toISOString()
        : '',
      radius_start_address: filters.latitude
        ? `${filters.latitude}_${filters.longitude}_${filters.startRadio}`
        : '',
      radius_end_address: filters.longitude
        ? `${filters.latitude}_${filters.longitude}_100`
        : '',
    };
    setParams(currentParams);
    handleToggleFilter();
  };

  const handleCleanFilters = () => {
    setParams(null);
  };
  const handleToggleFilter = () => setOpenFilters(!openFilters);

  return (
    <ScrollView
      flex={1}
      refreshControl={
        <RefreshControl
          colors={['#5B0570']}
          tintColor="#6a077a"
          refreshing={false}
          onRefresh={() => refetch()}
        />
      }
    >
      <HStack justifyContent="space-between" alignItems="center" p={4}>
        <Heading p={4} fontSize="2xl" fontWeight="normal" color="gray.700">
          Solicitudes de viaje
        </Heading>
        <Icon
          size="6"
          mr={2}
          color="gray.500"
          onPress={handleToggleFilter}
          as={<MaterialCommunityIcons name="tune" size={24} color="gray.600" />}
        />
      </HStack>
      {trips.map((page, index) => (
        <VStack
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          space={4}
          px={4}
          mb={4}
        >
          {page.data.map((trip: TripProps) => {
            return (
              <Box key={trip.id}>
                <RideCard {...trip} />
              </Box>
            );
          })}
        </VStack>
      ))}
      <Center>
        {hasNextPage ? (
          <Pressable
            ref={loadMoreButtonRef}
            disabled={!hasNextPage || isFetchingNextPage}
            onPress={() => fetchNextPage()}
            py={3}
          >
            <Center>
              <Text
                borderBottomColor="gray.400"
                borderBottomWidth="1"
                fontWeight="medium"
              >
                Más resultados
              </Text>
            </Center>
          </Pressable>
        ) : (
          <Center pt={4} pb={4}>
            <Text
              fontWeight="medium"
              borderColor="gray.400"
              borderBottomWidth="1"
            >
              No hay más resultados para mostrar
            </Text>
          </Center>
        )}
      </Center>
      <ModalFilter
        isOpen={openFilters}
        params={params}
        handleCloseModal={handleToggleFilter}
        handleCleanFilters={handleCleanFilters}
        handleFilters={handleFilters}
      />
    </ScrollView>
  );
}
