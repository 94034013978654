import React, { useContext } from 'react';
import { Text, Stack, useTheme, VStack } from 'native-base';

import { getPercentage } from 'utils/formats';
import { AuthContext } from 'context/AuthContext';

export default function StatusPayment({
  bestOfferAmount,
  service,
  status,
}: {
  bestOfferAmount: string;
  service: number;
  status?: {
    bgColorStatus: string;
    label: string;
  };
}) {
  const { state: userProfile } = useContext(AuthContext);
  const { colors } = useTheme();

  const isDriverPhysicalAssociated =
    userProfile.type === 'driver' &&
    userProfile.sub_type === 'physical' &&
    userProfile.reverse_subordinates.length > 0;

  return (
    <Stack
      p={4}
      mt={4}
      mb={0}
      flex="1"
      bg={status?.bgColorStatus}
      rounded={8}
      shadow={6}
      _web={{
        width: '100%',
      }}
      _ios={{
        borderRadius: 8,
      }}
    >
      <Text
        fontSize="md"
        color={colors.gray[100]}
        mb={isDriverPhysicalAssociated ? 0 : 4}
        textAlign={isDriverPhysicalAssociated ? 'center' : 'left'}
      >
        {status?.label}
      </Text>
      {!isDriverPhysicalAssociated && (
        <VStack alignItems="flex-end">
          <Text fontSize="12px" color={colors.gray[100]}>
            pago 50%
          </Text>
          <Text fontSize="24px" color={colors.gray[100]}>
            {getPercentage(bestOfferAmount, service)}
          </Text>
        </VStack>
      )}
    </Stack>
  );
}
