import React, { useState } from 'react';
import {
  useNavigation,
  CommonActions,
  useRoute,
} from '@react-navigation/native';

import { ProfileType } from 'types/user';
import { Box, Text, Radio, useTheme } from 'native-base';
import Button from 'components/Button';
import BackWeb from 'components/BackWeb';
import useDesignAuth from 'webdesign/useDesignAuth';
import { isWebDesktop } from 'utils/general';
import TermsContent from 'components/TermsContent';

export default function Terms() {
  const navigation = useNavigation();
  const route = useRoute();
  const { colors } = useTheme();
  const [value, setValue] = useState('');

  const { cardPx, cardPy, cardBg, cardShadow } = useDesignAuth();
  const { profileType } = (route.params as { profileType: ProfileType }) || '';

  const handleNavigate = () => {
    navigation.dispatch(
      CommonActions.navigate({
        name: 'Registration',
        params: { profileType },
      }),
    );
  };

  return (
    <Box
      flex="1"
      px={8}
      pt={10}
      _web={{
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        flex="1"
        justifyContent="center"
        width="100%"
        maxWidth="650px"
        _web={{
          py: cardPy,
          px: cardPx,
          borderRadius: 20,
          bg: cardBg,
          shadow: cardShadow,
          justifyContent: 'center',
        }}
      >
        {isWebDesktop && <BackWeb />}
        <TermsContent />
        {profileType && (
          <Radio.Group
            name="terms"
            mb={6}
            accessibilityLabel="terms"
            value={value}
            onChange={(nextValue: string) => {
              setValue(nextValue);
            }}
          >
            <Radio value="tems">
              <Text
                color={colors.gray[700]}
                fontSize="sm"
                fontWeight="medium"
                ml={4}
              >
                Confirmo haber leído los terminos y condiciones.
              </Text>
            </Radio>
          </Radio.Group>
        )}
        {profileType && (
          <Button
            mb={8}
            onPress={handleNavigate}
            button="primary"
            isDisabled={!value}
          >
            Continuar
          </Button>
        )}
      </Box>
    </Box>
  );
}
