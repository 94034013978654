import React from 'react';
import { StyleSheet } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import {
  Box,
  Stack,
  Icon,
  Flex,
  IBoxProps,
  Text,
  Modal,
  Spinner,
} from 'native-base';
import { DocumentStatus } from 'types/documents';
import { colors } from '../constants/colors';

const variants = {
  document: 'document',
  travelStatus: 'travelStatus',
  travelAssign: 'travelAssign',
};

type Icons = React.ComponentProps<typeof MaterialCommunityIcons>['name'];
export const icons: {
  [key in
    | 'irregularity'
    | 'default'
    | 'camera'
    | 'validated'
    | 'sended'
    | 'empty']: Icons;
} = {
  validated: 'check-circle',
  irregularity: 'message-alert-outline',
  sended: 'clock',
  default: 'cloud-upload',
  camera: 'camera',
  empty: 'clock',
};

export const borderColor: {
  [key in 'process' | 'default' | 'empty' | DocumentStatus]: string;
} = {
  validated: colors.success[900],
  irregularity: colors.error[900],
  sended: colors.secondary[900],
  process: colors.primary[900],
  default: colors.gray[500],
  empty: colors.gray[500],
};

interface CardProps extends IBoxProps {
  title?: string;
  children: React.ReactNode;
  icon?: keyof typeof icons | string;
  status?: keyof typeof borderColor;
  variant: keyof typeof variants;
  isLoading?: boolean;
}

export default function Card({
  variant,
  title,
  children,
  status = 'default',
  icon = 'default',
  ...props
}: CardProps) {
  return (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      width="100%"
      rounded="lg"
      shadow={1}
      overflow="hidden"
      bg={colors.gray[100]}
      style={styles(variant, status).wrapper}
      borderRadius={variants[variant] === variants.document ? '16px' : '8px'}
    >
      {/* {isLoading && (
        <Modal bg="rgba(46, 40, 57, 0.2)" isOpen={isLoading}>
          <Modal.Content bg="transparent" _web={{ shadow: 'none' }}>
            <Spinner size="lg" color={colors.warning[900]} />
          </Modal.Content>
        </Modal>
      )} */}
      {variants[variant] === variants.document && (
        <Flex
          padding="14px"
          direction="row"
          bg={borderColor[status]}
          justifyContent="space-between"
        >
          <Text color={colors.gray[100]} fontSize="16px" fontWeight="400">
            {title}
          </Text>
          <Icon
            size={6}
            color="gray.100"
            as={<MaterialCommunityIcons name={icons[icon]} />}
          />
        </Flex>
      )}
      <Stack
        space={3}
        paddingY="16px"
        paddingRight="16px"
        paddingLeft={variants[variant] === variants.document ? '14px' : '8px'}
      >
        {children}
      </Stack>
    </Box>
  );
}

const styles = (
  variant: keyof typeof variants,
  status: keyof typeof borderColor,
) =>
  StyleSheet.create({
    wrapper: {
      borderStyle: 'solid',
      borderLeftWidth:
        variants[variant] === variants.document ||
        variants[variant] === variants.travelAssign
          ? 0
          : 10,
      borderLeftColor:
        variants[variant] === variants.document ||
        variants[variant] === variants.travelAssign
          ? 'transparent'
          : borderColor[status],
    },
  });
