import React from 'react';
import { useRoute } from '@react-navigation/native';
import { useAssets } from 'expo-asset';
import * as Linking from 'expo-linking';
import { Box, Text, Heading, Image, useTheme, Pressable } from 'native-base';

import useCommon from 'webdesign/useCommon';

export default function AboutUs() {
  const { path } = useRoute();
  const { colors } = useTheme();
  const [images] = useAssets([require('assets/images/logo.png')]);

  const {
    containerPt,
    containerPx,
    marginLeft,
    flexAlign,
    cardBg,
    cardPx,
    cardShadow,
    cardPy,
  } = useCommon();

  return (
    <Box
      flex="1"
      px={10}
      py={4}
      _web={{
        pt: containerPt,
        px: containerPx,
        justifyContent: 'center',
        alignItems: path && flexAlign,
      }}
    >
      <Box
        flex="1"
        width="100%"
        maxWidth="650px"
        mb={4}
        _web={{
          py: cardPy,
          px: cardPx,
          borderRadius: 20,
          bg: cardBg,
          shadow: cardShadow,
          marginLeft: path ? marginLeft : 0,
        }}
      >
        <Heading
          mb={12}
          fontSize="24"
          fontWeight="normal"
          color={colors.gray[700]}
        >
          Acerca de INFAST
        </Heading>
        {images?.map(({ uri, name }) => (
          <Image
            maxWidth="280px"
            width="280px"
            height="60px"
            alt="logo image"
            mb={6}
            key={name}
            source={{ uri }}
            alignSelf="center"
          />
        ))}
        <Text fontSize="20px" fontWeight="normal" color={colors.gray[700]}>
          Comienza tu historia con nosotros
        </Text>
        <Text my={4} fontSize="24px" fontWeight="700" color={colors.gray[700]}>
          ¿Porque nosotros?
        </Text>
        <Text fontSize="14px" color={colors.gray[700]} mb="85px" mt={6}>
          INFAST nace como empresa registrada con el nombre de Forwarders
          Operadores Logísticos SA de CV empresa 100% mexicana, con la finalidad
          de apoyar cada día a más personas a involucrarse en el Comercio
          Internacional, lleven o traigan sus productos fuera o dentro de
          México, apoyamos desde la pequeña idea de la creación de tu proyecto
          hasta que ya está todo en marcha
        </Text>
        <Text mb={4} fontSize="24" fontWeight="normal" color={colors.gray[700]}>
          Contacto
        </Text>
        <Pressable
          onPress={() => Linking.openURL('mailto::contacto@infast.com.mx')}
        >
          <Text
            mb={12}
            fontSize="14"
            fontWeight="normal"
            color={colors.gray[700]}
          >
            contacto@infast.com.mx
          </Text>
        </Pressable>
      </Box>
    </Box>
  );
}
