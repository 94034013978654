/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import DateTimePicker, {
  ReactNativeModalDateTimePickerProps,
} from 'react-native-modal-datetime-picker';
import { Pressable, IInputProps } from 'native-base';
import { add, isBefore, isDate } from 'date-fns';
import FloatingInput from 'components/FloatingInput';
import { Keyboard, Platform } from 'react-native';
import { formatDate, formatDateTime } from 'utils/formats';
import WebDatePicker from 'components/WebDatePicker';

interface DatePickerProps extends IInputProps {
  name: string;
  label: string;
  mandatory: boolean;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  pickerProps?: Partial<ReactNativeModalDateTimePickerProps>;
}

const DatePicker = ({
  name,
  label,
  pickerProps,
  setFieldValue,
  mandatory = true,
  mode = 'date',
  inputProps,
  ...props
}: DatePickerProps & any) => {
  const [visible, setVisible] = useState(false);
  const isValidDate = isDate(props.value);
  const dateValue =
    isValidDate && mode === 'date' ? formatDate(props.value) : '';
  const dateTimeValue =
    isValidDate && mode === 'datetime' ? formatDateTime(props.value) : '';
  const minDate = add(new Date(), {
    days: 3,
  });

  const handleConfirm = (date: Date | string) => {
    const validate =
      pickerProps?.minimumDate &&
      isBefore(new Date(date), pickerProps.minimumDate);

    setVisible(false);
    return validate
      ? setFieldValue(name, pickerProps.minimumDate)
      : setFieldValue(name, date);
  };

  const hanldePress = () => {
    Keyboard.dismiss();
    setVisible(true);
  };

  if (Platform.OS === 'web') {
    return (
      <WebDatePicker
        inputProps={inputProps}
        name={name}
        label={label}
        mandatory={mandatory}
        setFieldValue={setFieldValue}
        {...props}
      />
    );
  }

  return (
    <Pressable onPress={hanldePress} {...inputProps}>
      <FloatingInput
        {...props}
        mbWrapper={0}
        mandatory={mandatory}
        label={label}
        name={name}
        onFocus={hanldePress}
        value={dateValue || dateTimeValue}
        onPressIcon={hanldePress}
      />
      <DateTimePicker
        isVisible={visible}
        date={dateValue || new Date()}
        mode={mode}
        onConfirm={handleConfirm}
        onCancel={() => setVisible(false)}
        locale="es_MX"
        timePickerModeAndroid="spinner"
        reactNativeModalPropsIOS={{
          useNativeDriver: true,
          hideModalContentWhileAnimating: true,
        }}
        minimumDate={pickerProps?.minimumDate || minDate}
        {...pickerProps}
      />
    </Pressable>
  );
};
export default DatePicker;
