import React from 'react';
import { RefreshControl, Platform } from 'react-native';
import { useAssets } from 'expo-asset';
import { useNavigation, CommonActions } from '@react-navigation/native';
import { Box, Text, Heading, Image, ScrollView, useTheme } from 'native-base';
import useNotification, { routeToRedirect } from 'hooks/useNotification';
import Notification from 'components/Notification';
import { ContextProps } from 'types/notification';
import { fromUnixTime } from 'date-fns';

export default function Notifications() {
  const navigation = useNavigation();
  const { colors } = useTheme();
  const { notifications, onOpenedNotification } = useNotification();
  const [images] = useAssets([require('assets/images/shipping.png')]);

  const isWeb = Platform.OS === 'web';

  const handleOpenedNotification = (identifier: string) => {
    if (!isWeb) {
      onOpenedNotification(identifier);
    }
  };

  const handleRoute = (context: ContextProps, identifier: string) => {
    handleOpenedNotification(identifier);
    navigation.dispatch(
      CommonActions.navigate({
        name: routeToRedirect[context.context],
        params: {
          rideId: context.context_id ?? undefined,
          screen:
            context.context === 'SECTION_DOCUMENTS' ? 'profile' : undefined,
        },
      }),
    );
  };

  const defaultNotification =
    notifications.filter(notification => !notification.opened) ||
    ([] as NotificationOptions[]);

  const openedNotification =
    notifications.filter(notification => notification.opened) ||
    ([] as NotificationOptions[]);

  return (
    <Box flex="1">
      <ScrollView
        flex="1"
        refreshControl={
          <RefreshControl
            colors={['#5B0570']}
            tintColor="#6a077a"
            refreshing={false}
          />
        }
      >
        <Box>
          {defaultNotification.length > 0 || openedNotification.length > 0 ? (
            <Box>
              {defaultNotification.length > 0 && (
                <Heading
                  mb={4}
                  pl={10}
                  mt={4}
                  fontSize="22px"
                  color={colors.gray[700]}
                  fontWeight={400}
                >
                  Ultimas
                </Heading>
              )}
              {defaultNotification.map(notification => {
                const {
                  date,
                  request: { content, identifier },
                } = notification;
                const notificationData = content.data as ContextProps;
                const parseDate = fromUnixTime(date);
                return (
                  <Notification
                    key={identifier}
                    handleRoute={handleRoute}
                    notificationData={notificationData}
                    identifier={identifier}
                    parseDate={parseDate}
                    content={content}
                  />
                );
              })}
              {openedNotification.length > 0 && (
                <Heading
                  mb={4}
                  pl={10}
                  mt={4}
                  fontSize="22px"
                  color={colors.gray[700]}
                  fontWeight={400}
                >
                  Anteriores
                </Heading>
              )}
              {openedNotification.map(notification => {
                const {
                  date,
                  request: { content, identifier },
                } = notification;
                const notificationData = content.data as ContextProps;
                const parseDate = fromUnixTime(date);
                return (
                  <Notification
                    key={identifier}
                    handleRoute={handleRoute}
                    notificationData={notificationData}
                    identifier={identifier}
                    parseDate={parseDate}
                    content={content}
                    isOpened
                  />
                );
              })}
            </Box>
          ) : (
            <Box flex="1" justifyContent="center" alignItems="center" mt="30%">
              <Heading
                mb={4}
                fontSize="22px"
                color={colors.gray[700]}
                fontWeight={400}
              >
                ¡No tienes notificaciones!
              </Heading>
              <Text mb={10}>¡Excelente estas al día!</Text>
              {images?.map(({ uri, name }) => (
                <Image
                  maxWidth="260px"
                  width="100%"
                  height="140px"
                  alt="shipping"
                  mb={6}
                  key={name}
                  source={{ uri }}
                  alignSelf="center"
                />
              ))}
            </Box>
          )}
        </Box>
      </ScrollView>
    </Box>
  );
}
