import React, { useState, useContext, useEffect } from 'react';
import { Formik } from 'formik';
import { useNavigation, CommonActions } from '@react-navigation/native';
import { useQuery } from 'react-query';
import {
  Box,
  Heading,
  Text,
  ScrollView,
  Modal,
  Pressable,
  Toast,
  useTheme,
} from 'native-base';
import * as Yup from 'yup';

import { ProgressContext } from 'context/ProgressContext';
import Button from 'components/Button';
import FloatingInput from 'components/FloatingInput';
import { ResetPasswordByEmail as ResetPassword } from 'api';
import useDesignAuth from 'webdesign/useDesignAuth';

const Schema = Yup.object().shape({
  email: Yup.string().email('Correo inválido').required('Correo requerido'),
});

export default function ResetPasswordByEmail() {
  const navigation = useNavigation();
  const { colors } = useTheme();
  const { onShowProgress, onHideProgress } = useContext(ProgressContext);
  const [email, setEmail] = useState<string>('');
  const [openModal, setOpenModal] = useState(false);
  const { cardPx, cardPy, cardShadow, cardBg } = useDesignAuth();

  const { refetch } = useQuery('resetPassword', () => ResetPassword(email), {
    enabled: false,
    onSuccess: (data: { status: string }) => {
      if (data) {
        onHideProgress();
        setOpenModal(!openModal);
      }
    },
    onError: () => {
      onHideProgress();
      Toast.show({
        title: 'Ocurrió un error. intenta nuevamente.',
        placement: 'top',
      });
    },
  });

  useEffect(() => {
    if (email) {
      onShowProgress();
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  const onSubmit = (values: { email: string }) => {
    setEmail(values.email);
  };

  const handleLogin = () => {
    setOpenModal(!openModal);
    navigation.dispatch(
      CommonActions.navigate({
        name: 'Public',
      }),
    );
  };

  return (
    <Box
      flex="1"
      pt={10}
      _web={{
        justifyContent: 'center',
        alignItems: 'center',
        px: cardPx,
      }}
    >
      <Formik
        validationSchema={Schema}
        initialValues={{ email: '' }}
        onSubmit={onSubmit}
      >
        {({ handleChange, handleSubmit, values }) => (
          <Box
            flex="1"
            maxWidth="650px"
            _web={{
              py: cardPy,
              px: cardPx,
              borderRadius: 20,
              bg: cardBg,
              shadow: cardShadow,
            }}
          >
            <ScrollView flex="1" px={4}>
              <Text fontSize="24px" mb="16" textAlign="center" mt={4}>
                ¿Olvidaste tu contraseña?
              </Text>
              <Text my={4} fontSize="14px" color={colors.gray[700]} mb={8}>
                Te enviaremos un correo electrónico para ayudarte a generar una
                contraseña nueva
              </Text>
              <FloatingInput
                name="email"
                mbWrapper="0"
                label="Correo electrónico"
                onChangeText={handleChange('email')}
                value={values.email}
                keyboardType="email-address"
              />
            </ScrollView>
            <Button
              rounded="0"
              height="64px"
              button="primary"
              variant="solid"
              isDisabled={!values.email}
              onPress={() => handleSubmit()}
            >
              Continuar
            </Button>
          </Box>
        )}
      </Formik>
      <Modal bg="rgba(46, 40, 57, 0.2)" isOpen={openModal}>
        <Modal.Content
          px={4}
          pt={10}
          pb={6}
          _web={{ shadow: 'none' }}
          width="xl"
          alignItems="center"
          justifyContent="center"
        >
          <Heading
            fontSize="16px"
            color={colors.gray[800]}
            textAlign="center"
            fontWeight="normal"
          >
            Revisa tu correo
          </Heading>
          <Text fontSize="14px" color={colors.gray[700]} mb={10} mt={6}>
            Sigue las instrucciones
          </Text>
          <Pressable onPress={handleLogin}>
            <Text fontSize="16px" color={colors.gray[900]} fontWeight="400">
              Aceptar
            </Text>
          </Pressable>
        </Modal.Content>
      </Modal>
    </Box>
  );
}
