import React from 'react';
import { Formik } from 'formik';
import { Box, Heading, Center, Container, Text, Toast } from 'native-base';
import * as Yup from 'yup';
import { useNavigation } from '@react-navigation/native';

import Button from 'components/Button';
import FloatingInput from 'components/FloatingInput';
import { useMutation } from 'react-query';
import { ValidateNip } from 'api';
import { getItemStorage, setItemStorage } from 'utils';
import { AxiosError } from 'axios';
import { Nip } from 'types/user';

const ValidationSchema = Yup.object().shape({
  nip: Yup.string().required('NIP requerido'),
});

const getError = (error: string) => {
  const errors: { [k: string]: string } = {
    ERR_NIP_INVALID: 'Error NIP invalido',
    ERR_UPDATED_ACTIVE_STATUS: 'Error: no se puede actualizar la cuenta',
  };
  return errors[error];
};

export default function ValidationNIP() {
  const navigation = useNavigation();
  const { mutate, isLoading } = useMutation(ValidateNip, {
    onSuccess: (data) => {
      if (data) {
        setItemStorage({ key: 'isActive', value: 'true' });
        navigation.navigate('Login');
      }
    },
    onError: (error: AxiosError<any, string>) => {
      Toast.show({
        title: `Error: ${getError(error.response?.data.errors.detail)}`,
        placement: 'top',
      });
    },
  });

  const handleValidatedNip = async (values: { nip: string }) => {
    try {
      const id = (await getItemStorage('id')) || '';
      const params: Nip = {
        ...values,
        id,
      };
      mutate(params);
    } catch (e) {
      Toast.show({
        title: '¡Ocurrió un error!. Intenta nuevamente',
        placement: 'top',
      });
    }
  };

  return (
    <Center flex="1">
      <Container>
        <Formik
          validationSchema={ValidationSchema}
          initialValues={{ nip: '' }}
          onSubmit={handleValidatedNip}
        >
          {({ handleChange, handleSubmit, values }) => (
            <Box flex="1" width="100%" justifyContent="center" safeArea>
              <Heading mb="10">Ingresa el NIP</Heading>
              <Text mb="5">Te enviamos un NIP de validación a tu correo</Text>

              <FloatingInput
                type="number"
                name="nip"
                mbWrapper="9"
                label="NIP"
                onChangeText={handleChange('nip')}
                value={values.nip}
              />

              <Button
                isLoading={isLoading}
                onPress={() => handleSubmit()}
                button="primary"
                isDisabled={!values.nip}
              >
                Continuar
              </Button>
            </Box>
          )}
        </Formik>
      </Container>
    </Center>
  );
}
