import React from 'react';
import { Text, Heading, useTheme, Modal, Center, HStack } from 'native-base';
import Button from 'components/Button';

export default function ModalCompleteDocs({
  isOpen,
  onHideWelcome,
  onCloseWelcome,
}: {
  isOpen: boolean;
  onHideWelcome: () => void;
  onCloseWelcome: () => void;
}) {
  const { colors } = useTheme();

  return (
    <Modal bg="rgba(46, 40, 57, 0.2)" isOpen={isOpen} size="xl">
      <Modal.Content px={4} py={8} _web={{ shadow: 'none' }}>
        <Heading
          mb={10}
          mt={4}
          fontSize="22px"
          color={colors.gray[700]}
          fontWeight={400}
          textAlign="center"
        >
          ¡Bienvenido a INFAST!
        </Heading>
        <Center mb={12}>
          <Text fontSize="16px" color={colors.gray[700]} textAlign="center">
            Antes de continuar necesitas completar todos los documentos
            requeridos. Posteriormente serán validados y, si todo esta bien,
            podrás continuar.
          </Text>
        </Center>
        <HStack justifyContent="space-between">
          <Button minWidth="40%" button="ghost" onPress={onHideWelcome}>
            No volver a mostrar
          </Button>
          <Button button="primary" minWidth="40%" onPress={onCloseWelcome}>
            Cerrar
          </Button>
        </HStack>
      </Modal.Content>
    </Modal>
  );
}
