import React, { useContext } from 'react';
import {
  createNativeStackNavigator,
  NativeStackHeaderProps,
} from '@react-navigation/native-stack';
import ResetPassword from 'screens/ResetPassword';
import ProfileType from 'screens/ProfileType';
import BackHeader from 'components/BackHeader';
import { AuthContext } from 'context/AuthContext';
import { Box } from 'native-base';
import Terms from 'screens/Terms';
import Privacy from 'screens/Privacy';
import PublicStack from './PublicStack';
import AuthStack from './AuthStack';
import IncompleteProfileStack from './IncompleteProfileStack';

const Stack = createNativeStackNavigator();

export default function MainLoadingScreen() {
  const { isLoading, screen } = useContext(AuthContext);

  if (isLoading) {
    return null;
  }

  return (
    <Box
      flex={1}
      maxWidth={1440}
      _web={{
        margin: 'auto',
        width: '100%',
      }}
    >
      <Stack.Navigator initialRouteName="/">
        {screen === 'PublicStack' && (
          <Stack.Screen
            name="PublicStack"
            component={PublicStack}
            options={{ headerShown: false }}
          />
        )}
        {screen === 'IncompleteProfileStack' && (
          <Stack.Screen
            name="IncompleteProfileStack"
            component={IncompleteProfileStack}
            options={{ headerShown: false }}
          />
        )}
        {screen === 'AuthStack' && (
          <Stack.Screen
            name="AuthStack"
            component={AuthStack}
            options={{ headerShown: false }}
          />
        )}
        <Stack.Screen
          name="ResetPassword"
          component={ResetPassword}
          options={{
            header: (props: NativeStackHeaderProps) => (
              <BackHeader hideNotification {...props} />
            ),
          }}
        />
        <Stack.Screen
          name="ProfileType"
          component={ProfileType}
          options={{
            header: (props: NativeStackHeaderProps) => (
              <BackHeader iconBack hideNotification {...props} />
            ),
          }}
        />
        <Stack.Screen
          name="Terms"
          component={Terms}
          options={{
            header: (props: NativeStackHeaderProps) => (
              <BackHeader iconBack hideNotification {...props} />
            ),
          }}
        />
        <Stack.Screen
          name="Privacy"
          component={Privacy}
          options={{
            header: (props: NativeStackHeaderProps) => (
              <BackHeader iconBack hideNotification {...props} />
            ),
          }}
        />
      </Stack.Navigator>
    </Box>
  );
}
