import { useEffect, useRef } from 'react';

export default function useInterval(callback: any, delay: number, time: any) {
  const savedCallback = useRef<() => void>(null);

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      if (typeof savedCallback.current === 'function') {
        savedCallback?.current();
      }
    }

    const id = setInterval(tick, delay);
    if (time.minutes === 0 && time.seconds === 0) {
      clearInterval(id);
    }

    return () => clearInterval(id);
  }, [delay, time]);
}
