import { AddressProps } from 'types/address';

export const NEW_ADDRESS_VALUES: AddressProps = {
  street: '',
  int_number: '',
  ext_number: '',
  neiborhood: '',
  city: '',
  state: '',
  zip_code: '',
  address_reference: '',
  address_name: '',
  country: '',
  latitude: '',
  longitude: '',
  user_id: '',
};
export const TRANSPORT_PAYMENT_VALUES = {
  budget: '',
  vehicle_id: '',
};
