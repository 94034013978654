import React, { useContext } from 'react';
import { CommonActions, useNavigation } from '@react-navigation/native';
import {
  DrawerContentScrollView,
  DrawerContentComponentProps,
} from '@react-navigation/drawer';
import {
  Divider,
  HStack,
  Image,
  Pressable,
  useTheme,
  VStack,
  Text,
  Icon,
  Center,
  Heading,
} from 'native-base';

import { AuthContext } from 'context/AuthContext';
import { Routes, WebMenuRoutes } from 'types/catalogs';
import { menuItems } from 'utils/catalogs';
import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';

export default function CustomDrawerContent(
  props: DrawerContentComponentProps,
) {
  const navigation = useNavigation();
  const { colors } = useTheme();
  const { signOut } = useContext(AuthContext);
  const { state } = useContext(AuthContext);
  const {
    avatar = '',
    names = '',
    type = '',
    sub_type = '',
    first_surname = '',
    second_surname = '',
  } = state;

  const handleCloseDrawer = () => {
    // eslint-disable-next-line react/destructuring-assignment
    props.navigation.closeDrawer();
  };

  const handleNavigate = (route: Routes | WebMenuRoutes) => {
    navigation.dispatch(
      CommonActions.navigate({
        name: route,
        key: 'Drawer',
        params: {
          screen: route === 'Profile' ? 'profile' : 'menu',
        },
      }),
    );
    handleCloseDrawer();
  };

  const hanldeCloseSession = () => {
    handleCloseDrawer();
    signOut();
  };

  const buildName = () => {
    return `${names} ${first_surname} ${second_surname}`;
  };

  return (
    <DrawerContentScrollView {...props}>
      <VStack space="6" px="6" pb="6">
        <HStack>
          <Icon
            size="6"
            mr="4"
            color="gray.800"
            onPress={handleCloseDrawer}
            as={<MaterialIcons name="arrow-back-ios" />}
          />
          <Heading fontSize="24" fontWeight="normal" mb="16px">
            Menú
          </Heading>
        </HStack>
        <HStack alignItems="center">
          {avatar ? (
            <Image
              source={{ uri: avatar }}
              style={{ width: 64, height: 64, borderRadius: 70 }}
            />
          ) : (
            <Center size="54px" bg={colors.gray[400]} rounded="full">
              <MaterialCommunityIcons
                size={32}
                name="camera"
                color={colors.gray[500]}
              />
            </Center>
          )}
          <Text pl="6" fontSize="16px">
            {buildName() || 'Agregar nombre'}
          </Text>
        </HStack>
        <VStack divider={<Divider />}>
          <VStack space="5">
            <VStack space="3">
              {menuItems(type, sub_type).map(
                ({ route, id, label, icon, show }) => {
                  return (
                    show && (
                      <Pressable
                        py="3"
                        key={id}
                        onPress={() => handleNavigate(route)}
                      >
                        <HStack
                          space="7"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Text color="gray.700" fontSize="22px">
                            {label}
                          </Text>
                          <Icon
                            color="gray.500"
                            size="6"
                            as={<MaterialIcons name={icon} />}
                          />
                        </HStack>
                      </Pressable>
                    )
                  );
                },
              )}
            </VStack>
          </VStack>
        </VStack>
        <VStack space="7">
          <Pressable onPress={hanldeCloseSession}>
            <HStack justifyContent="space-between" alignItems="center">
              <Text color="gray.700" fontSize="14px">
                Cerrar sesión
              </Text>
              <Icon
                size="6"
                color="gray.500"
                as={<MaterialIcons name="logout" />}
              />
            </HStack>
          </Pressable>
          <Pressable
            onPress={() => {
              navigation.dispatch(
                CommonActions.navigate({
                  name: 'Terms',
                  path: 'Terms',
                }),
              );
            }}
          >
            <Text
              width="160px"
              fontSize="14px"
              color="gray.700"
              borderBottomColor="gray.400"
              borderBottomWidth="1"
            >
              Términos y condiciones
            </Text>
            <Text
              width="160px"
              fontSize="14px"
              color="gray.700"
              borderBottomColor="gray.400"
              borderBottomWidth="1"
            >
              v1.0.9
            </Text>
          </Pressable>
        </VStack>
      </VStack>
    </DrawerContentScrollView>
  );
}
