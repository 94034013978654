import { useState } from 'react';

type UseSteps = {
  initialStep: number;
  steps: number;
};

export default function useSteps({ initialStep }: UseSteps) {
  const [activeStep, setActiveStep] = useState(initialStep);

  const nextStep = () => setActiveStep((prev: number) => prev + 1);
  const prevStep = () => setActiveStep((prev: number) => prev - 1);
  const resetStep = () => setActiveStep(activeStep);
  const setStep = (step: number) => setActiveStep(step);

  return { nextStep, prevStep, resetStep, setStep, activeStep };
}
