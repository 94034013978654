import React, { createContext } from 'react';
import useProgress from 'hooks/useProgress';
import { ProgressProps } from 'types/general';

const ProgressContext = createContext<ProgressProps>({
  onShowProgress: () => undefined,
  onHideProgress: () => undefined,
  progress: {},
});

function ProgressProvider({ children }: { children: React.ReactNode }) {
  const { onShowProgress, onHideProgress, progress } = useProgress();
  return (
    <ProgressContext.Provider
      value={{ onShowProgress, onHideProgress, progress }}
    >
      {children}
    </ProgressContext.Provider>
  );
}
export { ProgressContext, ProgressProvider };
