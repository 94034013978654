/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { Platform, RefreshControl } from 'react-native';
import {
  Box,
  Flex,
  Heading,
  Text,
  ScrollView,
  VStack,
  useTheme,
  Pressable,
  Center,
} from 'native-base';
import { useNavigation, CommonActions } from '@react-navigation/native';
import Button from 'components/Button';
import RideCard from 'components/RideCard';
import { TripProps } from 'types/trips';
import { ProgressContext } from 'context/ProgressContext';
import useTrips from 'hooks/useTrips';
import { isEmptyArray } from 'utils/validators';
import { AuthContext } from 'context/AuthContext';
import { isWebDesktop } from 'utils/general';

export default function Travels() {
  const navigation = useNavigation();
  const { state: userProfile } = useContext(AuthContext);
  const { onHideProgress } = useContext(ProgressContext);
  const { colors } = useTheme();
  const {
    trips = [],
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
  } = useTrips({
    not_status: ['in_progress'],
    client_id: userProfile?.id,
  });

  useEffect(() => {
    onHideProgress();
  }, []);

  const handleRequest = () => {
    navigation.dispatch(
      CommonActions.navigate({
        name: isWebDesktop ? 'travelRequest' : 'TravelRequest',
        path: 'TravelRequest',
      }),
    );
  };

  return (
    <Box flex="1" mb={4}>
      <Heading
        fontSize="2xl"
        fontWeight="normal"
        py={2}
        color="gray.700"
        px={4}
      >
        Viajes
      </Heading>
      <Flex
        flex="1"
        justifyContent={
          isEmptyArray(trips) || isEmptyArray(trips[0]?.data)
            ? 'center'
            : 'flex-start'
        }
        overflow="hidden"
        mb={4}
      >
        <ScrollView
          flex={1}
          refreshControl={
            <RefreshControl
              colors={['#5B0570']}
              tintColor="#6a077a"
              refreshing={false}
              onRefresh={() => refetch()}
            />
          }
        >
          {isEmptyArray(trips) || isEmptyArray(trips[0]?.data) ? (
            <>
              <Text fontSize="lg" textAlign="center" color={colors.gray[500]}>
                Aún no tienes viajes.
              </Text>
              <Text fontSize="lg" textAlign="center" color={colors.gray[500]}>
                Inicia creando una solicitud.
              </Text>
            </>
          ) : (
            <Box flex={1}>
              {trips.map((page, index) => (
                <VStack space={4} px={4} key={`page-${index}`} mb={4}>
                  {page.data.map((trip: TripProps) => {
                    return (
                      <Box key={trip.id}>
                        <RideCard {...trip} />
                      </Box>
                    );
                  })}
                </VStack>
              ))}

              {hasNextPage ? (
                <Pressable
                  disabled={!hasNextPage || isFetchingNextPage}
                  onPress={() => fetchNextPage()}
                  py={3}
                >
                  <Center>
                    <Text
                      borderBottomColor="gray.400"
                      borderBottomWidth="1"
                      fontWeight="medium"
                    >
                      Más resultados
                    </Text>
                  </Center>
                </Pressable>
              ) : (
                <Center py={3}>
                  <Text
                    borderBottomColor="gray.400"
                    borderBottomWidth="1"
                    fontWeight="medium"
                  >
                    No hay más resultados para mostrar
                  </Text>
                </Center>
              )}
            </Box>
          )}
        </ScrollView>
      </Flex>
      <Center px={4}>
        <Button
          mx={3}
          width="full"
          maxWidth={Platform.OS === 'web' ? '300px' : 'full'}
          onPress={handleRequest}
        >
          Solicitar Viaje
        </Button>
      </Center>
    </Box>
  );
}
