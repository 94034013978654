import React from 'react';
import { Text, Heading, HStack, Modal, useTheme, Center } from 'native-base';
import Button from 'components/Button';
import { Subordinates } from 'types/user';

export default function ModalRemovedSubordinate({
  isOpen,
  isLoading,
  toggleModal,
  onRemoveSubordinate,
  subordinate,
}: {
  isOpen: boolean;
  isLoading: boolean;
  toggleModal: () => void;
  onRemoveSubordinate: () => void;
  subordinate: Subordinates;
}) {
  const { colors } = useTheme();
  const primaryColors = colors.primary as typeof colors['gray'];

  return (
    <Modal bg="rgba(46, 40, 57, 0.2)" isOpen={isOpen} size="xl">
      <Modal.Content px={4} py={8} _web={{ shadow: 'none' }}>
        <Heading
          fontSize="24px"
          color={colors.gray[700]}
          textAlign="center"
          fontWeight="normal"
        >
          Eliminar subordinado
        </Heading>
        <Text
          my={6}
          textAlign="center"
          fontSize="20px"
          color={primaryColors[700]}
        >
          {subordinate.names} {subordinate.first_surname}
        </Text>
        <Center mb={12} mt={4}>
          <Text fontSize="16px" color={colors.gray[700]}>
            Esta acción no se puede deshacer.
          </Text>
          <Text fontSize="16px" color={colors.gray[700]}>
            ¿Estas seguro de continuar?
          </Text>
        </Center>
        <HStack width="100%" justifyContent="space-between">
          <Button
            mb="8"
            height={10}
            width="45%"
            button="ghost"
            paddingTop="0px"
            paddingBottom="0px"
            onPress={toggleModal}
          >
            Regresar
          </Button>
          <Button
            ml={5}
            paddingTop="0px"
            paddingBottom="0px"
            height={10}
            width="45%"
            button="primary"
            onPress={onRemoveSubordinate}
            isDisabled={isLoading}
            isLoading={isLoading}
          >
            Continuar
          </Button>
        </HStack>
      </Modal.Content>
    </Modal>
  );
}
