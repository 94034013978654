import React from 'react';
import { Text, Heading, useTheme, Modal, Center, HStack } from 'native-base';
import Button from 'components/Button';

export default function ModalConfirmTrip({
  isOpen,
  toggleModal,
  onConfirmTrip,
  step,
  isDisabled,
}: {
  isOpen: boolean;
  isDisabled: boolean;
  step: 'start' | 'inProgress' | 'end';
  toggleModal: () => void;
  onConfirmTrip: () => void;
}) {
  const { colors } = useTheme();

  const message = {
    start: 'Al confirmar, el viaje iniciará. \n ¿Desea iniciar el viaje?',
    inProgress: 'Viaje en progreso',
    end: 'Al confirmar el viaje finalizará. \n ¿Desea finalizar el viaje?',
  };

  return (
    <Modal
      bg="rgba(46, 40, 57, 0.2)"
      isOpen={isOpen}
      size="xl"
      onClose={toggleModal}
    >
      <Modal.Content px={4} py={8} _web={{ shadow: 'none' }}>
        <Modal.CloseButton />
        <Heading
          mb={10}
          mt={4}
          fontSize="22px"
          color={colors.gray[700]}
          fontWeight={400}
          textAlign="center"
        >
          {step === 'start' ? 'Iniciar' : 'Finalizar'} viaje
        </Heading>
        <Center mb={12}>
          <Text fontSize="16px" color={colors.gray[700]} textAlign="center">
            {message[step]}
          </Text>
        </Center>
        <HStack justifyContent="space-between">
          <Button button="ghost" minWidth="45%" onPress={toggleModal}>
            Regresar
          </Button>
          <Button
            button="primary"
            minWidth="45%"
            isDisabled={isDisabled}
            onPress={() => onConfirmTrip()}
          >
            Confirmar
          </Button>
        </HStack>
      </Modal.Content>
    </Modal>
  );
}
