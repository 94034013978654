import React from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useTheme } from 'native-base';
import { Platform } from 'react-native';

import Travels from 'screens/Travels/Travels';
import OnTheWay from 'screens/Travels/OnTheWay';

const Tab = createBottomTabNavigator();

export default function TabsClient() {
  const { colors } = useTheme();
  const colorIcon = colors.secondary as typeof colors['gray'];

  return (
    <Tab.Navigator
      initialRouteName="Viajes"
      screenOptions={{
        tabBarLabelStyle: {
          fontSize: 12,
          ...Platform.select({
            web: {
              marginTop: 15,
            },
          }),
        },
        tabBarStyle: {
          borderTopColor: colors.gray['100'],
          elevation: 0,
          paddingBottom: 0,
          paddingTop: 0,
          ...Platform.select({
            web: {
              height: 60,
            },
            android: {
              height: 60,
              paddingTop: 10,
              paddingBottom: 10,
            },
            ios: {
              height: 60,
              paddingTop: 0,
              paddingBottom: 0,
            },
          }),
        },
        lazy: true,
        unmountOnBlur: true,
      }}
      detachInactiveScreens
    >
      <Tab.Screen
        name="Viajes"
        component={Travels}
        options={{
          headerShown: false,
          tabBarActiveTintColor: colorIcon[900],
          tabBarIcon: ({ size, color }) => (
            <MaterialCommunityIcons
              name="file-document"
              color={color}
              size={size}
            />
          ),
        }}
      />
      <Tab.Screen
        name="En camino"
        component={OnTheWay}
        options={{
          headerShown: false,
          tabBarActiveTintColor: colorIcon[900],
          tabBarIcon: ({ size, color }) => (
            <MaterialCommunityIcons
              name="bus-clock"
              color={color}
              size={size}
            />
          ),
        }}
      />
    </Tab.Navigator>
  );
}
