import { useContext } from 'react';
import { useQuery } from 'react-query';

import Context from 'context/Documents';
import { GetDocuments } from 'api';

export default function useFetchDocuments() {
  const { setDocuments, documents } = useContext(Context);
  const { isLoading, error, refetch } = useQuery('documents', GetDocuments, {
    refetchOnMount: 'always',
    onSuccess: data => {
      if (data) {
        setDocuments(data);
      }
    },
  });

  return { refetch, documents, isLoading, error };
}
