import { MaterialCommunityIcons } from '@expo/vector-icons';
import { RideStatus } from 'types/ride';

type IconStatus = 'empty' | 'sended' | 'validated' | 'irregularity';
type CommunityIcons = React.ComponentProps<
  typeof MaterialCommunityIcons
>['name'];

export const validatedIconStatus: { [key in IconStatus]: CommunityIcons } = {
  empty: 'alert-circle',
  sended: 'clock',
  validated: 'check-circle',
  irregularity: 'message-alert',
};

export const getIconByStatus = ({
  clientStatus,
  driverStatus,
  tripStatus,
  canOpen,
}: {
  clientStatus: boolean;
  driverStatus: boolean;
  canOpen: boolean;
  tripStatus: RideStatus;
}) => {
  if ((driverStatus && clientStatus && canOpen) || tripStatus === 'completed') {
    return validatedIconStatus.validated;
  }
  if ((driverStatus || clientStatus) && canOpen) {
    return validatedIconStatus.sended;
  }
  if ((!driverStatus || !clientStatus) && canOpen) {
    return validatedIconStatus.sended;
  }
  return validatedIconStatus.empty;
};

export const getValidatePayments = ({
  canOpen,
  tripStatus,
  paymentStatus,
}: {
  canOpen: boolean;
  tripStatus: RideStatus;
  paymentStatus: boolean;
}) => {
  if (canOpen && tripStatus === 'completed') {
    return validatedIconStatus.validated;
  }
  if (canOpen && paymentStatus) {
    return validatedIconStatus.validated;
  }
  if (canOpen && !paymentStatus) {
    return validatedIconStatus.sended;
  }
  return validatedIconStatus.empty;
};

export const isEmptyObject = (obj: {
  [key: string]: string | number | unknown;
}) => {
  return (
    obj &&
    typeof obj === 'object' &&
    obj.constructor === Object &&
    Object.keys(obj).length === 0
  );
};

export const isEmptyArray = (array: unknown[]) => {
  return (
    array &&
    typeof array === 'object' &&
    array.constructor === Array &&
    array.length === 0
  );
};
