/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react';
import {
  createDrawerNavigator,
  DrawerContentComponentProps,
  DrawerHeaderProps,
} from '@react-navigation/drawer';
import { Platform, Dimensions } from 'react-native';
import { Box, useBreakpointValue } from 'native-base';
import { AuthContext } from 'context/AuthContext';
import BackHeader from 'components/BackHeader';
import WebStack from 'navigation/WebStack';
import TabsDriver from 'navigation/TabsDriver';
import TabsClient from 'navigation/TabsClient';
import DrawerContent from 'navigation/DrawerContent';
import DrawerContentWeb from 'navigation/DrawerContentWeb';
import { isWebDesktop } from 'utils/general';

const Drawer = createDrawerNavigator();

function validateDrawerHeader(props: DrawerContentComponentProps) {
  if (isWebDesktop) {
    return <DrawerContentWeb {...props} />;
  }
  return <DrawerContent {...props} />;
}

export default function CustomDrawer() {
  const { state } = useContext(AuthContext);
  const { type } = state;
  const isDriver = type === 'driver';

  const mainSize = useBreakpointValue({
    base: 360,
    sm: 360,
  });

  const { width } = Dimensions.get('window');

  const vaidateDrawerType =
    width < 992 && Platform.OS === 'web'
      ? 'front'
      : width > 992 && Platform.OS === 'web'
      ? 'permanent'
      : 'front';

  return (
    <Box flex={1}>
      <Drawer.Navigator
        screenOptions={{
          drawerType: vaidateDrawerType,
          drawerStyle: {
            width: 360,
            borderRightWidth: 0,
            ...Platform.select({
              web: {
                width: mainSize,
              },
            }),
          },
          headerStatusBarHeight: 0,
          headerStyle: {
            height: 60,
            borderBottomColor: '#ffffff',
            elevation: 0,
          },
        }}
        drawerContent={(props: DrawerContentComponentProps) =>
          validateDrawerHeader(props)
        }
      >
        <Drawer.Screen
          name="Tab"
          component={
            isWebDesktop ? WebStack : isDriver ? TabsDriver : TabsClient
          }
          options={{
            header: (rest: DrawerHeaderProps) => (
              <BackHeader showMenuIcon hideLogo={isWebDesktop} {...rest} />
            ),
          }}
        />
      </Drawer.Navigator>
    </Box>
  );
}
