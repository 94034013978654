import React, { useContext, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import {
  Box,
  Center,
  Checkbox,
  Toast,
  Text,
  VStack,
  useTheme,
} from 'native-base';
import {
  AcceptClientTripOffer,
  AcceptDriverTripOffer,
  GetUserStars,
} from 'api';
import { Offer, TripProps, Vehicle } from 'types/trips';
import {
  getBestOffer,
  getBestOfferAmount,
  getTimeOfferOver,
} from 'utils/rides';
import CardOffering from 'components/CardOffering';
import CardOfferingSelected from 'components/CardOfferingSelected';
import ModalConfirmOffer from 'components/modals/ModalConfirmOffer';
import { CommonActions, useNavigation } from '@react-navigation/native';
import Button from 'components/Button';
import { isEmptyArray } from 'utils/validators';
import ModalDetailsOffer from 'components/modals/ModalDetailOffer';
import { AuthContext } from 'context/AuthContext';

export default function RideDeals({
  trip,
  refetch,
}: {
  trip: TripProps;
  refetch: () => void;
}) {
  const navigation = useNavigation();
  const { state: userProfile } = useContext(AuthContext);
  const [value, setValue] = useState<string>('');
  const { colors } = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenOffer, setIsOpenOffer] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState<Offer>({} as Offer);

  const primaryColors = colors.primary as typeof colors['gray'];
  const secondaryColors = colors.secondary as typeof colors['gray'];
  const existsBestOffer = getBestOffer(trip?.offers);
  const bestOfferAmount = getBestOfferAmount(trip?.offers);
  const vehicle = trip?.vehicle || ({} as Vehicle);
  const totalOffers = trip?.offers?.length;

  const isTimeOfferOver =
    trip.client_accepted_offer_at || getTimeOfferOver(trip?.inserted_at, 1);
  const servicePercent = 0.12;
  const service: number = Number(bestOfferAmount) * servicePercent;
  const EndpointClientType =
    trip?.client?.type === 'client'
      ? AcceptClientTripOffer
      : AcceptDriverTripOffer;

  const filteredBestOffer =
    (existsBestOffer && trip.offers.find(offer => offer.is_best)) ||
    ({} as Offer);

  const { data: stars } = useQuery(
    'stars',
    () => GetUserStars(filteredBestOffer.user_id, 'driver'),
    {
      enabled: !!filteredBestOffer.user_id,
    },
  );

  const { mutate } = useMutation(EndpointClientType, {
    onSuccess: () => {
      Toast.show({
        title: 'Oferta aceptada.',
        placement: 'top',
      });
      refetch();
      if (trip?.client?.type === 'client') {
        handleToggleModal();
      }
    },
    onError: () => {
      Toast.show({
        title: 'Hubo un error. intenta nuevamente.',
        placement: 'top',
      });
      if (trip?.client?.type === 'client') {
        handleToggleModal();
      }
    },
  });

  const handleToggleModal = () => setIsOpen(!isOpen);

  const handleToggleModalOffer = (offer: Offer) => {
    setIsOpenOffer(!isOpenOffer);
    setSelectedOffer(offer);
  };

  const handleFinishTime = () => {
    console.log('tiempo terminado');
  };

  const handleAcceptOffer = () => {
    if (!value) {
      Toast.show({
        title: 'Debes leer y aceptar los términos y condiciones',
        placement: 'top',
      });
      return;
    }
    const params = {
      offer_id: userProfile.type === 'client' ? selectedOffer.id : '',
    };
    mutate({
      tripId: trip.id,
      params,
    });
  };

  return (
    <Box flex="1">
      {isTimeOfferOver && isEmptyArray(trip?.offers) && (
        <Center p={6} flex="1" rounded={8} bg={colors.gray[100]}>
          <Text fontSize="md" color={colors.gray[700]}>
            No hubo ofertas disponibles
          </Text>
        </Center>
      )}
      {trip?.status === 'waiting_for_offers' &&
      (isEmptyArray(trip?.offers) || !isEmptyArray(trip?.offers)) &&
      !isTimeOfferOver ? (
        <CardOffering
          totalOffers={totalOffers}
          from={trip.inserted_at}
          onFinishTime={handleFinishTime}
        >
          <CardOfferingSelected
            title=""
            halfPaymentTitle="Iniciar viaje 50%"
            {...{
              bestOfferAmount,
              vehicle,
              service,
              screen: 'offer',
              offers: trip?.offers,
              stars: stars?.average,
              hideDefault: true,
              handleToggleModalOffer,
            }}
          >
            <ModalDetailsOffer
              value={value}
              vehicle={vehicle}
              setValue={setValue}
              isOpen={isOpenOffer}
              tripStatus={trip.status}
              selectedOffer={selectedOffer}
              halfPaymentTitle="Iniciar viaje 50%"
              isTimeOfferOver={isTimeOfferOver}
              handleToggleModal={handleToggleModal}
              handleAcceptOffer={handleAcceptOffer}
              toggleModal={() => setIsOpenOffer(!isOpenOffer)}
            />
          </CardOfferingSelected>
        </CardOffering>
      ) : null}
      {!isEmptyArray(trip?.offers) && existsBestOffer && isTimeOfferOver && (
        <CardOfferingSelected
          title="Oferta aceptada"
          halfPaymentTitle="Iniciar viaje 50%"
          {...{ bestOfferAmount, vehicle, service }}
        >
          <VStack my={8} justifyContent="space-between" w="100%">
            <Checkbox
              defaultIsChecked={trip?.status !== 'waiting_for_offers'}
              value={value}
              name="type"
              accessibilityLabel="type transport"
              onChange={(isSelected: any) => setValue(isSelected)}
              isDisabled={trip?.status !== 'waiting_for_offers'}
            >
              <Text ml={4} maxW="90%">
                <Text>Confirmo haber leído los </Text>
                <Text
                  fontWeight="600"
                  color={primaryColors[600]}
                  onPress={() => {
                    navigation.dispatch(
                      CommonActions.navigate({
                        name: 'Terms',
                      }),
                    );
                  }}
                >
                  terminos y condiciones.{' '}
                </Text>
              </Text>
            </Checkbox>
          </VStack>
          <VStack alignItems="center">
            <Text
              fontSize="xs"
              color={colors.gray[800]}
              mb={4}
              w="2xs"
              alignSelf="center"
              textAlign="center"
            >
              Lee los términos y condiciones para continuar con el proceso de
              pago.
            </Text>
          </VStack>
          {trip?.client_accepted_offer_at && (
            <Text
              my={4}
              textAlign="center"
              color={secondaryColors[900]}
              fontWeight="medium"
            >
              Esperando a que el transportista acepte la oferta.
            </Text>
          )}
          <Button
            isDisabled={trip.status !== 'waiting_for_offers'}
            button="primary"
            onPress={handleAcceptOffer}
          >
            {trip.status !== 'waiting_for_offers'
              ? 'Oferta aceptada'
              : 'Aceptar oferta'}
          </Button>
        </CardOfferingSelected>
      )}
      <ModalConfirmOffer
        isOpen={isOpen}
        toggleModal={handleToggleModal}
        handleAcceptOffer={handleAcceptOffer}
      />
    </Box>
  );
}
