import React, { useState } from 'react';
import { Formik } from 'formik';
import { AxiosError } from 'axios';
import {
  Box,
  HStack,
  Switch,
  Text,
  ScrollView,
  FormControl,
  Toast,
} from 'native-base';
import { User, ProfileType } from 'types/user';
import { useNavigation, useRoute } from '@react-navigation/native';
import * as Yup from 'yup';

import Button from 'components/Button';
import FloatingInput from 'components/FloatingInput';
import Select from 'components/Select';
import { useMutation } from 'react-query';
import { Register } from 'api';
import { setItemStorage } from 'utils';
import useDesignAuth from 'webdesign/useDesignAuth';
import BackWeb from 'components/BackWeb';
import { isWebDesktop } from 'utils/general';
import { Platform } from 'react-native';

const RegistrationSchema = Yup.object().shape({
  rfc: Yup.string().required('RFC requerida'),
  sub_type: Yup.string().required('Tipo de regimen requerido'),
  names: Yup.string().required('Nombre requerido'),
  first_surname: Yup.string().required('Apellido Paterno requerido'),
  second_surname: Yup.string(),
  phone: Yup.string().required('Teléfono requerida'),
  email: Yup.string().required('Correo requerido'),
  password: Yup.string()
    .required('Contraseña requerida')
    .min(8, 'Debe tener mínimo 8 caracteres.'),
  company_legal_name: Yup.string(),
});

const initialValues: Partial<User> = {
  sub_type: 'physical',
  names: '',
  first_surname: '',
  second_surname: '',
  phone: '',
  rfc: '',
  email: '',
  password: '',
  company_legal_name: '',
  linked_to_company: false,
  type: 'driver',
};

const getError = (error: string) => {
  const errors: { [k: string]: string } = {
    ERR_USER_EXIST: 'El email ya existe',
    ERR_COMPANY_EMAIL_IS_EQUAL_TO_USER_REGISTER:
      'El email de la compañia es el mismo del usuario que se está registrando',
    ERR_COMPANY_EMAIL_NOT_EXISTS: 'El email de la compañia no existe',
  };
  return errors[error];
};

/**
 * We have four scenarios
 *
 * 1. Transportista:
 *   - moral: not allow to linked with a existing company, requires razon social
 *   - fisica: allow to linked with a existing company, not requires razon social
 * 2. Cliente:
 *   - moral: requires razon social, not allow to linked with an existing company
 *   - fisica: not allow to linked with an existing company, not requires razon social
 */
export default function Registration() {
  const navigation = useNavigation();
  const route = useRoute();
  const [showPassword, setShowPassword] = useState(false);
  const { cardPx, cardPy, cardBg, cardShadow } = useDesignAuth();

  const { mutate, isLoading } = useMutation(Register, {
    onSuccess: (data) => {
      if (data) {
        setItemStorage({ key: 'id', value: data.id });
        setItemStorage({ key: 'isActive', value: 'false' });
        navigation.navigate('ValidationNIP');
      }
    },
    onError: (error: AxiosError<any, string>) => {
      Toast.show({
        title: `Error: ${getError(error.response?.data.errors.detail)}`,
        placement: 'top',
      });
    },
  });

  const isWeb = Platform.OS === 'web';
  const { profileType } = route.params as { profileType: ProfileType };
  const handlePressIcon = () => setShowPassword(!showPassword);

  const handleRegistration = (values: Partial<User>) => {
    const params = {
      ...values,
      type: profileType,
    };
    mutate({ user: params });
  };

  return (
    <Box
      flex="1"
      px={8}
      pt={10}
      _web={{
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <ScrollView
        flex="1"
        width="100%"
        maxWidth="650px"
        _web={{
          py: cardPy,
          px: cardPx,
          borderRadius: 20,
          bg: cardBg,
          shadow: cardShadow,
        }}
      >
        {isWebDesktop && <BackWeb />}
        <Text fontSize="24px" textAlign="center" mb={10} mt={isWeb ? 4 : 0}>
          Crea tu cuenta
        </Text>
        <Formik
          validationSchema={RegistrationSchema}
          initialValues={initialValues}
          onSubmit={handleRegistration}
        >
          {({ handleChange, handleSubmit, values, setFieldValue }) => (
            <Box flex="1">
              {values.sub_type === 'physical' && profileType === 'driver' && (
                <HStack
                  mb="9"
                  alignItems="center"
                  justifyContent="space-around"
                  _web={{
                    maxWidth: '100%',
                  }}
                >
                  <FormControl.Label m={0} maxW="75%">
                    <Text color="primary.900">
                      ¿Perteneces a una empresa registrada en INFAST?
                    </Text>
                  </FormControl.Label>
                  <Switch
                    name="linked_to_company"
                    onToggle={() => {
                      setFieldValue(
                        'linked_to_company',
                        !values.linked_to_company,
                      );
                    }}
                    isChecked={values.linked_to_company}
                  />
                </HStack>
              )}
              {values.sub_type === 'physical' && values.linked_to_company && (
                <FloatingInput
                  name="company_email"
                  mbWrapper="9"
                  label="Correo electrónico Jefe"
                  onChangeText={handleChange('company_email')}
                  value={values.company_email || ''}
                  keyboardType="email-address"
                />
              )}
              <Select
                name="sub_type"
                label="Tipo de regimen"
                options={
                  [
                    { label: 'Persona Física', value: 'physical' },
                    { label: 'Persona Moral', value: 'moral' },
                  ] as { label: string; value: User['sub_type'] }[]
                }
                selectedValue={values.sub_type}
                isDisabled={
                  values.linked_to_company && values.sub_type === 'physical'
                }
                onValueChange={handleChange('sub_type')}
              />
              <FloatingInput
                name="rfc"
                mbWrapper="9"
                label="RFC"
                onChangeText={handleChange('rfc')}
                value={values.rfc || ''}
                autoCapitalize="characters"
              />
              {values.sub_type === 'moral' && !values.linked_to_company && (
                <FloatingInput
                  name="company_legal_name"
                  mbWrapper="9"
                  label="Razon Social"
                  onChangeText={handleChange('company_legal_name')}
                  value={values.company_legal_name}
                />
              )}
              <FloatingInput
                name="names"
                mbWrapper="9"
                label="Nombre"
                onChangeText={handleChange('names')}
                value={values.names || ''}
              />
              <FloatingInput
                name="first_surname"
                mbWrapper="9"
                label="Apellido Paterno"
                onChangeText={handleChange('first_surname')}
                value={values.first_surname || ''}
              />
              <FloatingInput
                name="second_surname"
                mbWrapper="9"
                label="Apellido Materno"
                onChangeText={handleChange('second_surname')}
                value={values.second_surname || ''}
                mandatory={false}
              />
              <FloatingInput
                name="email"
                mbWrapper="9"
                label="Correo electrónico"
                onChangeText={handleChange('email')}
                value={values.email || ''}
                keyboardType="email-address"
              />
              <FloatingInput
                name="phone"
                mbWrapper="9"
                label="Teléfono"
                onChangeText={handleChange('phone')}
                value={values.phone || ''}
                keyboardType="numeric"
              />
              <FloatingInput
                name="password"
                mbWrapper="9"
                label="Contraseña"
                onPressIcon={handlePressIcon}
                onChangeText={handleChange('password')}
                type={showPassword ? 'text' : 'password'}
                icon={showPassword ? 'visibility' : 'visibility-off'}
                value={values.password || ''}
              />
              <Button
                my={8}
                isLoading={isLoading}
                onPress={() => handleSubmit()}
                button="primary"
              >
                Continuar
              </Button>
            </Box>
          )}
        </Formik>
      </ScrollView>
    </Box>
  );
}
