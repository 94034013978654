import React from 'react';
import { Box, Text, ScrollView, useTheme } from 'native-base';
import { Platform } from 'react-native';

export default function TermsContent() {
  const { colors } = useTheme();
  const isWeb = Platform.OS === 'web';

  return (
    <>
      <Text fontSize="24px" textAlign="center" mb={10} mt={isWeb ? 4 : 0}>
        Términos y condiciones
      </Text>
      <ScrollView flex="1" mb={6}>
        {/* page 1 */}
        <Box mb={4}>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            1. Relación contractual
          </Text>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            Las presentes Condiciones de uso (“ Condiciones ”) regulan el acceso
            o uso que usted haga, como persona, desde cualquier país del mundo
            (excluidos los Estados Unidos y sus territorios y posesiones así
            como China Continental) de aplicaciones, páginas web, contenido,
            productos y servicios (los “ Servicios ”) puestos a disposición por
            INFAST Y FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES S.A. DE
            C.V. , una sociedad de responsabilidad limitada constituida en
            México, con domicilio social en Cuernavaca, Morelos, México,
            inscrita en el municipio de Jiutepec, Morelos, C. San Gaspar, No.16,
            Col. Pedregal de las fuentes, C.P. 62554 (“INFAST Y FORWARDERS
            OPERADORES LOGISTICOS INTERNACIONALES S.A. DE C.V.”). LEA ESTAS
            CONDICIONES DETENIDAMENTE ANTES DE ACCEDER O USAR LOS SERVICIOS.
          </Text>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            Mediante su acceso y uso de los Servicios usted acuerda vincularse
            jurídicamente por estas Condiciones, que estableció una relación
            contractual entre usted, INFAST Y FORWARDERS OPERADORES LOGISTICOS
            INTERNACIONALES S.A. DE C.V. Si usted no acepta estas Condiciones,
            no podrá acceder o usar los Servicios. Estas Condiciones sustituyen
            expresamente los acuerdos o compromisos previos con usted. En caso
            de incumplimiento de los presentes Términos y Condiciones y/o a las
            Guías Comunitarias por parte de usted, INFAST Y FORWARDERS
            OPERADORES LOGISTICOS INTERNACIONALES S.A. DE C.V. podrá denegarle
            el acceso a su cuenta y/o a los Servicios. Por otro lado, INFAST Y
            FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES S.A. DE C.V. podrá
            modificar o poner fin a estos Términos y Condiciones o a cualquiera
            de los Servicios ofrecidos en las aplicaciones de manera total o
            parcial, mediante notificación previa a través de las aplicaciones
            y/o correo electrónico.
          </Text>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            Se podrá aplicar condiciones adicionales a determinados Servicios,
            pudiendo consistir en, de manera enunciativa mas no limitativa,
            políticas para un evento específico a realizar, actividades o
            promociones diversas, en el entendido que dichas condiciones
            adicionales le serán comunicadas y notificadas en relación con los
            Servicios a los que les resulten correspondientes, teniendo en todo
            momento el derecho de negarse a participar en los mismos. Las
            condiciones adicionales se reforzarán además de los presentes
            Términos y Condiciones, y se considerarán una parte de estas para
            las multas de los Servicios aplicables. Las condiciones adicionales
            antes mencionadas prevalecerán sobre los presentes Términos y
            Condiciones, en el caso de conflicto con respecto a los Servicios
            aplicables.
          </Text>
          <Text fontSize="14px" color={colors.gray[700]}>
            Mediante notificación previa a través de las aplicaciones y/o por
            correo electrónico INFAST Y FORWARDERS OPERADORES LOGISTICOS
            INTERNACIONALES S.A. DE C.V. podrá modificar las Condiciones
            relativas a los Servicios cuando lo considere oportuno. Las
            modificaciones serán efectivas después de la publicación por parte
            de INFAST Y FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES S.A. DE
            C.V. de dichas Condiciones actualizadas en esta ubicación o las
            políticas modificadas o condiciones adicionales sobre el Servicio
            aplicable. Su acceso o uso continuado de los
          </Text>
        </Box>
        {/* page 2 */}
        <Box mb={4}>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            Servicios después de dicha publicación constituye su consentimiento
            a vincularse por las Condiciones y sus modificaciones.
          </Text>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            La recopilación y el uso que hacemos de la información personal en
            relación con los Servicios es conforme se dispone en la Política de
            privacidad de INFAST Y FORWARDERS OPERADORES LOGISTICOS
            INTERNACIONALES S.A. DE C.V., disponible en https://infast.com.mx/
            INFAST Y FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES S.A. DE
            C.V. podrá facilitar a un procesador de reclamaciones o a una
            asegurara cualquier información necesaria (incluida su información
            de contacto) si hubiera quejas, disputas o conflictos, que pudieran
            incluir un accidente, implicándole a usted ya un tercero (incluido
            el conductor de la compañía de transporte) y dicha información o
            dichos datos necesarios para resolver la queja, la disputa o el
            conflicto.
          </Text>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            2. Los Servicios
          </Text>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            Los Servicios constituyen una plataforma de tecnología que permite a
            los usuarios de aplicaciones móviles de INFAST Y FORWARDERS
            OPERADORES LOGISTICOS INTERNACIONALES S.A. DE C.V. o páginas web
            proporcionadas como parte de los Servicios (cada una, una “
            Aplicación ”) organizar y planear el transporte y/o servicios de
            logística con terceros proveedores independientes de dichos
            servicios, incluidos terceros transportistas independientes y
            terceros proveedores logísticos independientes, conforme a un
            acuerdo con INFAST Y FORWARDERS OPERADORES LOGISTICOS
            INTERNACIONALES S.A. DE C.V. o algunos afiliados de INFAST Y
            FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES S.A. DE C.V. (“
            Terceros proveedores”), de transporte individual o de movilidad
            compartida. A no ser que INFAST Y FORWARDERS OPERADORES LOGISTICOS
            INTERNACIONALES S.A. DE C.V. lo acepte mediante un contrato separado
            por escrito con usted, los Servicios se ponen a disposición solo
            para su uso personal, no comercial. USTED RECONOCE QUE INFAST Y
            FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES S.A. DE C.V. NO
            PRESTA SERVICIOS DE TRANSPORTE O DE LOGÍSTICA O FUNCIONA COMO UNA
            EMPRESA DE TRANSPORTES Y QUE DICHOS SERVICIOS DE TRANSPORTE O
            LOGÍSTICA SE PRESTAN POR TERCEROS CONTRATISTAS INDEPENDIENTES, QUE
            NO ESTÁN EMPLEADOS POR INFAST Y FORWARDERS OPERADORES LOGISTICOS
            INTERNACIONALES S.A. DE C.V. NI POR NINGUNA DE SUS AFILIADOS.
          </Text>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            Licenciado
          </Text>
          <Text fontSize="14px" color={colors.gray[700]}>
            Sujeto al cumplimiento de estas Condiciones, INFAST Y FORWARDERS
            OPERADORES LOGISTICOS INTERNACIONALES S.A. DE C.V. le otorga una
            licencia limitada, no exclusiva, no sublicenciable, revocable, no
            transferible para: (i) el acceso y uso de las Aplicaciones en su
            dispositivo personal solo en relación con su uso de los Servicios; y
            (ii) el acceso y uso de cualquier contenido, información y material
            relacionado que pueda ponerse a disposición a través de los
            Servicios, en cada caso solo para su uso personal, no comercial.
            INFAST Y FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES S.A. DE
            C.V. y sus licenciantes se reservan cualquier derecho que no haya
            sido otorgado expresamente por el presente.
          </Text>
        </Box>
        {/* page 3 */}
        <Box mb={4}>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            Restricciones.
          </Text>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            Usted no podrá: (i) retirar cualquier nota de derechos de autor,
            marca registrada u otra nota de propiedad de cualquier parte de los
            Servicios; (ii) reproducir, modificar, preparar obras derivadas
            sobre los Servicios, distribuir, licenciar, arrendar, revender,
            transferir, exhibir públicamente, presentar públicamente,
            transmitir, retransmitir o explotar de otra forma los Servicios,
            excepto como se permita expresamente por INFAST Y FORWARDERS
            OPERADORES LOGISTICOS INTERNACIONALES S.A. DE C.V; (iii)
            descompilar, realizar ingeniería inversa o desmontar los Servicios,
            excepto como se permita por la ley aplicable; (iv) enlazar, reflejar
            o enmarcar cualquier parte de los Servicios; (v) causar o lanzar
            cualquier programa o script con el objeto de sobrecargar o bloquear
            indebidamente la operación y/o funcionalidad de cualquier aspecto de
            los Servicios;
          </Text>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            Prestación de los Servicios.
          </Text>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            Usted reconoce que parte de los Servicios pueden ponerse a
            disposición bajo varias marcas u opciones de pedidos de INFAST Y
            FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES S.A. DE C.V.
            asociados con el transporte o los servicios logísticos, incluidas de
            manera enunciativa, mas no limitativa, las marcas de pedidos de
            transporte y/o de vehículos, actualmente denominados “INFAST ”, y
            las marcas de pedidos de servicios logísticas actualmente
            denominadas “ INFAST Y FORWARDERS OPERADORES LOGISTICOS
            INTERNACIONALES S.A. DE C.V. ”. Asimismo, usted reconoce que los
            Servicios podrán ponerse a disposición bajo dichas marcas u opciones
            de pedidos por o en relación con: (i) ciertas subsidiarias o
            afiliadas de INFAST Y FORWARDERS OPERADORES LOGISTICOS
            INTERNACIONALES S.A. DE C.V.; o (ii) Terceros proveedores
            independientes, conductores de empresas de red de transportes,
            titulares del permiso de carta de transporte o titulares de
            permisos, autorizaciones o licencias de transporte similares.
          </Text>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            Servicios y contenido de Terceros.
          </Text>
          <Text fontSize="14px" color={colors.gray[700]}>
            Los Servicios podrán ponerse a disposición o ser accesibles en
            relación con servicios y contenido de terceros (incluida la
            publicidad) que INFAST Y FORWARDERS OPERADORES LOGISTICOS
            INTERNACIONALES S.A. DE C.V. no controla. Usted reconoce que podrá
            ser de aplicación de diferentes condiciones y políticas de
            privacidad al uso que haga de dichos servicios y contenido de
            terceros. INFAST Y FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES
            S.A. DE C.V. no respalda dichos servicios y contenido de terceros y
            en ningún caso INFAST es responsable de cualquier producto o
            servicio de dichos terceros proveedores. Además, Apple Inc., Google,
            Inc., Microsoft Corporation o BlackBerry Limited y/o sus
            correspondientes subsidiarias o afiliados internacionales serán
            terceros beneficiarios en este contrato si usted accede a los
            Servicios utilizando Aplicaciones desarrolladas para dispositivos
            móviles con sistema iOS, Android, Microsoft ventanas,
            respectivamente. Estos terceros beneficiarios no son parte de este
            contrato y no son responsables de la prestación o apoyo de los
            Servicios de ninguna manera. Su acceso a los Servicios utilizando
            estos dispositivos se sujeta a las condiciones establecidas en las
            condiciones de servicio de terceros beneficiarios aplicables.
          </Text>
        </Box>
        {/* page 4 */}
        <Box mb={4}>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            Titularidad.
          </Text>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            Los Servicios y todos los derechos relativos a estos son y mejorarán
            de la propiedad de INFAST Y FORWARDERS OPERADORES LOGISTICOS
            INTERNACIONALES S.A. DE C.V. o de sus licenciantes. Ninguna de estas
            Condiciones ni su uso de los Servicios le ampliarán u otorgarán
            ningún derecho: (i) sobre o en relación con los Servicios, excepto
            en cuanto a la licencia limitada otorgada anteriormente; o bien (ii)
            a utilizar o mencionar en cualquier modo a los nombres de empresa,
            logotipos, nombres de producto y servicio, marcas comerciales o
            marcas de servicio de INFAST Y FORWARDERS OPERADORES LOGISTICOS
            INTERNACIONALES S.A. DE C.V. o de sus licenciantes.
          </Text>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            3. Su uso de los Servicios
          </Text>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            cuentas de usuario.
          </Text>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            Con el fin de usar la mayor parte de los aspectos de los Servicios,
            usted debe registrarse y mantener activa una cuenta personal de
            usuario de los Servicios (“Cuenta”). Para obtener una Cuenta debe
            tener como mínimo 18 años, o tener la mayoría de edad legal en su
            jurisdicción (si es distinta a los 18 años). El registro de la
            cuenta le requiere que comunique a INFAST Y FORWARDERS OPERADORES
            LOGISTICOS INTERNACIONALES S.A. DE C.V. determinada información
            personal, como su nombre, dirección, número de móvil, así como por
            lo menos un método de pago válido (bien una tarjeta de crédito o
            bien un socio de pago aceptado). Usted se compromete a mantener la
            información en su Cuenta de forma exacta, completa y actualizada. Si
            no mantiene la información de Cuenta de forma exacta, completa y
            actualizada, incluso el tener un método de pago inválido o que haya
            vencido, podrá resultar en su imposibilidad para acceder y utilizar
            los Servicios o en la resolución por parte de INFAST de estas
            Condiciones contenidas con usted. Usted es responsable de toda la
            actividad que ocurre en su Cuenta y se compromete a mantener en todo
            momento de forma segura y secreta el nombre de usuario y la
            contraseña de su Cuenta. A menos que INFAST Y FORWARDERS OPERADORES
            LOGISTICOS INTERNACIONALES S.A. DE C.V. permita otra cosa por
            escrito, usted solo puede poseer una Cuenta.
          </Text>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            Requisitos y conducta del usuario.
          </Text>
          <Text fontSize="14px" color={colors.gray[700]}>
            El Servicio no está disponible para el uso de personas menores de 18
            años. Usted no podrá autorizar a terceros a utilizar su Cuenta,
            probablemente no podrá permitir a personas menores de 18 años que
            reciban servicios de transporte o logística de Terceros proveedores,
            a menos que aquellos sean acompañados por usted. No podrá ceder o
            transferir de otro modo su Cuenta a cualquier otra persona o
            entidad. Usted acuerda cumplir con todas las leyes aplicables al
            utilizar los Servicios y solo podrá utilizar los Servicios con
            multas legítimas (p. ej., no para el transporte de materiales
            ilegales o peligrosos). En el uso de los Servicios, no causará
            estorbos, molestias, incomodidades o daños a la propiedad, tanto al
            Tercero proveedor como a cualquier otra parte. En algunos casos, se
            le podrá requerir que facilite un documento de identidad u otro
            elemento de verificación de identidad para el acceso o uso de los
            Servicios, y usted acepta que se le podrá denegar el acceso o uso de
            los Servicios si se niega a facilitar el documento de identidad o el
            elemento de verificación de identidad.
          </Text>
        </Box>
        {/* page 5 */}
        <Box mb={4}>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            Mensajes de texto.
          </Text>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            Al crear una Cuenta, usted acepta que los Servicios le pueden enviar
            mensajes de texto informativos (SMS) como parte de la actividad
            comercial normal de su uso de los Servicios. Usted podrá solicitar
            la no recepción de mensajes de texto informativos (SMS) de INFAST Y
            FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES S.A. DE C.V. en
            cualquier momento en la siguiente dirección https://infast.com.mx/
            indicando que no desea recibir más dichos mensajes, junto con el
            número de teléfono del dispositivo móvil que recibe los mensajes.
            Usted reconoce que solicitar la no recepción de mensajes de texto
            informativos (SMS) podrá afectar al uso que usted haga de los
            Servicios.
          </Text>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            Códigos promocionales.
          </Text>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            INFAST Y FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES S.A. DE
            C.V. podrá, a su sola discreción, crear códigos promocionales que
            podrán ser canjeados por crédito de Cuenta u otros elementos o
            beneficios relacionados con los Servicios y/o los servicios de un
            Tercero proveedor, con sujeción a cualquier condición adicional que
            INFAST Y FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES S.A. DE
            C.V. establezca sobre la base de cada código promocional (“Códigos
            promocionales”). Usted acuerda que los Códigos promocionales: (i)
            deben usarse para la audiencia y el propósito deseado, y de manera
            lícita; (ii) no podrá duplicarse, venderse o transferirse de ninguna
            manera, o ponerse a disposición del público general (tanto si se
            publica en un foro público como de otra forma), a menos que sea con
            el permiso de INFAST Y FORWARDERS OPERADORES LOGISTICOS
            INTERNACIONALES S.A. DE C.V. ; (iii) podrán ser invalidados por
            INFAST en cualquier momento por cualquier motivo sin responsabilidad
            para INFAST; (iv) podrá usar solo conforme a las condiciones
            específicas que INFAST estableció para dicho Código promocional; (v)
            no son válidos como efectivo; y (vi) podrá caducar antes de que
            usted los utilice.
          </Text>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            Contenido proporcionado por el Usuario.
          </Text>
          <Text fontSize="14px" color={colors.gray[700]}>
            INFAST Y FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES S.A. DE
            C.V. podrá permitirle, cuando considere oportuno, que envíe, cargue,
            publique o de otro modo ponga a disposición de INFAST, a través de
            los Servicios, contenido e información de texto, audio y/o visual,
            incluidos comentarios y opiniones relativas a los Servicios,
            iniciación de peticiones de apoyo, así como presentación de
            admisiones para competiciones y promociones (“Contenido de
            usuario”). Todo Contenido de usuario facilitado por usted seguirá
            siendo de su propiedad. No obstante, al proporcionar Contenido de
            usuario a INFAST Y FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES
            S.A. DE C.V., usted otorga a INFAST, una licencia mundial, perpetua,
            irrevocable, transferible, libre de regalías, con derecho a
            sublicenciar, usar, copiar, modificar, crear obras derivadas,
            distribuir, exhibir públicamente,
          </Text>
        </Box>
        {/* page 6 */}
        <Box mb={4}>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            Usted declara y garantiza que: (i) es el único y exclusivo
            propietario de todo el Contenido de usuario o que tiene todos los
            derechos, licencias, consentimientos y permisos necesarios para
            otorgar a INFAST la licencia al Contenido de usuario como se
            estableció anteriormente; y (ii) ni el Contenido de usuario ni su
            presentación, carga, publicación o puesta a disposición de otro modo
            de dicho Contenido de usuario, ni el uso por parte de INFAST Y
            FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES S.A. DE C.V. del
            Contenido de usuario como está aquí permitido, infringirán,
            malversarán o violarán la propiedad intelectual o los derechos de
            propiedad de un tercero o los derechos de publicidad o privacidad o
            resultarán en la violación de cualquier ley o reglamento aplicable.
          </Text>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            Usted acuerda no proporcionar Contenido de usuario que sea
            difamatorio, calumnioso, odioso, violento, obsceno, pornográfico,
            ilícito o de otro modo ofensivo, como determine INFAST, a su sola
            discreción, tanto si dicho material puede estar protegido o no por
            la ley. INFAST Y FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES
            S.A. DE C.V. podrá, a su sola discreción y en cualquier momento y
            por cualquier motivo, sin avisarle previamente, revisar, controlar o
            eliminar el Contenido de usuario, pero sin estar obligado a ello.
          </Text>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            Acceso a la red y dispositivos.
          </Text>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            Usted es responsable de obtener el acceso a la red de datos
            necesarios para utilizar los Servicios. Podrán aplicar las tarifas y
            tasas de datos y mensajes de su red móvil si usted accede o utiliza
            los Servicios desde un dispositivo inalámbrico y usted será
            responsable de dichas tarifas y tasas. Usted es responsable de
            adquirir y actualizar el hardware compatible o los dispositivos
            necesarios para acceder y utilizar los Servicios y Aplicaciones y
            cualquier actualización de estos. INFAST Y FORWARDERS OPERADORES
            LOGISTICOS INTERNACIONALES S.A. DE C.V. no garantiza que los
            Servicios, o cualquier parte de estos, funcionen en cualquier
            hardware o dispositivo en particular. Además, los Servicios pueden
            ser objeto de disfunciones o retrasos inherentes al uso de Internet
            y de las comunicaciones electrónicas.
          </Text>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            4. Pago
          </Text>
          <Text fontSize="14px" color={colors.gray[700]}>
            Usted entiende que el uso de los Servicios puede derivar en cargos
            por los servicios o bienes que reciben de un Tercer proveedor,
            terceros independientes o por los servicios de intermediación u
            otros que reciben de INFAST Y FORWARDERS OPERADORES LOGISTICOS
            INTERNACIONALES S.A. DE C.V. o las subsidiarias o afiliados de
            INFAST Y FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES S.A. DE
            C.V. en su jurisdicción o desde el extranjero, según corresponda
            (los “Cargos”). En caso de realizar Cargos por cuenta del Tercero
            proveedor, INFAST Y FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES
            S.A. DE C.V., sus afiliadas o subsidiarias serán éstas (el Tercero
            proveedor, INFAST, sus subsidiarias o afiliados) quienes le
            trasladarán y cobrarán los impuestos correspondientes en su
            jurisdicción o en el extranjero y que aplicarán conforme a la Ley.
            Después que haya recibido los servicios u obtenido los bienes
            (anteriormente referidos) mediante el uso de los Servicios, INFAST,
            como agente de cobro limitado, facilitará el pago de los Cargos e
          </Text>
        </Box>
        {/* page 7 */}
        <Box mb={4}>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            impuestos aplicables en nombre del Tercero proveedor o de INFAST o
            las subsidiarias o afiliados de INFAST en su jurisdicción o en el
            extranjero, según corresponda. El pago de los Cargos e impuestos
            (cuando resulten aplicables) de dicha manera se considerará como el
            pago realizado directamente por usted al Tercero proveedor, INFAST o
            las subsidiarias o afiliados de INFAST en su jurisdicción o en el
            extranjero, según corresponda. Los Cargos pagados por usted son
            definitivos y no reembolsables, a menos que INFAST Y FORWARDERS
            OPERADORES LOGISTICOS INTERNACIONALES S.A. DE C.V. determine lo
            contrario. Usted conserva el derecho de solicitar Cargos más bajos
            de un Tercero proveedor para los servicios o bienes recibidos por
            usted de dicho Tercero proveedor en el momento en que recibe dichos
            servicios o bienes. INFAST Y FORWARDERS OPERADORES LOGISTICOS
            INTERNACIONALES S.A. DE C.V. responderá en consecuencia a cualquier
            solicitud de un Tercero proveedor para modificar los Cargos por un
            servicio o bien en particular. Los cargos pueden incluir otras
            cuotas, peajes y/o recargos aplicables, incluyendo cuotas por
            solicitud, cuotas o cargos municipales, estatales o
            nacionales/federales, cargos por demoras, recargos aeroportuarios o
            tarifas de procesamiento por pagos fraccionados, entre otros que
            sean aplicables. Visite https://infast.com.mx/ para obtener más
            información sobre las tarifas estimadas de su viaje en su ubicación
            en particular.
          </Text>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            Todos los Cargos e impuestos (cuando resulten aplicables) son
            pagaderos inmediatamente y el pago se facilitará por INFAST Y
            FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES S.A. DE C.V.
            utilizando el método de pago preferido indicado en su Cuenta, y
            después de ello INFAST Y FORWARDERS OPERADORES LOGISTICOS
            INTERNACIONALES S.A. DE C.V. le obtendrá un recibo por correo
            electrónico en el que se detallarán los servicios prestados y/o
            bienes adquiridos. Si se determina que el método de pago de su
            Cuenta principal ha caducado, es inválido o de otro modo no sirve
            para cobrarle, usted acepta que INFAST Y FORWARDERS OPERADORES
            LOGISTICOS INTERNACIONALES S.A. DE C.V., como agente de cobro
            limitado del Tercero proveedor, utilice un método de pago secundario
            en su Cuenta, si estuviera disponible.
          </Text>
          <Text fontSize="14px" color={colors.gray[700]}>
            INFAST Y FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES S.A. DE
            C.V., se reserva el derecho de eliminar y/o modificar los Cargos
            para alguno o todos los servicios o bienes obtenidos a través del
            uso de los Servicios, a su solicitud. Además, usted reconoce y
            acepta que los Cargos aplicables en determinadas zonas geográficas
            podrán incrementar sustancialmente durante los períodos de alta
            demanda. INFAST Y FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES
            S.A. DE C.V. usará los esfuerzos razonables para informarle de los
            Cargos que podrán aplicar, siempre y cuando usted sea responsable de
            los Cargos incurridos en su Cuenta, independientemente de que usted
            conozca dichos Cargos o los importes de estos. INFAST Y FORWARDERS
            OPERADORES LOGISTICOS INTERNACIONALES S.A. DE C.V. directamente o a
            través de sus afiliadas o subsidiarias podrá, cuando lo considere
            oportuno, proporcionar a determinados usuarios ofertas promocionales
            y descuentos similares que pueden resultar en el cobro de diferentes
            importaciones por estos o servicios o bienes obtenidos a través del
            uso de los Servicios, y usted acepta que dichas ofertas
            promocionales y descuentos, a menos que también se pongan a su
            disposición, no se tendrán en cuenta en el uso por su parte de los
            Servicios o los Cargos aplicados a usted. Usted podrá optar por
            cancelar su solicitud para los servicios o bienes de un Tercero
          </Text>
        </Box>
        {/* page 8 */}
        <Box mb={4}>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            proveedor en cualquier momento antes de la llegada de ese Tercero
            proveedor, en cuyo caso se le cobrará una tasa de cancelación. no se
            tendrán en cuenta en el uso por su parte de los Servicios o los
            Cargos aplicados a usted. Usted podrá optar por cancelar su
            solicitud para los servicios o bienes de un Tercero proveedor en
            cualquier momento antes de la llegada de ese Tercero proveedor, en
            cuyo caso se le cobrará una tasa de cancelación. no se tendrán en
            cuenta en el uso por su parte de los Servicios o los Cargos
            aplicados a usted. Usted podrá optar por cancelar su solicitud para
            los servicios o bienes de un Tercero proveedor en cualquier momento
            antes de la llegada de ese Tercero proveedor, en cuyo caso se le
            cobrará una tasa de cancelación.
          </Text>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            Esta estructura de pago está destinada para compensar completamente
            al Tercero proveedor por los servicios o bienes proporcionados.
            Excepto con respecto a los servicios no designa ninguna parte de su
            pago como propina o gratificación al Tercero proveedor. Cualquier
            manifestación por parte de INFAST Y FORWARDERS OPERADORES LOGISTICOS
            INTERNACIONALES S.A. DE C.V. (en la página web de INFAST o en los
            materiales de marketing de INFAST) en el sentido de que dar una
            propina es “voluntario”, “no requerido”, y/o “incluido” en los pagos
            que realiza para los servicios o bienes proporcionados no pretende
            sugerir que INFAST Y FORWARDERS OPERADORES LOGISTICOS
            INTERNACIONALES S.A. DE C.V. proporciona importaciones adicionales,
            aparte de los descritos anteriormente, al Tercero proveedor. Usted
            entiende y acepta que, mientras es libre de proporcionar un pago
            adicional como gratificación a cualquier Tercero proveedor que le
            proporcione servicios o bienes obtenidos mediante los Servicios, no
            tiene obligación de ello. Las gratificaciones son voluntarias.
            Después de que haya recibido los bienes o servicios obtenidos a
            través del Servicio, tendrá la oportunidad de calificar su
            experiencia y dejar comentarios adicionales sobre el Tercero
            proveedor.
          </Text>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            Tarifa de reparación, limpieza o cargas por objetos olvidados.
          </Text>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            En el supuesto de que derivado del uso de los Servicios y/o el uso
            de su Cuenta ocasione daños o gastos de limpieza al vehículo del
            Tercero proveedor, Usted será responsable del costo de la reparación
            de dichos daños o de la limpieza necesaria de los vehículos y las
            propiedades del Tercero proveedor, que se ocasionen por el uso de
            los Servicios en su Cuenta que sobrepasen los daños considerados
            normales por “el desgaste” y la limpieza necesaria (“Reparación o
            Limpieza”).
          </Text>
          <Text fontSize="14px" color={colors.gray[700]}>
            En el caso, que un Tercero proveedor informe a INFAST Y FORWARDERS
            OPERADORES LOGISTICOS INTERNACIONALES S.A. DE C.V. la necesidad de
            reparación o limpieza y estos sean debidamente acreditados por el
            Tercero proveedor, usted acepta expresamente y otorga su
            consentimiento para que INFAST Y FORWARDERS OPERADORES LOGISTICOS
            INTERNACIONALES S.A. DE C.V. realice la carga a su cuenta como
            agente facilitador de pago del Tercero proveedor por el costo
            razonable de dicha reparación o limpieza en nombre del Tercero
            proveedor utilizando el método de pago indicado en su Cuenta, lo
            anterior, de conformidad con los valores que se afirma más adelante.
            Dichos importes, así como aquellos cargos por la devolución de
            objetos olvidados, se transferirán por INFAST Y FORWARDERS
            OPERADORES LOGISTICOS INTERNACIONALES S.A. DE C.V. al
            correspondiente Tercero proveedor y no son reembolsables.
          </Text>
        </Box>
        {/* page 9 */}
        <Box mb={4}>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            Los valores correspondientes a los cargos que se realizan a usted,
            por limpieza o devolución de objetos olvidados, los podrá encontrar
            en https://infast.com.mx/ y podrá ser actualizados en todo momento
            por INFAST Y FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES S.A.
            DE C.V.
          </Text>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            5. Soporte; Renuncias; limitación de responsabilidad; Indemnización.
          </Text>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            SOPORTE.
          </Text>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            INFAST Y FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES S.A. DE
            C.V. brinda soporte los 365 días del año, en caso de que lo
            necesite. Para recibir soporte, puede ingresar a la sección de
            “Ayuda”, ubicada en el menú principal de la aplicación o a través de
            la página web https://infast.com.mx/ . Es importante mencionar que
            INFAST Y FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES S.A. DE
            C.V. cuenta con Guías Comunitarias, a las que todos los que utilizan
            la aplicación se deben apegar. Las limitaciones y la renuncia en
            este apartado 5 no pretenden limitar la responsabilidad o alterar
            sus derechos como consumidor que no pueden suprimirse según la ley
            aplicable.
          </Text>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            RENUNCIA.
          </Text>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            Los servicios se garantizan “tal cual” y “como disponibles”. INFAST
            Y FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES S.A. DE C.V.
            renuncia a toda declaración y garantía, expresa, implícita o
            estatutaria, no expresamente establecida en estas condiciones,
            incluidas las garantías implícitas de comerciabilidad, idoneidad
            para un fin particular y no violación. Además, INFAST Y FORWARDERS
            OPERADORES LOGISTICOS INTERNACIONALES S.A. DE C.V. no hace
            declaración ni presta garantía alguna relativa a la confianza,
            puntualidad, calidad, idoneidad o disponibilidad de los servicios o
            cualquiera de los servicios o bienes solicitados a través del uso de
            los servicios, o que los servicios no serán interrumpidos o
            disponibles libre de errores. INFAST Y FORWARDERS OPERADORES
            LOGISTICOS INTERNACIONALES S.A. DE C.V. no garantiza la calidad,
            idoneidad, seguridad o habilidad de los terceros proveedores. Usted
            acuerda que todo riesgo derivado de su uso de los servicios y
            cualquier servicio o bien solicitado en relación con aquellos será
            únicamente suyo,
          </Text>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            LIMITACIÓN DE RESPONSABILIDAD.
          </Text>
          <Text fontSize="14px" color={colors.gray[700]}>
            INFAST Y FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES S.A. DE
            C.V. no será responsable de daños indirectos, incidentales,
            especiales, ejemplares, punitivos o emergentes, incluidos el lucro
            cesante, la pérdida de datos, la lesión personal o el daño a la
            propiedad, ni de perjuicios relativos, o en relación con, o de otro
            modo derivado de cualquier uso de los servicios,
          </Text>
        </Box>
        {/* page 10 */}
        <Box mb={4}>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            aunque INFAST Y FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES
            S.A. DE C.V. haya sido anunciado de la posibilidad de dichos daños.
            INFAST Y FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES S.A. DE
            C.V. no será responsable de cualquier daño, responsabilidad o
            pérdida que derive de: (I) Su uso o dependencia de los servicios o
            su incapacidad para acceder o utilizar los servicios; o (ii)
            Cualquier transacción o relación entre usted y cualquier tercero
            proveedor, aunque INFAST Y FORWARDERS OPERADORES LOGISTICOS
            INTERNACIONALES S.A. DE C.V. hubiera sido advertido de la
            posibilidad de dichos daños. INFAST Y FORWARDERS OPERADORES
            LOGISTICOS INTERNACIONALES S.A. DE C.V. no será responsable del
            retraso o de la falta de ejecución resultante de las causas que
            vayan más allá del control razonable de INFAST Y FORWARDERS
            OPERADORES LOGISTICOS INTERNACIONALES S.A. DE C.V.
          </Text>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            En ningún caso la responsabilidad total de INFAST Y FORWARDERS
            OPERADORES LOGISTICOS INTERNACIONALES S.A. DE C.V. hacia usted en
            relación con los servicios por todos los daños, las pérdidas y los
            juicios podrá exceder de quinientos USD ($500) o su equivalente en
            moneda nacional.
          </Text>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            Usted podrá utilizar los servicios de INFAST Y FORWARDERS OPERADORES
            LOGISTICOS INTERNACIONALES S.A. DE C.V. para solicitar y planificar
            servicios de transporte, bienes o logística con terceros
            proveedores, pero acepta que INFAST Y FORWARDERS OPERADORES
            LOGISTICOS INTERNACIONALES S.A. DE C.V. no tiene alguna
            responsabilidad hacia usted en relación con cualquier servicio de
            transporte, bienes o logística prestado a usted por terceros
            proveedores que no sea como se ha establecido expresamente en estas
            condiciones.
          </Text>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            Indemnización.
          </Text>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            Usted acuerda mantener indemnizaciones y responder frente a INFAST Y
            FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES S.A. DE C.V. y sus
            consejeros, directores, empleados y agentes por cualquier
            reclamación, demanda, pérdida, responsabilidad y gasto (incluidos
            los honorarios de abogados) que derivan de: (i) su uso de los
            Servicios o servicios o bienes obtenidos a través de su uso de los
            Servicios; (ii) su incumplimiento o violación de cualquiera de estas
            Condiciones; (iii) el uso por parte de INFAST Y FORWARDERS
            OPERADORES LOGISTICOS INTERNACIONALES S.A. DE C.V. de su Contenido
            de usuario; o (iv) su infracción de los derechos de cualquier
            tercero, incluidos Terceros proveedores.
          </Text>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            6. Legislación aplicable; Resolución Directa y Arbitraje.
          </Text>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            Resolución directa de Conflictos
          </Text>
          <Text fontSize="14px" color={colors.gray[700]}>
            En caso de que usted tenga algún problema con el uso de los
            Servicios y/o considere que alguna parte de las presentes
            Condiciones no está siendo observada o cumplida por INFAST Y
            FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES S.A. DE C.V. y/o el
            Tercero proveedor, o de presentar alguna diferencia entre las Partes
            derivadas o relacionado con las presentes Condiciones, usted
          </Text>
        </Box>
        {/* page 11 */}
        <Box mb={4}>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            acepta que el proceso a seguir consiste en comunicar dicha situación
            a través de la sección ubicada en el menú principal de la aplicación
            denominada “Tus Viajes” y “Ayuda” o directamente en la sección de
            “Ayuda” en el menú principal o en su defecto a través de la página
            web https://infast.com.mx/ , con el propósito de analizar y resolver
            el caso concreto.
          </Text>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            En el supuesto en que usted considere que la resolución del caso no
            es conforme a sus intereses, las Partes acuerdan que dicha disputa
            será resuelta de manera definitiva de conformidad a lo siguiente:
          </Text>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            Para los efectos judiciales y extrajudiciales correspondientes, las
            partes señalan, como sus domicilios los siguientes: Jiutepec,
            Morelos, C. San Gaspar, No.16, Col. Pedregal de las fuentes, C.P.
            62554.
          </Text>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            Para la interpretación y cumplimiento de este contrato, las partes
            convienen en someterse a las Leyes Tribunales competentes del primer
            Distrito Judicial del Estado de Morelos, con renuncia expresa de
            cualquier otra jurisdicción que por razón de sus domicilios
            presentes o futuros llegasen a tener.
          </Text>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            7. Otras disposiciones
          </Text>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            Reclamaciones por infracción de derechos de autor.
          </Text>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            Las reclamaciones por infracción de derechos de autor deben enviarse
            al agente designado de INFAST Y FORWARDERS OPERADORES LOGISTICOS
            INTERNACIONALES S.A. DE C.V. Visite la página web de INFAST Y
            FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES S.A. DE C.V. en
            https://infast.com.mx/ para obtener las direcciones designadas e
            información adicional.
          </Text>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            Notificaciones.
          </Text>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            INFAST Y FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES S.A. DE
            C.V. podrá notificar por medio de una notificación general en los
            Servicios, mediante un correo electrónico enviado a su dirección
            electrónica en su Cuenta o por comunicación escrita enviada a su
            dirección, según lo dispuesto en su Cuenta. Usted podrá notificar a
            INFAST Y FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES S.A. DE
            C.V. por comunicación escrita a la dirección de INFAST Y FORWARDERS
            OPERADORES LOGISTICOS INTERNACIONALES S.A. DE C.V. en Jiutepec,
            Morelos, C. San Gaspar, No.16, Col. Pedregal de las fuentes, C.P.
            62554.
          </Text>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            Disposiciones generales.
          </Text>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            No podrá ceder ni transferir estas Condiciones, en todo o en parte,
            sin el consentimiento previo por escrito de INFAST Y FORWARDERS
            OPERADORES LOGISTICOS INTERNACIONALES S.A. DE C.V. Usted da su
            aprobación a INFAST Y FORWARDERS OPERADORES LOGISTICOS
            INTERNACIONALES S.A. DE C.V. para ceder o transferir estas
            Condiciones, en todo o en parte, incluido a: (i) una subsidiaria o
            un
          </Text>
        </Box>
        {/* page 12 */}
        <Box mb={4}>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            afiliado; (ii) un adquirente del capital, del negocio o de los
            activos de INFAST Y FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES
            S.A. DE C.V; o (iii) un sucesor por fusión. No existe entre usted,
            INFAST Y FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES S.A. DE
            C.V. o cualquier Tercer proveedor una empresa conjunta o relación de
            socios, empleo o agencia como resultado del contrato entre usted y
            Uber o del uso de los Servicios.
          </Text>
          <Text fontSize="14px" color={colors.gray[700]} mb={4}>
            Si cualquier disposición de estas Condiciones se considerará ilegal,
            nula o inexigible, ya sea en su totalidad o en parte, de conformidad
            con cualquier legislación, dicha disposición o parte de esta se
            considerará que no forma parte de estas Condiciones, aunque la
            legalidad, validez y exigibilidad del resto de las disposiciones de
            estas Condiciones no se verá afectada. En ese caso, las partes
            reemplazarán dicha disposición ilegal, nula o inexigible, en todo o
            en parte por una disposición legal, válida y exigible que tenga, en
            la medida de lo posible, un efecto similar al que tenía la
            disposición ilegal, nula o inexigible, dados los contenidos y el
            propósito de estas Condiciones. Estas Condiciones constituyen el
            contrato íntegro y el entendimiento entre las partes en relación con
            el objeto y sustituye y reemplaza a todos los contratos o acuerdos
            anteriores o contemporáneos en relación con el objeto dicho. En
            estas Condiciones, las palabras “incluido/a/os/as” e “incluye/n”
            significan “incluido, de forma meramente enunciativa”.
          </Text>
        </Box>
      </ScrollView>
    </>
  );
}
