import React from 'react';

import { Box, Text, ScrollView, useTheme } from 'native-base';
import BackWeb from 'components/BackWeb';
import useDesignAuth from 'webdesign/useDesignAuth';
import { isWebDesktop } from 'utils/general';
import { Platform } from 'react-native';

export default function Privacy() {
  const isWeb = Platform.OS === 'web';
  const { colors } = useTheme();

  const { cardPx, cardPy, cardBg, cardShadow } = useDesignAuth();

  return (
    <Box
      flex="1"
      px={8}
      pt={10}
      _web={{
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        flex="1"
        justifyContent="center"
        width="100%"
        maxWidth="650px"
        _web={{
          py: cardPy,
          px: cardPx,
          borderRadius: 20,
          bg: cardBg,
          shadow: cardShadow,
          justifyContent: 'center',
        }}
      >
        {isWebDesktop && <BackWeb />}
        <Text fontSize="24px" textAlign="center" mb={10} mt={isWeb ? 4 : 0}>
          Aviso de privacidad
        </Text>
        <ScrollView flex="1" mb={6}>
          {/* page 1 */}
          <Box mb={4}>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              Aviso de privacidad de INFAST
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              I. Introducción / II. Descripción general / III. Recopilación y
              usos de los datos / IV. Opciones y transparencia / V.
              Actualizaciones a este aviso
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              I. Introducción
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              Cuando usa INFAST Y FORWARDERS OPERADORES LOGISTICOS
              INTERNACIONALES S.A. DE C.V., nos confía sus datos personales. Nos
              comprometemos a mantener esa confianza. Para esto, comenzaremos
              por ayudarlo a comprender nuestras prácticas de privacidad. En
              este aviso se describen los datos personales que recopilamos, cómo
              los usamos y compartimos, y sus opciones con respecto a ellos.
              Recomendamos que lea el aviso junto con nuestra descripción
              general de la privacidad, que destaca los puntos clave sobre
              nuestras prácticas en esta área.
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              II. Descripción general
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              A. Alcance
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              Este aviso se aplica a todos los usuarios de los servicios de
              INFAST Y FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES S.A. DE
              C.V. en cualquier parte del mundo, incluidos los que usan las
              apps, sitios web, funciones u otros servicios. Este aviso describe
              cómo INFAST Y FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES
              S.A. DE C.V. recopilan y usan los datos personales. También se
              aplica a todos los usuarios de nuestras apps, sitios web,
              funciones y otros servicios en cualquier lugar del mundo, a menos
              que estén cubiertos por otra política, como el Aviso de privacidad
              de INFAST o la Política de privacidad de Careem. Se aplica
              específicamente a las siguientes partes:
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              Usuarios: personas que piden viajes o que se transportan,
              incluidos quienes reciben los servicios programados por un
              tercero.
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              Socios conductores: personas que brindan servicios de transporte a
              los usuarios de manera individual o a través de empresas
              asociadas.
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              Clientes: personas que solicitan un medio de transporte de un
              punto a otro, p. ej., a través INFAST Y FORWARDERS OPERADORES
              LOGISTICOS INTERNACIONALES S.A. DE C.V.
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              Socios repartidores: personas que prestan servicios de entrega a
              domicilio, p. ej., a través de INFAST Y FORWARDERS OPERADORES
              LOGISTICOS INTERNACIONALES S.A. DE C.V.
            </Text>
            <Text fontSize="14px" color={colors.gray[700]}>
              Este aviso también rige otras recopilaciones de datos personales
              de INFAST Y FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES S.A.
              DE C.V. con relación a sus servicios. P. ej., podemos recopilar
              información de contacto de las personas que usan las cuentas de
              clientes de
            </Text>
          </Box>
          {/* page 2 */}
          <Box mb={4}>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              INFAST Y FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES S.A. DE
              C.V. para Empresas o de propietarios o empleados de restaurantes u
              otros comercios, datos personales de aquellos que inician
              solicitudes para ser socios conductores o socios repartidores
              (aunque no las completen) u otra información personal relacionada
              con nuestras funciones y tecnología de mapeo.
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              Se hará referencia a todas las personas sujetas a este aviso como
              “usuarios”.
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              Además, tenga en cuenta lo siguiente:
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              Para los usuarios en México: Consulte aquí para obtener
              información sobre las prácticas de privacidad de INFAST Y
              FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES S.A. DE C.V.
              previstas en la Ley Federal de Protección de Datos Personales en
              Posesión de los Particulares de México.
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              Para los usuarios invitados: Los datos personales de quienes hacen
              pedidos u obtienen viajes o entregas mediante sitios web o apps de
              socios, o a través de otros titulares de cuentas se pueden usar
              únicamente para proporcionar dichos viajes, entregas u otros
              servicios solicitados a través de un tercero. Esos datos también
              se pueden emplear con fines de seguridad, soporte al cliente,
              investigación y desarrollo, comunicación entre los usuarios y para
              procedimientos y requisitos legales, tal como se describe en la
              sección Cómo usamos los datos personales a continuación. Los datos
              personales de los usuarios invitados se pueden compartir con
              terceros para estos fines. Dichos datos pueden vincularse con el
              titular de esa cuenta, quien tiene acceso a ellos. Esto incluye
              específicamente a los usuarios invitados que obtienen viajes o
              entregas pedidos por los titulares de las cuentas INFAST cliente,
              INFAST transportista, o por sus familiares, amigos u otras
              personas. Para enviar preguntas, comentarios, solicitudes o
              reclamos relacionados con los datos de los usuarios invitados,
              consulte aquí.
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              Nuestras prácticas están sujetas a las leyes vigentes en los
              lugares donde operamos. Esto significa que aplicamos las prácticas
              descritas en el presente documento en un país o en una región solo
              si sus leyes lo permiten. Contáctenos aquí o a través de las
              direcciones a continuación si tiene preguntas sobre nuestras
              prácticas en un lugar específico.
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              B. Controlador y transferencia de datos
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              FORWARDERS OPERADORES LOGÍSTICOS INTERNACIONALES son los
              controladores de los datos personales recopilados con relación al
              uso de los servicios de INFAST Y FORWARDERS OPERADORES LOGISTICOS
              INTERNACIONALES S.A. DE C.V. en el Reino Unido, el Espacio
              Económico Europeo y Suiza.
            </Text>
            <Text fontSize="14px" color={colors.gray[700]}>
              FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES S.A. DE C.V. es
              el controlador de los datos personales recopilados con relación al
              uso de los servicios de INFAST en cualquier otro lugar.
            </Text>
          </Box>
          {/* page 3 */}
          <Box mb={4}>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              Socios comerciales de INFAST Y FORWARDERS OPERADORES LOGISTICOS
              INTERNACIONALES S.A. DE C.V. relacionados con tarjetas de débito o
              de crédito emitidas por una institución financiera asociada con
              INFAST Y FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES S.A. DE
              C.V., en la medida en que se indique en los Términos y condiciones
              de la tarjeta.
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              Proveedores que nos ayudan a verificar la identidad de los
              usuarios, información de antecedentes y cumplimiento de los
              requisitos para generar ganancias, o que revisan las cuentas de
              los usuarios en relación con sanciones, lavado de dinero o
              exigencias relativas a la identificación del cliente.
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              Proveedores de seguros, vehículos o servicios financieros para
              socios conductores o para socios repartidores.
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              Empresas de transporte asociadas (para socios conductores o socios
              repartidores que usan nuestros servicios a través de una cuenta
              relacionada con dicha compañía).
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              Fuentes disponibles públicamente.
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              Proveedores de servicios de marketing o distribuidores de datos,
              cuya información INFAST Y FORWARDERS OPERADORES LOGISTICOS
              INTERNACIONALES S.A. DE C.V. usa con fines publicitarios o de
              investigación.
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              Funcionarios de salud pública o de la fuerza del orden público y
              otras autoridades gubernamentales.
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              INFAST Y FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES S.A. DE
              C.V. puede combinar la información recopilada de estas fuentes con
              otros datos que posea.
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              B. Cómo usamos los datos personales
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              INFAST Y FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES S.A. DE
              C.V. usa los datos personales para que los servicios de transporte
              y de entregas, así como otros productos y servicios, sean
              confiables y convenientes. También usamos estos datos para lo
              siguiente:
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              • Mejorar la seguridad de los usuarios y de los servicios
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              • Brindar soporte al cliente
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              • Realizar tareas de investigación y de desarrollo
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              • Permitir la comunicación entre usuarios
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              • Enviar comunicaciones de marketing y de otro tipo a los usuarios
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              • Abordar temas relacionados con procedimientos legales
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              INFAST Y FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES S.A. DE
              C.V. no vende ni divulga datos personales del usuario a terceros
              con fines de marketing directo, salvo que los usuarios den su
              consentimiento.
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              Usamos los datos personales que recopilamos para lo siguiente:
            </Text>
          </Box>
          {/* page 4 */}
          <Box mb={4}>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              Uso de datos del socio conductor (como ubicación, calificación y
              género) e información del usuario (como calificación, origen y
              destino) para evitar compatibilidades que puedan aumentar el
              riesgo de conflicto.
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              Haga clic en los enlaces de esta sección para conocer más sobre
              los procesos. Para objetar una desactivación que resulte de ellos,
              contacte al soporte al cliente de INFAST Y FORWARDERS OPERADORES
              LOGISTICOS INTERNACIONALES S.A. DE C.V.
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              INFAST Y FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES S.A. DE
              C.V. realiza las actividades anteriores porque son necesarias para
              cumplir con nuestras obligaciones con los usuarios, conforme a los
              Términos de uso o a otros acuerdos con los usuarios, o para
              satisfacer los intereses legítimos de INFAST Y FORWARDERS
              OPERADORES LOGISTICOS INTERNACIONALES S.A. DE C.V sus usuarios y
              terceros.
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              C. Cookies y tecnologías de terceros
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              INFAST Y FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES S.A. DE
              C.V. y sus socios usan cookies y otras tecnologías de
              identificación en las apps, sitios web, correos electrónicos y
              anuncios en línea para los fines descritos en este aviso y en el
              Aviso de cookies de INFAST Y FORWARDERS OPERADORES LOGISTICOS
              INTERNACIONALES S.A. DE C.V.
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              Las cookies son pequeños archivos de texto que los sitios web,
              apps, medios en línea y anuncios almacenan en el navegador o en el
              dispositivo. INFAST Y FORWARDERS OPERADORES LOGISTICOS
              INTERNACIONALES S.A. DE C.V. usa cookies y tecnologías similares
              para los fines que se enumeran a continuación:
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              • Autenticar usuarios
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              • Recordar las preferencias y configuraciones del usuario
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              • Determinar la popularidad del contenido
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              • Brindar y medir la eficacia de las campañas de publicidad
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              • Analizar el tránsito y las tendencias del sitio para comprender
              los comportamientos en línea y los intereses de las personas que
              interactúan con nuestros servicios
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              También podemos permitir que terceros realicen mediciones de
              audiencia y servicios analíticos para nosotros, presenten anuncios
              en representación nuestra a través de internet, y realicen un
              seguimiento y nos informen sobre el rendimiento de estos anuncios.
              Estas entidades pueden usar cookies, balizas web, SDK y otras
              tecnologías para identificar los dispositivos que usan las
              personas cuando visitan nuestros sitios web, así como cuando
              ingresan a otros servicios y sitios en línea.
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              Consulte nuestro Aviso de cookies para conocer más sobre el uso de
              cookies y de otras tecnologías descritas en esta sección.
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              D. Intercambio y divulgación de datos
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              Algunos de los servicios y las funciones de INFAST Y FORWARDERS
              OPERADORES LOGISTICOS INTERNACIONALES S.A. DE C.V. requieren que
              intercambiemos datos personales con otros usuarios o a pedido de
              estos. También podemos compartir esa información con nuestros
              afiliados, subsidiarias y socios por motivos legales o
              relacionados con reclamos o disputas.s
            </Text>
          </Box>
          {/* page 5 */}
          <Box mb={4}>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              INFAST Y FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES S.A. DE
              C.V. puede compartir los datos personales de las siguientes
              maneras:
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              1. Con otros usuarios
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              Esto incluye los siguientes datos:
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              Nombre, calificación, punto de partida y destino de los usuarios
              con los socios conductores.
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              Nombre de los usuarios con otros pasajeros en un viaje compartido.
              Estos usuarios también pueden ver el destino de las demás personas
              en el mismo viaje.
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              Nombre, dirección de entrega y detalles del pedido de los clientes
              con el socio repartidor y el restaurante u otro comercio. También
              podemos compartir las calificaciones, comentarios u otra
              información que requiera la ley con el restaurante socio y con el
              socio repartidor.
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              En el caso de los socios conductores y de los socios repartidores,
              podemos compartir datos con los usuarios, clientes y
              transportistas, incluidos su nombre y foto de perfil, marca,
              modelo, color, matrícula y foto del vehículo, ubicación (antes y
              durante el viaje), calificación promedio proporcionada por los
              usuarios, cantidad total de viajes, tiempo de uso desde que se
              registró como socio conductor o socio repartidor, detalles de
              contacto (si lo permiten las leyes vigentes) y perfil del socio
              conductor o del socio repartidor, incluidos los reconocimientos y
              otros comentarios enviados por usuarios anteriores.
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              También proporcionamos a los usuarios y a los clientes recibos que
              incluyen información, como el mapa de ruta, desglose de los montos
              cobrados, y nombre y foto del socio conductor o del socio
              repartidor. También incluimos otros datos en los recibos si así lo
              exige la ley.
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              En el caso de aquellos que participan en el programa de referido
              de INFAST Y FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES S.A.
              DE C.V., compartimos con el usuario que los invitó ciertos datos
              personales de los referidos, como el recuento de viajes, en la
              medida necesaria para determinar la promoción.
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              2. A pedido del usuario
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              Esto incluye compartir datos con las siguientes partes:
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              Otras personas a pedido del usuario. P. ej., compartimos la
              llegada estimada y la ubicación de un usuario con un amigo, si así
              lo solicita, o los detalles del viaje de un usuario cuando divide
              una tarifa con otra persona.
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              Socios comerciales de INFAST Y FORWARDERS OPERADORES LOGISTICOS
              INTERNACIONALES S.A. DE C.V. P. ej., si un usuario solicita un
              servicio a través de una asociación o de una oferta promocional
              realizada por un tercero, INFAST puede compartir ciertos datos con
              dichas partes. Esto puede incluir, p. ej., otros servicios,
              plataformas, apps o sitios web que se integran con nuestras API,
              proveedores o servicios de vehículos, aquellos con una API o
              servicio con el que nos integramos, comercios u
            </Text>
          </Box>
          {/* page 6 */}
          <Box mb={4}>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              otros socios comerciales de INFAST y sus usuarios con relación a
              promociones, concursos o servicios especializados.
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              Servicios de emergencias. Ofrecemos funciones que permiten a los
              usuarios compartir sus datos con la policía, bomberos y servicios
              de ambulancia en caso de emergencia o después de ciertos
              incidentes. Para conocer más consulte las secciones Opciones y
              transparencia y Datos que se comparten con el servicio de
              emergencias.
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              3. Con el público en general
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              Las preguntas o los comentarios de los usuarios que se envían a
              través de foros públicos, como los blogs y las redes sociales de
              INFAST Y FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES S.A. DE
              C.V., pueden estar al alcance de todos, incluidos los datos
              personales incorporados en las preguntas o los comentarios
              enviados por un usuario.
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              4. Con el titular de la cuenta INFAST
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              Si un usuario pide un viaje a través de una cuenta que pertenece a
              otra persona, podemos compartir los detalles del pedido o del
              viaje, incluidos los datos de ubicación, con el titular de la
              cuenta. Esto sucede, p. ej., en los siguientes casos:
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              Un usuario usa la cuenta INFAST Y FORWARDERS OPERADORES LOGISTICOS
              INTERNACIONALES S.A. DE C.V. para Empresas de su empleador, como
              cuando realiza viajes programados a través de INFAST.
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              Un socio conductor o un socio repartidor usan una cuenta que
              pertenece o que se vincula a una empresa de transporte asociados
              con INFAST Y FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES S.A.
              DE C.V.
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              Un usuario realiza un viaje programado por un amigo o a través de
              un perfil familiar.
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              Un socio repartidor reemplaza a otro.
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              5. Con afiliados y subsidiarias de INFAST Y FORWARDERS OPERADORES
              LOGISTICOS INTERNACIONALES S.A. DE C.V.
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              Compartimos datos personales con nuestros afiliados y subsidiarias
              para que nos ayuden a proporcionar servicios o a realizar tareas
              de procesamiento de datos en nuestro nombre. P. ej., INFAST Y
              FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES S.A. DE C.V.
              procesa o almacena dichos datos en Estados Unidos en nombre de sus
              subsidiarias y afiliados internacionales.
            </Text>
          </Box>
          {/* page 7 */}
          <Box mb={4}>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              6. Con proveedores de servicios y socios comerciales de INFAST Y
              FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES S.A. DE C.V.
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              INFAST Y FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES S.A. DE
              C.V. proporciona datos personales a los proveedores, consultores,
              socios de marketing, empresas de investigación de mercado y otros
              proveedores de servicios o socios comerciales. Entre estos se
              incluyen los siguientes:
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              procesadores y facilitadores de pago
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              proveedores de verificación de antecedentes y de identidad
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              proveedores de almacenamiento en la nube
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              Google, en relación con el uso de Google Maps en las apps de
              INFAST Y FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES S.A. DE
              C.V (consulte la política de privacidad de Google para conocer más
              sobre cómo recopilan y usan los datos) empresas de redes sociales,
              incluso Facebook y TikTok, en relación con el uso de sus funciones
              en las apps y los sitios web de INFAST Y FORWARDERS OPERADORES
              LOGISTICOS INTERNACIONALES S.A. DE C.V (consulte las políticas de
              privacidad de Facebook y de TikTok para conocer cómo recopilan y
              usan los datos) socios de marketing y proveedores de plataformas
              de marketing, incluidos los servicios de publicidad en redes
              sociales, redes de publicidad, proveedores de datos externos y
              otros proveedores de servicios para lograr mayor alcance,
              comprender mejor a nuestros usuarios y medir la eficacia de la
              publicidad socios de investigación, incluidos aquellos que
              realizan encuestas o proyectos de investigación asociados con
              INFAST Y FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES S.A. DE
              C.V. o en su nombre.
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              Proveedores que asisten a INFAST Y FORWARDERS OPERADORES
              LOGISTICOS INTERNACIONALES S.A. DE C.V. para mejorar la seguridad
              y la protección de sus apps consultores, abogados, contadores y
              otros proveedores de servicios profesionales, socios financieros y
              de seguro, aeropuertos.
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              Proveedores de bicicletas y scooters que se pueden rentar a través
              de las apps de INFAST Y FORWARDERS OPERADORES LOGISTICOS
              INTERNACIONALES S.A. DE C.V., como Lime
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              restaurantes, supermercados y otros comercios donde los clientes
              hacen pedidos, así como los socios o sus proveedores de puntos de
              venta, incluso para el cumplimiento de pedidos, entregas,
              comunicaciones y fines de marketing proveedores externos de
              vehículos, incluidos socios de flotilla y de renta de vehículos.
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              7. Por motivos legales o ante un conflicto
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              INFAST Y FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES S.A. DE
              C.V. puede compartir los datos personales de los usuarios si así
              lo exige la ley, reglamentación, licencia o acuerdo de
            </Text>
          </Box>
          {/* page 8 */}
          <Box mb={4}>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              Después de recibir la solicitud, INFAST Y FORWARDERS OPERADORES
              LOGISTICOS INTERNACIONALES S.A. DE C.V. elimina la cuenta y los
              datos del usuario, a menos que se conserven debido a requisitos
              legales o reglamentarios, con fines de seguridad o de prevención
              de fraude, o por un problema relacionado con la cuenta del
              usuario, como créditos pendientes o un reclamo o disputa sin
              resolver. Debido a que estamos sujetos a requisitos legales y
              reglamentarios relacionados con los socios conductores y con los
              socios repartidores, conservaremos su cuenta y sus datos por un
              mínimo de 7 años después de una solicitud de eliminación. En el
              caso de los usuarios y de los clientes, sus datos suelen
              eliminarse dentro de los 90 días posteriores a la solicitud,
              excepto cuando su conservación sea necesaria por los motivos
              descritos anteriormente.
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              IV. Opciones y transparencia
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              INFAST Y FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES S.A. DE
              C.V. permite a los usuarios acceder o controlar los datos que
              recopila, incluso a través de los siguientes medios:
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              • Configuración de privacidad
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              • Permisos del dispositivo
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              • Páginas de calificaciones en la app
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              • Opciones de marketing
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              INFAST Y FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES S.A. DE
              C.V. también permite a los usuarios solicitar acceso o copias de
              sus datos, cambios, actualizaciones o eliminación de sus cuentas,
              o que Uber restrinja el procesamiento de su información personal.
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              1. Configuración de privacidad
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              Mediante el menú Configuración {'>'} Privacidad en la app de
              INFAST, los usuarios y los clientes pueden establecer o actualizar
              sus preferencias sobre la recopilación y uso compartido de datos
              de ubicación, datos que se comparten con el servicio de
              emergencias y notificaciones.
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              Recopilación de datos de ubicación (usuarios y clientes)
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              Los usuarios y los clientes pueden activar o desactivar en sus
              dispositivos la opción que permite que INFAST recopile datos de
              ubicación de sus dispositivos móviles. Se accede a través del
              menú.
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              Los usuarios pueden activar o desactivar en el dispositivo la
              opción que permite que INFAST Y FORWARDERS OPERADORES LOGISTICOS
              INTERNACIONALES S.A. DE C.V. comparta con los socios conductores
              los datos de ubicación en tiempo real de sus dispositivos móviles.
              Se accede a través del menú Configuración {'>'} Privacidad {'>'}{' '}
              Ubicación.
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              Datos que se comparten con el servicio de emergencias
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              Los usuarios pueden permitir que INFAST Y FORWARDERS OPERADORES
              LOGISTICOS INTERNACIONALES S.A. DE C.V. comparta los datos de
              ubicación en tiempo real de sus dispositivos móviles con la
              policía, bomberos y servicios de ambulancia en caso de emergencia.
              Estos datos
            </Text>
          </Box>
          {/* page 9 */}
          <Box mb={4}>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              pueden incluir la ubicación aproximada en el momento en que se
              realizó la llamada de emergencia, marca, modelo, color y matrícula
              del auto, nombre y número de teléfono del usuario, punto de
              partida y destino, y nombre del socio conductor.
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              Los usuarios pueden activar o desactivar esta función a través del
              menú Configuración {'>'} Privacidad
              {'>'} Ubicación o desde el Centro de seguridad.
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              Los socios conductores y los socios repartidores también pueden
              activar o desactivar los datos que se comparten con el servicio de
              emergencias en la app, mediante el menú Configuración {'>'} Datos
              compartidos con emergencias, o a través de las Funciones de
              seguridad.
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              Notificaciones: Actualizaciones de cuenta y de viajes
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              INFAST Y FORWARDERS OPERADORES LOGISTICOS INTERNACIONALES S.A. DE
              C.V. envía a los usuarios notificaciones sobre el estado del viaje
              y actualizaciones relacionadas con sus cuentas. Estos avisos son
              necesarios para usar la app de Uber y no pueden desactivarse. Sin
              embargo, los usuarios pueden elegir el método por el cual se
              reciben en el menú Configuración {'>'} Privacidad.
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              Notificaciones: Descuentos y noticias
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              Los usuarios pueden permitir que INFAST Y FORWARDERS OPERADORES
              LOGISTICOS INTERNACIONALES S.A. DE C.V. les envíe notificaciones
              push con descuentos y noticias de la empresa. Pueden activarlas o
              desactivarlas desde el menú Configuración {'>'} Privacidad en la
              app de INFAST.
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              2. Permisos del dispositivo
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              La mayoría de las plataformas de dispositivos móviles (iOS,
              Android, etc.) definieron ciertos tipos de datos de los
              dispositivos a los que las apps no pueden acceder sin la
              autorización del propietario. Estas plataformas tienen diferentes
              métodos para obtener dichos permisos. Verifique la configuración
              disponible en su dispositivo o consulte con su proveedor.
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              3. Páginas de calificaciones en la app
            </Text>
            <Text fontSize="14px" color={colors.gray[700]} mb={4}>
              Después de cada viaje, los socios conductores y los usuarios
              pueden calificarse entre sí con una escala del 1 al 5. El promedio
              de esas calificaciones se asocia con la cuenta de un usuario y se
              muestra a las personas a quienes se proporcionan o de quienes
              reciben servicios. P. ej., los socios conductores pueden ver las
              calificaciones de los usuarios que piden un viaje, y viceversa.
            </Text>
          </Box>
        </ScrollView>
      </Box>
    </Box>
  );
}
