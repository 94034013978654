import React, { useEffect, useState, useContext } from 'react';

import UploadPdfDocument from 'screens/UploadPdfDocument';
import useDocuments, { Action } from 'hooks/useDocuments';
import Context from 'context/Documents';
import useUpload from 'hooks/useUpload';
import ModalDocument from 'components/modals/ModalDocument';
import { DocumentTypes } from 'types/documents';
import { AuthContext } from 'context/AuthContext';

export default function UploadConstitutive() {
  const { selectedDocument, onSelectedDocument } = useDocuments();
  const { getDocument } = useContext(Context);
  const { state } = useContext(AuthContext);

  const { addDocument, nextDocument, documentUploaded } = useUpload();

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [documentToUpload, setDocumentToUpload] = useState<DocumentTypes>();

  useEffect(() => {
    if (selectedDocument.base64) {
      addDocument({
        base64: selectedDocument?.base64,
        user_id: state.id ?? '',
        type: documentToUpload ?? '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDocument.base64]);

  const handleDocumentToUpload = (type: DocumentTypes) => {
    setOpenModal(!openModal);
    setDocumentToUpload(type);
  };

  const handleSelectedPhoto = (action: Action) => {
    onSelectedDocument({
      action,
      web: { mimeType: '/*' },
    });
    setOpenModal(!openModal);
  };

  const handleNextDocument = () => {
    const isUploaded = Boolean(
      documentUploaded.constitutive_act || constitutiveAct,
    );
    nextDocument({
      isUploaded,
      route: 'constitutive',
    });
  };

  const constitutiveAct = getDocument('constitutive_act');
  const status = documentUploaded?.constitutive_act
    ? documentUploaded.constitutive_act.status
    : constitutiveAct.status;

  const isConstitutiveAct =
    Boolean(documentUploaded?.constitutive_act || constitutiveAct.status) &&
    constitutiveAct.status !== 'irregularity';

  const fileName = documentUploaded
    ? selectedDocument?.name
    : constitutiveAct.type;

  const isValidated = constitutiveAct.status === 'validated';

  return (
    <>
      <UploadPdfDocument
        fileName={fileName}
        cardStatus={status || 'default'}
        cardIcon={status || 'default'}
        textButton="Subir pdf"
        heading="Acta constitutiva"
        cardTitle="Acta constitutiva"
        description="Toma una foto o sube el documento en (pdf)."
        cardDescription="Toma una foto o sube el documento en (pdf)."
        showMessage={isConstitutiveAct && !isValidated}
        hideButton={!!fileName}
        onNextDocument={handleNextDocument}
        onPress={() => handleDocumentToUpload('constitutive_act')}
      />
      <ModalDocument
        open={openModal}
        toggle={() => setOpenModal(!openModal)}
        onSelectDocument={handleSelectedPhoto}
      />
    </>
  );
}
