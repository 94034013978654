/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useInfiniteQuery } from 'react-query';
import { useNavigation } from '@react-navigation/native';

import { GetTripes } from 'api';
import { TripProps, Params, Pagination } from 'types/trips';
import { pickBy } from 'utils';

export default function useTrips(params?: Partial<Params>, fn?: () => void) {
  const navigation = useNavigation();
  const {
    data,
    error,
    isFetching,
    hasNextPage,
    isFetchingNextPage,
    isPreviousData,
    fetchNextPage,
    refetch,
  } = useInfiniteQuery(
    'trips',
    ({ pageParam = 1 }) =>
      GetTripes({
        pageParam,
        params: {
          page_size: 10,
          ...pickBy(params),
        },
      }),
    {
      getNextPageParam: lastPage => {
        if (
          lastPage.pagination.page_number === lastPage.pagination.total_pages
        ) {
          return false;
        }
        return lastPage.pagination.page_number + 1;
      },
      onSuccess: successData => {
        if (successData && fn) {
          fn();
        }
      },
      cacheTime: 0,
      staleTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
    },
  );

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      refetch();
    });
    return unsubscribe;
  }, [navigation, refetch]);

  const trips =
    data?.pages || ([] as { data: TripProps[]; pagination: Pagination }[]);

  return {
    fetchNextPage,
    refetch,
    isFetchingNextPage,
    isPreviousData,
    trips,
    isFetching,
    error,
    hasNextPage,
  };
}
