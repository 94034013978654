import { DocumentTypes, DocumentStatus } from 'types/documents';
import { IIconProps } from 'native-base';

import { ProfileType, UserSubType } from 'types/user';
import { Document, MenuItems } from 'types/catalogs';
import { colors } from 'constants/colors';

export const DOCUMENT_TYPES: DocumentTypes[] = [
  'official_identification_front',
  'official_identification_back',
  'driver_license_front',
  'driver_license_back',
  'rfc',
  'business_name',
  'account_status',
  'sat',
  'power_of_attorney',
  'constitutive_act',
  'proof_of_address',
  'sua',
  'imss',
  'social_security',
];

export const COLORS_STATUS: { [key in DocumentStatus]: IIconProps['color'] } = {
  validated: colors.success[900],
  irregularity: colors.error[900],
  sended: colors.secondary[900],
  empty: colors.gray[300],
  incomplete: 'gray.500',
};

export const documentsToUpload = (
  typesUser: ProfileType,
  subType: UserSubType,
): Document[] => {
  return [
    {
      id: 0,
      label: 'Identificación oficial (INE)',
      route: 'ine',
      type: ['official_identification_front', 'official_identification_back'],
      show: true,
      key: 'official_identification',
      total: 2,
    },
    {
      id: 1,
      label: 'Licencia de manejo',
      route: 'license',
      type: ['driver_license_front', 'driver_license_back'],
      show:
        typesUser === 'driver' &&
        (subType === 'physical' || subType === 'moral'),
      key: 'driver_license',
      total: 2,
    },
    {
      id: 2,
      label: 'Estado de cuenta',
      route: 'account',
      type: ['account_status'],
      show: true,
      key: 'account_status',
      total: 1,
    },
    {
      id: 3,
      label: 'Constancia SAT',
      route: 'sat',
      type: ['sat'],
      show: true,
      key: 'sat',
      total: 1,
    },
    {
      id: 4,
      label: 'Acta constitutiva',
      route: 'constitutive',
      type: ['constitutive_act'],
      show:
        (typesUser === 'client' && subType === 'moral') ||
        (typesUser === 'driver' && subType === 'moral'),
      key: 'constitutive_act',
      total: 1,
    },
    {
      id: 5,
      label: 'Poder notarial',
      route: 'power_attorney',
      type: ['power_of_attorney'],
      show:
        (typesUser === 'client' && subType === 'moral') ||
        (typesUser === 'driver' && subType === 'moral'),
      key: 'power_of_attorney',
      total: 1,
    },
    {
      id: 6,
      label: 'SUA',
      route: 'sua',
      type: ['sua'],
      show: typesUser === 'driver' && subType === 'moral',
      key: 'sua',
      total: 1,
    },
    {
      id: 7,
      label: 'Seguro social',
      route: 'social',
      type: ['social_security'],
      show: typesUser === 'driver' && subType === 'physical',
      key: 'social_security',
      total: 1,
    },
  ];
};

export const menuItems = (
  typesUser: ProfileType | string,
  subType: UserSubType | string,
): MenuItems[] => {
  return [
    {
      id: 0,
      label: 'Mi perfil',
      route: 'Profile',
      icon: 'person',
      show: true,
    },
    {
      id: 1,
      label: 'Mis vehículos',
      route: 'Transportation',
      icon: 'local-shipping',
      show:
        (typesUser === 'driver' && subType === 'moral') ||
        (typesUser === 'driver' && subType === 'physical'),
    },
    {
      id: 2,
      label: 'Transportistas asociados',
      route: 'Subordinates',
      icon: 'supervisor-account',
      show: typesUser === 'driver' && subType === 'moral',
    },
    {
      id: 3,
      label: 'Mis direcciones',
      route: 'MyAddresses',
      icon: 'location-pin',
      show: typesUser === 'client',
    },
    {
      id: 7,
      label: 'Historial de viajes',
      route: 'Myhistory',
      icon: 'watch-later',
      show:
        (typesUser === 'driver' && subType === 'moral') ||
        (typesUser === 'driver' && subType === 'physical'),
    },
    {
      id: 4,
      label: 'Chat',
      route: 'Chat',
      icon: 'chat',
      show: true,
    },
    {
      id: 5,
      label: 'Ayuda',
      route: 'Help',
      icon: 'help',
      show: true,
    },
    {
      id: 6,
      label: 'Acerca de nosotros',
      route: 'About',
      icon: 'keyboard-control',
      show: true,
    },
  ];
};

export const menuItemsWeb = (
  typesUser: ProfileType | string,
  subType: UserSubType | string,
  isDriverPhysicalAssociated: boolean
): MenuItems[] => {
  return [
    {
      id: 0,
      label: 'Mi perfil',
      route: 'profile',
      icon: 'person',
      show: true,
    },
    {
      id: 7,
      label: 'Solicitudes',
      route: 'request',
      icon: 'insert-drive-file',
      show:
        typesUser === 'driver' &&
        (subType === 'moral' || subType === 'physical'),
    },
    {
      id: 8,
      label: 'Mis ofertas',
      route: 'myoffers',
      icon: 'bus-alert',
      show:
        !isDriverPhysicalAssociated &&
        typesUser === 'driver' &&
        (subType === 'moral' || subType === 'physical'),
    },
    {
      id: 10,
      label: 'Mis viajes',
      route: 'rides',
      icon: 'insert-drive-file',
      show:
        typesUser === 'driver' &&
        (subType === 'moral' || subType === 'physical'),
    },
    {
      id: 16,
      label: 'Viajes',
      route: 'travels',
      icon: 'insert-drive-file',
      show:
        typesUser === 'client' &&
        (subType === 'moral' || subType === 'physical'),
    },
    {
      id: 11,
      label: 'En camino',
      route: 'ontheway',
      icon: 'bus-alert',
      show:
        typesUser === 'client' &&
        (subType === 'moral' || subType === 'physical'),
    },
    {
      id: 9,
      label: 'Historial de viajes',
      route: 'myhistory',
      icon: 'access-time',
      show:
        typesUser === 'driver' &&
        (subType === 'moral' || subType === 'physical'),
    },
    {
      id: 1,
      label: 'Mis vehículos',
      route: 'transportation',
      icon: 'commute',
      show:
        (typesUser === 'driver' && subType === 'moral') ||
        (typesUser === 'driver' && subType === 'physical'),
    },
    {
      id: 2,
      label: 'Transportistas asociados',
      route: 'subordinates',
      icon: 'supervisor-account',
      show: typesUser === 'driver' && subType === 'moral',
    },
    {
      id: 3,
      label: 'Mis direcciones',
      route: 'myAddresses',
      icon: 'location-pin',
      show:
        typesUser === 'client' &&
        (subType === 'moral' || subType === 'physical'),
    },
    {
      id: 4,
      label: 'Chat',
      route: 'chat',
      icon: 'chat',
      show: true,
    },
    {
      id: 5,
      label: 'Ayuda',
      route: 'help',
      icon: 'help',
      show: true,
    },
    {
      id: 6,
      label: 'Acerca de nosotros',
      route: 'about',
      icon: 'keyboard-control',
      show: true,
    },
  ];
};

export const STATES = [
  { value: 'Aguascalientes', label: 'Aguascalientes' },
  { value: 'Baja California', label: 'Baja California' },
  { value: 'Baja California Sur', label: 'Baja California Sur' },
  { value: 'Campeche', label: 'Campeche' },
  { value: 'Coahuila', label: 'Coahuila' },
  { value: 'Colima', label: 'Colima' },
  { value: 'Chiapas', label: 'Chiapas' },
  { value: 'Chihuahua', label: 'Chihuahua' },
  { value: 'Ciudad de México', label: 'Ciudad de México' },
  { value: 'Durango', label: 'Durango' },
  { value: 'Guanajuato', label: 'Guanajuato' },
  { value: 'Guerrero', label: 'Guerrero' },
  { value: 'valuealgo', label: 'valuealgo' },
  { value: 'Jalisco', label: 'Jalisco' },
  { value: 'México', label: 'México' },
  { value: 'Michoacán', label: 'Michoacán' },
  { value: 'Morelos', label: 'Morelos' },
  { value: 'Nayarit', label: 'Nayarit' },
  { value: 'Nuevo León', label: 'Nuevo León' },
  { value: 'Oaxaca', label: 'Oaxaca' },
  { value: 'Puebla', label: 'Puebla' },
  { value: 'Querétaro', label: 'Querétaro' },
  { value: 'Quintana Roo', label: 'Quintana Roo' },
  { value: 'San Luis Potosí', label: 'San Luis Potosí' },
  { value: 'Sinaloa', label: 'Sinaloa' },
  { value: 'Sonora', label: 'Sonora' },
  { value: 'Tabasco', label: 'Tabasco' },
  { value: 'Tamaulipas', label: 'Tamaulipas' },
  { value: 'Tlaxcala', label: 'Tlaxcala' },
  { value: 'Veracruz', label: 'Veracruz' },
  { value: 'Yucatán', label: 'Yucatán' },
  { value: 'Zacatecas', label: 'Zacatecas' },
];
