import React from 'react';
import { Text, Heading, useTheme, HStack, Stack, Modal } from 'native-base';
import { theme } from 'constants/theme';
import Button from 'components/Button';
import { formatCurrency } from 'utils/formats';

export default function ModalOffer({
  isOpen,
  value,
  toggleModal,
  onNewOffer,
  isLoading,
}: {
  isOpen: boolean;
  value: string;
  isLoading: boolean;
  toggleModal: () => void;
  onNewOffer: () => void;
}) {
  const { colors } = useTheme<typeof theme>();

  const calculateComission = (value) => {
    const newValue = value * 1.1
    return parseInt('' + (newValue * 100)) / 100;
  }

  return (
    <Modal bg="rgba(46, 40, 57, 0.2)" isOpen={isOpen} size="xl">
      <Modal.Content px={4} py={8} _web={{ shadow: 'none' }}>
        <Heading
          mb={4}
          fontSize="2xl"
          color={colors.gray[700]}
          fontWeight="medium"
          textAlign="center"
        >
          Confirma tu oferta
        </Heading>
        <Stack mb={8} alignItems="center">
          <Text fontSize="16px" color={colors.gray[700]}>
            Monto de tu oferta
          </Text>
          <HStack justifyContent="space-between" alignItems="center">
            <Text
              fontSize="2xl"
              fontWeight="medium"
              color={colors.gray[700]}
              mr={2}
            >
              {formatCurrency(Number(value))}
            </Text>
            <Text fontSize="lg" color={colors.gray[700]}>
              MXN
            </Text>
          </HStack>
          {/* <Text fontSize="sm" color={colors.gray[700]} textAlign="center">
            INFAST Añadirá un 10% de su servicio a este monto.
          </Text>
          <HStack justifyContent="space-between" alignItems="center">
            <Text
                fontSize="2xl"
                fontWeight="medium"
                color={colors.gray[700]}
                mr={2}
              >
                {formatCurrency(calculateComission(value))}
              </Text>
              <Text fontSize="lg" color={colors.gray[700]}>
                MXN
              </Text>
              
            </HStack>
            <Text fontSize="sm" color={colors.gray[700]} textAlign="center">
              Al confirmar la oferta, te comprometes a realizar el servicio. 
              </Text> */}
        </Stack>
        <HStack justifyContent="space-between">
          <Button button="ghost" minWidth="45%" onPress={toggleModal}>
            Regresar
          </Button>
          <Button
            button="primary"
            minWidth="45%"
            isLoading={isLoading}
            isLoadingText="Creando..."
            onPress={onNewOffer}
          >
            Ofertar
          </Button>
        </HStack>
      </Modal.Content>
    </Modal>
  );
}
