import React, { useContext, useEffect } from 'react';
import { RefreshControl } from 'react-native';
import { Box, ScrollView, Text, Center, useTheme } from 'native-base';

import { ProgressContext } from 'context/ProgressContext';
import useRideMenu from 'hooks/useRideMenu';
import useTrip from 'hooks/useTrip';
import Dropdown from 'components/Dropdown';
import useCommon from 'webdesign/useCommon';
import { useRoute } from '@react-navigation/native';
import BackWeb from 'components/BackWeb';
import { isWebDesktop } from 'utils/general';

export default function Details() {
  const { path } = useRoute();
  const { refetch } = useTrip();
  const { ridesMenu, statusTrip } = useRideMenu();
  const { onHideProgress } = useContext(ProgressContext);
  const { colors } = useTheme();

  const {
    containerPt,
    containerPx,
    marginLeft,
    flexAlign,
    cardBg,
    cardPx,
    cardShadow,
    cardPy,
  } = useCommon();

  useEffect(() => {
    onHideProgress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRefetch = async () => {
    onHideProgress();
    await refetch();
  };

  return (
    <Box
      flex="1"
      _web={{
        pt: containerPt,
        px: containerPx,
        justifyContent: 'center',
        alignItems: path && flexAlign,
      }}
    >
      <Box
        flex="1"
        width="100%"
        maxWidth="650px"
        mb={4}
        _web={{
          py: cardPy,
          px: cardPx,
          borderRadius: 20,
          bg: cardBg,
          shadow: cardShadow,
          marginLeft: path ? marginLeft : 0,
        }}
      >
        {isWebDesktop && <BackWeb />}
        <Center
          bg={statusTrip.bg || colors.gray[600]}
          padding={5}
          alignItems="center"
          flexDirection="row"
          _web={{
            marginTop: 4,
          }}
        >
          <Text color={colors.gray[100]} fontSize="md" fontWeight="medium">
            {statusTrip.message || 'Cargando mensaje...'}
          </Text>
        </Center>
        <ScrollView
          flex="1"
          _contentContainerStyle={{ py: 4, px: 4 }}
          refreshControl={
            <RefreshControl
              colors={['#5B0570']}
              tintColor="#6a077a"
              refreshing={false}
              onRefresh={handleRefetch}
            />
          }
        >
          {ridesMenu.map(
            ({ id, icon, label, show, canOpen, openDefault, component }) =>
              show && (
                <Box key={id} mb={10}>
                  <Dropdown
                    showIcon
                    icon={icon}
                    title={label}
                    canOpen={canOpen}
                    openDefault={openDefault && id !== 0}
                  >
                    {component}
                  </Dropdown>
                </Box>
              ),
          )}
        </ScrollView>
      </Box>
    </Box>
  );
}
