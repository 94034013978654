/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import {
  useNavigation,
  CommonActions,
  useRoute,
} from '@react-navigation/native';
import { Image, Platform, RefreshControl } from 'react-native';
import {
  Box,
  Center,
  Text,
  HStack,
  VStack,
  useTheme,
  ScrollView,
  Divider,
  Pressable,
  Flex,
  Icon,
  AddIcon,
  Toast,
} from 'native-base';
import { useMutation, useQuery } from 'react-query';
import Dropdown from 'components/Dropdown';
import ModalPackage from 'components/modals/ModalPackage';
import ModalCompleteDocs from 'components/modals/ModalCompleteDocs';
import GeneralInformation from 'screens/GeneralInformation';
import useDocuments, { Action } from 'hooks/useDocuments';
import useDesignProfile from 'webdesign/useDesignProfile';
import Context from 'context/Documents';
import NotificationProvider from 'context/NotificationContext';
import { DocumentRoute, Documents, DocumentStatus } from 'types/documents';
import { User } from 'types/user';
import { COLORS_STATUS, documentsToUpload } from 'utils/catalogs';
import { getItemStorage, setItemStorage, validateDocuments } from 'utils';
import { AuthContext } from 'context/AuthContext';
import { GetDocuments, GetProfile, UploadAvatar } from 'api';
import { Document } from 'types/catalogs';
import {
  getDocumentStatus,
  getDocumentsKey,
  documentsUploaded,
  getSingleDocumentStatus,
} from 'utils/documents';

type Icons = React.ComponentProps<typeof MaterialCommunityIcons>['name'];

const documentIcons: { [key in DocumentStatus]: Icons } = {
  empty: 'alert-circle',
  sended: 'clock',
  incomplete: 'comment-alert',
  validated: 'checkbox-marked-circle',
  irregularity: 'message-alert',
};

export default function Profile() {
  const navigation = useNavigation();
  const routeParams = useRoute();
  const { key, name, params, path } = routeParams;
  const { state, showWelcome, updateProfile } = useContext(AuthContext);
  const { documents, setDocuments, addDocumentRequired } = useContext(Context);
  const { selectedDocument, onSelectedDocument } = useDocuments();
  const { colors } = useTheme();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [hideWelcome, setHideWelcome] = useState<boolean>(
    showWelcome && key !== 'Drawer' && name === 'Profile',
  );

  const {
    cardProfilePy,
    cardProfilePx,
    containerProfilePt,
    containerProfilePx,
    marginLeftProfile,
    alignProfile,
    cardBg,
    cardShadow,
  } = useDesignProfile();

  const documentsKey = getDocumentsKey(documents);
  const { screen } = (params as { screen: 'profile' }) || { screen: undefined };

  const { refetch, data } = useQuery<User>('profile', GetProfile, {
    enabled: key !== 'Drawer' && name === 'Profile',
    refetchOnWindowFocus: false,
    onSuccess: (userData: User) => {
      updateProfile(userData);
    },
    onError: () =>
      Toast.show({
        title: 'Hubo un cargar el perfil del usuario, intenta nuevamente.',
        placement: 'top',
      }),
  });
  const { refetch: refetchDocuments } = useQuery('documents', GetDocuments, {
    enabled: Boolean(data),
    refetchOnWindowFocus: false,
    onSuccess: (documentData: Documents[]) => {
      setDocuments(documentData);
    },
    onError: () => {
      Toast.show({
        title: 'Hubo un cargar el perfil del usuario, intenta nuevamente.',
        placement: 'top',
      });
    },
  });
  const { mutate } = useMutation(UploadAvatar, {
    onSuccess: (updatedUser: Partial<User>) => {
      if (updatedUser) {
        updateProfile({ avatar: updatedUser?.avatar });
      }
    },
    onError: () => {
      Toast.show({
        title:
          'Hubo un error al actualizar la foto de perfil, intenta nuevamente.',
        placement: 'top',
      });
    },
  });
  const getDocumentsToUpload = documentsToUpload(
    state.type,
    state.sub_type,
  ).filter((item: Document) => item.show);

  useEffect(() => {
    addDocumentRequired(getDocumentsToUpload);
  }, []);

  useEffect(() => {
    if (selectedDocument.base64) {
      onUploadAvatar();
    }
  }, [selectedDocument.base64]);

  const refetchUser = () => {
    refetch();
    refetchDocuments();
  };

  const onUploadAvatar = async () => {
    const { id } = JSON.parse(await getItemStorage('session')) || {};
    mutate({
      dataToSend: {
        user: {
          avatar: selectedDocument?.base64 || '',
        },
      },
      id,
    });
  };

  const handleAddDocument = (route: DocumentRoute) => {
    navigation.dispatch(
      CommonActions.navigate({
        name: 'Document',
        path: 'document',
        params: {
          type: route,
          screen,
        },
      }),
    );
  };

  const validatedInformation =
    state.document_status === 'validated' &&
    state.general_information_status === 'validated';
  const iconValidated = validatedInformation ? 'validated' : 'sended';

  const showValidationMessage = validateDocuments({
    documents,
    userType: state.type,
    userSubType: state.sub_type,
  });

  const handleSelectedPhoto = (action: Action) => {
    onSelectedDocument({
      action,
      web: { mimeType: 'image/*' },
    });
    setOpenModal(!openModal);
  };
  const handleTakePhoto = () => {
    setOpenModal(!openModal);
  };

  const handleCloseWelcome = () => {
    setHideWelcome(false);
  };

  const handleHideWelcome = () => {
    setHideWelcome(false);
    setItemStorage({
      key: 'showWelcome',
      value: JSON.stringify(true),
    });
  };

  return (
    <Box
      flex="1"
      _web={{
        pt: containerProfilePt,
        px: containerProfilePx,
        justifyContent: 'center',
        alignItems: path && alignProfile,
      }}
    >
      <NotificationProvider>
        <Box
          flex="1"
          width="100%"
          maxWidth="650px"
          _web={{
            py: cardProfilePy,
            px: cardProfilePx,
            borderRadius: 20,
            bg: cardBg,
            shadow: cardShadow,
            marginLeft: path ? marginLeftProfile : 0,
          }}
        >
          <ScrollView
            flex="1"
            refreshControl={
              <RefreshControl
                colors={['#5B0570']}
                tintColor="#6a077a"
                refreshing={false}
                onRefresh={refetchUser}
              />
            }
          >
            <Box alignItems="flex-start" padding="20px" flex="1">
              <Text fontSize="24px" mb="16px">
                Completa tu perfil
              </Text>
              <HStack>
                <Box mr={6}>
                  {state?.avatar ? (
                    <Image
                      source={{ uri: state?.avatar }}
                      style={{ width: 64, height: 64, borderRadius: 70 }}
                    />
                  ) : (
                    <Pressable position="relative" onPress={handleTakePhoto}>
                      <Center bg={colors.gray[400]} rounded="full" size="md">
                        <MaterialCommunityIcons
                          size={32}
                          name="camera"
                          color={colors.gray[500]}
                        />
                      </Center>
                      <Center
                        position="absolute"
                        bottom={0}
                        right={0}
                        size={5}
                        rounded="full"
                        bg="primary.900"
                      >
                        <AddIcon size="3" color="white" />
                      </Center>
                    </Pressable>
                  )}
                </Box>
                <VStack>
                  <HStack>
                    <Text fontSize="md">
                      {state
                        ? `${state?.names} ${state?.first_surname}`
                        : 'Nombre'}
                    </Text>
                  </HStack>
                  <Pressable onPress={handleTakePhoto} py={2}>
                    <Text underline fontSize="sm" color={colors.gray[800]}>
                      {state?.avatar ? 'Actualizar foto' : 'Subir una foto'}
                    </Text>
                  </Pressable>
                </VStack>
              </HStack>
              {/* Validate with status */}
              <Dropdown title="Información personal" mt="10" canOpen>
                <GeneralInformation user={state} />
              </Dropdown>
              <Dropdown title="Documentos" mt="10" openDefault canOpen>
                <Box mt="50px" flex="1">
                  {getDocumentsToUpload.map(
                    ({
                      id,
                      label,
                      route,
                      type,
                      key: documentToUploadKey,
                      total,
                    }) => {
                      const completeUserDocumentation = documentsUploaded(
                        documentsKey,
                        documentToUploadKey,
                      );
                      const currentUserDocumentStatus = getSingleDocumentStatus(
                        total,
                        documents,
                        type,
                      );
                      const status = getDocumentStatus(
                        total,
                        completeUserDocumentation,
                        currentUserDocumentStatus,
                      );
                      return (
                        <Box key={id} mb="12" height="40px">
                          <Pressable
                            onPress={() => handleAddDocument(route)}
                            _web={{
                              cursor: 'pointer',
                            }}
                          >
                            <Flex
                              justifyContent="space-between"
                              alignItems="center"
                              direction="row"
                              height="100%"
                            >
                              <Flex direction="row" alignItems="center">
                                <Icon
                                  mr={2}
                                  size={6}
                                  color={COLORS_STATUS[status]}
                                  as={
                                    <MaterialCommunityIcons
                                      name={documentIcons[status]}
                                    />
                                  }
                                />
                                <Text fontSize="16">{label}</Text>
                              </Flex>
                              <Icon
                                mr={4}
                                size={6}
                                color={colors.gray[800]}
                                as={
                                  <MaterialIcons name="keyboard-arrow-right" />
                                }
                              />
                            </Flex>
                          </Pressable>
                          <Divider
                            height="1px"
                            bg={colors.gray[200]}
                            width="100%"
                          />
                        </Box>
                      );
                    },
                  )}
                </Box>
              </Dropdown>
            </Box>
          </ScrollView>
          {showValidationMessage && (
            <Flex
              padding="5"
              alignItems="center"
              flexDirection="row"
              borderTopWidth="1px"
              borderTopColor={colors.gray[300]}
            >
              <Icon
                mr={2}
                size={6}
                color={COLORS_STATUS[iconValidated]}
                as={
                  <MaterialCommunityIcons name={documentIcons[iconValidated]} />
                }
              />
              <Text color={colors.gray[700]} fontSize="24">
                {validatedInformation
                  ? 'Información Verificada'
                  : 'Verificando información'}
              </Text>
            </Flex>
          )}
          <ModalPackage
            open={openModal}
            showUploadMobileFile={Platform.OS !== 'web'}
            onSelectDocument={handleSelectedPhoto}
            toggle={handleTakePhoto}
          />
          <ModalCompleteDocs
            isOpen={hideWelcome}
            onHideWelcome={handleHideWelcome}
            onCloseWelcome={handleCloseWelcome}
          />
        </Box>
      </NotificationProvider>
    </Box>
  );
}
