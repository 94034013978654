import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useNavigation, useRoute } from '@react-navigation/native';

import { GetTrip } from 'api';
import { TripProps } from 'types/trips';

export default function useTrip(tripId?: string) {
  const route = useRoute();
  const { rideId } = route.params as { rideId: string };

  const navigation = useNavigation();
  const { data, error, isLoading, refetch } = useQuery(
    ['trip', tripId ?? rideId],
    GetTrip,
  );

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      refetch();
    });
    return unsubscribe;
  }, [navigation, refetch]);

  const trip = data || ({} as TripProps);

  return {
    refetch,
    trip,
    isLoading,
    error,
  };
}
