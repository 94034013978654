import axios from 'axios';
import { QueryKey } from 'react-query';
import { List } from 'lodash';

import {
  AddDocumentParams,
  AddDocumentResponse,
  Documents,
} from 'types/documents';
import {
  User,
  UserResponse,
  LoginReponse,
  Nip,
  NipResponse,
  UserAvatarProps,
} from 'types/user';
import { AddressProps, CopomexProps } from 'types/address';
import {
  CreateTripProps,
  CreateTripResponse,
  SingleVehicleTypeProps,
  UserVehiclesProps,
  VehiclesProps,
} from 'types/transportation';
import {
  TripProps,
  Params,
  Pagination,
  ClientFirstStepProps,
} from 'types/trips';
import { CreateOfferProps, CreateOfferResponseProps } from 'types/offers';

import { Message } from 'types/chat';
import { object } from 'yup';
import { statusMap } from 'constants/rides';


const BASE_URL = 'https://backend.sandbox.infast.com.mx';
// const BASE_URL= 'https://9f0b-2806-104e-14-a79d-98ad-3cca-4c6a-7eb1.ngrok.io';
const PLACES_URL = 'https://maps.googleapis.com/maps/api/geocode/json?';
const COPOMEX = 'https://api.copomex.com/query';

export const Signin = async (dataToSend: {
  email: string;
  password: string;
}) => {
  const { data } = await axios.post<LoginReponse>(
    `${BASE_URL}/api/v1/auth/login`,
    dataToSend,
  );
  return data;
};

export const SaveExpoToken = async (dataToSend: { token: string }) => {
  const { data } = await axios.post<any>(
    `${BASE_URL}/api/v1/users/token/expo`,
    dataToSend,
  );
  return data;
};

export const Register = async (dataToSend: { user: Partial<User> }) => {
  const { data } = await axios.post<{ data: UserResponse }>(
    `${BASE_URL}/api/v1/users`,
    dataToSend,
  );
  return data.data;
};

export const ResetPasswordByEmail = async (email: string) => {
  const data = await axios.get<{ status: string }>(
    `${BASE_URL}/api/v1/users/reset_password/${email}`,
  );
  return data.data;
};

export const ResetPassword = async (dataToSend: {
  token: string;
  password: string;
}) => {
  const data = await axios.post<{ status: string }>(
    `${BASE_URL}/api/v1/users/reset_password`,
    dataToSend,
  );
  return data.data;
};

export const UpdatePassword = async (dataToSend: {
  old_password: string;
  new_password: string;
}) => {
  const { data } = await axios.put<{ status: string }>(
    `${BASE_URL}/api/v1/users/change_password`,
    dataToSend,
  );
  return data;
};

export const ValidateNip = async (dataToSend: Nip) => {
  const { data } = await axios.post<NipResponse>(
    `${BASE_URL}/api/v1/users/validate/nip`,
    dataToSend,
  );
  return data;
};

export const GetProfile = async () => {
  const { data } = await axios.get<{ data: User }>(
    `${BASE_URL}/api/v1/users/me`,
  );
  return data.data;
};

export const GetUserStars = async (userId: string, type: User['type']) => {
  const { data } = await axios.get<{ average: string }>(
    `${BASE_URL}/api/v1/users/${userId}/stars`,
    { params: { type } },
  );
  return data;
};

export const GetDocuments = async () => {
  const { data } = await axios.get<{ data: Documents[] }>(
    `${BASE_URL}/api/v1/documents/my-status`,
  );
  return data.data;
};

export const GetAllDocuments = async () => {
  const { data } = await axios.get<{ data: Documents[] }>(
    `${BASE_URL}/api/v1/documents`,
  );
  return data.data;
};

export const AddDocument = async (dataToSend: AddDocumentParams) => {
  const { data } = await axios.post<{ data: AddDocumentResponse }>(
    `${BASE_URL}/api/v1/documents`,
    dataToSend,
  );
  return data.data;
};
export const GeocodingApi = async (dataToSend: any) => {
  const { data } = await axios.get<{ results: []; status: string }>(
    `${PLACES_URL}`,
    {
      transformRequest: [
        (_, headers: any) => {
          // eslint-disable-next-line no-param-reassign
          delete headers?.common.Authorization;
          return headers;
        },
      ],
      params: {
        ...dataToSend,
        key: 'AIzaSyBclO38l4VD6IzkFn4WIrXk7wffuuks_Pk',
      },
    },
  );
  const { results = [] } = data || {};
  const [response = {}] = results;
  return response;
};
export const CopomexApi = async ({ cp }: { cp: string }) => {
  const { data } = await axios.get<{ data: any }>(
    `${BASE_URL}/api/v1/addresses/sepomex/zipcode?cp=${cp}`,
  );
  return data.data;
};
export const GetAddresses = async (params: { user_id: string }) => {
  const { data } = await axios.get<{ data: AddressProps[] }>(
    `${BASE_URL}/api/v1/addresses`,
    { params },
  );
  return data.data;
};

export const CreateAddress = async (dataToSend: Partial<AddressProps>) => {
  const { data } = await axios.post<{ data: Partial<AddressProps> }>(
    `${BASE_URL}/api/v1/addresses`,
    dataToSend,
  );
  return data.data;
};
export const UpdateAddress = async (address: any) => {
  const { data } = await axios.put<{ data: Partial<AddressProps> }>(
    `${BASE_URL}/api/v1/addresses/${address.id}`,
    { address },
  );
  return data.data;
};
export const GetVehiclesType = async () => {
  const { data } = await axios.get<{ data: SingleVehicleTypeProps[] }>(
    `${BASE_URL}/api/v1/vehicles/types`,
  );
  return data.data;
};
export const GetVehicles = async () => {
  const { data } = await axios.get<{ data: VehiclesProps[] }>(
    `${BASE_URL}/api/v1/vehicles`,
  );
  return data.data;
};
export const CreateTrip = async (dataToSend: CreateTripProps) => {
  const { data } = await axios.post<{ data: CreateTripResponse }>(
    `${BASE_URL}/api/v1/trips`,
    dataToSend,
  );
  return data.data;
};
export const GetRides = async () => {
  const { data } = await axios.get<{ data: TripProps[] }>(
    `${BASE_URL}/api/v1/trips?page=1&page_size=10`,
  );
  return data.data;
};

// Endpoints to get trips
export const GetTripes = async ({
  params,
  pageParam,
}: {
  params: Partial<Params>;
  pageParam: number;
}) => {
  const { data } = await axios.get<{
    data: TripProps[];
    pagination: Pagination;
  }>(`${BASE_URL}/api/v1/trips?page=${pageParam}`, {
    params,
  });
  return {
    ...data,
  };
};

export const CreateUserVehicle = async ({
  params,
  dataToSend,
}: {
  params: Partial<User>;
  dataToSend: any;
}) => {
  const { data } = await axios.post<{ data: CreateTripResponse }>(
    `${BASE_URL}/api/v1/users/${params}/vehicles`,
    dataToSend,
  );
  return data.data;
};
export const GetUserVehicles = async (id: string) => {
  const { data } = await axios.get<{ data: UserVehiclesProps[] }>(
    `${BASE_URL}/api/v1/users/${id}/vehicles`,
  );
  return data.data;
};
export const DeleteUserVehicle = async ({
  id,
  userVehicleId,
}: {
  id: string;
  userVehicleId: string;
}) => {
  const { data } = await axios.delete<{ data: any }>(
    `${BASE_URL}/api/v1/users/${id}/vehicles/${userVehicleId}`,
  );
  return data.data;
};
export const GetTrip = async ({ queryKey }: { queryKey: QueryKey }) => {
  const [_, id] = queryKey;
  const { data } = await axios.get<{ data: TripProps }>(
    `${BASE_URL}/api/v1/trips/${id}`,
  );
  return data.data;
};

export const AcceptClientTripOffer = async ({
  tripId,
  params,
}: {
  tripId: string;
  params?: { offer_id: string };
}) => {
  const { data } = await axios.put<{ data: TripProps }>(
    `${BASE_URL}/api/v1/trips/${tripId}/client-accepted-offer`,
    params,
  );
  return data;
};

export const AcceptDriverTripOffer = async ({
  tripId,
  params,
}: {
  tripId: string;
  params?: { driver_id: string };
}) => {
  const { data } = await axios.put<any>(
    `${BASE_URL}/api/v1/trips/${tripId}/driver-accepted-offer`,
    params,
  );
  return data;
};
export const CreateOffer = async (dataToSend: CreateOfferProps) => {
  const { data } = await axios.post<{ data: CreateOfferResponseProps }>(
    `${BASE_URL}/api/v1/offers`,
    dataToSend,
  );
  return data.data;
};

export const DriverReview = async ({
  tripId,
  comment,
  stars,
}: {
  tripId: string;
  comment: string;
  stars: number;
}) => {
  const { data } = await axios.put(
    `${BASE_URL}/api/v1/trips/${tripId}/driver-review`,
    { comment, stars },
  );
  return data;
};
export const ClientReview = async ({
  tripId,
  comment,
  stars,
}: {
  tripId: string;
  comment: string;
  stars: number;
}) => {
  const { data } = await axios.put(
    `${BASE_URL}/api/v1/trips/${tripId}/client-review`,
    { comment, stars },
  );
  return data;
};

export const DriveRequestStart = async ({ tripId }: { tripId: string }) => {
  const { data } = await axios.put(
    `${BASE_URL}/api/v1/trips/${tripId}/driver-requested-start`,
  );
  return data;
};

export const DriveRequestEnd = async ({ tripId }: { tripId: string }) => {
  const { data } = await axios.put<{ data: TripProps }>(
    `${BASE_URL}/api/v1/trips/${tripId}/driver-requested-end`,
  );
  return data.data;
};

export const UpdateTrip = async ({
  tripId,
  dataToUpdate,
}: {
  tripId: string;
  dataToUpdate: { trip: Partial<TripProps> };
}) => {
  const { data } = await axios.put<{ data: TripProps }>(
    `${BASE_URL}/api/v1/trips/${tripId}`,
    dataToUpdate,
  );
  return data.data;
};

export const UploadAvatar = async ({
  dataToSend,
  id,
}: {
  dataToSend: UserAvatarProps;
  id: string;
}) => {
  const { data } = await axios.put<{ data: User }>(
    `${BASE_URL}/api/v1/users/${id}`,
    dataToSend,
  );
  return data.data;
};
export const PutClientFirstStep = async ({
  dataToSend,
  tripId,
}: {
  dataToSend: ClientFirstStepProps;
  tripId: string;
}) => {
  const { data } = await axios.put<{ data: any }>(
    `${BASE_URL}/api/v1/trips/${tripId}/client-first-step`,
    dataToSend,
  );
  return data;
};
export const PutClientLastStep = async ({
  dataToSend,
  tripId,
}: {
  dataToSend: ClientFirstStepProps;
  tripId: string;
}) => {
  const { data } = await axios.put<{ data: any }>(
    `${BASE_URL}/api/v1/trips/${tripId}/client-last-step`,
    dataToSend,
  );
  return data;
};
export const ClientAcceptStart = async ({ tripId }: { tripId: string }) => {
  const { data } = await axios.put(
    `${BASE_URL}/api/v1/trips/${tripId}/client-accepted-start`,
  );
  return data;
};

export const ClientAcceptEnd = async ({
  dataToSend,
  tripId,
}: {
  dataToSend?: Partial<ClientFirstStepProps>;
  tripId: string;
}) => {
  const { data } = await axios.put<{ data: TripProps }>(
    `${BASE_URL}/api/v1/trips/${tripId}/client-accepted-end`,
    dataToSend,
  );
  return data.data;
};

export const DeleteUserSubordinate = async ({
  userId,
  subordinateId,
}: {
  userId: string;
  subordinateId: string;
}) => {
  const { data } = await axios.delete<{ data: string }>(
    `${BASE_URL}/api/v1/users/${userId}/subordinates/${subordinateId}`,
  );
  return data.data;
};

// ----------------------------------------------------------------------------
// Chat
// ----------------------------------------------------------------------------

export const ListMessages = async (client_id: string) => {
  const { data } = await axios.get<{ data: Message }>(
    `${BASE_URL}/api/v1/chat/messages?client_id=${client_id}`,
  );

  return data.data;
};


export const NewMessage = async (message: object) => {
  const { data } = await axios.post<{ data: Message }>(
    `${BASE_URL}/api/v1/chat/message/new`,
    message
  );

  return data.data;
};

function params<T>(arg0: string, params: any, arg2: { type: import("types/user").ProfileType; }) {
  throw new Error('Function not implemented.');
}

