/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useRoute } from '@react-navigation/native';

import { DocumentRoute } from 'types/documents';
import UploadINE from './UploadINE';
import UploadDrinvingLicense from './UploadDrivingLicense';
import UploadAccountStatus from './UploadAccountStatus';
import UploadSAT from './UploadSAT';
import UploadConstitutive from './UploadConstitutive';
import UploadPowerAttorney from './UploadPowerAttorney';
import UploadSUA from './UploadSUA';
import UploadPatronalRegister from './UploadSocialSecurity';

export default function Document() {
  const route = useRoute();
  const { type } = route.params as { type: DocumentRoute };

  const documentType: { [key in DocumentRoute]: React.ReactNode | undefined } =
    {
      ine: <UploadINE />,
      license: <UploadDrinvingLicense />,
      account: <UploadAccountStatus />,
      sat: <UploadSAT />,
      constitutive: <UploadConstitutive />,
      power_attorney: <UploadPowerAttorney />,
      sua: <UploadSUA />,
      social: <UploadPatronalRegister />,
      transportation: undefined,
    };

  return documentType[type];
}
