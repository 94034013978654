import React from 'react';
import { Box, Center, HStack, Text } from 'native-base';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { colors } from '../constants/colors';
import CountDownOffer from './CountDownOffer';

export default function CardOffering({
  totalOffers,
  from,
  onFinishTime,
  children,
}: {
  totalOffers: number;
  from: string;
  children: React.ReactNode;
  onFinishTime: () => void;
}) {
  return (
    <Box
      bg={colors.gray[100]}
      shadow={6}
      px={4}
      mt={4}
      mb={0}
      rounded={8}
      width="full"
      _web={{
        width: '100%',
      }}
      _ios={{
        borderRadius: 8,
      }}
    >
      <HStack justifyContent="space-between" alignItems="center" pb={3}>
        <Text fontSize="xs" color="gray.600">
          En espera de la oferta más baja ...
        </Text>
        <Box
          height="32px"
          bg="gray.500"
          borderBottomLeftRadius={8}
          borderBottomRightRadius={8}
        >
          <Text
            px={4}
            fontSize="sm"
            fontWeight="medium"
            color="white"
            lineHeight="32px"
          >
            Ofertando
          </Text>
        </Box>
      </HStack>
      <Text textAlign="center" mb={2}>
        Tiempo restante para cerrar la subasta del viaje
      </Text>
      <CountDownOffer from={from} onFinishTime={onFinishTime} />
      <Center>
        <HStack alignItems="center" space="3">
          <MaterialCommunityIcons
            name="truck"
            size={24}
            color={colors.secondary[900]}
          />
          <Text color="secondary.900" fontWeight="medium">
            {totalOffers} ofertas recibidas
          </Text>
        </HStack>
      </Center>
      {totalOffers > 0 ? children : null}
    </Box>
  );
}
