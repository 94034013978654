import React, { useState, useContext } from 'react';
import { Formik } from 'formik';
import {
  useNavigation,
  CommonActions,
  useRoute,
} from '@react-navigation/native';
import { useMutation } from 'react-query';
import {
  Box,
  Toast,
  ScrollView,
  HStack,
  Text,
  useTheme,
  Modal,
  Heading,
  Pressable,
} from 'native-base';
import * as Yup from 'yup';

import { ProgressContext } from 'context/ProgressContext';
import Button from 'components/Button';
import FloatingInput from 'components/FloatingInput';
import { ResetPassword } from 'api';
import useDesignAuth from 'webdesign/useDesignAuth';

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required('Nueva contraseña')
    .min(8, 'Debe tener mínimo 8 caracteres.'),
  confirmPassword: Yup.string()
    .required('Confirmar nueva contraseña')
    .when('password', {
      is: (val: string) => val && val.length > 0,
      then: Yup.string().oneOf(
        [Yup.ref('password')],
        'Ambas contraseñas deben ser las mismas',
      ),
    }),
});

export default function ResetPasswordByEmail() {
  const navigation = useNavigation();
  const route = useRoute();
  const { colors } = useTheme();
  const { onShowProgress, onHideProgress, progress } =
    useContext(ProgressContext);

  const { cardPx, cardPy, cardShadow, cardBg } = useDesignAuth();
  const { token } = route.params as { token: string };

  const [showPassword, setShowPassword] = useState(new Set());
  const [openModal, setOpenModal] = useState(false);

  const { mutate } = useMutation(ResetPassword, {
    onSuccess: (data: { status: string }) => {
      if (data) {
        Toast.show({
          title: 'Contraseña actualizada',
          placement: 'top',
        });
      }
      onHideProgress();
      handleLogin();
    },
    onError: () => {
      Toast.show({
        title: 'Ocurrió un error. Intenta de nuevo',
        placement: 'top',
      });
      onHideProgress();
    },
  });

  const onSubmit = (values: typeof initialValues) => {
    onShowProgress();
    mutate({
      token,
      password: values.password,
    });
  };

  const initialValues = {
    password: '',
    confirmPassword: '',
  };

  const handlePressIcon = (show: 'confirmPassword' | 'password') => {
    const newSet = new Set(showPassword);
    if (newSet.has(show)) {
      newSet.delete(show);
    } else {
      newSet.add(show);
    }
    setShowPassword(newSet);
  };

  const handleCancel = () => {
    navigation.dispatch(
      CommonActions.navigate({
        name: 'PublicStack',
      }),
    );
  };

  const handleLogin = () => {
    setOpenModal(!openModal);
    navigation.dispatch(
      CommonActions.navigate({
        name: 'PublicStack',
      }),
    );
  };

  const passwords = Array.from(showPassword);

  return (
    <Box
      pt={10}
      _web={{
        justifyContent: 'center',
        alignItems: 'center',
        px: cardPx,
      }}
    >
      <ScrollView
        px={4}
        width="100%"
        maxWidth="650px"
        _web={{
          py: cardPy,
          px: 6,
          borderRadius: 20,
          bg: cardBg,
          shadow: cardShadow,
        }}
      >
        <Text fontSize="24px" mb="16" textAlign="center" mt={4}>
          Actualizar contraseña
        </Text>
        <Text
          my={4}
          fontSize="14px"
          textAlign="center"
          color={colors.gray[700]}
          mb={8}
        >
          Actualiza tu contraseña. Debe contener mínimo 8 caracteres.
        </Text>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ values, handleChange, handleSubmit }) => (
            <>
              <FloatingInput
                mbWrapper={12}
                name="password"
                label="Nueva contraseña"
                value={values.password}
                onChangeText={handleChange('password')}
                onPressIcon={() => handlePressIcon('password')}
                type={passwords.includes('password') ? 'text' : 'password'}
                icon={
                  passwords.includes('password')
                    ? 'visibility'
                    : 'visibility-off'
                }
              />
              <FloatingInput
                mbWrapper={12}
                name="confirmPassword"
                label="Confirmar contraseña"
                value={values.confirmPassword}
                onChangeText={handleChange('confirmPassword')}
                onPressIcon={() => handlePressIcon('confirmPassword')}
                type={
                  passwords.includes('confirmPassword') ? 'text' : 'password'
                }
                icon={
                  passwords.includes('confirmPassword')
                    ? 'visibility'
                    : 'visibility-off'
                }
              />
              <HStack justifyContent="space-between" pb={10}>
                <Button button="ghost" minWidth="45%" onPress={handleCancel}>
                  Cancelar
                </Button>
                <Button
                  button="primary"
                  minWidth="45%"
                  isLoading={progress.show}
                  isDisabled={progress.show}
                  onPress={() => handleSubmit()}
                >
                  Actualizar
                </Button>
              </HStack>
            </>
          )}
        </Formik>
        <Modal bg="rgba(46, 40, 57, 0.2)" isOpen={openModal}>
          <Modal.Content
            px={4}
            pt={10}
            pb={6}
            _web={{ shadow: 'none' }}
            width="xl"
            alignItems="center"
            justifyContent="center"
          >
            <Heading
              fontSize="16px"
              color={colors.gray[800]}
              textAlign="center"
              fontWeight="normal"
            >
              Contraseña actualizada
            </Heading>
            <Text fontSize="14px" color={colors.gray[700]} mb={10} mt={6}>
              Tu contraseña ha sido actualizada con éxito.
            </Text>
            <Pressable onPress={handleLogin}>
              <Text fontSize="16px" color={colors.gray[900]} fontWeight="400">
                Aceptar
              </Text>
            </Pressable>
          </Modal.Content>
        </Modal>
      </ScrollView>
    </Box>
  );
}
