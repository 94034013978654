import React, { useContext } from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import { useTheme } from 'native-base';
import { Platform } from 'react-native';

import { AuthContext } from 'context/AuthContext';
import RidesInitial from 'screens/rides/RidesInitial';
import MyRequest from 'screens/rides/MyRequest';
import MyOffers from 'screens/rides/MyOffers';

const Tab = createBottomTabNavigator();

export default function TabsDriver() {
  const { colors } = useTheme();
  const { state: userProfile } = useContext(AuthContext);
  const colorIcon = colors.secondary as typeof colors['gray'];

  const isDriverPhysicalAssociated =
    userProfile.type === 'driver' &&
    userProfile.sub_type === 'physical' &&
    userProfile?.reverse_subordinates.length > 0;

  return (
    <Tab.Navigator
      initialRouteName="Inicio"
      screenOptions={{
        tabBarLabelStyle: {
          fontSize: 12,
          ...Platform.select({
            web: {
              marginTop: 15,
            },
          }),
        },
        tabBarStyle: {
          borderTopColor: colors.gray['100'],
          elevation: 0,
          paddingBottom: 0,
          paddingTop: 0,
          ...Platform.select({
            web: {
              height: 60,
            },
            android: {
              height: 60,
              paddingTop: 10,
              paddingBottom: 10,
            },
            ios: {
              height: 60,
              paddingTop: 0,
              paddingBottom: 0,
            },
          }),
        },
      }}
    >
      <Tab.Screen
        name="Solicitudes"
        component={MyRequest}
        options={{
          headerShown: false,
          tabBarActiveTintColor: colorIcon[900],
          tabBarIcon: ({ size, color }) => (
            <MaterialCommunityIcons name="file" color={color} size={size} />
          ),
        }}
      />
      {!isDriverPhysicalAssociated && (
        <Tab.Screen
          name="Mis ofertas"
          component={MyOffers}
          options={{
            headerShown: false,
            tabBarActiveTintColor: colorIcon[900],
            tabBarIcon: ({ size, color }) => (
              <MaterialIcons name="departure-board" color={color} size={size} />
            ),
          }}
        />
      )}
      <Tab.Screen
        name="Mis viajes"
        component={RidesInitial}
        options={{
          headerShown: false,
          tabBarActiveTintColor: colorIcon[900],
          tabBarIcon: ({ size, color }) => (
            <MaterialCommunityIcons name="truck" color={color} size={size} />
          ),
        }}
      />
    </Tab.Navigator>
  );
}
