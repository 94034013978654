import { useState, useContext } from 'react';
import { useMutation } from 'react-query';
import {
  useNavigation,
  CommonActions,
  useRoute,
} from '@react-navigation/native';

import {
  DocumentUploaded,
  AddDocumentParams,
  DocumentRoute,
} from 'types/documents';
import { AddDocument } from 'api';
import useFetchDocuments from 'hooks/useFetchDocuments';
import DocumentContext from 'context/Documents';
import { documentsToUpload } from 'utils/catalogs';
import { AuthContext } from 'context/AuthContext';
import { Platform } from 'react-native';

export default function useUpload() {
  const navigation = useNavigation();
  const { params } = useRoute();
  const { state } = useContext(AuthContext);
  const { documentsRequired, updateDocumentRequired } =
    useContext(DocumentContext);
  const { refetch } = useFetchDocuments();
  const { screen } = (params as { screen: 'profile' }) || { screen: undefined };

  const [documentUploaded, setDocumentUploaded] = useState<DocumentUploaded>(
    {} as DocumentUploaded,
  );

  const isWeb = Platform.OS === 'web';

  const { mutate, error } = useMutation(AddDocument, {
    onSuccess: data => {
      if (data) {
        setDocumentUploaded(prevState => ({
          ...prevState,
          [data.type]: data,
        }));
        refetch();
      }
    },
  });

  const nextDocument = ({
    isUploaded,
    route,
  }: {
    isUploaded: boolean;
    route: DocumentRoute | null;
  }) => {
    const getDocumentsToUpload = documentsToUpload(
      state.type,
      state.sub_type,
    ).filter(item => item.show);
    if (!route) return;
    if (isUploaded && documentsRequired.length > 1) {
      const nextRoutes = documentsRequired.filter(item => item.route !== route);
      updateDocumentRequired(nextRoutes);
      navigation.dispatch(
        CommonActions.navigate({
          name: 'Document',
          params: {
            type: nextRoutes[0].route,
            screen,
          },
        }),
      );
      return;
    }
    navigation.dispatch(
      CommonActions.navigate({
        name: isWeb ? 'profile' : 'Profile',
        params: { screen },
      }),
    );
    updateDocumentRequired(getDocumentsToUpload);
  };

  const addDocument = ({
    base64,
    user_id: userId,
    type,
  }: AddDocumentParams) => {
    mutate({
      base64,
      type,
      user_id: userId,
    });
  };
  return { addDocument, nextDocument, error, documentUploaded };
}
