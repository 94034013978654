import React from 'react';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import {
  Box,
  HStack,
  Icon,
  Pressable,
  VStack,
  Text,
  Divider,
  useTheme,
  extendTheme,
} from 'native-base';
import { ContextProps } from 'types/notification';
import useNotification from 'hooks/useNotification';
import { timeAgo } from 'utils/formats';
import { NotificationContent } from 'expo-notifications';

type Colors = ReturnType<typeof extendTheme>['colors'] | Record<string, any>;
type Icons = React.ComponentProps<typeof MaterialCommunityIcons>['name'];

const notificationIcons: { [key in ContextProps['context']]: Icons } = {
  SECTION_DOCUMENTS: 'file',
  SECTION_TRIP_DETAIL: 'truck',
  SECTION_MESSAGES: 'message',
};

const notificationIconColor = (colors: Partial<Colors>) => ({
  SECTION_DOCUMENTS: colors.success[900],
  SECTION_TRIP_DETAIL: colors.warning[900],
  SECTION_MESSAGES: colors.warning[900],
});

type NotificationProps = {
  handleRoute: (notificationData: ContextProps, identifier: string) => void;
  notificationData: ContextProps;
  identifier: string;
  isOpened?: boolean;
  content: NotificationContent;
  parseDate: Date | string;
};

export default function Notification({
  handleRoute,
  notificationData,
  identifier,
  parseDate,
  content,
  isOpened,
}: NotificationProps) {
  const { colors } = useTheme();
  const primaryColors = colors.primary as typeof colors['gray'];

  const { removeNotifications } = useNotification();

  return (
    <Pressable onPress={() => handleRoute(notificationData, identifier)}>
      <Box>
        <HStack
          justifyContent="space-between"
          alignItems="center"
          bg={isOpened ? colors.gray[200] : colors.gray[100]}
          height="80px"
          px={6}
        >
          <HStack alignItems="center" width="80%">
            <Icon
              mr={4}
              size={6}
              color={
                notificationIconColor(colors)[notificationData.context] ||
                primaryColors[900]
              }
              as={
                <MaterialCommunityIcons
                  name={
                    notificationIcons[notificationData.context] || 'bell-circle'
                  }
                />
              }
            />
            <VStack>
              <Text fontWeight="400" color={colors.gray[700]} fontSize="sm">
                {notificationData.title || content.title}
              </Text>
              <Text fontSize="12px" color={colors.gray[700]}>
                Hace {timeAgo(parseDate)}
              </Text>
            </VStack>
          </HStack>
          <Icon
            size={6}
            color={primaryColors[900]}
            onPress={() => removeNotifications(identifier)}
            as={<MaterialCommunityIcons name="trash-can-outline" />}
          />
        </HStack>
        <Divider />
      </Box>
    </Pressable>
  );
}
