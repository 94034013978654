import React, { useContext } from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import RidesHistory from 'screens/rides/RidesHistory';
import RidesInitial from 'screens/rides/RidesInitial';
import AboutUs from 'screens/AboutUs';
import Help from 'screens/Help';
import Chat from 'screens/Chat';
import Profile from 'screens/Profile';
import Document from 'screens/Document';
import Transportation from 'screens/Transportation';
import TransportationAdd from 'screens/TransportationAdd';
import Subordinates from 'screens/Subordinates';
import Details from 'screens/rides/Details';
import Travels from 'screens/Travels/Travels';
import OnTheWay from 'screens/Travels/OnTheWay';
import TravelRequest from 'screens/Travels/TravelRequest';
import MyAddresses from 'screens/MyAddresses';
import NewAddress from 'screens/NewAddress';
import Terms from 'screens/Terms';
import MyRequest from 'screens/rides/MyRequest';
import MyOffers from 'screens/rides/MyOffers';
import { AuthContext } from 'context/AuthContext';
import Carrier from 'screens/rides/Carrier';
import TransportationCatalog from 'screens/TransportationCatalog';

const Stack = createNativeStackNavigator();

export default function WebStack() {
  const { state: userProfile } = useContext(AuthContext);
  const initialRouteName =
    userProfile.type === 'driver' ? 'request' : 'travels';

  return (
    <Stack.Navigator initialRouteName={initialRouteName}>
      <Stack.Screen
        name="request"
        component={MyRequest}
        options={{
          title: 'Request',
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="myoffers"
        component={MyOffers}
        options={{
          title: 'Myoffers',
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="rides"
        component={RidesInitial}
        options={{
          title: 'Rides',
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="myhistory"
        component={RidesHistory}
        options={{
          title: 'Myhistory',
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="about"
        component={AboutUs}
        options={{
          title: 'About',
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="help"
        component={Help}
        options={{
          title: 'Help',
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="chat"
        component={Chat}
        options={{
          title: 'Chat',
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="profile"
        component={Profile}
        options={{
          title: 'Profile',
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="Document"
        component={Document}
        options={{
          title: 'Document',
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="transportation"
        component={Transportation}
        options={{
          title: 'Transportation',
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="TransportationAdd"
        component={TransportationAdd}
        options={{
          title: 'TransportationAdd',
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="subordinates"
        component={Subordinates}
        options={{
          title: 'Subordinates',
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="Details"
        component={Details}
        options={{
          title: 'Details',
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="travels"
        component={Travels}
        options={{
          title: 'Travels',
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="ontheway"
        component={OnTheWay}
        options={{
          title: 'Ontheway',
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="travelRequest"
        component={TravelRequest}
        options={{
          title: 'travelRequest',
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="myAddresses"
        component={MyAddresses}
        options={{
          title: 'Myaddress',
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="NewAddress"
        component={NewAddress}
        options={{
          title: 'Newaddress',
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="Terms"
        component={Terms}
        options={{
          title: 'Terms',
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="Carrier"
        component={Carrier}
        options={{
          title: 'Carrier',
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="TransportationCatalog"
        component={TransportationCatalog}
        options={{
          title: 'TransportationCatalog',
          headerShown: false,
        }}
      />
    </Stack.Navigator>
  );
}
