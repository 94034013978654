import React from 'react';
import { Box, Flex, Text, useTheme } from 'native-base';
import { theme } from 'constants/theme';

function Step({
  activeStep,
  numberStep,
}: {
  activeStep: number;
  numberStep: number;
}) {
  const { colors } = useTheme<typeof theme>();
  const primaryColors = colors.primary as typeof colors['gray'];

  return (
    <Box p={2} alignItems="center" position="relative" textAlign="center">
      <Text
        minW="1.125rem"
        fontSize="2xl"
        fontWeight="medium"
        textAlign="center"
        color={activeStep >= numberStep ? primaryColors[900] : colors.gray[400]}
      >
        {numberStep}
      </Text>
    </Box>
  );
}

export default function Stepper({
  activeStep,
  steps,
}: {
  activeStep: number;
  steps: number;
}) {
  const { colors } = useTheme<typeof theme>();
  const primaryColors = colors.primary as typeof colors['gray'];

  return (
    <Box px={0}>
      <Flex direction="row" justifyContent="space-between" width="100%">
        {new Array(steps).fill(null).map((_, index) => (
          <Flex
            direction="row"
            justifyContent={index + 1 === steps ? 'flex-end' : 'flex-start'}
            alignItems="center"
            flex={index + 1 === steps ? 0 : 1}
            minW={0}
            key={index + 1}
          >
            <Step activeStep={activeStep} numberStep={index + 1} />
            {index + 1 !== steps ? (
              <Box
                display="flex"
                flexDirection="row"
                flex={1}
                borderTopWidth={6}
                my={0}
                mx={1}
                borderColor={
                  activeStep >= index + 1
                    ? primaryColors[900]
                    : colors.gray[400]
                }
              />
            ) : null}
          </Flex>
        ))}
      </Flex>
    </Box>
  );
}
