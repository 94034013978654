import React, { useContext } from 'react';
import { Center } from 'native-base';
import { ProgressContext } from 'context/ProgressContext';
import { ActivityIndicator } from 'react-native';

export default function Progress() {
  const { progress } = useContext(ProgressContext);
  if (!progress.show) {
    return null;
  }
  return (
    <Center
      position="absolute"
      top={0}
      bottom={0}
      left={0}
      right={0}
      bg={progress.bg}
      opacity={progress.opacity}
    >
      <ActivityIndicator
        size="large"
        color={progress.colorSpinner}
        animating={progress.show}
      />
    </Center>
  );
}
