import React, { useContext } from 'react';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import {
  Box,
  Text,
  HStack,
  Flex,
  Checkbox,
  Radio,
  Icon,
  Pressable,
  useTheme,
  VStack,
} from 'native-base';
import { CardVehicleProps } from 'types/transportation';
import Context from 'context/Vehicles';
import { getClientVehicleId } from 'utils/vehicles';

export default function CardVehicle({
  isTransportist,
  isClient,
  canDelete,
  onChangeMultipleValues,
  onChangeValue,
  onDeleteVehicle,
  configuration,
  m3,
  length,
  height,
  width,
  checkboxValue = [],
  userVehicleId = '',
  charge,
  vehicle_type: vehicleType,
  id = '',
  currentDriveVehicleSelected = [],
  canChangeVehicle,
  onChangeVehicle = () => {},
}: CardVehicleProps) {
  const { vehicle } = useContext(Context);
  const { colors } = useTheme();
  const checkboxSelected = [...currentDriveVehicleSelected, ...checkboxValue];
  return (
    <Box
      mb={4}
      shadow={0}
      w="full"
      rounded="2xl"
      borderColor="gray.300"
      borderWidth={1}
      bg="white"
    >
      <Flex padding={4} direction="row" justifyContent="space-between">
        <Box>
          <Box>
            {isTransportist && (
              <Checkbox.Group
                value={checkboxSelected}
                accessibilityLabel="multiple transport"
                onChange={onChangeMultipleValues}
              >
                <Checkbox value={id} my={1} size="md">
                  <VStack>
                    <Text
                      fontSize="14px"
                      fontWeight="600"
                      color="gray.800"
                      ml={4}
                    >
                      {vehicleType.name}
                    </Text>
                    <Text
                      fontSize="12px"
                      fontWeight="300"
                      color="gray.800"
                      ml={4}
                    >
                      {configuration} - {charge}kg
                    </Text>
                  </VStack>
                </Checkbox>
              </Checkbox.Group>
            )}
            {isClient && (
              <Radio.Group
                name="type"
                value={getClientVehicleId(vehicle)}
                accessibilityLabel="type transport"
                onChange={onChangeValue}
              >
                <Radio value={id} my={1} size="lg">
                  <VStack>
                    <Text
                      fontSize="14px"
                      fontWeight="600"
                      color="gray.800"
                      ml={4}
                    >
                      {vehicleType.name}
                    </Text>
                    <Text
                      fontSize="12px"
                      fontWeight="300"
                      color="gray.800"
                      ml={4}
                    >
                      {configuration} - {charge}kg
                    </Text>
                  </VStack>
                </Radio>
              </Radio.Group>
            )}
            {!isTransportist && !isClient && (
              <VStack>
                <Text fontSize="14px" fontWeight="600" color="gray.800">
                  {vehicleType.name}
                </Text>
                <Text fontSize="12px" fontWeight="300" color="gray.800">
                  {configuration} - {charge}kg
                </Text>
              </VStack>
            )}
          </Box>
          <Box ml={isTransportist || isClient ? 9 : 0} mt={2}>
            <Text fontSize="sm" fontWeight={300} mb={2}>
              Dimensiones
            </Text>
            <HStack mb={2} space={3}>
              <Text fontSize="sm" fontWeight={600}>
                M3 <Text color={colors.gray[700]}>{m3}m</Text>
              </Text>
              <Text fontSize="sm" fontWeight={600}>
                Largo <Text color={colors.gray[700]}>{length}m</Text>
              </Text>
            </HStack>
            <HStack mb={2} space={3}>
              <Text fontSize="sm" fontWeight={600}>
                Alto <Text color={colors.gray[700]}>{height}m</Text>
              </Text>
              <Text fontSize="sm" fontWeight={600}>
                Ancho <Text color={colors.gray[700]}>{width}m</Text>
              </Text>
            </HStack>
          </Box>
        </Box>
        {canChangeVehicle ? (
          <Pressable p={2} onPress={onChangeVehicle}>
            <Text fontWeight="bold" color="primary.700">
              Cambiar
            </Text>
          </Pressable>
        ) : null}
        {canDelete && (
          <Pressable
            alignSelf="center"
            p={2}
            onPress={() => onDeleteVehicle?.(userVehicleId)}
          >
            <Icon
              size={6}
              color="gray.500"
              as={<MaterialCommunityIcons name="delete" />}
            />
          </Pressable>
        )}
      </Flex>
    </Box>
  );
}
