/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { MaterialIcons } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';
import { useMutation } from 'react-query';
import {
  Pressable,
  Center,
  Text,
  Stack,
  Icon,
  useTheme,
  HStack,
  VStack,
  TextArea,
  Toast,
} from 'native-base';
import { NativeSyntheticEvent, TextInputChangeEventData } from 'react-native';
import Button from 'components/Button';
import { TripProps } from 'types/trips';
import { DriverReview, ClientReview } from 'api';
import { reviewOptions } from 'constants/rides';

type Icons = React.ComponentProps<typeof MaterialIcons>['name'];

const stars: Icons[] = new Array(5).fill('star-border');

export default function Review({
  trip,
  typeUser = '',
  refetch,
}: {
  trip: TripProps;
  typeUser: string;
  refetch: () => void;
}) {
  const { colors } = useTheme();
  const [selectedMessageReview, setSelectedMessageReview] =
    useState<string>('');
  const [comment, setComment] = useState('');
  const [selectedStart, setSelectedStart] = useState(0);
  const ReviewEndpoint = typeUser === 'client' ? ClientReview : DriverReview;

  const { mutate, isLoading } = useMutation(ReviewEndpoint, {
    onSuccess: () => {
      Toast.show({
        title: 'Review enviado con éxito!',
        placement: 'top',
      });
      setComment('');
      setSelectedMessageReview('');
      setSelectedStart(0);
      refetch();
    },
    onError: () => {
      Toast.show({
        title: 'Hubo un error. intenta nuevamente.',
        placement: 'top',
      });
    },
  });

  const handleChangeValue = (
    event: NativeSyntheticEvent<TextInputChangeEventData>,
  ) => {
    const { text } = event.nativeEvent;
    setComment(text);
  };

  const handleSubmit = () => {
    if (!comment || !selectedMessageReview || selectedStart === 0) {
      Toast.show({
        title: 'Por favor, completa el review',
        placement: 'bottom',
      });
      return;
    }
    mutate({
      stars: selectedStart,
      tripId: trip.id,
      comment: `${selectedMessageReview}. ${comment}`,
    });
  };

  return (
    <Stack
      flex="1"
      bg={colors.gray[100]}
      p={4}
      mt={4}
      mb={0}
      shadow={6}
      rounded={8}
      width="full"
      _web={{
        width: '100%',
      }}
      _ios={{
        borderRadius: 8,
      }}
    >
      {typeUser === 'client' ? (
        <>
          {!trip?.review?.client_comment ? (
            <>
              <Center>
                <Text fontSize="24px" color={colors.gray[900]}>
                  Califica al transportista
                </Text>
              </Center>
              <HStack justifyContent="center" mt={6} space={6}>
                {stars.map((start, index) => (
                  <Icon
                    key={index}
                    size={6}
                    as={
                      <MaterialIcons
                        name={selectedStart < index + 1 ? start : 'star'}
                      />
                    }
                    onPress={() => setSelectedStart(index + 1)}
                    color={
                      selectedStart < index + 1
                        ? colors.gray[500]
                        : colors.orange[400]
                    }
                  />
                ))}
              </HStack>
              <HStack justifyContent="center" space={4} my={8}>
                {reviewOptions[typeUser].map((option: string) => (
                  <LinearGradient
                    key={option}
                    colors={
                      selectedMessageReview === option
                        ? ['#FFA40099', '#FF5C5299']
                        : ['#e6e6e6', '#cccccc']
                    }
                  >
                    <Center rounded="lg">
                      <Pressable
                        onPress={() => setSelectedMessageReview(option)}
                      >
                        <Text px={4} py={2}>
                          {option}
                        </Text>
                      </Pressable>
                    </Center>
                  </LinearGradient>
                ))}
              </HStack>
              <Stack px={4}>
                <VStack mb={1}>
                  <Text fontSize="md" color={colors.gray[700]}>
                    Comentarios
                  </Text>
                  <Text fontSize="sm" color={colors.gray[600]} fontWeight="300">
                    Comparte tu experiencia
                  </Text>
                </VStack>
                <TextArea
                  height="120px"
                  minWidth="100%"
                  rounded="0"
                  value={comment}
                  mb={12}
                  textAlignVertical="top"
                  onChange={handleChangeValue}
                />
                <Button
                  mb={6}
                  button="primary"
                  isDisabled={
                    isLoading ||
                    trip.status === 'completed' ||
                    !comment ||
                    !selectedMessageReview ||
                    selectedStart === 0
                  }
                  isLoading={isLoading}
                  onPress={handleSubmit}
                >
                  Publicar
                </Button>
              </Stack>
            </>
          ) : (
            <>
              {!trip?.review?.driver_comment ? (
                <Center>
                  <Text color="gray.500">Review enviado al transportista</Text>
                  <Text mt={4}>El transportista aún no escribe su review.</Text>
                </Center>
              ) : (
                <Center p={4}>
                  <Text fontSize="xl" mb={4}>
                    Review de este viaje
                  </Text>
                  <HStack justifyContent="center" mb={4} space={6}>
                    {stars.map((start, index) => (
                      <Icon
                        key={index}
                        size={6}
                        as={
                          <MaterialIcons
                            name={
                              trip.review.driver_stars < index + 1
                                ? start
                                : 'star'
                            }
                          />
                        }
                        color={
                          trip.review.driver_stars < index + 1
                            ? colors.gray[500]
                            : colors.orange[400]
                        }
                      />
                    ))}
                  </HStack>
                  <Text>{trip.review.driver_comment}</Text>
                </Center>
              )}
            </>
          )}
        </>
      ) : (
        <>
          {!trip?.review?.driver_comment ? (
            <>
              <Center>
                <Text fontSize="24px" color={colors.gray[900]}>
                  Califica al cliente
                </Text>
              </Center>
              <HStack justifyContent="center" mt={6} space={6}>
                {stars.map((start, index) => (
                  <Icon
                    key={index}
                    size={6}
                    as={
                      <MaterialIcons
                        name={selectedStart < index + 1 ? start : 'star'}
                      />
                    }
                    onPress={() => setSelectedStart(index + 1)}
                    color={
                      selectedStart < index + 1
                        ? colors.gray[500]
                        : colors.orange[400]
                    }
                  />
                ))}
              </HStack>
              <HStack justifyContent="center" space={4} my={8}>
                {reviewOptions[typeUser].map((option: string) => (
                  <LinearGradient
                    key={option}
                    colors={
                      selectedMessageReview === option
                        ? ['#FFA40099', '#FF5C5299']
                        : ['#e6e6e6', '#cccccc']
                    }
                  >
                    <Center rounded="lg">
                      <Pressable
                        onPress={() => setSelectedMessageReview(option)}
                      >
                        <Text px={4} py={2}>
                          {option}
                        </Text>
                      </Pressable>
                    </Center>
                  </LinearGradient>
                ))}
              </HStack>
              <Stack px={4}>
                <VStack mb={1}>
                  <Text fontSize="md" color={colors.gray[700]}>
                    Comentarios
                  </Text>
                  <Text fontSize="sm" color={colors.gray[600]} fontWeight="300">
                    Comparte tu experiencia
                  </Text>
                </VStack>
                <TextArea
                  height="120px"
                  minWidth="100%"
                  rounded="0"
                  value={comment}
                  mb={12}
                  textAlignVertical="top"
                  onChange={handleChangeValue}
                />
                <Button
                  mb={6}
                  button="primary"
                  isDisabled={
                    isLoading ||
                    trip.status === 'completed' ||
                    !comment ||
                    !selectedMessageReview ||
                    selectedStart === 0
                  }
                  isLoading={isLoading}
                  onPress={handleSubmit}
                >
                  Publicar
                </Button>
              </Stack>
            </>
          ) : (
            <>
              {!trip.review.client_comment ? (
                <Center height={100}>
                  <Text color="gray.500">Review enviado al cliente.</Text>
                  <Text mt={4}>El cliente aún no escribe su review.</Text>
                </Center>
              ) : (
                <Center p={4}>
                  <Text fontSize="xl" mb={4}>
                    Review de este viaje
                  </Text>
                  <HStack justifyContent="center" mb={4} space={6}>
                    {stars.map((start, index) => (
                      <Icon
                        key={index}
                        size={6}
                        as={
                          <MaterialIcons
                            name={
                              trip.review.client_stars < index + 1
                                ? start
                                : 'star'
                            }
                          />
                        }
                        color={
                          trip.review.client_stars < index + 1
                            ? colors.gray[500]
                            : colors.orange[400]
                        }
                      />
                    ))}
                  </HStack>
                  <Text>{trip.review.client_comment}</Text>
                </Center>
              )}
            </>
          )}
        </>
      )}
    </Stack>
  );
}
