import React from 'react';
import { Platform } from 'react-native';
import {
  Pressable,
  Icon,
  Modal,
  Text,
  useTheme,
  Center,
  Divider,
} from 'native-base';

import { theme } from 'constants/theme';
import { MaterialIcons, MaterialCommunityIcons } from '@expo/vector-icons';
import { Action } from '../../hooks/useDocuments';

interface ModalDocumentProps {
  open: boolean;
  showUploadMobileFile?: boolean;
  hideUploadPhoto?: boolean;
  toggle?: () => void;
  onSelectDocument: (arg: Action) => void;
}

export default function ModalPackage({
  open = true,
  showUploadMobileFile = false,
  hideUploadPhoto = false,
  toggle,
  onSelectDocument,
}: ModalDocumentProps) {
  const { colors } = useTheme<typeof theme>();
  const isWeb = Platform.OS === 'web' || hideUploadPhoto;
  return (
    <Modal
      justifyContent="flex-end"
      bg="rgba(46, 40, 57, 0.6)"
      isOpen={open}
      onClose={toggle}
    >
      <Modal.Content
        width="100%"
        maxWidth="100%"
        maxHeight="200px"
        borderRadius="0"
      >
        <Modal.Footer height="100%">
          <Center width="100%">
            {!isWeb && (
              <>
                <Pressable
                  onPress={() => onSelectDocument('takePhoto')}
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                  w="full"
                  py={6}
                >
                  <Icon
                    size={6}
                    mr={4}
                    color={colors.gray[800]}
                    as={<MaterialIcons name="photo-camera" />}
                  />
                  <Text color={colors.gray[800]} fontSize="16px">
                    Tomar foto
                  </Text>
                </Pressable>
                <Divider my={2} />
                <Pressable
                  onPress={() => onSelectDocument('selectPhoto')}
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                  w="full"
                  py={6}
                >
                  <Icon
                    size={6}
                    mr={4}
                    color={colors.gray[800]}
                    as={<MaterialIcons name="image" />}
                  />
                  <Text color={colors.gray[800]} fontSize="16px">
                    Selecionar foto
                  </Text>
                </Pressable>
              </>
            )}
            {!showUploadMobileFile && (
              <Pressable
                onPress={() => onSelectDocument('uploadPdf')}
                flexDirection="row"
                justifyContent="flex-start"
                width="170px"
              >
                <Icon
                  size={6}
                  mr={4}
                  color={colors.gray[800]}
                  as={<MaterialCommunityIcons name="file-document" />}
                />
                <Text color={colors.gray[800]} fontSize="16px">
                  Subir archivo
                </Text>
              </Pressable>
            )}
          </Center>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
}
