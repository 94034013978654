import { useState, useEffect } from 'react';
import { ImageInfo } from 'expo-image-picker/build/ImagePicker.types';
import {
  ImagePickerOptions,
  launchCameraAsync,
  launchImageLibraryAsync,
  MediaTypeOptions,
} from 'expo-image-picker';
import * as FileSystem from 'expo-file-system';
import { Platform } from 'react-native';
import { getDocumentAsync } from 'expo-document-picker';
import { Toast } from 'native-base';
import * as Permissions from 'expo-permissions';
import * as Location from 'expo-location';

export type Action = 'takePhoto' | 'selectPhoto' | 'uploadPdf';
interface DocumentResult extends ImageInfo {
  name: string;
}

const request = {
  takePhoto: launchCameraAsync,
  selectPhoto: launchImageLibraryAsync,
};

export default function useDocuments() {
  const [hasPermissions, setHasPersmissions] = useState<null | string>(null);
  const [selectedDocument, setSelectedDocument] = useState<DocumentResult>(
    {} as DocumentResult,
  );

  const askPermissionsAsync = async () => {
    await Permissions.askAsync(Permissions.CAMERA);
    await Permissions.askAsync(Permissions.CAMERA_ROLL);
  };

  useEffect(() => {
    (async () => {
      if (Platform.OS !== 'web') {
        const { status } = await Location.requestForegroundPermissionsAsync();
        if (status === 'granted') {
          setHasPersmissions('granted');
        } else {
          Toast.show({
            title: 'No access to camera',
            placement: 'top',
          });
        }
      }
    })();
  }, []);

  const onSelectedDocument = ({
    action,
    web,
    imageOptions,
  }: {
    action: Action;
    web?: { mimeType: string };
    imageOptions?: ImagePickerOptions;
  }) => {
    if (action !== 'uploadPdf') {
      handleSelectedImage({ action, imageOptions });
    } else {
      handleSelectedDocument({ mimeType: web?.mimeType });
    }
  };

  const handleSelectedImage = async ({
    action,
    imageOptions,
  }: {
    action: 'takePhoto' | 'selectPhoto';
    imageOptions?: ImagePickerOptions;
  }) => {
    askPermissionsAsync();
    if (hasPermissions) {
      const result = await request[action]({
        ...imageOptions,
        mediaTypes: MediaTypeOptions.All,
        allowsMultipleSelection: true,
        base64: true,
        aspect: [4, 3],
        quality: 0.3,
      });

      if (!result.cancelled) {
        const imageResult = {
          ...result,
          name: '',
          base64: `data:image/jpeg;base64,${result.base64}`,
        };
        setSelectedDocument(imageResult);
      }
    }
  };

  const handleSelectedDocument = async ({
    mimeType,
  }: {
    mimeType?: string;
  }) => {
    const type = Platform.OS !== 'web' ? 'application/pdf' : mimeType;
    const file = await getDocumentAsync({ type, copyToCacheDirectory: false });

    if (file.type !== 'cancel') {
      const imageResult = {
        uri: file.uri,
        base64: file.uri,
        name: file.name,
        width: 0,
        height: 0,
      };
      if (Platform.OS !== 'web') {
        if (Platform.OS === 'android') {
          FileSystem.copyAsync({
            from: file.uri,
            to: `${FileSystem.documentDirectory}/${file.name}`,
          });
          const getBase64 = await FileSystem.readAsStringAsync(
            `${FileSystem.documentDirectory}/${file.name}`,
            {
              encoding: 'base64',
            },
          );

          const base64 = `data:application/pdf;base64,${getBase64}`;
          setSelectedDocument({ ...imageResult, uri: base64, base64 });
        }

        if (Platform.OS === 'ios') {
          const getBase64 = await FileSystem.readAsStringAsync(file.uri, {
            encoding: 'base64',
          });
          const base64 = `data:application/pdf;base64,${getBase64}`;
          setSelectedDocument({ ...imageResult, uri: base64, base64 });
        }
      } else {
        setSelectedDocument(imageResult);
      }
    }
  };

  return {
    hasPermissions,
    selectedDocument,
    onSelectedDocument,
  };
}
