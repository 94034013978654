import React, { useContext } from 'react';
import useTrip from 'hooks/useTrip';
import { MenuRides } from 'types/catalogs';
import { colors } from 'constants/colors';
import { statusMap } from 'constants/rides';

import { AuthContext } from 'context/AuthContext';
import RideDetails from 'screens/rides/RideDetails';
import RideDeals from 'screens/rides/RideDeals';
import RideDealsMoral from 'screens/rides/RideDealsMoral';
import AssignedRide from 'screens/rides/AssignedRide';
import Payment from 'screens/rides/Payment';
import Review from 'screens/rides/Review';
import RidesTrack from 'screens/rides/RidesTrack';
import AssignRide from 'screens/rides/AssignRide';
import { RideStatus } from 'types/ride';
import { Payments } from 'types/trips';
import { getStatusPayments } from 'utils/payments';
import {
  getIconByStatus,
  getValidatePayments,
  isEmptyObject,
} from 'utils/validators';
import { getBestOffer } from 'utils/rides';

// reverse_subordinates = [any] => driver physical asociado;

export default function useRideMenu() {
  const { state } = useContext(AuthContext);
  const { trip, refetch } = useTrip();
  const isDriverMoral = state?.type === 'driver' && state.sub_type === 'moral';
  const isClient = state?.type === 'client';
  const isEmpty = isEmptyObject(trip);

  const myOffers = isEmpty ? [] : trip.offers.filter(offer => offer.user_id === state.id);
  const existsBestOffer = getBestOffer(myOffers);
  let message = null;
  let bg = null;

  
  const statusColor: { [k in Partial<RideStatus>]?: string } = {
    completed: colors.gray[800],
    cancelled: colors.gray[500],
    in_progress: colors.secondary[900],
    waiting_for_offers: colors.warning[900],
    offer_accepted: colors.success[900],
    default: colors.gray[800],
    pending_last_step: colors.primary[900],
    pending_first_step: colors.primary[900],
    failed_first_step: isClient ? colors.error[900] : colors.gray[500],
    failed_last_step: isClient ? colors.error[900] : colors.gray[500],
    in_validation_first_step: colors.secondary[900],
    in_validation_last_step: colors.secondary[900],
    waiting_for_review: colors.warning[900],
    pending_start: colors.success[900],
  };
  const newStatusMap = {
    ...statusMap,
    failed_first_step: isClient ? 'Pago rechazado' : 'En espera de pago',
    failed_last_step: isClient ? 'Pago rechazado' : 'En espera de pago',
  };

  if (trip?.status === 'waiting_for_offers') {
    message = newStatusMap[trip?.status]
    bg = statusColor[trip?.status]
  } 

  if (!existsBestOffer && trip?.status !== 'waiting_for_offers') {
    message = 'No aceptada'
    bg = colors.gray[800]
  } 

  const statusTrip = {
    message: existsBestOffer ? newStatusMap[trip?.status] : message,
    bg: existsBestOffer ? statusColor[trip?.status] : bg
  };

  const isDriverPhysicalAssociated =
    state?.type === 'driver' &&
    state.sub_type === 'physical' &&
    state.reverse_subordinates.length > 0;

  const payments = trip?.payments || ([] as Payments[]);
  const validated = getStatusPayments(payments, 'validated');
  const sended = getStatusPayments(payments, 'sended');

  const openInProgress =
    Boolean(trip?.driver_start_accepted_at) &&
    Boolean(trip?.client_start_accepted_at);

  const openEndTrip =
    Boolean(trip?.driver_finish_accepted_at) &&
    Boolean(trip?.client_finish_accepted_at);

  const RideComponent = isClient ? RideDeals : RideDealsMoral;
  const AssignComponent = isDriverMoral ? AssignRide : AssignedRide;
  
  const ridesMenu: MenuRides[] = [
    {
      id: 0,
      label: 'Detalles de Viaje',
      icon: getIconByStatus({
        clientStatus: true,
        driverStatus: true,
        tripStatus: 'completed',
        canOpen: !isEmpty,
      }),
      show: true,
      canOpen: !isEmpty,
      openDefault: !isEmpty,
      component: <RideDetails trip={trip} />,
    },
    {
      id: 1,
      label: 'Oferta de viaje',
      show: true,
      icon: getIconByStatus({
        clientStatus: Boolean(trip.client_accepted_offer_at),
        driverStatus: Boolean(trip.driver_accepted_offer_at),
        tripStatus: trip.status,
        canOpen: !isEmpty,
      }),
      canOpen: !isEmpty,
      openDefault:
        trip.status === 'offer_accepted' ||
        trip.status === 'waiting_for_offers',
      component: <RideComponent trip={trip} refetch={refetch} />,
    },
    {
      id: 3,
      label: 'Pago inicial 50%',
      show: true,
      icon: getValidatePayments({
        tripStatus: trip.status,
        paymentStatus: validated.length >= 1,
        canOpen:
          (Boolean(trip.driver_accepted_offer_at) &&
            Boolean(trip.client_accepted_offer_at)) ||
          trip.status === 'completed',
      }),
      canOpen:
        (Boolean(trip.driver_accepted_offer_at) &&
          Boolean(trip.client_accepted_offer_at)) ||
        trip.status === 'completed',
      openDefault:
        trip.status === 'pending_first_step' ||
        trip.status === 'in_validation_first_step' ||
        trip.status === 'failed_first_step',
      component: (
        <Payment
          trip={trip}
          refetch={refetch}
          payment="initial"
          label="Pago inicial 50%"
          isDriverMoral={isDriverMoral}
          isClient={isClient}
        />
      ),
    },
    {
      id: 2,
      label: 'Asignar viaje',
      icon:
        trip.driver_id || trip.status === 'completed'
          ? 'check-circle'
          : 'credit-card-outline',
      canOpen: validated.length >= 1 || trip.status === 'completed',
      show:
        (isDriverMoral || isDriverPhysicalAssociated) &&
        trip?.status === 'pending_start',
      openDefault:
        trip.status === 'pending_start' ||
        trip.status === 'in_validation_first_step',
      component: <AssignComponent trip={trip} />,
    },
    {
      id: 4,
      label: 'Iniciar Viaje',
      show: true,
      icon: getIconByStatus({
        driverStatus: Boolean(trip.driver_start_accepted_at),
        clientStatus: Boolean(trip.client_start_accepted_at),
        tripStatus: trip.status,
        canOpen: validated.length >= 1 || trip.status === 'completed',
      }),
      canOpen: validated.length >= 1 || trip.status === 'completed',
      openDefault: trip.status === 'pending_start',
      component: <RidesTrack trip={trip} ride="start" refetch={refetch} />,
    },
    {
      id: 5,
      label: 'Viaje en Progreso',
      show: true,
      icon: getIconByStatus({
        clientStatus: Boolean(trip.client_start_accepted_at),
        driverStatus: Boolean(trip.driver_start_accepted_at),
        tripStatus: trip.status,
        canOpen: openInProgress || trip.status === 'completed',
      }),
      canOpen: openInProgress || trip.status === 'completed',
      openDefault: false,
      component: <RidesTrack trip={trip} ride="inProgress" />,
    },
    {
      id: 6,
      label: 'Finalizar Viaje',
      show: true,
      icon: getIconByStatus({
        clientStatus: Boolean(trip.client_finish_accepted_at),
        driverStatus: Boolean(trip.driver_finish_accepted_at),
        tripStatus: trip.status,
        canOpen: openInProgress || trip.status === 'completed',
      }),
      canOpen: openInProgress || trip.status === 'completed',
      openDefault: trip.status === 'in_progress',
      component: <RidesTrack trip={trip} ride="end" refetch={refetch} />,
    },
    {
      id: 7,
      label: 'Pago Final 50%',
      show: true,
      icon: getValidatePayments({
        tripStatus: trip.status,
        paymentStatus: validated.length === 2,
        canOpen: openEndTrip || trip.status === 'completed',
      }),
      canOpen: openEndTrip || trip.status === 'completed',
      openDefault:
        trip.status === 'pending_last_step' ||
        trip.status === 'in_validation_last_step' ||
        trip.status === 'failed_last_step',
      component: (
        <Payment
          trip={trip}
          refetch={refetch}
          payment="final"
          label="Pago final 50%"
          isDriverMoral={isDriverMoral}
        />
      ),
    },
    {
      id: 8,
      label: 'Review',
      icon: getIconByStatus({
        driverStatus: Boolean(trip.review?.driver_comment),
        clientStatus: Boolean(trip.review?.client_comment),
        tripStatus: trip.status,
        canOpen:
          validated.length === 2 ||
          (sended.length === 1 && validated.length === 1) ||
          trip.status === 'completed',
      }),
      show:
        trip?.status === 'waiting_for_review' || trip.status === 'completed',
      canOpen:
        validated.length === 2 ||
        (sended.length === 1 && validated.length === 1) ||
        trip.status === 'completed',
      openDefault: trip.status === 'waiting_for_review',
      component: (
        <Review trip={trip} refetch={refetch} typeUser={state?.type} />
      ),
    },
  ];

  return { ridesMenu, statusTrip, trip };
}
