import React, { useEffect, useState, useContext } from 'react';
import { useAssets } from 'expo-asset';
import { useRoute } from '@react-navigation/native';
import {
  Box,
  Text,
  ScrollView,
  Heading,
  Flex,
  useTheme,
  Image,
} from 'native-base';
import { DocumentTypes } from 'types/documents';
import useDocuments, { Action } from 'hooks/useDocuments';
import ModalDocument from 'components/modals/ModalDocument';
import useUpload from 'hooks/useUpload';
import Context from 'context/Documents';
import Card from 'components/Card';
import Button from 'components/Button';
import { AuthContext } from 'context/AuthContext';
import useDesignProfile from 'webdesign/useDesignProfile';
import BackWeb from 'components/BackWeb';
import { isWebDesktop } from 'utils/general';

export default function UploadSAT() {
  const { path } = useRoute();
  const { colors } = useTheme();
  const [images] = useAssets([require('assets/images/sat.png')]);
  const { selectedDocument, onSelectedDocument } = useDocuments();
  const { getDocument } = useContext(Context);
  const { state } = useContext(AuthContext);

  const { addDocument, nextDocument, documentUploaded } = useUpload();

  const {
    cardProfilePy,
    containerProfilePx,
    containerProfilePt,
    cardProfilePx,
    alignProfile,
    marginLeftProfile,
    cardBg,
    cardShadow,
  } = useDesignProfile();

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [documentToUpload, setDocumentToUpload] = useState<DocumentTypes>();

  useEffect(() => {
    if (selectedDocument.base64) {
      addDocument({
        base64: selectedDocument?.base64,
        user_id: state.id ?? '',
        type: documentToUpload ?? '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDocument.base64]);

  const handleDocumentToUpload = (type: DocumentTypes) => {
    setOpenModal(!openModal);
    setDocumentToUpload(type);
  };

  const handleSelectedPhoto = (action: Action) => {
    onSelectedDocument({
      action,
      web: { mimeType: 'image/*' },
    });
    setOpenModal(!openModal);
  };

  const handleNextDocument = () => {
    const isUploaded = Boolean(documentUploaded.sat || sat);
    nextDocument({
      isUploaded,
      route: 'sat',
    });
  };

  const sat = getDocument('sat');
  const status = documentUploaded?.sat
    ? documentUploaded.sat.status
    : sat.status;
  const isSat =
    Boolean(documentUploaded?.sat || sat.status) &&
    sat.status !== 'irregularity';

  const isValidated = sat.status === 'validated';

  const hideButton = isSat && sat.status !== 'irregularity';

  return (
    <Box
      flex="1"
      pt={10}
      _web={{
        pt: containerProfilePt,
        px: containerProfilePx,
        justifyContent: 'center',
        alignItems: path && alignProfile,
      }}
    >
      <Box
        flex="1"
        width="100%"
        maxWidth="650px"
        _web={{
          py: cardProfilePy,
          px: cardProfilePx,
          borderRadius: 20,
          bg: cardBg,
          shadow: cardShadow,
          marginLeft: path ? marginLeftProfile : 0,
        }}
      >
        {isWebDesktop && <BackWeb />}
        <ScrollView
          flex="1"
          _contentContainerStyle={{
            px: 4,
          }}
        >
          <Box
            alignItems="flex-start"
            flex="1"
            _web={{
              padding: 4,
            }}
          >
            <Heading fontSize="24" fontWeight="normal" mb="20px">
              Constancia SAT
            </Heading>
            <Text fontSize="14px" color={colors.gray[700]}>
              Toma una foto o sube el documento en (pdf).
            </Text>
            <Flex
              justifyContent="center"
              direction="row"
              alignItems="center"
              height="170px"
              width="100%"
            >
              {images?.map(({ uri, name }) => (
                <Image
                  width="124px"
                  height="75px"
                  alt="sat image"
                  key={name}
                  source={{ uri }}
                />
              ))}
            </Flex>
            <Card
              mb="44px"
              title="Constancia SAT"
              variant="document"
              status={status || 'default'}
              icon={status || 'default'}
            >
              <Text fontSize="14px" color={colors.gray[700]}>
                Cuida que en el documento se lea la información de forma clara.
              </Text>
              <Flex
                justifyContent="flex-end"
                alignItems="center"
                direction="row"
              >
                <Button
                  py={2}
                  hide={hideButton}
                  onPress={() => handleDocumentToUpload('sat')}
                >
                  Tomar foto
                </Button>
                {hideButton && !isValidated && (
                  <Text color="secondary.800">
                    Estamos validando tu información
                  </Text>
                )}
              </Flex>
            </Card>
          </Box>
        </ScrollView>
        <Button
          rounded="0"
          height="64px"
          button="primary"
          variant="solid"
          onPress={handleNextDocument}
        >
          Continuar
        </Button>
        <ModalDocument
          open={openModal}
          toggle={() => setOpenModal(!openModal)}
          onSelectDocument={handleSelectedPhoto}
        />
      </Box>
    </Box>
  );
}
