import { add, differenceInSeconds, parseISO } from 'date-fns';
import { Offer } from 'types/trips';
import { formatCurrency } from './formats';
import { isEmptyArray } from './validators';

const getTimeElapsed = (time: number): boolean => {
  return time < 0;
};

export const getTotalAmount = (amount: string, service: number): string => {
  const total = Number(amount) + Number(service);
  return formatCurrency(total);
};

export const getBestOfferAmount = (offers: Offer[]) => {
  if (isEmptyArray(offers)) {
    return '0';
  }
  return offers?.find((item: Offer) => item.is_best)?.amount || '';
};

export const getBestOffer = (offers: Offer[]): boolean => {
  return isEmptyArray(offers) || offers?.some((offer: Offer) => offer.is_best);
};

export const getHalfPayment = (amount: string, service: number) => {
  const percentage = 50;
  const total = Number(amount) + Number(service);
  return (percentage / 100) * total;
};
export const getDeadLineDays = (timeZoneDateParsed: Date, days?: number) => {
  return add(timeZoneDateParsed, {
    days: 1,
  });
};
export const getDiffInSeconds = (deadLinePlus24: Date) => {
  return differenceInSeconds(deadLinePlus24, new Date().getTime());
};
export const getTimeOfferOver = (tripInsertedAt: string, days?: number) => {
  const parsedDate = parseISO(tripInsertedAt);
  const timeDeadline = getDeadLineDays(parsedDate, days);
  const timeElapsed = getDiffInSeconds(timeDeadline);
  const isTimeOver = getTimeElapsed(timeElapsed);
  return isTimeOver;
};
export const getTimeLeft = (tripInsertedAt: string, days?: number) => {
  const parsedDate = parseISO(`${tripInsertedAt}Z`);
  const timeDeadline = getDeadLineDays(parsedDate, days);

  return { diffInSeconds: getDiffInSeconds(timeDeadline) };
};

export const getDateFilter = ({
  date,
  hours,
  minutes,
}: {
  date: string | any;
  hours: string;
  minutes: string;
}) => {
  const [day, month, year] = date.split('/');

  const dateWeb = new Date(
    Number(year), // year
    Number(month) - 1, // month
    Number(day), // day
    Number(hours),
    Number(minutes),
  );
  return new Date(dateWeb).toISOString();
};
