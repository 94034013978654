import React, { createContext, useState } from 'react';

import { Documents, DocumentTypes } from 'types/documents';
import { Document } from 'types/catalogs';

export const Context = createContext<{
  getDocument: (doc: DocumentTypes) => Documents;
  setDocuments: React.Dispatch<React.SetStateAction<Documents[]>>;
  updateDocumentRequired: (arg: Document[]) => Document[];
  addDocumentRequired: (arg: Document[]) => Document[];
  documents: Documents[];
  documentsRequired: Document[];
}>({
  getDocument: () => ({} as Documents),
  setDocuments: () => [] as Documents[],
  updateDocumentRequired: () => [] as Document[],
  addDocumentRequired: () => [] as Document[],
  documents: [] as Documents[],
  documentsRequired: [] as Document[],
});

export function DocumentsProvider({ children }: { children: React.ReactNode }) {
  const [documents, setDocuments] = useState<Documents[]>([] as Documents[]);
  const [documentsRequired, setDocumentsRequired] = useState<Document[]>(
    [] as Document[],
  );

  const updateDocumentRequired = (docs: Document[]): Document[] => {
    setDocumentsRequired(docs);
    return docs;
  };

  const addDocumentRequired = (docs: Document[]): Document[] => {
    setDocumentsRequired(docs);
    return docs;
  };

  const getDocument = (doc: DocumentTypes): Documents => {
    const filteredDocument =
      documents.find(item => item.type === doc) || ({} as Documents);
    return filteredDocument;
  };

  return (
    <Context.Provider
      value={{
        getDocument,
        setDocuments,
        updateDocumentRequired,
        addDocumentRequired,
        documentsRequired,
        documents,
      }}
    >
      {children}
    </Context.Provider>
  );
}
export default Context;
