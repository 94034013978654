import React, { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Center,
  Image,
  Heading,
  ScrollView,
  HStack,
  Icon,
  useTheme,
} from 'native-base';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import useDocuments, { Action } from 'hooks/useDocuments';
import { getStepData, getUriPicture } from 'utils/addressUtil';
import Button from 'components/Button';
import FloatingInputWithElements from 'components/FloatingInputWithElements';
import FloatingInput from 'components/FloatingInput';
import ModalPackage from 'components/modals/ModalPackage';
import LabelPressable from 'components/LabelPressable';
import { PageProductTypeProps, ProductTypeProps } from 'types/address';

export default function ProductType({
  submitForm,
  prevStep,
}: PageProductTypeProps) {
  const { colors } = useTheme();
  const { selectedDocument, onSelectedDocument } = useDocuments();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [stepValues, setStepValues] = useState<ProductTypeProps>({
    weight: '',
    volume: '',
    description: '',
    picturePackage: '',
  });
  const [picture, setPicture] = useState<string>('');
  const ProductTypeSchema = Yup.object()
    .shape({
      weight: Yup.string().required('El campo es requerido'),
      volume: Yup.string().required('El campo es requerido'),
      description: Yup.string().required('El campo es requerido'),
    })
    .when((values, schema) => {
      if (!picture) {
        return schema.shape({
          picturePackage: Yup.string().required('El campo es requerido'),
        });
      }
      return schema.shape({
        picturePackage: Yup.string().notRequired(),
      });
    });
  useEffect(() => {
    handleGetStoragePackagePicture();
  }, []);

  useEffect(() => {
    if (selectedDocument) {
      setPicture(selectedDocument?.uri);
    }
  }, [selectedDocument]);

  const handleGetStoragePackagePicture = async () => {
    const stepData: ProductTypeProps = await getStepData('step2');
    if (!stepData) {
      return;
    }
    const {
      weight = '',
      volume = '',
      description = '',
      picturePackage = '',
    } = stepData;
    const uriPicture = await getUriPicture(picturePackage);
    if (uriPicture) {
      setStepValues({
        weight,
        volume,
        description,
        picturePackage: uriPicture,
      });
      setPicture(uriPicture);
    }
  };

  const handleTakePhoto = () => {
    setOpenModal(!openModal);
  };

  const handleSelectedPhoto = (action: Action) => {
    onSelectedDocument({
      action,
      web: { mimeType: 'image/*' },
    });
    setOpenModal(!openModal);
  };
  return (
    <>
      <Box flex="1">
        <ScrollView flex="1">
          <Heading
            fontSize="2xl"
            fontWeight="normal"
            mb={8}
            color={colors.gray[700]}
          >
            Describe el tipo de producto
          </Heading>
          <Formik
            validationSchema={ProductTypeSchema}
            initialValues={stepValues}
            onSubmit={(values: ProductTypeProps) => {
              submitForm({ ...values, picturePackage: picture });
            }}
            enableReinitialize
          >
            {({ handleSubmit, handleChange, values }) => (
              <Box flex="1" mb={8}>
                <Box justifyContent="space-between">
                  <FloatingInputWithElements
                    mb={8}
                    name="weight"
                    label="Peso"
                    onChangeText={handleChange('weight')}
                    rightElement="ton"
                    value={values.weight}
                    maxLength={5}
                    inputProps={{ keyboardType: 'numeric', maxLength: 5 }}
                    leftElement={
                      <Icon
                        name="weight"
                        ml={2}
                        size={6}
                        color={colors.gray[600]}
                        as={MaterialCommunityIcons}
                      />
                    }
                  />
                  <FloatingInputWithElements
                    mb={8}
                    name="volume"
                    label="Volúmen"
                    onChangeText={handleChange('volume')}
                    rightElement="m3"
                    value={values.volume}
                    maxLength={5}
                    inputProps={{ keyboardType: 'numeric', maxLength: 5 }}
                    leftElement={
                      <Icon
                        ml={2}
                        size={6}
                        color={colors.gray[600]}
                        name="package-variant-closed"
                        as={MaterialCommunityIcons}
                      />
                    }
                  />
                  <FloatingInput
                    name="description"
                    label="Descripción"
                    onChangeText={handleChange('description')}
                    value={values.description}
                  />
                  {picture ? (
                    <Center flex="1" w="100%" mb={10}>
                      <Image
                        w="full"
                        h="250px"
                        source={{
                          uri: picture,
                        }}
                        alt="Package picture"
                      />
                    </Center>
                  ) : (
                    <LabelPressable
                      text="Agregar foto del producto"
                      icon="photo-camera"
                      onLabelPress={handleTakePhoto}
                      iconRounded={false}
                      value={picture}
                      name="picturePackage"
                      mb={8}
                      iconProps={{
                        color: 'primary.900',
                      }}
                      mandatory
                    />
                  )}
                </Box>
                <HStack justifyContent="space-around">
                  <Button minWidth="45%" button="ghost" onPress={prevStep}>
                    Regresar
                  </Button>
                  <Button minWidth="45%" onPress={() => handleSubmit()}>
                    Continuar
                  </Button>
                </HStack>
              </Box>
            )}
          </Formik>
        </ScrollView>
      </Box>
      <ModalPackage
        open={openModal}
        showUploadMobileFile={Platform.OS !== 'web'}
        onSelectDocument={handleSelectedPhoto}
      />
    </>
  );
}
