import React from 'react';
import { useAssets } from 'expo-asset';
import { useRoute } from '@react-navigation/native';
import {
  Box,
  Text,
  ScrollView,
  Heading,
  Flex,
  useTheme,
  Image,
} from 'native-base';
import { GestureResponderEvent } from 'react-native';
import Card, { icons, borderColor } from 'components/Card';
import Button from 'components/Button';
import useDesignProfile from 'webdesign/useDesignProfile';
import BackWeb from 'components/BackWeb';
import { isWebDesktop } from 'utils/general';

interface UploadPdfDocumentProps {
  heading: string;
  description: string;
  cardDescription: string;
  cardTitle: string;
  fileName?: string;
  hideButton?: boolean;
  textButton?: string;
  showMessage?: boolean;
  onNextDocument: null | ((event: GestureResponderEvent) => void);
  onPress: null | ((event: GestureResponderEvent) => void);
  cardIcon?: keyof typeof icons | string;
  cardStatus?: keyof typeof borderColor;
}

export default function UploadPdfDocument({
  heading,
  description,
  cardDescription,
  cardTitle,
  onPress,
  onNextDocument,
  showMessage,
  hideButton,
  fileName,
  textButton = 'Tomar foto',
  cardStatus = 'default',
  cardIcon = 'camera',
}: UploadPdfDocumentProps) {
  const { path } = useRoute();
  const { colors } = useTheme();
  const {
    cardProfilePy,
    containerProfilePx,
    containerProfilePt,
    cardProfilePx,
    alignProfile,
    marginLeftProfile,
    cardBg,
    cardShadow,
  } = useDesignProfile();

  const [images] = useAssets([require('assets/images/account.png')]);
  return (
    <Box
      flex="1"
      pt={10}
      _web={{
        pt: containerProfilePt,
        px: containerProfilePx,
        justifyContent: 'center',
        alignItems: path && alignProfile,
      }}
    >
      <Box
        flex="1"
        width="100%"
        maxWidth="650px"
        _web={{
          py: cardProfilePy,
          px: cardProfilePx,
          borderRadius: 20,
          bg: cardBg,
          shadow: cardShadow,
          marginLeft: path ? marginLeftProfile : 0,
        }}
      >
        {isWebDesktop && <BackWeb />}
        <ScrollView
          flex="1"
          _contentContainerStyle={{
            px: 4,
          }}
        >
          <Box
            alignItems="flex-start"
            flex="1"
            _web={{
              padding: 4,
            }}
          >
            <Heading fontSize="24" fontWeight="normal" mb="20px">
              {heading}
            </Heading>
            <Text fontSize="14px" color={colors.gray[700]}>
              {description}
            </Text>
            <Flex
              justifyContent="center"
              direction="row"
              alignItems="center"
              height="170px"
              width="100%"
            >
              {images?.map(({ uri, name }) => (
                <Image
                  width="64px"
                  height="78px"
                  alt="account image"
                  key={name}
                  source={{ uri }}
                />
              ))}
            </Flex>
            <Card
              mb="44px"
              variant="document"
              title={cardTitle}
              status={cardStatus}
              icon={cardIcon}
            >
              <Text fontSize="14px" color={colors.gray[700]}>
                {cardDescription}
              </Text>
              <Flex
                alignItems="center"
                direction="row"
                justifyContent={fileName ? 'space-between' : 'flex-end'}
              >
                {fileName && <Text color="secondary.800">{fileName}</Text>}
                <Button py={2} hide={hideButton} onPress={onPress}>
                  {textButton}
                </Button>
              </Flex>
              {showMessage && (
                <Text color="secondary.800">
                  Estamos validando tu información
                </Text>
              )}
            </Card>
          </Box>
        </ScrollView>
        <Button
          rounded="0"
          height="64px"
          button="primary"
          variant="solid"
          onPress={onNextDocument}
        >
          Continuar
        </Button>
      </Box>
    </Box>
  );
}
