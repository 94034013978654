import React from 'react';
import AppLoading from 'expo-app-loading';
import { StyleSheet, View, StatusBar } from 'react-native';
import { NativeBaseProvider } from 'native-base';
import { SafeAreaProvider, SafeAreaView } from 'react-native-safe-area-context';
import { LinkingOptions, NavigationContainer } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import { QueryClient, QueryClientProvider } from 'react-query';

import { theme } from 'constants/theme';
import { RootStackParamList } from 'types/routes';
import { DocumentsProvider } from 'context/Documents';
import { AuthProvider } from 'context/AuthContext';
import NotificationProvider from 'context/NotificationContext';
import { VehicleProvider } from 'context/Vehicles';
import {
  useFonts,
  Rubik_300Light,
  Rubik_400Regular,
  Rubik_700Bold,
  Rubik_500Medium,
} from '@expo-google-fonts/rubik';
import MainLoadingScreen from 'navigation/MainLoadingScreen';
import 'react-native-gesture-handler';
import { ProgressProvider } from 'context/ProgressContext';
import Progress from 'components/Progress';

const prefix = Linking.createURL('/');
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 0,
    },
  },
});

export default function App() {
  const [fontsLoaded] = useFonts({
    Rubik_300Light,
    Rubik_400Regular,
    Rubik_700Bold,
    Rubik_500Medium,
  });

  const linking: LinkingOptions<RootStackParamList> = {
    prefixes: [prefix],
    config: {
      initialRouteName: 'PublicStack',
      /**
       * Translate app routes to web routes
       * @see https://reactnavigation.org/docs/configuring-links/
       */
      screens: {
        PublicStack: 'PublicStack',
        IncompleteProfileStack: 'IncompleteProfileStack',
        AuthStack: 'AuthStack',
        Login: 'login',
        ProfileType: 'user/signup',
        Profile: 'profile',
        Help: 'help',
        Chat: 'chat',
        About: 'about',
        Document: 'document',
        Terms: 'terms',
        Transportation: 'transportation',
        Myhistory: 'Myhistory',
        TransportationAdd: 'transportation/add',
        Registration: 'signup',
        ValidationNIP: 'validation',
        ResetPasswordByEmail: 'resetpasswordbyemail',
        RidesHistory: 'travel',
        Home: 'home',
        Notifications: 'Notifications',
        Subordinates: 'subordinates',
        ResetPassword: 'reset/password/:token',
        Privacy: 'privacy',
      },
    },
  };
  if (!fontsLoaded) {
    return <AppLoading />;
  }
  return (
    <QueryClientProvider client={queryClient}>
      <NativeBaseProvider theme={theme}>
        <SafeAreaProvider>
          <NavigationContainer linking={linking} fallback={<AppLoading />}>
            <SafeAreaView
              style={{ flex: 1 }}
              edges={['top', 'left', 'right', 'bottom']}
            >
              <ProgressProvider>
                <AuthProvider>
                  <NotificationProvider>
                    <View style={styles.container}>
                      <StatusBar barStyle="dark-content" />
                      <DocumentsProvider>
                        <VehicleProvider>
                          <MainLoadingScreen />
                        </VehicleProvider>
                      </DocumentsProvider>
                    </View>
                    <Progress />
                  </NotificationProvider>
                </AuthProvider>
              </ProgressProvider>
            </SafeAreaView>
          </NavigationContainer>
        </SafeAreaProvider>
      </NativeBaseProvider>
    </QueryClientProvider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#ffffff',
  },
});
