import React, { useState } from 'react';

import {
  Box,
  Text,
  Heading,
  useTheme,
  HStack,
  VStack,
  Input,
  Stack,
  ScrollView,
  Toast,
} from 'native-base';
import { useNavigation, useRoute } from '@react-navigation/native';
import { AxiosError } from 'axios';

import useTrip from 'hooks/useTrip';
import Button from 'components/Button';
import ModalOffer from 'components/modals/ModalOffer';
import { useMutation } from 'react-query';
import { CreateOffer } from 'api';
import { getItemStorage } from 'utils';
import { isEmptyObject } from 'utils/validators';
import { formatCurrency } from 'utils/formats';
import { CreateOfferResponseProps } from 'types/offers';
import { Offer } from 'types/trips';

export default function MakeOffer() {
  const navigation = useNavigation();
  const route = useRoute();
  const { colors } = useTheme();
  const { tripId } = route.params as { tripId: string };
  const { trip } = useTrip(tripId);

  const [value, setValue] = useState<string>('');
  const [openModal, setOpenModal] = useState(false);
  const { mutate, isLoading } = useMutation(CreateOffer, {
    onSuccess: (data: CreateOfferResponseProps) => {
      if (!isEmptyObject(data)) {
        navigation.goBack();
      }
    },
    onError: (error: AxiosError<any, string>) => {
      Toast.show({
        title: `Error: ${error.response?.data.errors.detail}`,
        placement: 'top',
      });
    },
  });

  const handleToggleModal = () => {
    const message = 'Debes realizar una oferta';
    
      if (!value) {
      Toast.show({
        title: message,
        placement: 'top',
      });
      return;
    }
    setOpenModal(!openModal);
  };

  const hanldeChangeValue = (inputValue: string) => {
    setValue(inputValue);
  };

  const handleNewOffer = async () => {
    const { id } = JSON.parse(await getItemStorage('session')) || {};
    mutate({
      offer: {
        amount: Number(value),
        trip_id: tripId,
        user_id: id,
      },
    });
  };

  const bestOffer = trip.offers.find(offer => offer.is_best) || ({} as Offer);

  return (
    <Box flex="1">
      <ScrollView _contentContainerStyle={{ pt: 12, px: 10 }}>
        <Heading
          fontSize="24"
          color={colors.gray[700]}
          fontWeight="normal"
          mb={12}
        >
          Ingresa la cantidad que quieres ofertar
        </Heading>
        <HStack justifyContent="space-between" alignItems="center">
          <Stack
            w={{
              base: '100%',
            }}
            flexDirection="row"
            alignItems="center"
          >
            <Text fontSize="4xl" color="gray.800">
              $
            </Text>
            <Input
              flex="1"
              keyboardType="numeric"
              placeholder="0"
              fontSize="4xl"
              mx={2}
              fontWeight="medium"
              borderWidth={0}
              shadow={0}
              onChangeText={hanldeChangeValue}
              _web={{ shadow: 'none' }}
              _android={{
                shadow: -1,
                borderBottomWidth: 0,
              }}
              style={{
                elevation: 0,
                shadowOpacity: 0,
                shadowOffset: { width: 0, height: 0 },
              }}
            />
            <Text fontSize="2xl" color={colors.gray[800]}>
              MXN
            </Text>
          </Stack>
        </HStack>
        <VStack mt={2}>
          <Text fontSize="16px" color={colors.gray[500]}>
            Presupuesto del viaje: {formatCurrency(Number(trip.budget))}
          </Text>
          <Text fontSize="16px" color={colors.gray[500]}>
            {bestOffer.amount
              ? `Última oferta recibida: ${formatCurrency(
                  (Number(bestOffer.amount)/1.1),
                )}`
              : 'No hay ofertas'}
          </Text>
          <Text fontSize="16px" color={colors.gray[500]}>
          </Text>
        </VStack>
      </ScrollView>
      <Button
        rounded="0"
        height="64px"
        button="primary"
        variant="solid"
        onPress={handleToggleModal}
      >
        Ofertar
      </Button>
      <ModalOffer
        isOpen={openModal}
        value={value}
        toggleModal={handleToggleModal}
        onNewOffer={handleNewOffer}
        isLoading={isLoading}
      />
    </Box>
  );
}
