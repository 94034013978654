import React, { useState, useEffect, useContext } from 'react';

import UploadPdfDocument from 'screens/UploadPdfDocument';
import useDocuments, { Action } from 'hooks/useDocuments';
import useUpload from 'hooks/useUpload';
import Context from 'context/Documents';
import ModalDocument from 'components/modals/ModalDocument';
import { DocumentTypes } from 'types/documents';
import { AuthContext } from 'context/AuthContext';

export default function UploadPowerAttorney() {
  const { selectedDocument, onSelectedDocument } = useDocuments();
  const { getDocument } = useContext(Context);
  const { state } = useContext(AuthContext);

  const { addDocument, nextDocument, documentUploaded } = useUpload();

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [documentToUpload, setDocumentToUpload] = useState<DocumentTypes>();

  useEffect(() => {
    if (selectedDocument.base64) {
      addDocument({
        base64: selectedDocument?.base64,
        user_id: state.id ?? '',
        type: documentToUpload ?? '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDocument.base64]);

  const handleDocumentToUpload = (type: DocumentTypes) => {
    setOpenModal(!openModal);
    setDocumentToUpload(type);
  };

  const handleSelectedPhoto = (action: Action) => {
    onSelectedDocument({
      action,
      web: { mimeType: '/*' },
    });
    setOpenModal(!openModal);
  };

  const handleNextDocument = () => {
    const isUploaded = Boolean(
      documentUploaded.power_of_attorney || powerOfAttorney,
    );
    nextDocument({
      isUploaded,
      route: 'power_attorney',
    });
  };

  const powerOfAttorney = getDocument('power_of_attorney');
  const status = documentUploaded?.power_of_attorney
    ? documentUploaded.power_of_attorney.status
    : powerOfAttorney.status;

  const isPowerOfAttorney =
    Boolean(documentUploaded?.power_of_attorney || powerOfAttorney.status) &&
    powerOfAttorney.status !== 'irregularity';

  const isValidated = powerOfAttorney.status === 'validated';

  return (
    <>
      <UploadPdfDocument
        heading="Poder notarial"
        cardStatus={status || 'default'}
        cardIcon={status || 'default'}
        cardTitle="Poder notarial"
        description="Toma una foto o sube el documento en (pdf)."
        cardDescription="Cuida que en el documento se lea la información de forma clara."
        showMessage={isPowerOfAttorney && !isValidated}
        hideButton={isPowerOfAttorney}
        onNextDocument={handleNextDocument}
        onPress={() => handleDocumentToUpload('power_of_attorney')}
      />
      <ModalDocument
        open={openModal}
        toggle={() => setOpenModal(!openModal)}
        onSelectDocument={handleSelectedPhoto}
      />
    </>
  );
}
