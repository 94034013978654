import React, { useContext } from 'react';
import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import { useNavigation, CommonActions } from '@react-navigation/native';

import { Box, Text, HStack, VStack } from 'native-base';
import { RideStatus } from 'types/ride';
import { TripProps } from 'types/trips';
import { formatCurrency, formatDateTimeCard } from 'utils/formats';
import { statusMap } from 'constants/rides';
import { AuthContext } from 'context/AuthContext';
import { colors } from '../constants/colors';
import Button from './Button';
import { getBestOffer } from 'utils/rides';

export const statusColor: { [k in Partial<RideStatus>]?: string } = {
  completed: colors.gray[800],
  cancelled: colors.gray[500],
  in_progress: colors.secondary[900],
  waiting_for_offers: colors.warning[900],
  offer_accepted: colors.success[900],
  default: colors.gray[800],
  pending_last_step: colors.primary[900],
  pending_first_step: colors.primary[900],
  failed_first_step: colors.error[900],
  failed_last_step: colors.error[900],
  in_validation_first_step: colors.secondary[900],
  in_validation_last_step: colors.secondary[900],
  waiting_for_review: colors.warning[900],
  pending_start: colors.success[900],
};

export default function RideCard({
  status = 'default',
  start_at,
  start_address,
  end_address,
  budget,
  offers,
  id,
}: TripProps) {
  const navigation = useNavigation();
  const { state: userProfile } = useContext(AuthContext);
  const isClient = userProfile?.type === 'client';
  let message = null;
  let bg = null;

  const newStatusColors = {
    ...statusColor,
    failed_first_step: isClient ? colors.error[900] : colors.gray[500],
    failed_last_step: isClient ? colors.error[900] : colors.gray[500],
  };

  const newStatusMap = {
    ...statusMap,
    failed_first_step: isClient ? 'Pago rechazado' : 'En espera de pago',
    failed_last_step: isClient ? 'Pago rechazado' : 'En espera de pago',
  };

  const myOffers = offers.filter(
    offer => offer.user_id === userProfile.id,
  );
 
  const existsBestOffer = getBestOffer(myOffers);
  
  if (status === 'waiting_for_offers') {
    message = newStatusMap[status]
    bg = statusColor[status]
  } 

  if (!existsBestOffer && status !== 'waiting_for_offers') {
    message = 'No aceptada'
    bg = colors.gray[800]
  } 

  const labelStatus = existsBestOffer ? newStatusMap[status] : message
  const borderColor = existsBestOffer ? newStatusColors[status] : bg

  const handleDetails = () => {
    navigation.dispatch(
      CommonActions.navigate({
        name: 'Details',
        path: 'Details',
        params: {
          rideId: id,
        },
      }),
    );
  };

  return (
    <Box
      rounded="lg"
      shadow={3}
      px={3}
      pb={4}
      bg="gray.100"
      borderRadius={8}
      borderLeftWidth={8}
      borderLeftColor={borderColor}
      width="full"
      _web={{
        width: '100%',
      }}
      _ios={{
        borderRadius: 8,
        shadowColor: 'black',
        shadowOffset: {
          width: 0,
          height: 2,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
      }}
    >
      <HStack justifyContent="space-between" pb={3}>
        <Text pt={3} fontSize="xs" color={colors.gray[600]}>
          {formatDateTimeCard(start_at)}
        </Text>
        <Box
          height="32px"
          bg={borderColor}
          borderBottomLeftRadius={8}
          borderBottomRightRadius={8}
        >
          <Text
            px={4}
            fontSize="sm"
            fontWeight="medium"
            color={colors.gray[100]}
            lineHeight="32px"
          >
            {labelStatus}
          </Text>
        </Box>
      </HStack>
      <HStack>
        <VStack width="60%">
          <HStack mb={4}>
            <MaterialCommunityIcons
              name="truck"
              size={24}
              color={colors.gray[500]}
            />
            <VStack>
              <Text ml={2} color={colors.gray[700]} fontWeight="medium">
                {start_address?.state ? start_address.state : ''}
              </Text>
              <Text ml={2} color={colors.gray[700]}>
                {`${start_address?.street} ${start_address?.ext_number}`}
              </Text>
            </VStack>
          </HStack>
          <HStack mb={4}>
            <MaterialIcons
              name="location-pin"
              size={24}
              color={colors.gray[500]}
            />
            <VStack>
              <Text ml={2} color={colors.gray[700]} fontWeight="medium">
                {end_address?.state}
              </Text>
              <Text ml={2} color={colors.gray[700]} isTruncated>
                {`${end_address?.street} ${end_address?.ext_number}`}
              </Text>
            </VStack>
          </HStack>
        </VStack>
        <VStack width="40%">
          <Box alignSelf="flex-end" mb={2}>
            <Text alignSelf="flex-end" fontSize="12px" color={colors.gray[600]}>
              Presupuesto
            </Text>
            <Text fontSize="24px" color="black">
              {formatCurrency(Number(budget))}
            </Text>
          </Box>
          <Button
            py={3}
            px={5}
            button="primary"
            alignSelf="flex-end"
            onPress={handleDetails}
          >
            Ver detalle
          </Button>
        </VStack>
      </HStack>
    </Box>
  );
}
