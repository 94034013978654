import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
} from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Notifications from 'expo-notifications';
import { useTheme, useToast } from 'native-base';
import { Platform, AppState } from 'react-native';

import useFirebase from '../webconfig/useFirebase';
import { ProgressContext } from './ProgressContext';

interface NotificationProps extends Notifications.Notification {
  opened?: boolean;
  tapNotication?: boolean;
}

export const NotificationContext = createContext<{
  notifications: NotificationProps[];
  setLocalStorageNotifications: (notifications: NotificationProps[]) => void;
  setLocalOnTapNotication: (
    notifications: Notifications.Notification,
    arg?: boolean,
  ) => void;
  setNotificationOpened: (identifier: string) => void;
  removeLocalNotifications: (identifier: string) => void;
  getNotifications: () => void;
}>({
  notifications: [] as NotificationProps[],
  setLocalStorageNotifications: () => ({} as NotificationProps),
  setLocalOnTapNotication: () => ({} as NotificationProps),
  removeLocalNotifications: () => ({} as NotificationProps),
  setNotificationOpened: () => ({} as NotificationProps),
  getNotifications: () => [] as NotificationProps[],
});

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
});

export default function NotificationProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { colors } = useTheme();
  const toast = useToast();
  const appState = useRef(AppState.currentState);
  const { onShowProgress, onHideProgress } = useContext(ProgressContext);
  const [globalNotifications, setGlobalNotifications] = useState<
    NotificationProps[]
  >([]);

  const { onMessageListener } = useFirebase();
  const primaryColors = colors.primary as typeof colors['gray'];

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (Platform.OS !== 'web') {
      const subscription = AppState.addEventListener('change', nextAppState => {
        if (
          appState.current.match(/inactive|background/) &&
          nextAppState === 'active'
        ) {
          getNotifications();
        }
        appState.current = nextAppState;
      });
      return () => {
        subscription.remove();
      };
    }
  }, []);

  useEffect(() => {
    if (Platform.OS !== 'web') {
      getNotifications();
    }
    const onNotificationListener = onMessageListener()
      .then(payload => {
        console.log('>>>>>>>>>>>payload', payload);
        toast.show({
          title: payload.notification.title,
          placement: 'top',
        });
      })
      .catch(err => console.log('failed: ', err));
    return () => {
      onNotificationListener.remove();
    };
  }, []);

  const getNotifications = async () => {
    onShowProgress({
      show: true,
      opacity: 0.8,
      bg: colors.gray[800],
      colorSpinner: primaryColors[900],
    });
    const localNotifications = await getLocalStorageNotifications();
    const filteredTapNotifications = localNotifications.filter(
      item => item.tapNotication,
    );
    Notifications.getPresentedNotificationsAsync()
      .then(allNotificactions => {
        setLocalStorageNotifications(
          allNotificactions.concat(filteredTapNotifications),
        );
      })
      .finally(() => onHideProgress());
  };

  const setLocalNotifications = async (notifications: NotificationProps[]) => {
    const notificationsToSet = notifications.map(item => ({
      ...item,
      opened: item.opened || false,
    }));
    try {
      await AsyncStorage.setItem(
        'notifications',
        JSON.stringify(notificationsToSet),
      );
      setGlobalNotifications(notificationsToSet);
    } catch (error) {
      console.error('error', error);
    }
  };

  const getLocalStorageNotifications = async () => {
    const localStorageNotifications =
      (await AsyncStorage.getItem('notifications')) || '';
    const parseNotifications: NotificationProps[] = localStorageNotifications
      ? JSON.parse(localStorageNotifications)
      : [];
    return parseNotifications;
  };

  const setLocalStorageNotifications = async (
    notifications: NotificationProps[],
  ) => {
    const localNotifications = await getLocalStorageNotifications();
    const notificationsToSet = notifications.map(notification => {
      return {
        ...notification,
        opened:
          localNotifications.find(
            item => item.request.identifier === notification.request.identifier,
          )?.opened || false,
      };
    });
    const hasOpened = notificationsToSet.some(item => item.opened);
    setLocalNotifications(hasOpened ? notificationsToSet : notifications);
  };

  const setNotificationOpened = async (identifier: string) => {
    const localNotifications = await getLocalStorageNotifications();
    const filteredNotifications = localNotifications.map(notification => {
      if (notification.request.identifier === identifier) {
        return { ...notification, opened: true };
      }
      return notification;
    });
    setLocalStorageNotifications(filteredNotifications);
  };

  const removeLocalNotifications = async (identifier: string) => {
    const localNotifications = await getLocalStorageNotifications();
    const filteredNotifications = localNotifications.filter(
      notification => notification.request.identifier !== identifier,
    );
    setLocalStorageNotifications(filteredNotifications);
  };

  const setLocalOnTapNotication = async (
    notifications: Notifications.Notification,
    backgroundNoptification?: boolean,
  ) => {
    const localNotifications = await getLocalStorageNotifications();
    const tapNotification = !backgroundNoptification;
    const filteredNotification = [
      { ...notifications, opened: false, tapNotication: tapNotification },
    ];
    setLocalNotifications(localNotifications.concat(filteredNotification));
  };

  return (
    <NotificationContext.Provider
      value={{
        getNotifications,
        setNotificationOpened,
        removeLocalNotifications,
        setLocalStorageNotifications,
        notifications: globalNotifications,
        setLocalOnTapNotication,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
}
