import React, { useContext } from 'react';
import {
  Text,
  Heading,
  useTheme,
  Modal,
  Center,
  Toast,
  HStack,
  Box,
} from 'native-base';
import { useMutation, useQuery } from 'react-query';

import { AuthContext } from 'context/AuthContext';
import { CreateUserVehicle, GetUserVehicles } from 'api';
import { Vehicle } from 'types/trips';
import { CreateTripResponse, UserVehiclesProps } from 'types/transportation';
import Button from 'components/Button';

export default function ModalAddVehicle({
  isOpen,
  toggleModal,
  vehicleTypeRequired,
  vehicle,
  charge,
}: {
  isOpen: boolean;
  vehicle: Vehicle;
  charge: string;
  toggleModal: () => void;
  vehicleTypeRequired: Vehicle['vehicle_type'];
}) {
  const { colors } = useTheme();
  const { state: userProfile, updateProfile } = useContext(AuthContext);

  const { refetch: refetchVehicles } = useQuery(
    'userVehicles',
    () => GetUserVehicles(userProfile.id),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: (vehicles: UserVehiclesProps[]) => {
        updateProfile(vehicles);
      },
      onError: (error) => console.error(error)
    },
  );

  const { mutate, isLoading } = useMutation(CreateUserVehicle, {
    onSuccess: (data: CreateTripResponse) => {
      if (data) {
        Toast.show({
          title: `Vehículo añadido correctamente a tu catálogo.`,
          placement: 'top',
        });
        toggleModal();
        refetchVehicles();
      }
    },
    onError: () => {
      Toast.show({
        title: 'Hubo un error, intenta nuevamente.',
        placement: 'top',
      });
      toggleModal();
    },
  });

  const handleSaveVehicles = () => {
    mutate({
      params: userProfile.id,
      dataToSend: { vehicles: [vehicle.id] },
    });
  };

  return (
    <Modal
      bg="rgba(46, 40, 57, 0.2)"
      isOpen={isOpen}
      size="xl"
      onClose={toggleModal}
    >
      <Modal.Content px={4} py={4} _web={{ shadow: 'none' }}>
        <Heading
          mb={4}
          mt={4}
          fontSize="22px"
          color={colors.gray[700]}
          fontWeight={400}
          textAlign="center"
        >
          Vehículo requerido
        </Heading>
        <Box p={6} rounded="2xl">
          <Box mb={4}>
            <Text fontSize="14px" fontWeight="600" color={colors.gray[800]}>
              {vehicleTypeRequired.name}
            </Text>
            <Text fontSize="12px" fontWeight="300" color={colors.gray[800]}>
              {vehicle.configuration} - {charge}kg
            </Text>
          </Box>
          <Box>
            <Text fontSize="sm" fontWeight={300} mb={2}>
              Dimensiones
            </Text>
            <HStack>
              <Text fontSize="sm" fontWeight={600} mr={4}>
                M3 <Text color={colors.gray[700]}>{vehicle.m3}m</Text>
              </Text>
              <Text fontSize="sm" fontWeight={600}>
                Largo <Text color={colors.gray[700]}>{vehicle.length}m</Text>
              </Text>
            </HStack>
            <HStack>
              <Text fontSize="sm" fontWeight={600} mr={4}>
                Alto <Text color={colors.gray[700]}>{vehicle.height}m</Text>
              </Text>
              <Text fontSize="sm" fontWeight={600}>
                Ancho <Text color={colors.gray[700]}>{vehicle.width}m</Text>
              </Text>
            </HStack>
          </Box>
        </Box>
        <Center mb={12}>
          <Text
            mb={4}
            fontSize="16px"
            color={colors.gray[800]}
            textAlign="center"
            fontWeight="600"
          >
            ¿Deseas añadirlo a tus vehículos?
          </Text>
          <Text fontSize="16px" color={colors.gray[700]} textAlign="center">
            Al aceptar estas confirmando que tienes el vehículo para realizar
            este viaje.
          </Text>
        </Center>
        <HStack justifyContent="space-between">
          <Button button="ghost" minWidth="45%" onPress={toggleModal}>
            Regresar
          </Button>
          <Button
            button="primary"
            minWidth="45%"
            onPress={handleSaveVehicles}
            isDisabled={isLoading}
            isLoading={isLoading}
          >
            Confirmar
          </Button>
        </HStack>
      </Modal.Content>
    </Modal>
  );
}
