import { extendTheme } from 'native-base';

type Components =
  | ReturnType<typeof extendTheme>['components']
  | Record<string, any>;

export const components: Components = {
  Input: {
    baseStyle: ({ theme: { colors } }) => ({
      placeholderTextColor: colors.gray[500],
      color: colors.gray[800],
      _disabled: {
        opacity: 0.5,
        bg: colors.gray[300],
      },
    }),
    defaultProps: {
      variant: 'rounded',
      borderRadius: '20px',
    },
    sizes: {
      sm: {
        fontSize: '16px',
      },
    },
  },
  Link: {
    baseStyle: ({ theme: { colors } }) => ({
      _text: {
        textDecorationLine: 'underline',
        color: colors.gray[800],
        mt: -0.5,
        _web: {
          mb: -2,
        },
      },
    }),
  },
  Button: {
    variants: {
      ghost: ({ theme: { colors } }) => ({
        _pressed: {
          bg: colors.gray[300],
        },
      }),
      link: ({ theme: { colors } }) => ({
        _text: {
          textDecorationLine: 'underline',
          color: colors.gray[800],
        },
        _pressed: {
          _text: {
            color: colors.gray[800],
          },
        },
      }),
      solid: ({ theme: { colors } }) => ({
        _pressed: {
          bg: colors.gray[500],
        },
      }),
    },
  },
};
