/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Box, Center, Heading, Text, Toast } from 'native-base';
import { Dimensions, Platform } from 'react-native';

import {
  CommonActions,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import Tabs from 'components/Tabs';
import Button from 'components/Button';
import { SingleVehicleTypeProps, VehiclesProps } from 'types/transportation';
import { TabsProps } from 'types/general';
import { isEmptyArray } from 'utils/validators';
import { getItemStorage, pickBy } from 'utils';
import { createVehiclesObjectMap, createVehicleTabs } from 'utils/vehicles';
import Context from 'context/Vehicles';
import { CreateUserVehicle, GetVehicles, GetVehiclesType } from 'api';
import BackWeb from 'components/BackWeb';
import useCommon from 'webdesign/useCommon';
import { isWebDesktop } from 'utils/general';
import TransportationCars from './TransportationCars';
import TransportationAddOther from './TransportationAddOther';

const otherVehicle = {
  id: Math.random().toString(16).slice(2),
  inserted_at: new Date(),
  name: 'OTRO',
  updated_at: new Date(),
};

export default function TransportationAdd() {
  const navigation = useNavigation();
  const { params, key, path } = useRoute();
  const { vehicleIds, setVehicleIds, setCurrentVehicles } = useContext(Context);
  const { screen, title } = (params as {
    screen: string;
    title: 'MADRINA' | 'OTRO';
  }) || {
    screen: undefined,
    title: undefined,
  };
  const [tabs, setTabs] = useState<TabsProps[]>([]);

  const { width } = Dimensions.get('window');
  const isDesktopWeb = Platform.OS === 'web' && width > 1200;
  
  const from = key || '';
  const isOtherTab = title === 'OTRO';

  const {
    containerPt,
    containerPx,
    marginLeft,
    flexAlign,
    cardBg,
    cardPx,
    cardShadow,
    cardPy,
  } = useCommon();

  const { data: dataVehiclesType = [] } = useQuery<SingleVehicleTypeProps[]>(
    'vehiclesType',
    async () => {
      return GetVehiclesType();
    },
    {
      refetchOnWindowFocus: false,
      onError: () => {
        Toast.show({
          title: 'Ocurrio un error. Intenta de nuevo',
          placement: 'top',
        });
      },
    },
  );
  useQuery(
    'vehicles',
    async () => {
      return GetVehicles();
    },
    {
      enabled: !isEmptyArray(dataVehiclesType),
      onSuccess: (data: VehiclesProps[]) => {
        handleVehiclesTabs(data);
      },
      onError: () => {
        Toast.show({
          title: 'Ocurrio un error. Intenta de nuevo',
          placement: 'top',
        });
      },
    },
  );
  const { isLoading, mutate } = useMutation(CreateUserVehicle, {
    onSuccess: () => {
      const paramsToSend = {
        screen: screen || 'home',
      };
      setVehicleIds([]);
      Toast.show({
        title: `Vehículos añadidos correctamente a tu catálogo.`,
        placement: 'top',
      });

      if (isDesktopWeb) {
        return navigation.goBack()
      }

      navigation.dispatch(
        CommonActions.navigate({
          name: from,
          key: 'TransportationCars',
          params: pickBy(paramsToSend),
        }),
      );
    },
    onError: () => {
      Toast.show({
        title: 'Hubo un error al añadir los vehículos, intenta nuevamente.',
        placement: 'top',
      });
    },
  });

  useEffect(() => {
    return () => setVehicleIds([]);
  }, []);

  const handleSaveVehicles = async () => {
    const { id } = JSON.parse(await getItemStorage('session')) || {};
    mutate({ params: id, dataToSend: { vehicles: vehicleIds } });
  };

  const handleVehiclesTabs = (dataVehicles: VehiclesProps[]) => {
    const dataVehiclesTypes = [...dataVehiclesType, otherVehicle];
    const vehicleTabs = createVehicleTabs(dataVehiclesTypes);
    const vehiclesTabViewer = createVehiclesObjectMap(
      dataVehiclesType,
      dataVehicles,
    );
    setCurrentVehicles(vehiclesTabViewer);
    const vehicleTabsWithComponent: TabsProps[] = vehicleTabs.map(
      (tab: Partial<TabsProps>) => ({
        ...(tab as TabsProps),
        component: () => {
          const hasOtherTab = tab.title === 'OTRO';
          if (hasOtherTab) {
            return <TransportationAddOther />;
          }
          return (
            <TransportationCars
              vehicles={vehiclesTabViewer[tab.key]}
              isTransportist
            />
          );
        },
      }),
    );
    setTabs(vehicleTabsWithComponent);
  };

  const handleSwipe = ({ title: tabTitle }: { title: string }) => {
    navigation.dispatch(CommonActions.setParams({ title: tabTitle }));
  };

  return (
    <Box
      flex="1"
      _web={{
        pt: containerPt,
        px: containerPx,
        justifyContent: 'center',
        alignItems: path && flexAlign,
      }}
    >
      <Box
        flex="1"
        width="100%"
        maxWidth="650px"
        mb={4}
        _web={{
          py: cardPy,
          px: cardPx,
          borderRadius: 20,
          bg: cardBg,
          shadow: cardShadow,
          marginLeft: path ? marginLeft : 0,
        }}
      >
        {isWebDesktop && <BackWeb />}
        <Box px={5} mt={Platform.OS === 'web' ? 4 : 0}>
          <Heading fontSize="2xl" fontWeight="normal" mb={4} color="gray.700">
            Catálogo de Transportes
          </Heading>
          <Text fontSize="md" color="gray.600">
            ¿Qué tipo de camiones tienes?
          </Text>
        </Box>
        <Box flex="1">
          {!isEmptyArray(tabs) ? (
            <Tabs tabs={tabs} handleSwipe={handleSwipe} />
          ) : null}
        </Box>
        {from === 'Transportation' && !isOtherTab ? (
          <Center mb={5}>
            <Button
              width="80%"
              button="primary"
              isDisabled={isLoading || isEmptyArray(vehicleIds)}
              onPress={handleSaveVehicles}
            >
              Guardar
            </Button>
          </Center>
        ) : null}
      </Box>
    </Box>
  );
}
